import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/shared/api/auth.service';
import { MessageService } from 'src/app/shared/api/message.service';
import { StaticData } from 'src/app/shared/constants/constant';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Profile } from '../../models/signup.model';
import { DataService } from '../../public/service/data.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CommentComponent } from '../comment/comment.component';
import { ShareComponent } from '../share/share.component';
import { PhotoVideoUploadComponent } from '../photo-video-upload/photo-video-upload.component';

//Added by Poonam
import { TemplateRef } from '@angular/core';
import { SightengineService } from 'src/app/shared/services/sightengine.service';

//Added by Poonam




@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})

export class ProfileComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('profileUpload', { static: false }) profileUpload: ElementRef;
  @ViewChild('editPostDialog') editPostDialog!: TemplateRef<any>;

  editPostForm: FormGroup;
  selectedPost: any = {};
  tempImageUrl: string | ArrayBuffer | null = null;
  tempVideoUrl: string | ArrayBuffer | null = null;
  form: FormGroup;
  containsBannedWords: boolean = false;
  files = [];
  public userDetails: any;
  public userType: any;
  profileForm = this.initForm();
  public countryList: any[] = [];
  public states: any[] = [];
  public genderList: any[];
  public ageList: any[];
  public educationLevelList: any[];
  public personalityList: any[];
  public lookingForList: any[];
  public exerciseHabitsList: any[];
  public alcoholUseList: any[];
  public drugUseList: any[];
  public politicalViewsList: any[];
  public religionList: any[];
  public isEdit: boolean;
  public isPicExists: string;
  public myPosts: any;
  public profileImage:any;
  public topimagesandvideodetails:any;
  imageUrl: string;
  previewClass: string;
  clickIndex: number;
  copyText: string = "Copy Profile URL";
  clickBlurIndex: number;
  menuOpen: boolean = false;
  temporaryfile: any;
  temptype: string;
  baseUrl: string;
  isButtonDisabled: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private sightengineService: SightengineService

  ) {
    this.previewClass = 'as';
    this.countryList = StaticData.countries;
    this.genderList = StaticData.Gender;
    this.ageList = StaticData.age;
    this.educationLevelList = StaticData.educationLevel;
    this.personalityList = StaticData.personality;
    this.lookingForList = StaticData.lookingFor;
    this.exerciseHabitsList = StaticData.exerciseHabits;
    this.alcoholUseList = StaticData.alcohalUse;
    this.drugUseList = StaticData.drugUse;
    this.politicalViewsList = StaticData.politicalViews;
    this.religionList = StaticData.religion;
    this.isEdit = false;
    this.getMyPost();
    this.imageUrl = environment.rearGuardImageUri;
    this.baseUrl = environment.rearGuardImageUri;
    this.clickBlurIndex = -1;
  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  ngOnInit(): void {
    this.loadUserProfile();
  }
  
  private loadUserProfile(): void {
    this.dataService.GetProfile().subscribe(
      (result: any) => {
        if (result) {
          this.updateLocalStorage(result.data.customerData);
          this.updateUserDetails();
          this.getTopImagesAndVideos();
        }
      },
      (error) => {
        console.error("Error fetching profile:", error);
      }
    );
  }
  
  private updateLocalStorage(userData: any): void {
    const storedUserData = JSON.parse(localStorage.getItem('loggedInUser') || '{}');
    storedUserData.usertype = userData.usertype;
    localStorage.setItem('userData', JSON.stringify(storedUserData));
    
    const sessionUserData = JSON.parse(sessionStorage.getItem('loggedInUser') || '{}');
    sessionUserData.usertype = userData.usertype;
    sessionStorage.setItem('sessionData', JSON.stringify(sessionUserData));
  }
  
  private updateUserDetails(): void {
    this.userDetails = this.authService.getloggedUserDetails();
    this.userType = this.userDetails.usertype;
    this.profileImage = this.userDetails.imageURL.original;
    this.isPicExists = this.userDetails.bannerURL.original
      ? 'https://natural.selectnaturally.com/' + this.userDetails.bannerURL.original
      : '../../../../../assets/images/banner1.jpg';
  }
  
  private getTopImagesAndVideos(): void {
    this.dataService.GetProfile().subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.topimagesandvideodetails = result.data.customerData;
      }
    });
  }
  

  removeBlur(i) {
    this.clickBlurIndex = i;
  }
  toggleClass(className, i) {
    if (className === 'as') {
      this.previewClass = 'ash';
    } else {
      this.previewClass = 'as';
    }
    this.clickIndex = i;
  }
  navigateToProfile() {
    this.router.navigateByUrl('/profile');
  }

  profilePicChange() {
    const profileUpload = this.profileUpload.nativeElement;
    console.log(this.profileUpload)
    profileUpload.onchange = () => {
      const file = profileUpload.files[0];
      this.sendFile(file);
    };
    profileUpload.click();
  }

  sendFile(file) {
    const formData = new FormData();
    formData.append('document', file);
    this.dataService.uploadProfilePic(formData).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Image Upload Succesfully', '');
        sessionStorage.setItem('loggedInUser', JSON.stringify(result.data));
        localStorage.setItem('loggedInUser', JSON.stringify(result.data));
        this.messageService.sendMessage(true);
        this.bindDetails();
      } else {
        this.notifyService.showWarning(result.message, '');
      }
    });
  }
  onClick(){
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      this.uploadBanner(file);
    };
    fileUpload.click();
  }

  uploadBanner(file) {
    const formData = new FormData();
    formData.append('document', file);
    this.dataService.uploadBannerImage(formData).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Banner Upload Succesfully', '');
        sessionStorage.setItem('loggedInUser', JSON.stringify(result.data));
        localStorage.setItem('loggedInUser', JSON.stringify(result.data));
        this.messageService.sendMessage(true);
        this.bindDetails();
      } else {
        this.notifyService.showWarning(result.message, '');
      }
    });
  }

  editProfile() {
    this.isEdit = true;
    if (this.userType === "0") {
      this.profileForm = this.fb.group({
        fullName: [this.userDetails.fullName],
        country: [this.userDetails.country],
        gender: [this.userDetails.gender],
        about:[this.userDetails.about],
        state: [this.userDetails.state],
        city: [this.userDetails.city],
        email: [this.userDetails.email],
        phoneNumber: [this.userDetails.phoneNumber],
      });
    } else {
      this.profileForm = this.fb.group({
        fullName: [this.userDetails.fullName],
        country: [this.userDetails.country],
        gender: [this.userDetails.gender],
        about:[this.userDetails.about],
        state: [this.userDetails.state],
        city: [this.userDetails.city],
        email: [this.userDetails.email],
        dob: [this.userDetails.dob],
        age: [this.userDetails.age],
        occupation: [this.userDetails.occupation],
        education_level: [this.userDetails.education_level],
        personality: [this.userDetails.personality],
        looking_for: [this.userDetails.looking_for],
        interests: [this.userDetails.interests],
        hobbies: [this.userDetails.hobbies],
        exercise_habits: [this.userDetails.exercise_habits],
        alcohol_use: [this.userDetails.alcohol_use],
        drug_use: [this.userDetails.drug_use],
        political_views: [this.userDetails.political_views],
        religion: [this.userDetails.religion],
        phoneNumber: [this.userDetails.phoneNumber],
      });
    }
  }
  resetForm() {
    this.isEdit = false;
  }
  updateProfile() {
    const profile = new Profile();
    if (this.userType === "0") {
      profile.fullName = this.profileForm.value.fullName;
      profile.about = this.profileForm.value.about;
      profile.city = this.profileForm.value.city;
      profile.state = this.profileForm.value.state;
      profile.country = this.profileForm.value.country;
      profile.gender = this.profileForm.value.gender;
      profile.phoneNumber = this.profileForm.value.phoneNumber;
    } else {
      profile.fullName = this.profileForm.value.fullName;
      profile.about = this.profileForm.value.about;
      profile.city = this.profileForm.value.city;
      profile.state = this.profileForm.value.state;
      profile.country = this.profileForm.value.country;
      profile.gender = this.profileForm.value.gender;
      profile.age = this.profileForm.value.age;
      profile.occupation = this.profileForm.value.occupation;
      profile.education_level = this.profileForm.value.education_level;
      profile.personality = this.profileForm.value.personality;
      profile.looking_for = this.profileForm.value.looking_for;
      profile.interests = this.profileForm.value.interests;
      profile.exercise_habits = this.profileForm.value.exercise_habits;
      profile.alcohol_use = this.profileForm.value.alcohol_use;
      profile.drug_use = this.profileForm.value.drug_use;
      profile.political_views = this.profileForm.value.political_views;
      profile.religion = this.profileForm.value.religion;
      profile.phoneNumber = this.profileForm.value.phoneNumber;
    }
    this.dataService.saveProfileDate(profile).subscribe((result) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Success', '');
        sessionStorage.setItem(
          'loggedInUser',
          JSON.stringify(result.data.customerData)
        );

        localStorage.setItem(
          'loggedInUser',
          JSON.stringify(result.data.customerData)
        );

        this.isEdit = false;
        this.bindDetails();
        this.messageService.sendMessage(true);
      } else {
        this.notifyService.showWarning(result.message, '');
      }
    });
  }
  getMyPost() {
    this.dataService.getMyPost().subscribe((result) => {
      if (result && result.message === 'Success') {
        this.myPosts = result.data.myPost;
      }
      
    });
  }

  copyMessage() {
    var user = this.authService.getloggedUserDetails();


    var urlToCopy = document.location.origin + '/profile/' + (user ? user._id : "");
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = urlToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copyText = "Profile URL Copied";
    setTimeout(function () {
      this.copyText = "Copy Profile URL";
    }.bind(this), 3000);
  }
  private initForm() {
    if (this.userType === "0") {
      return this.fb.group({
        fullName: ['', Validators.required],
        country: ['', Validators.required],
        gender: ['', Validators.required],
        about: [''],
        state: [''],
        city: ['', Validators.required],
        email: ['', Validators.required],
        phoneNumber: ['', [Validators.required, Validators.maxLength(10)]],
      });
    } else {
      return this.fb.group({
        fullName: ['', Validators.required],
        country: ['', Validators.required],
        gender: ['', Validators.required],
        about: [''],
        state: [''],
        city: ['', Validators.required],
        email: ['', Validators.required],
        age: ['', Validators.required],
        occupation: [''],
        education_level: ['', Validators.required],
        personality: [''],
        looking_for: ['', Validators.required],
        interests: [''],
        exercise_habits: [''],
        alcohol_use: [''],
        drug_use: [''],
        political_views: [''],
        religion: [''],
        phoneNumber: ['', [Validators.required, Validators.maxLength(10)]],
      });
    }

  }

  onCountryChange(countryName: string) {
    const extractedCountryName = countryName.split(':')[1]?.trim() || '';

    const selectedCountry = this.countryList.find((country: any) =>
      country.name === extractedCountryName);

    if (selectedCountry) {
      this.states = selectedCountry.states;
    } else {
      this.states = [];
      console.error('Selected country not found in countryList:', extractedCountryName);
    }
    // Reset the state selection when the country changes
    this.profileForm.get('state')?.setValue('');
  }


  private bindDetails() {
    this.userDetails = this.authService.getloggedUserDetails();
    this.profileImage = this.userDetails.imageURL.original;
    this.isPicExists =
      this.userDetails.bannerURL.original !== ''
        ? 'https://natural.selectnaturally.com/' +
        this.userDetails.bannerURL.original
        : '../../../../../assets/images/banner1.jpg';
  }
  openDialog(postId, item, index, type) {
    this.dataService.getPostComment(postId).subscribe((commentResult: any) => {
      if (commentResult && commentResult.message === 'Success') {
        if (
          commentResult &&
          commentResult.data &&
          commentResult.data.comments
        ) {
          const json = { postDetail: [], commentDetail: [], index: 0 };
          json.postDetail = item;
          json.commentDetail = commentResult.data.comments;
          json.index = index;

          const dialogRef = this.dialog.open(CommentComponent, {
            data: json,
            height: '600px',
            width: '700px',
            autoFocus: false,
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe((data) => {

            this.myPosts[index].totalComment = data.count;
            this.myPosts[index].comment = data.comment;

          });
        }
      }
    });
  }
  saveComment(comment, i, postId) {
    this.dataService.postComment(postId, comment).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Comment added successfully.', '');
      }
    });
  }
  onKeydown(event) {
    event.preventDefault();
  }
  // updated by Anjali 12/07/24
  UpdateLikeStatus(id, status, index) {
    let likeStatus: boolean = !status;

    this.dataService.postLike(id, likeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Post Like status updated.', '');
        if (likeStatus === false) {
          this.myPosts[index].totalLike =
            this.myPosts[index].totalLike === 0
              ? 0
              : this.myPosts[index].totalLike - 1;
        } else {
          this.myPosts[index].totalLike++;

          if (this.myPosts[index].isDislike) {
            this.myPosts[index].totalDislike =
              this.myPosts[index].totalDislike === 0
                ? 0
                : this.myPosts[index].totalDislike - 1;
            this.myPosts[index].isDislike = false;
          }
        }
        this.myPosts[index].isLike = likeStatus;
      }
    });
  }
  UpdateDislikeStatus(id, status, index) {

    let dislikeStatus: boolean = !status;

    this.dataService.postDislike(id, dislikeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Post Like status updated.', '');
        if (dislikeStatus === false) {
          this.myPosts[index].totalDislike =
            this.myPosts[index].totalDislike === 0
              ? 0
              : this.myPosts[index].totalDislike - 1;
        } else {
          this.myPosts[index].totalDislike++;
          if (this.myPosts[index].isLike) {
            this.myPosts[index].totalLike =
              this.myPosts[index].totalLike === 0
                ? 0
                : this.myPosts[index].totalLike - 1;
            this.myPosts[index].isLike = false;
          }
        }
        this.myPosts[index].isDislike = dislikeStatus;
        // 
      }

    });
  }
  // -------

  openShareDialog(id, index) {

    const dialogRef = this.dialog.open(ShareComponent, {
      height: '350px',
      width: '700px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom',
      data: id,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getMyPost();
    });
  }

  deletePost(id, index) {
    this.previewClass = 'as';
    this.dataService.deletePost(id).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Post deleted successfully.', '');
        this.myPosts.splice(index, 1);
      }
    });
  }

  openEditDialog(id, index): void {

    this.selectedPost = this.myPosts.find(post => post._id === id);
    this.dialog.open(this.editPostDialog, {
      width: '400px'
    });
  }
  uploadFile(type: string) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = type === 'image' ? 'image/*' : 'video/*';
    fileInput.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file) {

        const reader = new FileReader();
        reader.onload = async (e: any) => {
          if (type === 'image') {
            this.sightengineService.checkImage(file, 'nudity-2.1').subscribe(
              response => {
                if (response.nudity.none >= '0.90') {
                  this.tempImageUrl = e.target.result;
                  this.tempVideoUrl = '';
                }
                else {
                  this.notifyService.showInfo(
                    'This image contains inappropriate content. Please select image again.',
                    ''
                  );
                }
              }
            );
          }
          else if (type === 'video') {
            this.isButtonDisabled=true;
            const videoDuration = await this.getVideoDuration(file);
            if (videoDuration > 60) {
              let framecheck = true;
              const frames = await this.extractFramesFromVideo(file, 1); // Extract frames at 1 FPS
              console.log(frames);
              for (let frame of frames) {
                this.sightengineService.checkImage(this.base64ToFile(frame,'xyz.png'), 'nudity-2.1').subscribe(
                  response => {
                    if(response.nudity.none>='0.90'){
                    // this.uploadedFile.push(fileUpload.files[index]);
                  }
                  else{
                    framecheck=false;           
                  }       
                  },
                  error => {
                    framecheck=false;
                  }       
              )
             if(!framecheck ){
              break;
             }
            }
            if(!framecheck){
              this.notifyService.showInfo('Video contains inappropriate content', '');
            }
            else{
              console.log('Done');
              this.isButtonDisabled=false;
              this.tempVideoUrl = e.target.result;
              this.tempImageUrl = '';
            }
            }
            else{
            let istrue: Boolean = false;
            this.sightengineService.checkvideo(file, 'nudity-2.1').subscribe(
              response => {
                for (let i = 0; i < response.data.frames.length; i++) {
                  const frame = response.data.frames[i];
                  let nudity = frame.nudity.none;
                  if (nudity < '0.90') {
                    istrue = true;
                  }
                }

                if (!istrue) {
                  this.tempVideoUrl = e.target.result;
                  this.tempImageUrl = '';
                }
                else {
                  this.notifyService.showInfo(
                    'video contain inappropriate content.',
                    ''
                  );
                }
              },
              error => {
                this.notifyService.showInfo(
                  'A video cannot be uploaded',
                  ''
                );
              }
            )
          }}
        };
        this.temporaryfile = file;
        this.temptype = type;
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  }

  
base64ToFile(base64: string, filename: string): File {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

async getVideoDuration(file: File): Promise<number> {
  return new Promise<number>((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.src = URL.createObjectURL(file);
  });
}

async extractFramesFromVideo(file: File, frameRate: number): Promise<string[]> {
  console.log("extractFramesFromVideo");
  return new Promise<string[]>((resolve) => {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const frames: string[] = [];

    video.src = URL.createObjectURL(file);
    video.muted = true;
    video.play();

    video.onloadeddata = () => {
      const interval = 1 / frameRate;
      const duration = video.duration;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      let currentTime = 0;

      const captureFrame = () => {
        if (currentTime < duration) {
          video.currentTime = currentTime;
          currentTime += interval;
        } else {
          video.pause();
          resolve(frames);
          return;
        }
      };

      video.ontimeupdate = () => {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataURL = canvas.toDataURL('image/png');
        frames.push(dataURL);
        captureFrame();
      };

      captureFrame();
    };
  });
}

  onSaveClick(data) {

    this.containsBannedWords = this.sightengineService.checkContent(this.selectedPost.postTitle);

    if (!this.containsBannedWords) {
      const formData = new FormData();

      formData.append('postId', this.selectedPost._id);
      formData.append('postTitle', this.selectedPost.postTitle);
      formData.append('postContent', this.selectedPost.postTitle);
      formData.append('postType', this.selectedPost.postType);
      if (this.selectedPost.taggedFriendList.length > 0) {
        formData.append('tagFriendList', JSON.stringify(this.selectedPost.taggedFriendList));
      }
      if (this.temptype !== 'image' && this.temptype !== 'video' && this.selectedPost.imageURL.original === null && this.selectedPost.videoThumbnailUrl.original === null) {
        formData.append('isMediaFileUploaded', 'false');
        formData.append('isMediaTypeVideo', 'false');
      }
      if (this.temptype === 'image') {
        formData.append('isMediaFileUploaded', 'true');
        formData.append('isMediaTypeVideo', 'false');
        if (this.temporaryfile === null) {
          formData.append('mediaFile', this.selectedPost.imageURL.original);
        }
        else {
          formData.append('mediaFile', this.temporaryfile);
        }
        // formData.append('mediaFile', this.temporaryfile);
      }
      if (this.temptype === 'video') {
        formData.append('isMediaFileUploaded', 'true');
        formData.append('isMediaTypeVideo', 'true');
        if (this.temporaryfile === null) {
          formData.append('mediaFile', this.selectedPost.videoThumbnailUrl.original);
        }
        else {
          formData.append('mediaFile', this.temporaryfile);
        }
        // formData.append('mediaFile', this.temporaryfile);
      }
      this.dataService.UpdatePost(formData).subscribe((result: any) => {
        this.previewClass = 'as';
        this.notifyService.showSuccess('Post updated successfully.', '');
        this.getMyPost();
      });
    }
    else {
      this.notifyService.showSuccess('Post contains inappropriate content.', '');
    }
  }

  closeComment() {
    this.previewClass = 'as';
    this.getMyPost();
    this.dialog.closeAll();
  }

  openImageVideoUploadDialog(item) {
    console.log(item)
    console.log(this.userDetails);
    const json = { request: item, data: this.topimagesandvideodetails };
    const dialogRef = this.dialog.open(PhotoVideoUploadComponent, {
      height: '400px',
      width: '700px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'customvideoupload',
      data: json,
    });
  }

}


