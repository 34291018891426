
<form style="padding: 20px;">
    <div class="form-group">
        <p>Invite your Freinds</p>
      <label for="exampleInputEmail1">your Freind's Email<span style="color:red;">*</span></label>
      <input type="text" class="form-control" aria-describedby="titleHelp" name="email" [(ngModel)]="email" placeholder="Enter your friend email">
      <small id="titleHelp" class="form-text text-muted">Please enter your friend's  email.</small>
    </div>
    <div class="form-group">
       
      <label for="exampleInputEmail1">Message</label>
      <input type="text" class="form-control"  name="message" [(ngModel)]="message" placeholder="Enter some message to your friend">
      
    </div>
    <button type="submit"  (click)="inviteuser()" style="margin-left: 5px;" class="btn btn-primary">Submit</button>
  </form>