<a class="close-button" style="cursor: pointer;" (click)="closeComment()"><i class="fa fa-close"
        style="color: #0041c1"></i></a>
<a *ngIf="!isGroupMember" class="back-button" style="cursor: pointer;" (click)="goback()"><i
        class="fa fa-arrow-circle-left" style="color: #0041c1"></i></a>
<h2 mat-dialog-title class="title">{{title}}</h2>
<div class="container">
    <div class="row">
        <div class="col-md-12 col-12">
            <div class="public-post" *ngIf="isGroupMember">

                <p style=" cursor: pointer;margin-left: -358px;" (click)="showAddMember()"><i _ngcontent-rli-c83=""
                        aria-hidden="true" class="fa fa-user-plus"></i>
                    Add Members
                </p>

            </div>
            <div class="add-friends" *ngIf="isGroupMember">
                <div class="tab-content">
                    <div class="friends-list" *ngFor="let item of groupMembers;let i = index">
                        <div *ngIf="item && item.groupMemberId && item.groupMemberId.email != 'email@genderfreaks.com'" class="friends-list-inner">
                            <div class="friends-list-image" *ngIf="item && item.groupMemberId && item.groupMemberId.imageURL" style="cursor: pointer;">
                                <img src="{{imageUrl}}/{{item.groupMemberId.imageURL.thumbnail}}">
                            </div>
                            <div class="friends-list-details" style="cursor: pointer;">
                                <p>{{item.groupMemberId.fullName}}
                                   <!--<span class="emailid">{{item.groupMemberId.email}}</span>--> 

                                </p>

                            </div>
                            <div class="friends-list-add" *ngIf="item.memberType === 'Admin'">
                                {{item.memberType}}

                            </div>
                            <div class="friends-list-add" *ngIf="item.memberType !== 'Admin'">
                                <a (click)="toggleClass(previewClass,i)"><i class=" fa fa-ellipsis-v delete"
                                        aria-hidden="true">
                                    </i></a>
                                <span *ngIf="previewClass==='ash' && clickIndex === i" class="ash"><a
                                        (click)="deleteMember(item,i)">Delete this member from
                                        Group</a></span>
                            </div>


                        </div>

                    </div>
                </div>

            </div>
            <div class="friends-heading" *ngIf="!isGroupMember">
                <form [formGroup]="searchFriendForm">
                    <div class="search-Friends">
                        <input type="text" placeholder="search" name="searchValue" (input)="searchFriend()"
                            formControlName="searchValue">
                        <i class="fa fa-search" aria-hidden="true" (click)="searchFriend()"></i>

                    </div>
                </form>
            </div>
            <div class="add-friends" *ngIf="!isGroupMember">
                <div class="tab-content">
                    <div id="home">

                        <div class="friends-list">
                            <div class="friends-list-inner" *ngFor="let item of friendsList;let i = index">
                                <div class="friends-list-image" style="cursor: pointer;">
                                    <img src="{{imageUrl}}/{{item.imageURL.thumbnail}}">
                                </div>
                                <div class="friends-list-details" style="cursor: pointer;">
                                    <p>{{item.fullName}}
                                        <!--<span class="emailid">{{item.email}}</span>-->
                                    </p>
                                </div>
                                <div class="friends-list-add">
                                    <button (click)="AddFriend(item,i)">Add</button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>