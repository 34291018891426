import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/components/public/service/data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

import { AuthService } from 'src/app/shared/api/auth.service';

@Component({
  selector: 'app-create-private-group',
  templateUrl: './create-private-group.component.html',
  styleUrls: ['./create-private-group.component.css']
})
export class CreatePrivateGroupComponent implements OnInit {
  public userDetails: any;
  createGroupForm = this.initForm();
  processing: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CreatePrivateGroupComponent>,
    private dataService: DataService,
    private authService: AuthService,
    private notifyService: NotificationService
  ) {

    this.userDetails = this.authService.getloggedUserDetails();

  }

  ngOnInit(): void {}

  private initForm() {
    return this.fb.group({
      groupName: ['', Validators.required],
      description: ['']
    });
  }
  closeComment() {
    this.dialogRef.close();
  }

  createGroup() {
    if (this.createGroupForm.invalid) {
      return;
    }
    this.processing = true;
    var data = {
      authorization: this.userDetails.accessToken,
      isPrivate:true,
      name:this.createGroupForm.value.groupName,
      description:this.createGroupForm.value.description
    };

    this.dataService
      .createGroup(data)
      .subscribe((result: any) => {
        if (result && result.Message === 'Created Successfully') {
          this.notifyService.showSuccess('Private Group Created Successfully.', '');
          setTimeout(() => {
            this.dialogRef.close(true);
          }, 500);
        }
      });
  }
}
