<a class="close-button" style="cursor: pointer;" (click)="closeComment()"><i class="fa fa-close"
    style="color: #0041c1"></i></a>
<h2 mat-dialog-title class="title">Edit Group</h2>

<mat-dialog-content>
<div class="row" style="border-top: 1px solid;">
    <div class="col-md-12 col-12">
        <form [formGroup]="createGroupForm">
            <div class="form-group">
                
                <input type="hidden" class="form-control groupname" placeholder="id" name="_id"
                    formControlName="_id">
                <input type="text" class="form-control groupname" placeholder="Enter Group Name" name="groupName"
                    formControlName="groupName">
                <textarea class="form-control groupname" placeholder="Enter Group Description" name="description"
                    formControlName="description"></textarea>
                <button id="done" style="margin-top: 40px;" class="btn info" [disabled]="!createGroupForm.valid"
                    (click)="editGroup()">Done</button>
            </div>

        </form>

    </div>
</div>
</mat-dialog-content>