export class Login {
  email: string;
  password: string;
  deviceType: string;
  deviceToken: string;
  latitude: number;
  longitude: number;
}
export class LoginWithGoogle {
  email: string;
  googleId: string;
  deviceType: string;
  deviceToken: string;
  latitude: number;
  longitude: number;
}
