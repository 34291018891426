import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { AppComponent } from 'src/app/app.component';
import { SearchFriend } from 'src/app/components/models/friend.model';
import { DataService } from 'src/app/components/public/service/data.service';
import { AuthService } from 'src/app/shared/api/auth.service';
import { MessageService } from 'src/app/shared/api/message.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CallingComponent } from '../../calling/calling.component';
import { filter } from 'rxjs/operators';
import { FriendRequest } from '../../../models/friend.model';
import { loadStripe, Stripe, StripeCardNumberElement, StripeElements } from '@stripe/stripe-js';
import { chagetype, GeTbALANCE, PyamentMethodImpl } from 'src/app/components/models/signup.model';

@Component({
  selector: 'app-private-container',
  templateUrl: './private-container.component.html',
  styleUrls: ['./private-container.component.css'],
})
export class PrivateContainerComponent implements OnInit {
  public userDetails: any;
  public updatedUserDetails: any;
  subscription: Subscription;
  public dropDownNavMenu: string;
  public dropDownNavMenuChild: string;

  public dropDownVideoNavMenu: string;
  public dropDownVideoNavMenuChild: string;
  public isPicExists: string;
  searchMasterForm = this.initForm();
  public isSearchTabVisible: string;
  public isSearchResultVisible: string;
  public isdashboardVisible: string;
  public showloader: string;
  public showResponseClass: string;
  public imageURl: string;
  public notificationCount: number;
  public chatCount: number;
  public searchFriendList: [];
  public showCallButton: boolean;
  public type: string;
  public previousUrl: string;
  public socket;
  public callRequestReceived: any;
  public showMessage: boolean = false;
  public isAddMore:boolean = false;
  public usersInCall:any = [];
  public chatUserList:any = [];
  public callType:string = 'i';
  public userGroups:any = [];
  public IsDatingUser:string=""; // added on 7 Aug 24
  public IsPaidUser:boolean=true;
  public allplans:any;
  public showPlans:boolean=false;
  public showPaymentDetails:boolean=false;
  selectedItem: any | null = null;
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  cardNumber: StripeCardNumberElement | null = null;
  public accesstoken:string="";
  private reloadSource = new Subject<void>();
  reload$ = this.reloadSource.asObservable();

  
  // tslint:disable-next-line:member-ordering
  public static returned: Subject<any> = new Subject();
  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private dataService: DataService,
    private notifyService: NotificationService,
    private fb: FormBuilder,
    private chatService: ChatService,
    private dialog: MatDialog,
    public messagingService: MessagingService
 ) {
    this.imageURl = environment.rearGuardImageUri;
    this.socket = io(environment.rearGuradSocketBaseUri);
    this.showCallButton = false;
    this.getNotifcation();
    this.getChatCount();
    setInterval(function(){
      this.getChatCount();
    }.bind(this), 30000);
    // this.socket.on('call-request-received', (result) => {
    //   this.callRequestReceived = result;
    // });

    this.socket.on('call-request-received', (result) => {
      console.log('>>>>>>>Private Compoennt Called>>>>>');
      this.userDetails = this.authService.getloggedUserDetails();
      if (result.userid === this.userDetails._id) {
        localStorage.setItem('roomId', result.roomId);
        this.type = result.type;
        this.showCallButton = true;

        setTimeout(() => {
          this.notifyService.showInfo(
            result.callingUsername + ' is calling you.Please Pick the Call.',
            ''
          );
          const dialogRef = this.dialog.open(CallingComponent, {
            height: '200px',
            width: '400px',
            autoFocus: false,
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res === 'Accept') {
              this.Acceptcall();
            }
            if (res === 'Decline') {
              this.notifyService.showInfo('Call Declined..........', '');
            }
          });
        }, 2000);
      }
    });
    this.dropDownNavMenu = 'nav-item dropdown';
    this.dropDownNavMenuChild = 'dropdown-menu profile-dropdown';
    this.dropDownVideoNavMenu = 'nav-item dropdown';
    this.dropDownVideoNavMenuChild = 'dropdown-menu profile-dropdown';
    this.isSearchTabVisible = 'main-body';
    this.isdashboardVisible = 'dashboard';
    this.isSearchResultVisible = 'main-body-None';
    this.showloader = 'notloaderImage';
    this.showResponseClass = 'emptyResponseHide';

    this.subscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message.text === true) {
          this.userDetails = this.authService.getloggedUserDetails();
          this.isPicExists =
            this.userDetails.imageURL.original !== ''
              ? 'https://natural.selectnaturally.com/' +
                this.userDetails.imageURL.original
              : '../../../../../assets/images/user.jfif';

             
        } else {
          this.getNotifcation();
        }
      });
  }
  private initForm() {
    return this.fb.group({
      searchValue: ['', Validators.required],
    });
  }

  ngOnInit(): void {
   
    
    console.log("================================");
    this.userDetails = this.authService.getloggedUserDetails();

    this.dataService.GetProfile().subscribe(
        (result: any) => {
            if (result) {
                    this.updatedUserDetails = result.data.customerData;
                    // Added on 7 Aug 24
                    this.IsDatingUser = this.updatedUserDetails.usertype;

                    // Retrieve existing data from localStorage
                    const userData = JSON.parse(localStorage.getItem('loggedInUser') || '{}');

                    // Update specific fields
                    userData.usertype = this.updatedUserDetails.usertype;                    

                    // Save updated data back to localStorage
                    localStorage.setItem('userData', JSON.stringify(userData));

                  // Retrieve existing data from sessionStorage
                  const sessionData = JSON.parse(sessionStorage.getItem('loggedInUser') || '{}');

                  // Update specific fields
                  sessionData.usertype  = this.updatedUserDetails.usertype;  

                  // Save updated data back to sessionStorage
                  sessionStorage.setItem('sessionData', JSON.stringify(sessionData));



                if (this.updatedUserDetails.usertype == 1) {
                    const servicecall = new GeTbALANCE();
                    servicecall.authorization = this.userDetails.accessToken;

                    this.dataService.CheckPayment(servicecall).subscribe((result: any) => {
                        if (result.Data != null) {
                            
                        }
                        else{
                          console.log(result.Data);
                            this.IsPaidUser = false;
                            this.showPlans = true;

                            this.dataService.GetStripePlans().subscribe(
                                (planData: any) => {
                                    this.allplans = planData.data;
                                    this.selectedItem = this.allplans[0];
                                    this.showPlans = true;
                                },
                                (error) => {
                                    console.error(error);
                                }
                            );
                        }
                    });
                }

                console.log("User Details");
                console.log(this.userDetails);

                if (this.userDetails.imageURL.original === null) {
                    this.showMessage = true;
                }

                if (this.userDetails && this.userDetails.accessToken) {
                    this.messagingService.requestPermissionToken().subscribe(
                        (token) => {
                            console.log("==>>>", token);
                            localStorage.setItem("deviceToken", token);

                            this.dataService.updateDeviceToken({
                                "DeviceTokenString": token,
                                "uid": localStorage.getItem("uid")
                            }).subscribe((res) => {
                                // Handle response if necessary
                            });
                        }
                    );

                    this.messagingService.receiveMessage();
                    this.isPicExists = this.userDetails.imageURL.original !== ''
                        ? 'https://natural.selectnaturally.com/' + this.userDetails.imageURL.original
                        : '../../../../../assets/images/user.jfif';

                } else {
                    console.log("redirected");
                    this.router.navigateByUrl('/login');
                }
            }
        },
        (error) => {
            console.error("Error fetching profile:", error);
        }
    );
}

  navigateToProfile() {
    this.isSearchTabVisible = 'main-body';
    this.isdashboardVisible = 'dashboard';
    this.isSearchResultVisible = 'main-body-None';
    this.router.navigateByUrl('/profile');
  }
  navigateToGroup() {
    this.router.navigateByUrl('/group');
  }
  showNavMenu() {
    this.dropDownVideoNavMenu = 'nav-item dropdown';
      this.dropDownVideoNavMenuChild = 'dropdown-menu profile-dropdown';

    if (this.dropDownNavMenu.indexOf('show') >= 0) {
      this.dropDownNavMenu = 'nav-item dropdown';
      this.dropDownNavMenuChild = 'dropdown-menu profile-dropdown';
    } else {
      this.dropDownNavMenu = 'nav-item dropdown show';
      this.dropDownNavMenuChild = 'dropdown-menu profile-dropdown show';
    }
  }

  showVideoNavMenu() {
      this.dropDownNavMenu = 'nav-item dropdown';
      this.dropDownNavMenuChild = 'dropdown-menu profile-dropdown';
    if (this.dropDownVideoNavMenu.indexOf('show') >= 0) {
      this.dropDownVideoNavMenu = 'nav-item dropdown';
      this.dropDownVideoNavMenuChild = 'dropdown-menu profile-dropdown';
    } else {
      this.dropDownVideoNavMenu = 'nav-item dropdown show';
      this.dropDownVideoNavMenuChild = 'dropdown-menu profile-dropdown show';
    }
  }

  
addMore(){
  if(!this.usersInCall || this.usersInCall.length == 0){
      this.loadChatList();
      this.getGroupsByUserId();
    }
    else{
      this.isAddMore = true;
    }
  }

  loadChatList(){
    this.chatUserList = [];
    const searchFriend = new FriendRequest();
        searchFriend.limit = 0;
        searchFriend.skip = 0;
        searchFriend.status = 'Accepted';
      this.dataService.getFriendRequest(searchFriend).subscribe((result: any) => {
        if (result && result.statusCode === 200) {
          this.chatUserList = result.data.friendList;
  
          this.isAddMore = true;
        }
      });
    }

  
  getGroupsByUserId() {
    this.userGroups = [];
    this.dataService
      .getGroupsByUserId(this.userDetails._id)
      .subscribe((result: any) => {
        if (result && result.length > 0) {
          this.userGroups = result;
        } else {
          this.userGroups = [];
        }
      });
  }

  
  navigateToVideo(userGroup, event) {
    console.log(arguments);
    event.stopPropagation();
    this.isAddMore = false;
    if(userGroup.ExtraElements && userGroup.ExtraElements.isActive){
      this.router.navigateByUrl('/video/groupcall-join-'+userGroup._id);

    }
    else{
        this.router.navigateByUrl('/video/groupcall-'+userGroup._id);
    }
  }

  navigateToVideoIndividual() {
    this.isAddMore = false;
    this.router.navigateByUrl('/video/'+this.usersInCall.join(','));
  }

  closeAddMore(){
    this.isAddMore = false;
  }

  
  AddUser(frd){
    if(this.usersInCall.indexOf(frd._id) < 0){
      this.usersInCall.push(frd._id);
    }
  }

  RemoveUser(frd){
    if(this.usersInCall.indexOf(frd._id) > -1){
      this.usersInCall.splice(this.usersInCall.indexOf(frd._id), 1);
    }
  }

  logout() {
    this.dataService.setOnlineStatus(0).subscribe((res: any) => {
      this.dataService.logout().subscribe((result: any) => {
        if (result && result.message === 'Success') {
          this.notifyService.showSuccess('LogOut Successfully', '');
          sessionStorage.clear();
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }
      });
    });
  }
  public searchFriend() {
    const searchValue = this.searchMasterForm.value.searchValue;
    const searchFriend = new SearchFriend();
    this.searchFriendList = [];
    searchFriend.limit = 0;
    searchFriend.skip = 0;
    searchFriend.searchText = searchValue;
    this.showloader = 'loaderImage';
    this.showResponseClass = 'emptyResponseHide';
    if (searchValue !== '') {
      this.isSearchTabVisible = 'main-body-None';
      this.isdashboardVisible = 'dashboard-none';
      this.isSearchResultVisible = 'main-body-Search';
      this.dataService.searchFriend(searchFriend).subscribe((result: any) => {
        if (result && result.message === 'Success') {
          if (result.data.totalCount > 0) {
            this.searchFriendList = result.data.customerData;
          } else {
            this.showResponseClass = 'emptyResponseShow';
            this.searchFriendList = [];
            this.notifyService.showWarning('No Records Found.', '');
          }
        } else {
          this.notifyService.showWarning(result.message, '');
        }
        this.showloader = 'notloaderImage';
      });
    } else {
      this.isSearchTabVisible = 'main-body';
      this.isdashboardVisible = 'dashboard';
      this.isSearchResultVisible = 'main-body-None';
      this.showloader = 'notloaderImage';
    }
  }
  public AddFriend(data: any) {
    const friendRequest = {
      userToId: data._id,
    };
    this.dataService
      .sendFriendRequest(friendRequest)
      .subscribe((result: any) => {
        if (result && result.message === 'Success') {
          this.notifyService.showSuccess('Friend Request Sent.', '');
        } else {
          this.notifyService.showWarning(result.message, '');
        }
      });
  }
  public redirectToDashboard() {
    this.isSearchTabVisible = 'main-body';
    this.isdashboardVisible = 'dashboard';
    this.isSearchResultVisible = 'main-body-None';
    this.router.navigateByUrl('/dashboard');
  }
  public redirectToFriend() {
    this.isSearchTabVisible = 'main-body';
    this.isdashboardVisible = 'dashboard';
    this.isSearchResultVisible = 'main-body-None';
    this.router.navigateByUrl('/friend');
  }
  public openFriendProfile(data: any, isFriend: string) {
    this.isSearchTabVisible = 'main-body';
    this.isdashboardVisible = 'dashboard';
    this.isSearchResultVisible = 'main-body-None';
    this.showloader = 'notloaderImage';
    localStorage.setItem('isFriend', isFriend);
    this.router.navigateByUrl('/profile/' + data._id);
  }
  Acceptcall() {
    this.showCallButton = false;
    localStorage.removeItem('callingUserId');
    if (this.type === 'Audio') {
      this.router.navigateByUrl('/audio');
    } else {
      this.router.navigateByUrl('/video');
    }
  }
  DeclineCall() {
    //this.showCallButton = false;
    localStorage.removeItem('callingUserId');
    //this.socket.emit('call-decline', this.userDetails._id);
  }

  public getNotifcation() {
    this.dataService.getNotification().subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notificationCount = result.data.totalUnRead;
      }
    });
  }

  public chatList(){
    this.router.navigateByUrl('/chatlist');
  }

  public getChatCount() {
    this.dataService.getChatCount().subscribe((result: any) => {
      if (result && result.StatusCode === 200) {
        this.chatCount = result.Data;
      }
    });
  }

  public navigateToNotification() {
    this.router.navigateByUrl('/notification');
  }
  public navigateToRandom() {
    this.router.navigateByUrl('/randomcall');
  }
  public scheduleMeeting() {
    this.router.navigateByUrl('/schedule-meeting');
  }


  //Added on 7 August 2024
  async initializeStripe() {
    this.stripe = await loadStripe('pk_test_51OeCKVBOfCO7ilUokdzDowOyC7C55g5LhV5neUfmTg9nJLif4BUOz7sen8KVGdv8B7fgAg858ybAPGR3soA30NhI00BC2IwEeS');
    this.elements = this.stripe.elements();

    const style = {
      base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      }
    };

    // const loginWrapper = new Login();
    // loginWrapper.email = this.loginForm.value.userName;
    // loginWrapper.deviceType = StaticData.DeviceType;
    // loginWrapper.password = this.loginForm.value.password;
    // loginWrapper.longitude = +localStorage.getItem('latitude');
    // loginWrapper.latitude = +localStorage.getItem('latitude');

    // loginWrapper.deviceToken = Guid.create().toString();

  
    //     this.dataService.login(loginWrapper).subscribe((result) => {
    //       if (result && result.message === 'Success') {
    //       this.accesstoken=result.data.customerData.accessToken;
    //       } else {
    //         this.notifyService.showWarning(result.message, '');
    //       }
    //     });
  

    this.cardNumber = this.elements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number-element');

    const cardExpiry = this.elements.create('cardExpiry', { style });
    cardExpiry.mount('#card-expiry-element');

    const cardCvc = this.elements.create('cardCvc', { style });
    cardCvc.mount('#card-cvc-element');
  }


  async handlePayment() {

    if (!this.stripe || !this.cardNumber) {
      return;
    }

    const { token, error } = await this.stripe.createToken(this.cardNumber);
    if (error) {
    } else {
      const servicecall=new PyamentMethodImpl();

      
      servicecall.authorization=this.userDetails.accessToken;
      servicecall.packageId= this.selectedItem._id;
      servicecall.charges= this.selectedItem.Price;
      servicecall.stripeToken= token.id;
      servicecall.stripeEmail=this.userDetails.email;

      this.dataService.CompletePayment(servicecall).subscribe(
       
        (data: any) => {
          if(data.StatusCode==200){
          this.notifyService.showSuccess("Payment is processed Successfully!!","");
          this.IsPaidUser=true;
        }
        else{
          this.notifyService.showError("Payment Failed!!","");
        }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

    isChecked(item:any): boolean {
    return this.selectedItem?._id === item._id;
  }

  onCheckboxChange(item: any, event: any) {
    if (event.target.checked) {
      this.selectedItem = item;
      this.uncheckOtherItems(item._id);  // Uncheck other items
    } else {
      this.selectedItem = null;
    }
  }
  uncheckOtherItems(selectedItemId: any) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      if (+checkbox.value !== selectedItemId) {
        checkbox.checked = false;
      }
    });
  }
  CompletePurchase(){
    this.showPlans=false;
    this.showPaymentDetails=true;
    this.initializeStripe();

  }

  toggleUserType(){
    console.log("here");
    const changetypeWrapper = new chagetype();
    changetypeWrapper.authorization = this.userDetails.accessToken;
    changetypeWrapper.user_type = this.IsDatingUser=="1" ? 0 : 1;
    changetypeWrapper.user_id = this.userDetails._id;
    this.dataService.ToggleUser(changetypeWrapper).subscribe(
       
      (data: any) => {
        if(data.StatusCode==200){
        this.notifyService.showSuccess("Successfully changed the user type","");
        this.IsPaidUser=true;
        window.location.reload();
            }
      else{
        this.notifyService.showError("Failed!! Try Again","");
      }
      },
      (error) => {
        console.log(error);
      }
    );
  
  }
}
