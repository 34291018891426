<div *ngIf="IsPaidUser">
    <div *ngIf="isAddMore" id="myModal" class="modal-custom">

        <!-- Modal content -->
        <div class="modal-content popup">
            <span class="close_button"><i (click)="closeAddMore()" class="fa fa-times-circle"
                    aria-hidden="true"></i></span>
            <div class="radio-group">
                <label style="margin: 15px;">

                    <input name="CallType" ng-control="options" type="radio" value="i" [(ngModel)]="callType">
                    Individual Call
                </label>

                <label style="margin: 15px;">
                    <input name="CallType" ng-control="options" type="radio" value="g" [(ngModel)]="callType"> Group
                    Call

                </label>
            </div>
            <div *ngIf="callType == 'i'">
                <!--<span style="border: 1px solid black;">
                    <label style="padding: 3px;">Select Users</label>
                    <i (click)="closeAddMore()" style="float: right;padding: 4px;cursor: pointer;" class="fa fa-times-circle danger" aria-hidden="true"></i>
                </span>-->
                <h3>Select Users</h3>
                <div class="user-list">
                    <div *ngFor="let frd of chatUserList" class="addMoreCard">
                        <span class="nameEmaiCls">{{frd.fullName}}</span>
                        <span *ngIf="usersInCall.indexOf(frd._id) > -1" style="float: right;color: green;"><i
                                class="fa fa-check-circle-o" aria-hidden="true"></i>Selected</span>
                        <button *ngIf="usersInCall.indexOf(frd._id) < 0" class="btn " (click)="AddUser(frd)"
                            style="float: right;">Add</button>
                        <button *ngIf="usersInCall.indexOf(frd._id) > -1" class="btn" (click)="RemoveUser(frd)"
                            style="float: right;">Remove</button>

                    </div>
                </div>
                <div style="margin-top: 15px;text-align: center;" *ngIf="usersInCall.length > 0">
                    <button (click)="navigateToVideoIndividual()"
                        style="background-color: #007bff;color: #fff;border: #007bff;" class="btn btn-primary">Start a
                        Call</button>
                </div>
            </div>
            <div *ngIf="callType == 'g'" class="user-list">
                <h3>Select a Group</h3>
                <!--<span style="border: 1px solid black;">
                    <label style="padding: 3px;">Select a Group</label>
                    <i (click)="closeAddMore()" style="float: right;padding: 4px;cursor: pointer;" class="fa fa-times-circle danger" aria-hidden="true"></i>
                </span>-->

                <div *ngFor="let grp of userGroups" class="addMoreCard">
                    <span class="nameEmaiCls">{{grp.groupName}}</span>
                    <button *ngIf="grp.ExtraElements && grp.ExtraElements.isActive" class="btn btn-sm"
                        style="cursor: pointer;float: right;" (click)="navigateToVideo(grp, $event)">Join Call</button>

                    <button class="btn btn-sm" (click)="navigateToVideo(grp, $event)"
                        style="cursor: pointer;float: right;"> <i class="fa fa-video-camera"
                            aria-hidden="true"></i></button>
                </div>
            </div>
        </div>

    </div>
    <div [ngClass]="isdashboardVisible">
        <div class=" container-fluid">
            <div class="row">
                <div class="col-md-12 col-12">
                    <nav class="navbar navbar-expand-lg navbar-light">

                        <a class="navbar-brand" a routerLink="/dashboard"><img
                                src="../../../../../assets/images/logo.png"></a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <form class="form-inline" [formGroup]="searchMasterForm">
                                <input class="form-control mr-sm-2" type="search" formControlName="searchValue"
                                    placeholder="Search" aria-label="Search" name="searchValue"
                                    (input)="searchFriend()">
                            </form>
                            <ul class="navbar-nav m-auto center-icons">
                                <li class="nav-item" (click)="redirectToDashboard()">
                                    <a class="nav-link" routerLink="/dashboard"><i class="fa fa-home"
                                            aria-hidden="true"></i></a>
                                </li>
                                <li class="nav-item" style="cursor: pointer" (click)="chatList()">
                                    <a class="nav-link"><i class="fa fa-comments" aria-hidden="true"></i></a>

                                    <span *ngIf="chatCount > 0" class="notification">{{chatCount}}</span>
                                </li>
                                <li class="nav-item" (click)="redirectToFriend()">
                                    <a class="nav-link" routerLink="/friend"><i class="fa fa-user-plus"
                                            aria-hidden="true"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" style="cursor: pointer;" (click)="navigateToNotification()"><i
                                            class="fa fa-bell" aria-hidden="true"></i></a>
                                    <span class="notification">{{notificationCount}}</span>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" routerLink="/group"><i class="fa fa-users"
                                            aria-hidden="true"></i></a>
                                </li>
                                <li class="nav-item">
                                    <ul class="navbar-nav ml-auto profile-navbar">

                                        <li [ngClass]="dropDownVideoNavMenu" style="cursor: pointer;"
                                            (click)="showVideoNavMenu()">
                                            <a class="nav-link dropdown-toggle" id="navbarDropdownVideo" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fa fa-video-camera" style="margin-right: 5px;"
                                                    aria-hidden="true"></i>
                                            </a>
                                            <div [ngClass]="dropDownVideoNavMenuChild"
                                                aria-labelledby="navbarDropdownVideo">
                                                <a class="dropdown-item" (click)="addMore()">Host a Call</a>
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" (click)="scheduleMeeting()">Schedule a Call</a>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" routerLink="/inviteuser"><i class="fa fa-handshake-o"
                                            aria-hidden="true"></i></a>
                                </li>
                                <li style="display: none;" class="nav-item">
                                    <a class="nav-link"><i class="fa fa-cog" aria-hidden="true"></i></a>
                                </li>

                            </ul>
                            <div class="blink_me" *ngIf="showMessage">
                                Your haven't upload your Profile picture. Please upload your profile picture.
                            </div>
                            <div>

                                <!-- <p *ngIf="IsDatingUser=='1'" style="color: green; font-weight: bold;">Flirting User</p>
                                <div class="user-toggle-container">
                                    <label class="switch">
                                        <input type="checkbox" [checked]="IsDatingUser=='1'"
                                            (change)="toggleUserType()">
                                        <span class="slider round"></span>
                                        <span class="tooltip-text">You can switch your profile type from Social Media to
                                            Flirting/Dating Social Media here.</span>
                                    </label>
                                </div> -->

                                <!-- Added by poonam 24 August -->
                                <p *ngIf="IsDatingUser=='1'" style="color: green; font-weight: bold;">Flirting User</p>
                                <div class="user-toggle-container" (click)="toggleUserType()">
                                    <i [ngClass]="{'fa fa-heart': true, 'heart-red': IsDatingUser=='1', 'heart-white': IsDatingUser!='1'}"></i>
                                    <span class="tooltip-text">You can switch your profile type from Social Media to
                                        Flirting/Dating Social Media here.</span>
                                </div>
                            </div>
                            <ul class="navbar-nav ml-auto profile-navbar">
                                <li [ngClass]="dropDownNavMenu" style="cursor: pointer;" (click)="showNavMenu()">
                                    <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="{{isPicExists}}">
                                    </a>

                                    <div [ngClass]="dropDownNavMenuChild" aria-labelledby="navbarDropdown">
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" routerLink="profile">
                                            <i class="bi bi-person" aria-hidden="true"></i> Profile
                                        </a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" routerLink="settings"><i class="fa fa-cog" aria-hidden="true"></i>
                                            Settings </a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" routerLink="support"><i class="fa fa-question-circle"
                                                aria-hidden="true"></i>
                                            Support </a>

                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" (click)="logout()">
                                            <i class="bi bi-box-arrow-right ml-2" aria-hidden="true"></i> Logout
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar">
        <a (click)="navigateToProfile()"> <img src="{{isPicExists}}"> {{
            this.userDetails.fullName}}</a>
        <a (click)="navigateToGroup()"><span class="icons"><i class="fa fa-users" aria-hidden="true"></i></span>
            Groups</a>
        <a style="display: none;" class="fav"><span class="icons"><i class="fa fa-star" aria-hidden="true"></i></span>
            Favourite</a>
        <a class="fav" [routerLink]="['/portfolio']" routerLinkActive="router-link-active"><span class="icons"><i
                    class="fa fa-picture-o" aria-hidden="true"></i></span> Portfolio</a>
        <a style="display: none;;" class="fav" href="https://queerslist.com" target="_blank"
            routerLinkActive="router-link-active"><span class="icons"><i class="fa fa-globe"
                    aria-hidden="true"></i></span> MarketPlace</a>

        <a style="display: none;" class="fav" (click)="navigateToRandom()"><span class="icons"><i
                    class="fa fa-video-camera" aria-hidden="true"></i></span> Random Call</a>

        <a class="fav" (click)="scheduleMeeting()"><span class="icons"><i class="fa fa-video-camera"
                    aria-hidden="true"></i>
            </span> Schedule a Meeting</a>
    </div>
    <div [ngClass]="isSearchResultVisible">
        <div class="container">
            <div class="row">
                <div class="add-friends" style="width: 100% !important;">
                    <div class="tab-content">
                        <div id="home">
                            <h3 style="margin-top: 125px;margin-bottom: 40px;">Search Results</h3>
                            <div [ngClass]="showloader"> <img src="../../../../assets/images/loading.gif">
                            </div>
                            <div [ngClass]="showResponseClass">No Records Found.
                            </div>
                            <div class="friends-list">
                                <div class="friends-list-inner" *ngFor="let item of searchFriendList">
                                    <div class="friends-list-image" style="cursor: pointer;"
                                        (click)="openFriendProfile(item,'No')">
                                        <img src="{{imageURl}}/{{item.imageURL.thumbnail}}">
                                    </div>
                                    <div class="friends-list-details" style="cursor: pointer;"
                                        (click)="openFriendProfile(item,'No')">
                                        <p>{{item.fullName}}
                                            <span class="emailid">{{item.email}}</span>
                                        </p>
                                    </div>
                                    <div class="friends-list-add" *ngIf="!item.isFriendRequestSend && !item.isFriend">
                                        <button (click)="AddFriend(item)">Add Friend</button>
                                    </div>
                                    <div class="friends-list-add" *ngIf="item.isFriendRequestSend">
                                        Friend Request Sent.
                                    </div>
                                    <div class="friends-list-add" *ngIf="item.isFriend">
                                        Friends.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="isSearchTabVisible">

        <router-outlet></router-outlet>
    </div>
</div>

<!-- Added on 7 August 24 -->
<div *ngIf="!IsPaidUser">
    <div [ngClass]="isdashboardVisible" style="">
        <div class=" container-fluid">
            <div class="row">
                <div class="col-md-12 col-12">
                    <nav class="navbar navbar-expand-lg navbar-light">

                        <a class="navbar-brand" a routerLink="/dashboard"><img
                                src="../../../../../assets/images/logo.png"></a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <form class="form-inline" [formGroup]="searchMasterForm">

                            </form>


                            <ul class="navbar-nav ml-auto profile-navbar">

                                <div>
                                    <p *ngIf="IsDatingUser=='1'" style="    color: green;
                                         font-weight: bold;">Flirting User</p>
                                    <div class="user-toggle-container">
                                        <label class="switch">
                                            <input type="checkbox" [checked]="IsDatingUser=='1'"
                                                (change)="toggleUserType()">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <li [ngClass]="dropDownNavMenu" style="cursor: pointer;" (click)="showNavMenu()">
                                    <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="{{isPicExists}}">
                                    </a>


                                    <div [ngClass]="dropDownNavMenuChild" aria-labelledby="navbarDropdown">
                                        <a style="display: none;" class="dropdown-item"><i class="fa fa-cog"
                                                aria-hidden="true"></i>
                                            Settings <span><i class="fa fa-angle-right"
                                                    aria-hidden="true"></i></span></a>
                                        <a style="display: none;" class="dropdown-item"><i class="fa fa-question-circle"
                                                aria-hidden="true"></i>
                                            Help <span><i class="fa fa-angle-right" aria-hidden="true"></i></span></a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" (click)="logout()"><i class="fa fa-cog"
                                                aria-hidden="true"></i>
                                            Log
                                            Out</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="main-body2" style="">

        <div class="container">
            <div class="login-inner">

                <div *ngIf="showPlans" style="padding-top: 100px;">
                    <div class="row">
                        <div class="col-md-12 col-12" style="font-size: 30px;">
                            <B>Flirting/Dating Social Media Plan</B>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-12">
                            The most popular FriendLinq plan
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-6 col-6" *ngFor="let item of allplans">
                            <label>
                                <input type="checkbox" (change)="onCheckboxChange(item, $event)"
                                    [checked]="isChecked(item)" value="item._id" />
                                {{ item.Name }}
                            </label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-3"></div>
                        <div class="col-md-6 col-6">
                            <div *ngIf="selectedItem" style="color:green ;
    box-shadow: 10px 10px 10px grey;">

                                <p>{{ selectedItem.Description}}</p>
                                <div style="background-color: lightgrey;
    font-size: 35px;">
                                    <i class="fa fa-check-circle"></i>
                                    Free trail
                                </div>

                            </div>
                            <div class="col-md-3 col-3"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-12" style="    font-weight: bolder;
          font-size: 30px;
          color: green;">
                            Main Features
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12 col-12">
                            <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Download , Copy & Share
                            any post image or video.<br />
                            <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Unlimited live Video
                            Calls between Android and iPhone.<br />
                            <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Customize your profile
                            with: Banners, Top-Friends, Video & Photos.<br />
                            <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Unlimited chats, group
                            calls and FriendLinqs.<br />
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12 col-12">
                            <button style="margin-top: 20px;
          border-radius: 30px;    color: white;
        background-color: green;
        height: 50px;
        width: 30%;
       " (click)="CompletePurchase()">Complete purchase</button>
                        </div>
                    </div>
                </div>

                <div [ngStyle]="{ 'display': showPaymentDetails ? 'block' : 'none' }" style="padding-top: 100px;">
                    <div class="payment-form">
                        <form (submit)="handlePayment()">
                            <div class="form-group">
                                <label for="card-number-element">Card Number</label>
                                <div id="card-number-element" class="stripe-element"></div>
                            </div>

                            <div class="form-group">
                                <label for="card-expiry-element">Expiration Date</label>
                                <div id="card-expiry-element" class="stripe-element"></div>
                            </div>

                            <div class="form-group">
                                <label for="card-cvc-element">CVC</label>
                                <div id="card-cvc-element" class="stripe-element"></div>
                            </div>

                            <button type="submit" class="btn btn-primary" style="background-color: green;
            border-radius: 35px;
            margin-bottom: 30px;">Submit Payment</button>
                        </form>

                        <!-- <div *ngIf="errorMessage" class="error-message">
            {{ errorMessage }}
          </div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- <footer>
            <div class="footer-section">
                <div class="container-fluid rem-pad">
                    <div class="row">
                        <div class="col-md-12 col-12">
                            <div class="footer-bottom text-center">
                                <p>Loream Ipsum 2012 - 2016. All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer> -->