import { identifierModuleUrl } from '@angular/compiler';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/components/public/service/data.service';
import { AuthService } from 'src/app/shared/api/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-group-request',
  templateUrl: './group-request.component.html',
  styleUrls: ['./group-request.component.css'],
})
export class GroupRequestComponent implements OnInit {
  public groupRequest: any;
  public imageUrl: string;
  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private notifyService: NotificationService,
    private dialogRef: MatDialogRef<GroupRequestComponent>,

    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.groupRequest = data;
    this.imageUrl = environment.rearGuardImageUri;
  }

  ngOnInit(): void {}
  AcceptRequest(id, index) {
    const json = {
      authorization: this.authService.getloggedUserDetails().accessToken,
      requestId: id,
      status: 'accepted',
    };
    this.dataService.processGroupRequest(json).subscribe((result: any) => {
      this.notifyService.showSuccess('Request Accepted.', '');
      this.groupRequest.splice(index, 1);
    });
  }
  DeleteRequest(id, index) {
    const json = {
      authorization: this.authService.getloggedUserDetails().accessToken,
      requestId: id,
      status: 'deny',
    };
    this.dataService.processGroupRequest(json).subscribe((result: any) => {
      this.notifyService.showSuccess('Request Denied.', '');
      this.groupRequest.splice(index, 1);
    });
  }
  closeComment() {
    this.dialogRef.close();
  }
}
