import { Component, OnInit } from '@angular/core';
import { DataService } from '../service/data.service'

@Component({
    selector: 'privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.css']
  })
  export class PrivacyPolicyComponent implements OnInit {
    ngOnInit(): void {
    }
  }