<div class="settings-container" *ngIf="showSettings">
    <div class="setting-item">
      <i class="fa fa-bell"></i>
      <span>Get Updates in email</span>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="emailUpdates" (change)="onToggleEmailUpdates()">
        <span class="slider round"></span>
      </label>
    </div>
  
    <div class="setting-item" (click)="togglePasswordForm()">
      <i class="fa fa-font"></i>
      <span>Change Password</span>
    </div>
    
     <!-- Password change form -->
  <div *ngIf="isPasswordFormVisible" class="password-form">
    <form (ngSubmit)="onChangePassword()" style="background-color: green;padding: 8px;border-radius: 10px;">
      <div>
        <label for="currentPassword">Current Password:</label>
        <input type="password" id="currentPassword" [(ngModel)]="currentPassword" name="currentPassword" required>
      </div>
      <div>
        <label for="newPassword">New Password:</label>
        <input type="password" id="newPassword" [(ngModel)]="newPassword" name="newPassword" required>
      </div>
      <div>
        <label for="confirmPassword">Confirm New Password:</label>
        <input type="password" id="confirmPassword" [(ngModel)]="confirmPassword" name="confirmPassword" required>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>

    <div class="setting-item" (click)="showAllPlans()">
      <i class="fa fa-font"></i>
      <span>Plans</span>
    </div>
  
    <div class="setting-item">
      <i class="fa fa-font"></i>
      <span>Balance</span>
      <div class="balance-details">
        <p>Total Balance ${{TotalBalance}}</p>
        <p>Private portfolio ${{PrivatePortfolioBalance}}</p>
        <p>Private group ${{PrivateGroupBalance}}</p>
      </div>
    </div>
  
    <div class="setting-item" (click)="SuspendAccount()">
      <i class="fa fa-font"></i>
      <span>Delete Account</span>
    </div>  
   
</div>


<!-- DISPLAYING PLANS-->

<div *ngIf="showPlans" style="padding-left: 150px;">
    <div class="row">
       <div class="col-md-12 col-12" style="font-size: 30px;">
          <B>Flirting/Dating Social Media Plan</B>
       </div>
    </div>
    <div class="row">
       <div class="col-md-12 col-12">
          The most popular FriendLinq plan
       </div>
    </div>
    <div class="row">

       <div class="col-md-6 col-6" *ngFor="let item of allplans">
          <label>
             <input type="checkbox" (change)="onCheckboxChange(item, $event)" [checked]="isChecked(item)"
                value="item._id" />
             {{ item.Name }}
          </label>

       </div>
    </div>
    <div class="row">
       <div class="col-md-3 col-3"></div>
       <div class="col-md-6 col-6">
          <div *ngIf="selectedItem" style="color:green ;
box-shadow: 10px 10px 10px grey;">

             <p>{{ selectedItem.Description}}</p>
             <div style="background-color: lightgrey;
font-size: 35px;">
                <i class="fa fa-check-circle"></i>
                Free trail
             </div>

          </div>
          <div class="col-md-3 col-3"></div>
       </div>
    </div>
    <div class="row">
       <div class="col-md-12 col-12" style="    font-weight: bolder;
        font-size: 30px;
        color: green;">
          Main Features
       </div>

    </div>
    <div class="row">
       <div class="col-md-12 col-12">
          <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Download , Copy & Share any post
          image or video.<br />
          <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Unlimited live Video Calls between
          Android and iPhone.<br />
          <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Customize your profile with:
          Banners, Top-Friends, Video & Photos.<br />
          <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Unlimited chats, group calls and
          FriendLinqs.<br />
       </div>

    </div>
    <div class="row">
       <div class="col-md-12 col-12">
          <button style="margin-top: 20px;
            border-radius: 30px;    color: white;
            background-color: green;
            height: 50px;
            width: 30%;
            " (click)="CompletePurchase()">Complete purchase</button>
       </div>
    </div>
</div>


 <!--COMPLETE PAYMENT  -->
<div [ngStyle]="{ 'display': showPaymentDetails ? 'block' : 'none' }">
    <div class="payment-form">
       <form (submit)="handlePaymentfromsettings()">
          <div class="form-group">
             <label for="card-number-element">Card Number</label>
             <div id="card-number-element" class="stripe-element"></div>
          </div>

          <div class="form-group">
             <label for="card-expiry-element">Expiration Date</label>
             <div id="card-expiry-element" class="stripe-element"></div>
          </div>

          <div class="form-group">
             <label for="card-cvc-element">CVC</label>
             <div id="card-cvc-element" class="stripe-element"></div>
          </div>

          <button type="submit" class="btn btn-primary" style="background-color: green;
            border-radius: 35px;
            margin-bottom: 30px;">Submit Payment</button>
       </form>
    </div>
</div>