<div class="container">

    <form [formGroup]="genderForm" class="row">
        <div class="row">
            <div class="col-md-12 col-12">

                <div class="post">
                    <div>
                        <img src="{{imageUrl}}/{{userDetails.imageURL.original}}">
                    </div>
                    <div class="fake-image" *ngIf="format==='image'">
                        <img src="{{imageSrc}}">
                    </div>
                    <div class="fake-image" *ngIf="format==='video'">
                        <video src="{{imageSrc}}" controls></video>
                    </div>
                    <div class="post-area" *ngIf="format==='image' || format==='video'">
                        <textarea formControlName="postContent"></textarea>

                    </div>
                    <div class="post-area-nomargin" *ngIf="format!=='image' && format!=='video'">
                        <textarea formControlName="postContent"></textarea>

                    </div>

                    <div class="post-button">
                        <a (click)="CreatePost()" [attr.disabled]="!genderForm.valid || postDisabled"
                            style="cursor: pointer;">POST</a>
                    </div>
                </div>


                <div class="post-public">

                    <p><input type="radio" formControlName="postType" value="Friend_Only">Friends</p>
                    <p><input type="radio" formControlName="postType" value="Public">Community</p>

                </div>

                <div class="public-post">
                    <p style="cursor: pointer;" (click)="onClick()">

                        <img src="../../../../assets/images/photo.png">
                        <input type="file" #fileUpload id="fileUpload" (click)="onClick()" name="fileUpload"
                            accept="image/*" style="display:none;" />
                        Photo
                    </p>
                    <p style="cursor: pointer;" (click)="tagFriend()">
                        <img src="../../../../assets/images/tagfriend.png">

                        Tag Friend
                    </p>
                    <p style="cursor: pointer;" (click)="onClickVideo()"><i class="fa fa-video-camera"
                            aria-hidden="true"></i> <input type="file" #fileUploadVideo id="fileUploadVideo"
                            (click)="onClickVideo()" name="fileUploadVideo" accept="video/*" style="display:none;" />
                        Video</p>
                    <button (click)="getPublicPost()" *ngIf="isFriendPost" class="greenbutton">Feed</button>
                    <button (click)="getFriendsPost()" *ngIf="!isFriendPost" class="greenbutton">See Friends Posts</button>
                </div>

            </div>
        </div>
    </form>
</div>
<div class="container">
    <div class=" row">
        
        <div class="col-md-12 col-12">
            <div class="add-friends" style="display: none;" *ngIf="randomSuggestionLoaded">
                <div class="tab-content">
                    <div id="home">
                        <h3>
                            People you may know 
                            <i class="fa fa-chevron-right nextIcon" title="Next" (click)="nextSuggestion()" aria-hidden="true"></i>
                            <i *ngIf="pageIndex > 0" title="Previous" (click)="lastSuggestion()" class="fa fa-chevron-left nextIcon" aria-hidden="true"></i>
                        </h3>
                        <div class="friends-list">
                            <div class="friends-list-inner" *ngFor="let item of randomSuggestion;let i = index">
                                <div class=" friends-list-image" style="cursor: pointer;"
                                    (click)="openFriendProfile(item)">
                                    <img src="{{imageUrl}}/{{item.imageURL.thumbnail}}">
                                </div>
                                <div class="friends-list-details" style="cursor: pointer;"
                                    (click)="openFriendProfile(item)">
                                    <p>{{item.fullName}}
                                        <span class="emailid">{{item.email}}</span>
                                    </p>
                                </div>
                                <div class="friends-list-add">
                                    <button (click)="AddFriend(item,i)" >Add Friend</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showLoader && !isScrolling" class="col-md-12 col-12">
                <img  style="    margin: auto;display: block;" src=" ../../../../assets/images/loading.gif">
            </div>

            <div *ngIf="friendPostList.length>0" class="people-all-comment">
                <div *ngFor="let item of friendPostList;let i = index">
                    <div class="person-post">
                        <p style="cursor: pointer;" (click)="openFriendProfilepost(item.postAuthor._id)"><img
                                src="{{imageUrl}}/{{item.postAuthor.imageURL.original}}">
                                <!---<img *ngIf="item.postAuthor.isOnline" src="../../../../assets/images/greenststus.png" style="height: 15px;width: 15px;margin-left: -30px;margin-top: 40px;position:absolute;">
                                <img *ngIf="!item.postAuthor.isOnline" src="../../../../assets/images/greyofflinestatus.jpg" style="height: 15px;width: 15px;margin-left: -30px;margin-top: 40px;position:absolute;">--->
                            </p>
                        <p style="width: 100%;">{{item.postAuthor.fullName}}
                            <span style="display: inline;"
                                *ngIf="item.taggedFriend &&  item.taggedFriend.length>0 || item.taggedFriendList &&  item.taggedFriendList.length>0">
                                is
                                with <span *ngFor="let tagItme of item.taggedFriend" style="display: inline;">

                                    {{tagItme.fullname}}&nbsp;</span>
                                <span *ngFor="let tagItme of item.taggedFriendList" style="display: inline;">
                                    <span *ngIf="tagItme.tagAuthor" style="display: inline;">
                                        {{tagItme.tagAuthor.fullName}}</span></span>

                            </span>
                            <span style="display: inline;" *ngIf="item.isShared">

                                <span style="display: inline;">
                                    shared {{item.sharedPostOwner}} post</span>

                            </span>

                            <span>{{ item.createdAt | date :'h:mm a
                                dd-MMM-yy'
                                }}</span>
                        </p>

                        <p>
                            <a (click)=" toggleClass(previewClass,i)"><i class=" fa fa-ellipsis-v delete"
                                    aria-hidden="true">
                                </i></a>

                            <span *ngIf="previewClass==='ash' && clickIndex === i" class="ash"><a
                                    (click)="reportPost(item._id,'Friend',i)">Report this
                                    Post</a></span>
                        </p>
                    </div>
                    <div class="latest-post" *ngIf="!item.sensitive">
                        <p>{{item.postContent}}</p>
                        <p *ngIf="item.imageURL.original !== null"><img class="timelineImage"
                                src="{{imageUrl}}/{{item.imageURL.original}}">
                        <p *ngIf="item.videoURL !== null"> 
                            
                            <video-item></video-item>
                            <video width="420" height="345" controls>
                                <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                            </video>
                        </p>
                        
                    </div>
                    <div class="latest-post" *ngIf="item.sensitive">
                        <p>{{item.postContent}}</p>
                        <p *ngIf="item.imageURL.original !== null && clickBlurIndex !== i"><img
                                class="blurPost timelineImage" src="{{imageUrl}}/{{item.imageURL.original}}">
                        </p>

                        <p *ngIf="item.imageURL.original !== null && clickBlurIndex === i"><img class="timelineImage"
                                src="{{imageUrl}}/{{item.imageURL.original}}">
                        </p>
                        <p *ngIf="item.videoURL !== null &&  clickBlurIndex !== i">
                            <video width="420" height="345" controls class="blurPost">
                                <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                            </video>
                        </p>
                        <p *ngIf="item.videoURL !== null &&  clickBlurIndex === i"> <video width="420" height="345"
                                controls>
                                <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                            </video>
                        </p>
                        <p>This post is bad content.
                            <strong> <a style="cursor: pointer;" 
                                    (click)="removeBlur(i)">See this
                                    post</a></strong>
                        </p>
                      
                    </div>
                    
                    <div class="likecomment-section">
                        <div class="like-part">
                            <p><span><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></span> {{item.totalLike}}
                                <span class="ml-4"><i class="fa fa-thumbs-down" aria-hidden="true"></i></span> {{item.totalDislike}}
                            </p>
                        </div>
                        <div class="comment-part">
                            <p>{{item.totalComment}} Comments</p>
                        </div>
                    </div>
                    <div class="share">
                        <div class="like-part">
                            <p *ngIf="item.isLike" style="color: #0041c1;cursor: pointer"
                                (click)="UpdateLikeStatus(item._id,item.isLike,i,'Friend',item)"><i
                                    class="fa fa-thumbs-o-up" aria-hidden="true"
                                    style="cursor: pointer"></i>
                                Like</p>
                            <p style="cursor: pointer;" *ngIf="!item.isLike"
                                (click)="UpdateLikeStatus(item._id,item.isLike,i,'Friend',item)"><i
                                    class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                Like</p>
                        </div>
                        <div class="like-part">
                            <p *ngIf="item.isDislike" style="color: #0041c1;cursor: pointer"
                                (click)="UpdateDislikeStatus(item._id,item.isDislike,i,'Friend',item)"><i
                                    class="fa fa-thumbs-down" aria-hidden="true"
                                    style="cursor: pointer"></i>
                                Dislike</p>
                            <p style="cursor: pointer;" *ngIf="!item.isDislike"
                                (click)="UpdateDislikeStatus(item._id,item.isDislike,i,'Friend',item)"><i
                                    class="fa fa-thumbs-down" aria-hidden="true"></i>
                                Dislike</p>
                        </div>
                        <div class="comment-part">
                            <p style="cursor: pointer;" style="cursor: pointer;"
                                (click)="openDialog(item._id,item,i,'Friend')"><i class="fa fa-comment-o"
                                    aria-hidden="true"></i>
                                Comment</p>
                        </div>
                        <div class="comment-part">
                            <p style="cursor: pointer;" (click)="openShareDialog(item)"><i class="fa fa-share"
                                    aria-hidden="true"></i> Share</p>
                        </div>
                    </div>
                    <div class="public-comments-main">
                        <div *ngFor="let comment of item.Comments">
                            <div class="public-comments" *ngIf="comment.Author && comment.Author !== 'undefined'">
                                <div class="public-img">
                                    <img (click)="openFriendProfilepost(comment.Author._id)" src="{{imageUrl}}/{{comment.commentAuthor.imageURL.original}}">
                                </div>
                                <div class="publiccommentInner">
                                    <h5>{{comment.commentAuthor.fullName}}</h5>
                                    <p class="comment">{{comment.commentText}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="self-commnet" *ngIf="item.totalComment < 2">
                        <div class="public-comments">
                            <div class="public-img">
                                <img (click)="openFriendProfilepost(comment.Author._id)" src="{{imageUrl}}/{{userDetails.imageURL.original}}">
                            </div>
                            <div class="mycomment">
                                <textarea #myFriendElement name="txtComment{{i}}"
                                    placeholder="Write a public comment here..." (keydown.enter)="onKeydown($event)"
                                    (keyup.enter)="saveComment($event.target.value,i,'Friend',item._id,item)"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="load-commnet">
                        <p style="cursor: pointer;" (click)="openDialog(item._id,item,i,'Friend')">More Comments</p>
                    </div>
                </div>

            </div>
            <div *ngIf="publicPostList.length>0" class="people-all-comment">
                <div *ngFor=" let item of publicPostList ;let i=index">
                    <div *ngIf="i != 0 && i%5 == 0 && this.randomAdvertisement.length > 0">
                        {{getRandom(i)}}
                        <div class="contiainer">
                            <div class="card" (click)="navigate(displayedAdvertisement[i])">
                                <span style="margin: auto;">Advertisement</span>
                              <div class="card-header">
                                <img src="https://friendlinq.com/admin_rearguardadvertising/upload/{{displayedAdvertisement[i].Image}}" alt="rover" />
                              </div>
                              <div class="card-body">
                                <h4>
                                    {{displayedAdvertisement[i].Title}}
                                </h4>
                                <p>
                                  {{displayedAdvertisement[i].Description}}
                                </p>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="person-post">
                       
                        <p style="cursor: pointer;" (click)="openFriendProfilepost(item.Author._id)">                            
                            <img *ngIf="item.Author.imageURL.original" src="{{imageUrl}}/{{item.Author.imageURL.original}}">
                            <img *ngIf="!item.Author.imageURL.original" src="../../../../../assets/images/no-usesr-profile-image.jpg">
                                <img *ngIf="item.Author.isOnline" src="../../../../assets/images/greenststus.png" style="height: 15px;width: 15px;margin-left: -30px;margin-top: 40px;">
                                <img *ngIf="!item.Author.isOnline" src="../../../../assets/images/greyofflinestatus.jpg" style="height: 15px;width: 15px;margin-left: -30px;margin-top: 40px;">
                            </p>
                        <p><span style="display: inline;font-size:18px;font-weight: bold;">{{item.Author.fullName}} </span><span style="display: inline;"
                                *ngIf="item.taggedFriend &&  item.taggedFriend.length>0 || item.taggedFriendList &&  item.taggedFriendList.length>0">
                                is
                                with <span *ngFor="let tagItme of item.taggedFriend" style="display: inline;font-size: 50px;font-weight: bold;">

                                    {{tagItme.fullname}}&nbsp;</span>
                                <span *ngFor="let tagItme of item.taggedFriendList" style="display: inline;">
                                    <span *ngIf="tagItme.tagAuthors" style="display: inline;">
                                     {{tagItme.tagAuthors.fullName}}</span></span>

                            </span>

                            <span style="display: inline;" *ngIf="item.isShared">

                                <span style="display: inline;">
                                    shared {{item.sharedPostOwner}} post</span>

                            </span>

                            <span>{{ item.createdAt | date :'h:mm a dd-MMM-yy'
                                }}</span>
                        </p>

                        <p style="cursor: pointer;">
                            <button *ngIf="item.IsFriend == 1" (click)="navigateToChat(item.Author._id)" class="btn btn-primary greenbutton"><i aria-hidden="true" class="fa fa-comments"></i> Chat</button>
                            <button *ngIf="item.IsFriend == 0" (click)="SendFriendToChat(item)" class="btn btn-primary greenbutton"><i aria-hidden="true" class="fa fa-comments"></i> Send Friend Request</button>
                            <button *ngIf="item.IsFriend == 2" disabled class="btn btn-primary greenbutton"><i aria-hidden="true" class="fa fa-comments"></i> Friend Request Sent</button>
                            <button *ngIf="item.IsFriend == 3" (click)="AcceptRequest(item)" class="btn btn-primary greenbutton"><i aria-hidden="true" class="fa fa-comments"></i> Accept Friend Request & Chat</button>
                            <a (click)="toggleClass(previewClass,i)"><i class=" fa fa-ellipsis-v delete"
                                    aria-hidden="true">
                                </i></a>

                            <span *ngIf="previewClass==='ash' && clickIndex === i" class="ash"><a
                                    (click)="reportPost(item._id,'Public',i)">Report this
                                    Post</a></span>
                        </p>
                    </div>
                    <div class="latest-post" *ngIf="!item.sensitive">
                        <p style="display:inline;font-size:20px;">{{item.postContent}}</p>
                        <p *ngIf="item.imageURL.original !== null"><img class="timelineImage"
                                src="{{imageUrl}}/{{item.imageURL.original}}">
                        <p *ngIf="item.videoURL !== null"> <video onloadedmetadata="this.muted=true" inViewport (inViewportAction)="onIntersection($event)" width="420" height="345" controls>
                                <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                            </video>
                        </p>
                    </div>
                    <div class="latest-post" *ngIf="item.sensitive">
                        <p>{{item.postContent}}</p>
                        <p *ngIf="item.imageURL.original !== null && clickBlurIndex !== i"><img
                                class="blurPost timelineImage" src="{{imageUrl}}/{{item.imageURL.original}}">
                        </p>

                        <p *ngIf="item.imageURL.original !== null && clickBlurIndex === i"><img class="timelineImage"
                                src="{{imageUrl}}/{{item.imageURL.original}}">
                        </p>
                        <p *ngIf="item.videoURL !== null &&  clickBlurIndex!== i">
                            <video inViewport  (inViewportAction)="onIntersection($event)" width="420" height="345" controls class="blurPost">
                                <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                            </video>
                        </p>
                        <p *ngIf="item.videoURL !== null &&  clickBlurIndex === i"> <video width="420" height="345"
                                controls>
                                <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                            </video>
                        </p>
                        <p>This post is bad content.
                            <strong> <a style="cursor: pointer;" 
                                    (click)="removeBlur(i)">See this
                                    post</a></strong>
                        </p>
                    </div>
                    <div *ngIf="item.broadcastId" class="likecomment-section">
                        <div style="background-color: red;
                        color: white;
                        padding: 3px;                        
                        border-radius: 12px;                        
                        margin-left: 90%;">
                            Live
                        </div>
                    </div>
                    <div class="likecomment-section">
                        <div class="like-part">
                            <p><span><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></span> {{item.totalLike}}
                                <span class="ml-4"><i class="fa fa-thumbs-down" aria-hidden="true"></i></span> {{item.totalDislike}}
                            </p>
                        </div>
                        <div class="comment-part">
                            <p>{{item.totalComment}} Comments</p>
                        </div>
                    </div>
                    <div class="share">
                        <div class="like-part">
                            <p *ngIf="item.isLike" style="color: #0041c1;cursor: pointer"
                                (click)="UpdateLikeStatus(item._id,item.isLike,i,'Public',item)"><i
                                    class="fa fa-thumbs-o-up" aria-hidden="true"
                                    style="color: #0041c1;cursor: pointer"></i> 
                                Like</p>
                            <p style="cursor: pointer;" *ngIf="!item.isLike"
                                (click)="UpdateLikeStatus(item._id,item.isLike,i,'Public',item)"><i
                                    class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                Like</p>
                        </div>
                        <div class="like-part">
                            <p *ngIf="item.isDislike" style="color: #0041c1;cursor: pointer"
                                (click)="UpdateDislikeStatus(item._id,item.isDislike,i,'Public',item)"><i
                                    class="fa fa-thumbs-down" aria-hidden="true"
                                    style="cursor: pointer"></i>
                                Dislike</p>
                            <p style="cursor: pointer;" *ngIf="!item.isDislike"
                                (click)="UpdateDislikeStatus(item._id,item.isDislike,i,'Public',item)"><i
                                    class="fa fa-thumbs-down" aria-hidden="true"></i>
                                Dislike</p>
                        </div>
                        <div class="comment-part">
                            <p style="cursor: pointer;" (click)="openDialog(item._id,item,i,'Public')"><i
                                    class="fa fa-comment-o" aria-hidden="true"></i>
                                Comment</p>
                        </div>
                        <div class="comment-part">
                            <p style="cursor: pointer;" (click)="openShareDialog(item)"><i class="fa fa-share"
                                    aria-hidden="true"></i> Share</p>
                        </div>
                    </div>
                    <div class="public-comments-main">
                        <div *ngFor="let comment of item.Comments">
                            <div class="public-comments" *ngIf="comment.Author && comment.Author !== 'undefined'">
                                <div class="public-img">
                                    <img (click)="openFriendProfilepost(comment.Author._id)" src="{{imageUrl}}/{{comment.Author.imageURL.original}}">
                                </div>
                                <div class="publiccommentInner">
                                    <h5>{{comment.Author.fullName}}</h5>
                                    <p class="comment">{{comment.commentText}} </p>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div class="load-commnet">
                            <p style="cursor: pointer;" (click)="openDialog(item._id,item,i,'Public')">More Comments</p>
                        </div>
                       
                    </div>
                </div>

            </div>
            <div *ngIf="showLoader && (isScrolling||isFriendScrolling)" sclass="col-md-12 col-12">
                <img  style="    margin: auto;display: block;" src=" ../../../../assets/images/loading.gif">
            </div>
        </div>
    </div>
</div>