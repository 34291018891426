

    <header class="header_wrap">
<div class="container">

    <div class="header">
        <div class="logo">
            <img  src="../../../../assets/images/logo.png" />
            <h3 style="text-align: left;">FriendLinq</h3>
        </div>
        <div class="navbar">
            <div class="login_signup">
                <a routerLink="/login" class="pointer">Login</a> /
                <a  routerLink="/signup-main" class="pointer">Sign Up</a>
            </div>
           
        </div>	
    </div>
    
</div>
</header>
<div class="body-wrapper" style="background: url(../../../../assets/images/homebackground.png);">
    <div class="body-content">
    <div class="container">
        <div class="content_area">
            <div class="left-section">
                <img src="../../../../assets/images/phoenappnewone.png" />
                <div  class="freedownload">
                    <img src="../../../../assets/images/freedownload.png" />
                </div>
            </div>
            <div class="right-section">
                <h3><span>Introducing</span> FriendLinq</h3>
                <h4>Real Time Private Messaging</h4>
                <div class="join-section">
                    <div class="content_area">
                    <div class="left-section-inner">
                <p class="first">Connect with like-minded people <br />
                    Post what you want to share   <br />
                    Break away from Big Tech Censorship 
                 </p>
                <p>  
                    FriendLinq never shares your information <br />
                    FriendLinq never sells  your information <br />
                    FriendLinq never shadow bans users </p>
                    
                   
                </div>
                <div class="right-section-inner">
                    <p class="first">Download & Share any Videos & Photos <br />
                        Make Video Calls Between Android-iPhone  <br />
                        Live, Multiple User Video Calls & Chats 
                     </p>
                    <p>  
                        Live Streaming  <br />
                       
                        Customize your profile with: Banners, Top-Friends, Videos, Photos & Music Clips <br />
                        Beautiful wallpapers in your profile    </p>
                        <div>
                            <p><a class="btn" style="color: aliceblue;" (click)="showJoinUsFunc()" >Join Us</a></p>
                        </div>
                </div>
                </div>
                    
                </div>
                </div>
        </div>
           
    </div>	
</div>
<div class="Connect-section">
    <div class="container">
        <div class="connect-wrap">
            <div class="connect">
                <a href="#" style="display: none;" class="btn">Connect with Healthy Souls</a>
            </div>
            <div class="links">
                <div class="app_link">
                    <a href="#"><img src="../../../../assets/images/app-store.png"></a>
                    <a href="#"><img src="../../../../assets/images/googleplay.png"></a>
                </div>
                <div class="social_media">
                    <div class="social_links">
                        <a href="#"><img src="../../../../assets/images/fb.png"></a>
                        <a href="#"><img src="../../../../assets/images/tw.png"></a>
                        <a href="#"><img src="../../../../assets/images/yt	.png"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <div class="container">
        <div class="bottom_area">
            <div class="navbar-footer">
                <!---<ul>
                    <li><a href="#">Home</a></li>
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Social Media</a></li>
                    <li><a href="#">Terms and Conditions</a></li>
                    <li><a href="#">Contact</a></li>
                </ul>--->
            </div>
            <div class="copyright">
                <p>&copy; 2024 Friend Linq, Inc. All Rights Reserved</p>
            </div>
        </div>
    </div>
</div>
</div>

<div *ngIf="showMessage" class="login-popup">
    <div class="message">
        <span class="pointer" (click)="hideMessageFunc()">X</span>
        <h2>We appreciate your interest in FriendLinq and are hard at work to provide a great  social media user experience. Please click the Join Button below to get on our waiting list.You'll be amoung the first to get notified when we go live. Thank you for your support and patience.<br/>The FreindLinq Team. </h2>
    </div>
</div>


<div *ngIf="showJoinUs" class="login-popup">
    <div class="message">
        <span class="pointer" (click)="showJoinUsFunc()">X</span>
        <div>
            <h5>Please Enter your contact information to be among the first to obtain early access to FriendLinq. If you'd be interested in becoming a Beta tester, let us know.</h5>
                <ul class="form-style-1">
                    <li>
                        <label>Email <i style="color: red;">*</i></label>
                        <input [(ngModel)]="email" type="email" name="field3" class="field-long" />
                        <i style="display: flex;color: red;" *ngIf="showValidation">{{validationMessage}}</i>
                    </li>
                    <li>
                        <label>Full Name</label>
                        <input [(ngModel)]="name" type="text" name="name" class="field-long" />
                    </li>
                    <li>
                        <label>Contact Phone Number.</label>
                        <input type="text" [(ngModel)]="phoneNumber" name="phoneNumber" class="field-long" />
                    </li>
                    <li>
                        <label>Your Message</label>
                        <textarea name="message" [(ngModel)]="message" id="message" class="field-long field-textarea"></textarea>
                    </li>
                    <li *ngIf="showSuccessMessage">
                        <label style="color: green;">You've been added to our waiting list. Our FriendLinq team will let you know when we go live.   </label>
                    </li>
                    <li>
                        <input (click)="SubmitEnquiry()" type="submit" value="Join Us" />    
                    </li>
                </ul>
        </div>
    </div>
</div>
