<div class="loginpage">
   <div class="container">
      <div class="login-inner">
         <div *ngIf="LoginNeeded" class="login">
            <div class="container">
               <div class="row">
                  <div class="col-md-12 col-12">
                     <div class="login-inner">
                        <form [formGroup]="loginForm">
                           <h1>Login</h1>
                           <div class="form-group">
                              <input type="text" placeholder="Email" name="userName" formControlName="userName">
                           </div>
                           <div class="form-group">
                              <input [type]="passwordVisible ? 'text' : 'password'" placeholder="Password" name="password" formControlName="password">
                              <span class="toggle-password" (click)="togglePasswordVisibility()">
                                <i [ngClass]="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                              </span>
                            </div>
                           <!-- Login check boxes -->
                           <!-- <div class="form-group">
                     <label style="font-weight: bolder;font-size: 18px;">
                        <input type="checkbox"  [checked]="CheckBoxForDatingUser" style="width:15px;font-weight: bolder;font-size: 18px;" (change)="OnChangeForDatingUser('DatingUser')"/>
                        Login to Flirting / Dating Social Media
                     </label>
                     <br/>
                     or
                     <br/>
                     <label>
                        <input type="checkbox"  [checked]="CheckBoxForNormalUser" style="width:15px" (change)="OnChangeForDatingUser('normaluser')"/>
                        Login to Friendlinq general social media
                     </label>
                   </div> -->
                           <!-- Login check boxes -->
                           <div class="form-group button-div" style="margin-top: 20px;">
                              <button type="submit" class="btn btn-success" [disabled]="!loginForm.valid"
                                 (click)="login()" style="margin-top: 10px; border-radius: 30px;">
                                 Login
                              </button>
                              <button style="display:none;" class="google" (click)="loginWithGoogle()">
                                 <img src="../../../../assets/images/google.png"> Continue with Google
                              </button>
                           </div>
                           <div>
                              <label>
                                 <input style="width: 20px;" type="checkbox" [checked]="acceptTerms"
                                    (change)="acceptTerms = !acceptTerms" />
                                 By continuing, I agree to <a target="terms-and-conditions"
                                    href="#/terms-and-conditions">Terms of Use</a> and <a target="privacy-policy"
                                    href="#/privacy-policy">Privacy Policy</a>
                              </label>
                           </div>
                           <div class="extra-text">
                              <!-- <p>Prefer our Social Media Site <span><a routerLink="/signup">Sign Up</a></span></p>
                     <p>Flirting/Dating social media plateform for singles only? click here to <span><a routerLink="/signup-image">Sign Up</a></span></p> -->
                              <p class="forget"><a routerLink="/forgotpassword">Forgot Password?</a></p>
                              <a href="https://friendlinq.com/demoVideo.mp4" style="background: #1e9de4;
                    color: #fbffff;
                    border-radius: 5px;
                    padding: 10px 35px;
                    border-color: transparent;
                    font-weight: 600;
                    font-size: 15px;
                    cursor: pointer;display: none;" target="_blank"> App Demo Video</a>
                           </div>
                        </form>
                        <div class="extra-text">
                           <p>Don't have an account? <span><a routerLink="/signup-main">Sign Up</a></span></p>
                        </div>
                        <p>Join others finding connections on FriendLinq</p>

                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div *ngIf="showPlans">
            <div class="row">
               <div class="col-md-12 col-12" style="font-size: 30px;">
                  <B>Flirting/Dating Social Media Plan</B>
               </div>
            </div>
            <div class="row">
               <div class="col-md-12 col-12">
                  The most popular FriendLinq plan
               </div>
            </div>
            <div class="row">

               <div class="col-md-6 col-6" *ngFor="let item of allplans">
                  <label>
                     <input type="checkbox" (change)="onCheckboxChange(item, $event)" [checked]="isChecked(item)"
                        value="item._id" />
                     {{ item.Name }}
                  </label>

               </div>
            </div>
            <div class="row">
               <div class="col-md-3 col-3"></div>
               <div class="col-md-6 col-6">
                  <div *ngIf="selectedItem" style="color:green ;
box-shadow: 10px 10px 10px grey;">

                     <p>{{ selectedItem.Description}}</p>
                     <div style="background-color: lightgrey;
font-size: 35px;">
                        <i class="fa fa-check-circle"></i>
                        Free trail
                     </div>

                  </div>
                  <div class="col-md-3 col-3"></div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-12 col-12" style="    font-weight: bolder;
      font-size: 30px;
      color: green;">
                  Main Features
               </div>

            </div>
            <div class="row">
               <div class="col-md-12 col-12">
                  <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Download , Copy & Share any post
                  image or video.<br />
                  <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Unlimited live Video Calls between
                  Android and iPhone.<br />
                  <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Customize your profile with:
                  Banners, Top-Friends, Video & Photos.<br />
                  <i class="fa fa-check" aria-hidden="true" style="color:green"></i> Unlimited chats, group calls and
                  FriendLinqs.<br />
               </div>

            </div>
            <div class="row">
               <div class="col-md-12 col-12">
                  <button style="margin-top: 20px;
      border-radius: 30px;    color: white;
    background-color: green;
    height: 50px;
    width: 30%;
   " (click)="CompletePurchase()">Complete purchase</button>
               </div>
            </div>
         </div>

         <div [ngStyle]="{ 'display': showPaymentDetails ? 'block' : 'none' }">
            <div class="payment-form">
               <form (submit)="handlePayment()">
                  <div class="form-group">
                     <label for="card-number-element">Card Number</label>
                     <div id="card-number-element" class="stripe-element"></div>
                  </div>

                  <div class="form-group">
                     <label for="card-expiry-element">Expiration Date</label>
                     <div id="card-expiry-element" class="stripe-element"></div>
                  </div>

                  <div class="form-group">
                     <label for="card-cvc-element">CVC</label>
                     <div id="card-cvc-element" class="stripe-element"></div>
                  </div>

                  <button type="submit" class="btn btn-primary" style="background-color: green;
        border-radius: 35px;
        margin-bottom: 30px;">Submit Payment</button>
               </form>

               <!-- <div *ngIf="errorMessage" class="error-message">
        {{ errorMessage }}
      </div> -->
            </div>
         </div>
      </div>
   </div>
</div>
<style>
   .loginpage {
      background-color: #e9e9ea !important;
   }

   /* payment.component.css */
   .payment-form {
      max-width: 400px;
      margin: 0 auto;
   }

   .form-group {
      margin-bottom: 15px;
   }

   .stripe-element {
      padding: 10px;
      border: 1px solid #ccd0d2;
      border-radius: 4px;
   }

   .btn-primary {
      background-color: #6772e5;
      color: #fff;
      border: none;
      padding: 10px 15px;
      cursor: pointer;
      border-radius: 4px;
   }

   .btn-primary:hover {
      background-color: #5469d4;
   }

   .error-message {
      color: red;
      margin-top: 10px;
   }




   .toggle-password {
      position: relative;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      cursor: pointer;
      color: #6c757d;
   }

   .toggle-password i {
      font-size: 1.1em;
   }
</style>