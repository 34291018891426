<div class="login signup">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-12">
				<div class="login-inner forgot">
					<form [formGroup]="fogotPasswordForm">
						<h1>Forget Password</h1>
						<p>Enter the email you used to join FriendLinq below and we'll send you OTP for resetting your password.</p>

						<div class="form-group">
							<input type="email" placeholder="Email" name="email" formControlName="email">
						</div>

						<div class="form-group button-div">
							<button class="btn btn-primary" [disabled]="!fogotPasswordForm.valid"
								(click)="onSubmit()">Confirm</button>
						</div>
					</form>

				</div>

			</div>
		</div>
	</div>
</div>