import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DataService } from 'src/app/components/public/service/data.service';
import { AuthService } from 'src/app/shared/api/auth.service';
import { feedback } from 'src/app/components/models/signup.model';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  public userDetails: any;


  supportData = {
    name: '',
    email: '',
    phone: '',
    message: ''
  }

  constructor(private authService:AuthService,private notifyService:NotificationService,private dataService:DataService) { }

  ngOnInit(): void {
  }

  onSubmit(supportForm:any) {
    // Handle form submission logic here
    this.userDetails = this.authService.getloggedUserDetails();
    
    const changetypeWrapper = new feedback();
    changetypeWrapper.authorization = this.userDetails.accessToken;
    changetypeWrapper.name = supportForm.value.name;
    changetypeWrapper.email = supportForm.value.email;
    changetypeWrapper.phoneNumber=supportForm.value.phone;
    changetypeWrapper.feedback=supportForm.value.message;

    this.dataService.SendFeedback(changetypeWrapper).subscribe(
       
      (data: any) => {
        if(data.StatusCode==200){
        this.notifyService.showSuccess("Feedback sent successfully","");    
        
            }
      else{
        this.notifyService.showError("Failed!! Try Again","");
      }
      },
      (error) => {
        console.log(error);
      }
    );
  }

}
