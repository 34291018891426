<div class="container-fluid banner">
    <p class="banner-text">Video Call</p>
  </div>

<div *ngIf="stream.isConnecting" class="modal-custom">
</div>
<!-- The Modal -->
<div *ngIf="isAddMore"  id="myModal" class="modal-custom">

  <!-- Modal content -->
  <div class="modal-content">
    <span style="border: 1px solid black;">
       <label style="padding: 3px;">Add More User</label>
      <i (click)="closeAddMore()" style="float: right;padding: 4px;cursor: pointer;" class="fa fa-times-circle danger" aria-hidden="true"></i>
    </span>
    <div *ngFor="let frd of chatList" class="addMoreCard">
      {{frd.fullName}}

      <button class="btn close" (click)="AddUser(frd)" style="float: right;">Add</button>
      <span *ngIf="usersInCall.indexOf(frd.email) > -1 && !stream.remoteUsersEmail[frd.email]" style="float: right;color: green;"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Request Sent</span>
      <span *ngIf="stream.remoteUsersEmail[frd.email]" style="float: right;color: green;"><i class="fa fa-check-circle-o" aria-hidden="true"></i> In Call</span>
    </div> 
  </div>

</div>

<div class="container">
  <div class="row">
    <form id="join-form" name="join-form">
      <!-- Input Field -->
      <!-- UI Controls -->
      <div class="button-group">
        <button id="mic-btn" style="display: none;" type="button" class="btn btn-live btn-sm" >
          <i id="mic-icon" class="fas fa-microphone"></i>
        </button>
        <button id="leave" type="button" class="btn danger close"  (click)="logout()"><i class="fa fa-phone" style="rotate: 135deg;" aria-hidden="true"></i></button>
        <button id="leave" type="button" class="btn close"  (click)="muteVideo()">
          
          <i class="fa fa-video-camera" *ngIf="isMuteVideo" aria-hidden="true"></i><span *ngIf="isMuteVideo" class="mute-video">/</span>
          <i class="fa fa-video-camera" *ngIf="!isMuteVideo" aria-hidden="true"></i>
        </button>
        <button id="leave" type="button" class="btn close"  (click)="muteUnmuteVolumn()" [innerHtml]="muteVolumeText"></button>
        <button id="leave" type="button" class="btn close" [disabled]="isScreenShare" (click)="startScreenShare()"><i class="fa fa-desktop" aria-hidden="true"></i><span *ngIf="!isScreenShare" class="mute-video">/</span></button>
        <button id="leave" type="button" class="btn close" (click)="addMore()"><i class="fa fa-plus-square" aria-hidden="true"></i></button>
        <button id="join" type="button" *ngIf="isNotify" (click)="startCall1()" class="btn close" >Join</button>
      </div>
    </form>
  </div>
</div>
  
<div *ngIf="maximizedUser">
  <button  class="danger video-close" (click)="maximizeClose()"><i class="fa fa-window-close" aria-hidden="true"></i></button>
  <div id="ScreenShare" class="container" style="height: 600px;padding: 10px;">

  </div>
  <span *ngIf="maximizedUser" class="video-name video-name-max">{{stream.remoteUsersObj[maximizedUser.uid]}}</span>

</div>
  <div class="container">
    <div *ngIf="stream.remoteUsers.length == 0 && showLoader" id="loader" class="row showLoader">
      <img src="../../../../assets/images/call-loader.gif">
      <p class="callingText">Calling ..............</p>
    </div>
    <!-- Streams -->
    <div class="row video-group">
      <div class="col-md-3">
        <div id="local-player"  style="width: 200px;height: 200px;margin-top: 10px;"></div>
        <span class="video-name" *ngIf="currentUser">{{currentUser.fullName}}</span>
      </div>
      <div class="col-md-3" *ngFor="let i of stream.remoteUsers">
        <span (click)="maximize(i)">
        <div  id="{{ 'remote-playerlist' + i.uid}}" style="width: 200px;height: 200px;margin-top: 10px;cursor: pointer;border: lightgray 1px solid;"></div>
        <span class="video-name">{{stream.remoteUsersObj[i.uid]}}</span>
      </span>
      </div>
    </div>
  
  </div>

<!---<div>
<button class="fa fa-video" aria-hidden="true" *ngIf="toggleCamera" (click)="setVideo()">Video...</button>
<button class="fa fa-video" aria-hidden="true" *ngIf="!toggleCamera" (click)="setVideo()">Video2...</button>
</div>

<div id="video-chat-container" class="video-position">
    <video id="local-video" #videoLocalElement playsinline autoplay></video>
    <video id="remote-video" #videoRemoteElement playsinline autoplay></video>
    <button id="Disconnect" (click)="disconnect()" class="btn danger close">Disconnect</button>

</div>
--->