import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../service/data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { RequestToAdmin, SignUp } from '../../models/signup.model';
import { StaticData } from 'src/app/shared/constants/constant';

@Component({
  selector: 'app-signup-main',
  templateUrl: './signup-main.component.html',
  styleUrls: ['./signup-main.component.css']
})
export class SignupMainComponent implements OnInit {

  public mainSignupStatus: boolean = true;
  public passwordNotMatch: boolean = false;
  public emailVerified: boolean = false;
  public showOTP: boolean = false;
  public sentOTP: boolean = false;
  public otpMessageText: string = "Send OTP";
  public emailToVerify: string = "";
  public otpToVerify: string = "";
  public otp: string = "";
  public generatingEmail: boolean = false;
  isProcessing: boolean = false;
  public showPlans: boolean = false;
  passwordVisible = false;
  confirmPasswordVisible = false;
  signupForm = this.initForm();
  constructor(
    private fb: FormBuilder,
    private dataservice: DataService,
    private notifyService: NotificationService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  private initForm() {
    return this.fb.group({
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  sendOTP(event) {
    if (!this.signupForm.valid) {
      this.notifyService.showWarning("Please Enter valid values for signup.", '');
      return;
    }

    if (this.signupForm.value.password != this.signupForm.value.confirmpassword) {
      this.notifyService.showWarning("Confirm Password should match with Password.", '');
      return;
    }

    this.emailToVerify = this.signupForm.value.email;

    console.log(this.emailToVerify);

    const emailJson = {
      email: this.emailToVerify,

    };
    this.dataservice.GetUserByEmail(emailJson).subscribe(
      (data) => {
        if (Array.isArray(data) && data.length > 0) {
          console.log(data);

        } else {
          console.log('No data found');
          // Handle the case where no user is found
        }
      },
      (error) => {
        console.error('Error fetching user data:', error.error.message);
        // Handle error appropriately
        if (error.error.message == "rev is not defined") {
          this.notifyService.showError("Email already exists", "");
        }
        else {
          this.generatingEmail = true;
          this.isProcessing = true;
          this.dataservice.verifyEmail({ "Email": this.emailToVerify }).subscribe(
            (response) => {
              // Uncomment and handle the response as needed
              this.otp = typeof response == 'object' ? response["Data"]["OTP"] : "";
              console.log(this.otp);
              this.sentOTP = true;
              this.generatingEmail = false;
              this.emailVerified = true;
              this.isProcessing = false;
              this.otpMessageText = "Resend OTP";
              this.mainSignupStatus = false;
              this.showOTP = true;
              
            },
            (error) => {
              console.error('Error verifying email:', error);
              // Handle error appropriately
            }
          );
        }
      }
    );
  }
  verifyEmail() {

    if (this.otp == this.otpToVerify) {


      this.emailVerified = true;
      this.showOTP = false;
      this.isProcessing = false;
      this.showPlans = true;
      this.signup();
    }
    else {
      alert("Invalid OTP");
    }
  }


  signup() {
    this.markFormGroupTouched(this.signupForm);
    if (this.signupForm.invalid) {
      return;
    }
    if (
      this.signupForm.value.password !== this.signupForm.value.confirmpassword
    ) {
      this.passwordNotMatch = true;
      return;
    }

    this.isProcessing = true;
    const signupWrapper = new SignUp();

    signupWrapper.email = this.signupForm.value.email;
    signupWrapper.password = this.signupForm.value.password;
    signupWrapper.deviceType = StaticData.DeviceType;
    console.log(signupWrapper);

    this.dataservice.signup(signupWrapper).pipe(
      catchError((error) => {
        this.otp = '';
        this.isProcessing = false;
        this.emailVerified = false;
        return throwError(error);
      })
    ).subscribe((result) => {
      this.isProcessing = false;
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess(
          'You have successfully registered with friendlinq',
          'Congratulations......'
        );

        // Store email and password in local storage
        localStorage.setItem('email', this.signupForm.value.email);
        localStorage.setItem('password', this.signupForm.value.password);
        
        const msg =
          'Hi MK ,' +
          this.signupForm.value.name +
          ' (' +
          this.signupForm.value.email +
          ') ' +
          'Signup in our friendlinq. please contact this new user';
        const emailArr = [];
        emailArr.push('friendlinq@gmail.com');
        // emailArr.push('email@genderfreaks.com');
        //emailArr.push('vimalynrsharma@gmail.com');

        const emailJson = {
          email: emailArr,
          message: msg,
          Subject: 'Friendlinq SignUp Notification',
        };
        this.dataservice.notifyUserByEmail(emailJson).subscribe((rlt: any) => {
          console.log('Post Created', rlt);
        });

        const requestToAdmin = new RequestToAdmin();
        requestToAdmin.UserId = result.data.customerData._id;
        this.dataservice
          .MakeUserFriendWithAdmin(requestToAdmin)
          .subscribe((res) => {
            console.log('result', res);
          });


      } else {
        console.log(result.message);
        this.notifyService.showWarning(result.message, '');
        return;
      }
    });
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }


  togglePasswordVisibility(field: 'password' | 'confirmPassword') {
    if (field === 'password') {
      this.passwordVisible = !this.passwordVisible;
    } else if (field === 'confirmPassword') {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }
  }


}
