
<div *ngIf="isAddMore || usersInCall.length == 0"  id="myModal" class="modal-custom">

    <!-- Modal content -->
    <div class="modal-content">
      <span style="border: 1px solid black;">
         <label style="padding: 3px;">Add Users To Schedule a Call</label>
         <button (click)="closeAddMore()" class="btn btn-primary" *ngIf="usersInCall.length > 0" style="float: right;">Done</button>
      </span>
      <div *ngFor="let frd of chatList" class="addMoreCard">
        {{frd.fullName}} <{{frd.email}}>
        <button class="btn danger" (click)="RemoveUser(frd.email)" *ngIf="usersInCall.indexOf(frd.email) > -1" style="float: right;">Remove</button>
  
        <button class="btn" (click)="AddUser(frd)" *ngIf="usersInCall.indexOf(frd.email) < 0" style="float: right;">Add</button>
        <span *ngIf="usersInCall.indexOf(frd.email) > -1" style="float: right;color: green; margin-right: 5px;"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Added To Meeting</span>
      </div>
    </div>
  
  </div>

<form style="padding: 20px;">
    <div class="form-group">
      <label for="exampleInputEmail1">Meeting Title</label>
      <input type="text" class="form-control" aria-describedby="titleHelp" name="titleSchedule" [(ngModel)]="titleSchedule" placeholder="Enter Title">
      <small id="titleHelp" class="form-text text-muted">Please Enter some meeting Title.</small>
    </div>
    <div class="form-group">
      <label for="scheduledOn">Date Time</label>

      <input type="datetime-local" class="form-control"  id="scheduledOn" [value]="mydate" 
      (change)="mydate=$event.target.value" >
    </div>
    <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" name="type" id="exampleRadios1" [(ngModel)]="type" value="video" checked>
        <label class="form-check-label" for="exampleRadios1">
          Video
        </label>
      </div>
      <div style="display: none;" class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" name="type"  id="exampleRadios2" [(ngModel)]="type" value="audio">
        <label class="form-check-label" for="exampleRadios2">
          Audio
        </label>
      </div>
      <div class="form-group" style="display: flex;">
        <span style="    border: 1px solid;margin: 3px; padding: 3px; border-radius: 3px;" class="row" *ngFor="let usr of usersInCall">
            {{userNameInCall[usr]}} <{{usr}}>

            <button (click)="RemoveUser(usr)" style="border: 0px;background: transparent;">
            <i class="fa fa-times-circle" style="padding: 3px;" aria-hidden="true"></i>
          </button>
        </span>
      </div>

    <button type="button" (click)="addMore()" class="btn btn-default">Add Recipients</button>
    <button type="submit" *ngIf="usersInCall.length > 0" (click)="SaveMeeting()" style="margin-left: 5px;" class="btn btn-primary">Submit</button>
  </form>