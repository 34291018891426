import { Component, OnInit } from '@angular/core';
import { DataService } from '../../public/service/data.service';
import { environment } from 'src/environments/environment';
import { MessageService } from 'src/app/shared/api/message.service';
import { Route, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent implements OnInit {
  public notificationList: any;
  public imageUrl: string;

  constructor(
    private dataService: DataService,
    private messageService: MessageService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.imageUrl = environment.rearGuardImageUri;
    this.dataService.getNotification().subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notificationList = result.data.notificationData;      }
    });
  }
  readNotification(id, i) {
    this.dataService.readNotification(id).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notificationList[i].isRead = true;
        this.messageService.sendMessage(false);
        if(this.notificationList[i].notificationType==="New_Post"){
          this.router.navigate(['/post', this.notificationList[i].postId]);
        }
      }
    });

  }



  ngOnInit(): void {}
}
