import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { Login, LoginWithGoogle } from '../../models/login.model';
import { DataService } from '../service/data.service';
import { Guid } from 'guid-typescript';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { StaticData } from 'src/app/shared/constants/constant';
import { MessagingService } from '../../../shared/services/messaging.service';
import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
} from 'angularx-social-login';
import { AuthService } from 'src/app/shared/api/auth.service';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { chagetype, GeTbALANCE, PyamentMethodImpl } from '../../models/signup.model';
import { loadStripe, Stripe, StripeCardNumberElement, StripeElements } from '@stripe/stripe-js';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  loginForm = this.initForm();
  acceptTerms: boolean = true;
  LoginNeeded:boolean=true;
  public allplans:any;
  public showPlans:boolean=false;
  public showPaymentDetails:boolean=false;
  selectedItem: any | null = null;
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  cardNumber: StripeCardNumberElement | null = null;
  public accesstoken:string="";
  public CheckBoxForDatingUser:boolean=false;
  public CheckBoxForNormalUser:boolean=false;
  passwordVisible = false;

  
  public userDetails: any;
  password: boolean;
  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private notifyService: NotificationService,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private http: HttpClient,
    private messagingService:MessagingService
  ) { 
    this.getIPAddress();
    this.userDetails = this.authService.getloggedUserDetails();
    if (this.userDetails && this.userDetails.accessToken) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  // tslint:disable-next-line:typedef
  private initForm() {
    return this.fb.group({
      userName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  async initializeStripe() {
    this.stripe = await loadStripe('pk_test_51OeCKVBOfCO7ilUokdzDowOyC7C55g5LhV5neUfmTg9nJLif4BUOz7sen8KVGdv8B7fgAg858ybAPGR3soA30NhI00BC2IwEeS');
    this.elements = this.stripe.elements();

    const style = {
      base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      }
    };

    const loginWrapper = new Login();
    loginWrapper.email = this.loginForm.value.userName;
    loginWrapper.deviceType = StaticData.DeviceType;
    loginWrapper.password = this.loginForm.value.password;
    loginWrapper.longitude = +localStorage.getItem('latitude');
    loginWrapper.latitude = +localStorage.getItem('latitude');

    loginWrapper.deviceToken = Guid.create().toString();

  
        this.dataService.login(loginWrapper).subscribe((result) => {
          if (result && result.message === 'Success') {
          this.accesstoken=result.data.customerData.accessToken;
          } else {
            this.notifyService.showWarning(result.message, '');
          }
        });
  

    this.cardNumber = this.elements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number-element');

    const cardExpiry = this.elements.create('cardExpiry', { style });
    cardExpiry.mount('#card-expiry-element');

    const cardCvc = this.elements.create('cardCvc', { style });
    cardCvc.mount('#card-cvc-element');
  }


  async handlePayment() {

    if (!this.stripe || !this.cardNumber) {
      return;
    }

    const { token, error } = await this.stripe.createToken(this.cardNumber);
    if (error) {
    } else {
      const servicecall=new PyamentMethodImpl();

      
      servicecall.authorization=this.accesstoken;
      servicecall.packageId= this.selectedItem._id;
      servicecall.charges= this.selectedItem.Price;
      servicecall.stripeToken= token.id;
      servicecall.stripeEmail=this.loginForm.value.userName;

      this.dataService.CompletePayment(servicecall).subscribe(
       
        (data: any) => {
          if(data.StatusCode==200){
          this.notifyService.showSuccess("Payment is processed Successfully!!","");
          const loginWrapper = new Login();
          loginWrapper.email = this.loginForm.value.userName;
          loginWrapper.deviceType = StaticData.DeviceType;
          loginWrapper.password = this.loginForm.value.password;
          loginWrapper.longitude = +localStorage.getItem('latitude');
          loginWrapper.latitude = +localStorage.getItem('latitude');
  
          loginWrapper.deviceToken = Guid.create().toString();
  
          this.dataService.IsSuspended({"Email":  loginWrapper.email}).subscribe((result: any) => {
            if (result && result["Message"] == 'Success' && result.IsSuspended){
              this.notifyService.showWarning("Your Account has been Suspened!!", '');
            }
            else{
              this.dataService.login(loginWrapper).subscribe((result) => {
                if (result && result.message === 'Success') {
                  const msg =
                    'Hi Robert ,' +
                    result.data.customerData.fullName +
                    ' (' +
                    this.loginForm.value.userName +
                    ') ' +
                    'Login in our FriendLinq. please contact this new user';
                  const emailArr = [];
                  // emailArr.push('admin@rearguardapp.com');
                  emailArr.push('info@friendlinq.com');
      
                  const emailJson = {
                    email: emailArr,
                    message: msg,
                    Subject: 'FriendLinq Login Notification',
                  };
                  this.dataService.notifyUserByEmail(emailJson).subscribe((rlt: any) => {
                    console.log('Post Created', result);
                  });
                  this.notifyService.showSuccess('Success', '');
                  sessionStorage.setItem('isLoginDone', 'true');
                  sessionStorage.setItem(
                    'loggedInUser',
                    JSON.stringify(result.data.customerData)
                  );
      
                  localStorage.setItem("uid", this.generateUid().toString());
                  localStorage.setItem('isLoginDone', 'true');
                  localStorage.setItem(
                    'loggedInUser',
                    JSON.stringify(result.data.customerData)
                  );
                  localStorage.setItem('isLoginDone', 'true');
                  localStorage.setItem(
                    'loggedInUser',
                    JSON.stringify(result.data.customerData)
                  );
      
                  this.dataService.setOnlineStatus(1).subscribe((res: any) =>{
                    
                  });
                  this.router.navigateByUrl('/dashboard');
                } else {
                  this.notifyService.showWarning(result.message, '');
                }
              });
            }
          });
        }
        else{
          this.notifyService.showError("Payment Failed!!","");
        }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

    isChecked(item:any): boolean {
    return this.selectedItem?._id === item._id;
  }

  OnChangeForDatingUser(data:any){
    console.log(data);
    if(data=="normaluser"){
       this.CheckBoxForDatingUser=false;
       this.CheckBoxForNormalUser=true;
    }
    else{
      this.CheckBoxForDatingUser=true;
      this.CheckBoxForNormalUser=false;
    }
  }
  onCheckboxChange(item: any, event: any) {
    if (event.target.checked) {
      this.selectedItem = item;
      this.uncheckOtherItems(item._id);  // Uncheck other items
    } else {
      this.selectedItem = null;
    }
  }
  uncheckOtherItems(selectedItemId: any) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      if (+checkbox.value !== selectedItemId) {
        checkbox.checked = false;
      }
    });
  }
  CompletePurchase(){
    this.showPlans=false;
    this.showPaymentDetails=true;
    this.initializeStripe();

  }
  // tslint:disable-next-line:typedef
  public login() {

    // if(!this.CheckBoxForDatingUser &&  !this.CheckBoxForNormalUser){
    //   this.notifyService.showError("Please select a login type","");
    // }
    if (this.loginForm.invalid) {
      return;
    }

    if(!this.acceptTerms){
      this.notifyService.showWarning('Please agree to Terms of Use and Privacy Policy', '');
    }

    const initialLoginWrapper = new Login();
    initialLoginWrapper.email = this.loginForm.value.userName;
    initialLoginWrapper.deviceType = StaticData.DeviceType;
    initialLoginWrapper.password = this.loginForm.value.password;
    initialLoginWrapper.longitude = +localStorage.getItem('longitude');
    initialLoginWrapper.latitude = +localStorage.getItem('latitude');
    initialLoginWrapper.deviceToken = Guid.create().toString();
    
    this.dataService.login(initialLoginWrapper).pipe(
      switchMap((initialResult: any) => {
        if (initialResult && initialResult.message === 'Success') {
          // Preparing for the ToggleUser API call
          let initialUserDetails = initialResult.data.customerData;
          const changetypeWrapper = new chagetype();
          changetypeWrapper.authorization = initialUserDetails.accessToken;
          changetypeWrapper.user_type = this.CheckBoxForDatingUser ? 1 : 0;
          changetypeWrapper.user_id = initialUserDetails._id;
    
          // ToggleUser API call
          return this.dataService.ToggleUser(changetypeWrapper).pipe(
            switchMap((toggleResult: any) => {
              if (toggleResult) {
                console.log(toggleResult);
    
                // Prepare for the main login API call
                const mainLoginWrapper = new Login();
                mainLoginWrapper.email = this.loginForm.value.userName;
                mainLoginWrapper.deviceType = StaticData.DeviceType;
                mainLoginWrapper.password = this.loginForm.value.password;
                mainLoginWrapper.longitude = +localStorage.getItem('longitude');
                mainLoginWrapper.latitude = +localStorage.getItem('latitude');
                mainLoginWrapper.deviceToken = Guid.create().toString();
    
                return this.dataService.login(mainLoginWrapper).pipe(
                  switchMap((result: any) => {
                    if (result && result.message === 'Success') {
                      let userDetails = result.data.customerData;
                      const servicecall = new GeTbALANCE();
                      servicecall.authorization = userDetails.accessToken;
                      return this.dataService.CheckPayment(servicecall).pipe(
                        switchMap((data: any) => {
                          if (data.StatusCode == 200 && userDetails.usertype == '1') {
                            if (data.Data != null) {
                              return this.dataService.IsSuspended({ "Email": mainLoginWrapper.email });
                            } else {
                              this.dataService.GetStripePlans().subscribe(
                                (planData: any) => {
                                  this.allplans = planData.data;
                                  this.selectedItem = this.allplans[0];
                                  this.showPlans = true;
                                },
                                (error) => {
                                  console.error(error);
                                }
                              );
    
                              this.LoginNeeded = false;
                              this.showPlans = true;
                              return [];
                            }
                          } else {
                            return this.dataService.IsSuspended({ "Email": mainLoginWrapper.email });
                          }
                        })
                      );
                    } else {
                      this.notifyService.showWarning(result.message, '');
                      throw new Error('Main login failed');
                    }
                  })
                );
              } else {
                this.notifyService.showWarning("Please try again", '');
                throw new Error('ToggleUser API call failed');
              }
            }),
            catchError((error) => {
              console.error('ToggleUser API error', error);
              return throwError(error);
            })
          );
        } else {
          this.notifyService.showWarning(initialResult.message, '');
          throw new Error('Initial login failed');
        }
      })
    ).subscribe(
      (suspendResult: any) => {
        if (suspendResult && suspendResult["Message"] == 'Success' && suspendResult.IsSuspended) {
          this.notifyService.showWarning("Your Account has been Suspended!!", '');
        } else {
          this.dataService.login(initialLoginWrapper).subscribe((finalResult) => {
            if (finalResult && finalResult.message === 'Success') {
              const msg =
                'Hi Robert ,' +
                finalResult.data.customerData.fullName +
                ' (' +
                this.loginForm.value.userName +
                ') ' +
                'Login in our FriendLinq. please contact this new user';
              const emailArr = ['info@friendlinq.com'];
    
              const emailJson = {
                email: emailArr,
                message: msg,
                Subject: 'FriendLinq Login Notification',
              };
    
              this.dataService.notifyUserByEmail(emailJson).subscribe(() => {
                console.log('Post Created', finalResult);
              });
    
              this.notifyService.showSuccess('Success', '');
              sessionStorage.setItem('isLoginDone', 'true');
              sessionStorage.setItem('loggedInUser', JSON.stringify(finalResult.data.customerData));
    
              localStorage.setItem("uid", this.generateUid().toString());
              localStorage.setItem('isLoginDone', 'true');
              localStorage.setItem('loggedInUser', JSON.stringify(finalResult.data.customerData));
    
              this.dataService.setOnlineStatus(1).subscribe(() => {});
    
              this.router.navigateByUrl('/dashboard');
            } else {
              this.notifyService.showWarning(finalResult.message, '');
            }
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
    
    
      
        

    
  }

  
generateUid() {
  /* var user = this.authService.getloggedUserDetails();
   if(user && user.email){
     return this.checksum(user.email);
   }*/
   const length = 5;
   const randomNo = (Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)));
   return randomNo;
 }

  // tslint:disable-next-line:typedef
  loginWithGoogle() {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        const loginWrapper = new LoginWithGoogle();
        loginWrapper.email = user.email;
        loginWrapper.deviceToken = Guid.create().toString();
        loginWrapper.deviceType = StaticData.DeviceType;
        loginWrapper.googleId = user.id;
        loginWrapper.longitude = 5.6;
        loginWrapper.latitude = 6.3;
        this.dataService.loginWithGoogle(loginWrapper).subscribe((result) => {
          if (result && result.message === 'Success') {
            this.notifyService.showSuccess('Success', '');
            sessionStorage.setItem(
              'loggedInUser',
              JSON.stringify(result.data.customerData)
            );
            
            localStorage.setItem(
              'loggedInUser',
              JSON.stringify(result.data.customerData)
            );
            this.router.navigateByUrl('/dashboard');
          } else {
            this.notifyService.showWarning(result.message, '');
          }
        });
      } else {
        this.notifyService.showError(
          'There is some error while getting response from google',
          'Error'
        );
      }
    });
  }
  getIPAddress() {
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((res: any) => {
        this.http
          .get('https://ipapi.co/' + res.ip + '/json')
          .subscribe((result: any) => {
            const json = {
              ipAddress: result.ip,
              country: result.country_name,
              state: result.region,
              city: result.city,
              postalcode: result.postal,
              latitude: result.latitude,
              longitude: result.longitude,
              type: 'home',
              deviceToken: moment().format('YYYY-MM-DD HH:mm:ss'),
            };
            this.dataService.saveRandomUser(json).subscribe((res: any) => {
              localStorage.setItem('Id', res.accessToken);
              localStorage.setItem('previousUserId', '0');
            });
          });
      });
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
}
