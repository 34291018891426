import { Component, Inject, OnInit,ViewChild ,TemplateRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/api/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from 'src/environments/environment';
import { FriendRequest } from '../../models/friend.model';
import { DataService } from '../../public/service/data.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css'],
})
export class CommentComponent implements OnInit {
  @ViewChild('editPostDialog') editPostDialog!: TemplateRef<any>;

  imageUrl: string;
  postDetail: any;
  commentDetail: any;
  userDetails: any;
  comment: string;
  index: number;
  clickBlurIndex: number;
  selectedPost: any = {};

  updatedComment:string;
  commentDialogOpen = false;
  editDialogRef: MatDialogRef<any>;
  editDialogOpen = false;

  constructor(
    private dialogRef: MatDialogRef<CommentComponent>,
    private authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private notifyService: NotificationService,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialog: MatDialog
  ) {
    this.imageUrl = environment.rearGuardImageUri;
    this.postDetail = data.postDetail;
    console.log(this.postDetail)
    this.commentDetail = data.commentDetail;
    this.index = data.index;
    this.clickBlurIndex = -1;
    this.userDetails = this.authService.getloggedUserDetails();
  }

  ngOnInit(): void {}
  onKeydown(event) {
    event.preventDefault();
  }
  removeBlur() {
    this.clickBlurIndex = 0;
  }
  saveComment(comment) {
    this.dataService
      .postComment(this.postDetail._id, comment)
      .subscribe((result: any) => {
        if (result && result.message === 'Success') {
          this.notifyService.showSuccess('Comment added successfully.', '');
          const res = result.data.comment;
          const json = { email: '', fullName: '', _id: '', imageURL: {} };
          json.email = this.userDetails.email;
          json.fullName = this.userDetails.fullName;
          json._id = this.userDetails._id;
          json.imageURL = this.userDetails.imageURL;
          res.commentAuthor = json;
          this.commentDetail.push(result.data.comment);
          this.comment = '';
          const searchFriend = new FriendRequest();
          searchFriend.limit = 0;
          searchFriend.skip = 0;
          searchFriend.status = 'Accepted';
          this.dataService
            .getFriendRequest(searchFriend)
            .subscribe((result: any) => {
              if (result && result.message === 'Success') {
                const userEmails = result.data.friendList.map((d) => d.email);

                const emailJson = {
                  email: userEmails,
                  message:
                    this.userDetails.fullName +
                    ' has commented on ' +
                    this.postDetail.postAuthor.fullName +
                    'post(' +
                    this.postDetail.postTitle +
                    ').',
                  Subject: 'Friendlinq Comment Notification',
                };
                this.dataService
                  .notifyUserByEmail(emailJson)
                  .subscribe((result: any) => {
                    console.log('Post Created', result);
                  });

                result.data.friendList.forEach((element) => {
                  const json = {
                    isDeleted: false,
                    isView: false,
                    isRead: false,
                    senderId: this.userDetails._id,
                    receiverId: element._id,
                    textMessage:
                      this.userDetails.fullName +
                      ' has commented on ' +
                      this.postDetail.postAuthor.fullName +
                      'post(' +
                      this.postDetail.postTitle +
                      ').',
                    notificationType: 'Friends_Post_Comment',
                  };
                  this.dataService.notifyUser(json).subscribe((result: any) => {
                    console.log('Post Created', result);
                  });
                });
              }
            });
        }
      });
  }
  closeComment() {
    const json = {};
    json['comment'] =
      this.commentDetail.length > 3
        ? this.commentDetail
            .slice(Math.max(this.commentDetail.length - 2, 1))
            .reverse()
        : this.commentDetail.reverse();
    json['index'] = this.index;
    json['count'] = this.commentDetail.length;

    this.dialogRef.close(json);
  }
  deleteComment(id, index) {
    this.dataService.deleteComment(id).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Comment deleted successfully.', '');
        this.commentDetail.splice(index, 1);
      }
    });
  }
 
  UpdateLikeStatus(id, status) {
    let likeStatus: boolean = !status;

    this.dataService.postLike(id, likeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Post Like status updated.', '');
        if (likeStatus === false) {
          this.postDetail.totalLike =
            this.postDetail.totalLike === 0
              ? 0
              : this.postDetail.totalLike - 1;
        } else {
          this.postDetail.totalLike++;

          if (this.postDetail.isDislike) {
            this.postDetail.totalDislike =
              this.postDetail.totalDislike === 0
                ? 0
                : this.postDetail.totalDislike - 1;
            this.postDetail.isDislike = false;
          }
        }
        this.postDetail.isLike = likeStatus;
      }
    });
  }
  UpdateDislikeStatus(id, status) {

    let dislikeStatus: boolean = !status;

    this.dataService.postDislike(id, dislikeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Post Like status updated.', '');
        if (dislikeStatus === false) {
          this.postDetail.totalDislike =
            this.postDetail.totalDislike === 0
              ? 0
              : this.postDetail.totalDislike - 1;
        } else {
          this.postDetail.totalDislike++;
          if (this.postDetail.isLike) {
            this.postDetail.totalLike =
              this.postDetail.totalLike === 0
                ? 0
                : this.postDetail.totalLike - 1;
            this.postDetail.isLike = false;
          }
        }
        this.postDetail.isDislike = dislikeStatus;
        // 
      }

    });
  }

  openEditDialog(id, index): void {
    this.updatedComment='';
    // edited by Anjali
    this.selectedPost = this.commentDetail[index];
    // ------
    this.editDialogRef=this.dialog.open(this.editPostDialog, {
      width: '400px',
      data:{index:index}
    });
    this.editDialogOpen = true;

  }

  onSaveClick(id,data){
    const formData = new FormData();

    formData.append('postId', this.postDetail._id);
    formData.append('commentId', this.selectedPost._id);
    formData.append('commentText', this.selectedPost.commentText);
    
    this.dataService.UpdatePostComment(formData).subscribe((result: any) => {
          this.notifyService.showSuccess('Comment updated successfully.', '');
          // updated by Anjali
          const commentIndex = this.commentDetail.findIndex(comment => comment._id === this.selectedPost._id);
        if (commentIndex !== -1) {
            this.commentDetail[commentIndex].commentText = this.selectedPost.commentText;
        }
        // ------
      });
      this.closeEditDialog();
    }

    closeCommentEdit() {

      this.closeEditDialog();
    }

    closeEditDialog() {
      if (this.editDialogRef) {
       
        this.editDialogRef.close();
        this.editDialogOpen = false;
      }
    }
}
