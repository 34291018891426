<a class="close-button" style="cursor: pointer;" (click)="closeComment()"><i class="fa fa-close"
        style="color: #0041c1"></i></a>
<h2 mat-dialog-title class="title">Comments</h2>

<mat-dialog-content>
    <div class="row">
        <div class="col-md-12 col-12">

            <div class="people-all-comment">

                <div class="person-post">
                    <p *ngIf="postDetail.postAuthor.imageURL!== undefined"><img
                            src="{{imageUrl}}/{{postDetail.postAuthor.imageURL.original}}"></p>
                    <p *ngIf="postDetail.postAuthor.imageURL!== undefined">{{postDetail.postAuthor.fullName}} <span>{{
                            postDetail.createdAt |
                            date :'h:mm a dd-MMM-yy'
                            }}</span></p>
                    <p *ngIf="postDetail.Author && postDetail.Author.imageURL!== undefined"><img
                            src="{{imageUrl}}/{{postDetail.Author.imageURL.original}}"></p>
                    <p *ngIf="postDetail.Author && postDetail.Author.imageURL!== undefined">
                        {{postDetail.Author.fullName}} <span>{{
                            postDetail.createdAt |
                            date :'h:mm a dd-MMM-yy'
                            }}</span></p>
                    <p>

                    </p>
                </div>


                <div class="latest-post" *ngIf="!postDetail.sensitive">
                    <p>{{postDetail.postContent}}</p>
                    <p *ngIf="postDetail.imageURL.original !== null"><img class="timeline"
                            src="{{imageUrl}}/{{postDetail.imageURL.original}}">
                    <p *ngIf="postDetail.videoURL !== null"> <video width="420" height="345" controls>
                            <source src="{{imageUrl}}/{{postDetail.videoURL}}" type="video/mp4">
                        </video>
                    </p>
                </div>
                <div class="latest-post" *ngIf="postDetail.sensitive">
                    <p>{{postDetail.postContent}}</p>
                    <p *ngIf="postDetail.imageURL.original !== null && clickBlurIndex === -1"><img
                            class="blurPost timeline" src="{{imageUrl}}/{{postDetail.imageURL.original}}">
                    </p>

                    <p *ngIf="postDetail.imageURL.original !== null && clickBlurIndex === 0"><img class="timeline"
                            src="{{imageUrl}}/{{postDetail.imageURL.original}}">
                    </p>
                    <p *ngIf="postDetail.videoURL !== null &&  clickBlurIndex === -1">
                        <video width="420" height="345" controls class="blurPost">
                            <source src="{{imageUrl}}/{{postDetail.videoURL}}" type="video/mp4">
                        </video>
                    </p>
                    <p *ngIf="postDetail.videoURL !== null &&  clickBlurIndex === 0"> <video width="420" height="345"
                            controls>
                            <source src="{{imageUrl}}/{{postDetail.videoURL}}" type="video/mp4">
                        </video>
                    </p>
                    <p>This post is bad content.
                        <strong> <a style="cursor: pointer;" style="color: #0041c1;cursor: pointer;"
                                (click)="removeBlur()">See this
                                post</a></strong>
                    </p>
                </div>
                <div class="likecomment-section">
                    <div class="like-part">
                        <p><span><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></span> {{postDetail.totalLike}}
                            <span class="ml-4"><i class="fa fa-thumbs-down" aria-hidden="true"></i></span>
                            {{postDetail.totalDislike}}
                        </p>
                    </div>
                    <div class="comment-part">
                        <p>{{commentDetail.length}} Comments</p>
                    </div>
                </div>
                <div class="share">
                    <div class="like-part">
                        <p *ngIf="postDetail.isLike" style="color: #0041c1;cursor: pointer;"
                            (click)="UpdateLikeStatus(postDetail._id,postDetail.isLike)"><i class="fa fa-thumbs-o-up"
                                aria-hidden="true" style="color: #0041c1;cursor: pointer"></i>
                            Like</p>
                        <p style="cursor: pointer;" *ngIf="!postDetail.isLike"
                            (click)="UpdateLikeStatus(postDetail._id,postDetail.isLike)"><i class="fa fa-thumbs-o-up"
                                aria-hidden="true"></i>
                            Like</p>
                    </div>
                    <div class="like-part">
                        <p *ngIf="postDetail.isDislike" style="color: #0041c1;cursor: pointer;"
                            (click)="UpdateDislikeStatus(postDetail._id,postDetail.isDislike)"><i
                                class="fa fa-thumbs-o-up" aria-hidden="true" style="color: #0041c1;cursor: pointer"></i>
                            Dislike</p>
                        <p style="cursor: pointer;" *ngIf="!postDetail.isDislike"
                            (click)="UpdateDislikeStatus(postDetail._id,postDetail.isDislike)"><i
                                class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                            Dislike</p>
                    </div>
                    <div style="cursor: pointer;" class="comment-part">
                        <p><i class="fa fa-comment-o" aria-hidden="true"></i> Comment</p>
                    </div>
                    <!-- <div class="comment-part">
                        <p><i class="fa fa-share" aria-hidden="true"></i> Share</p>
                    </div> -->
                </div>
                <div class="public-comments-main">
                    <div *ngFor="let comment of commentDetail,let i = index">
                        <div class="public-comments">
                            <div class="public-img">
                                <img src="{{imageUrl}}/{{comment.commentAuthor.imageURL.original}}">
                            </div>
                            <div class="publiccommentInner">
                                <h5>{{comment.commentAuthor.fullName}}</h5>
                                <p class="comment"> {{ comment.commentText }}
                                    <a *ngIf="comment.commentAuthor._id===userDetails._id" class="comment-delete"
                                        (click)="deleteComment(comment._id,i)"><i class="fa fa-close"
                                            style="color: #0041c1"></i></a>

                                    <a *ngIf="comment.commentAuthor._id===userDetails._id" class="comment-update"
                                        (click)="openEditDialog(comment._id,i)"><i class="fa fa-edit"
                                            style="color: #0041c1"></i></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Dialog template -->
                <ng-template #editPostDialog let-dialogRef>
                    <a class="close-button" style="cursor: pointer;" (click)="closeCommentEdit()"><i class="fa fa-close"
                            style="color: #0041c1"></i></a>
                    <h1 mat-dialog-title>Edit Comment</h1>
                    <div mat-dialog-content>
                        <form>
                            <mat-form-field appearance="fill" class="full-width">
                                <textarea matInput placeholder="Post content" [(ngModel)]="selectedPost.commentText"
                                    name="content"></textarea>
                            </mat-form-field>
                        </form>
                    </div>

                    <div mat-dialog-actions>
                        <button mat-raised-button color="primary" class="update-button"
                            (click)="onSaveClick(postDetail._id,dialogRef)">Update</button>
                    </div>
                </ng-template>
                <!-- Dialog template -->

                <!-- <div class="self-commnet">
                    <div class="public-comments">
                        <div class="public-img">
                            <img src="{{imageUrl}}/{{userDetails.imageURL.original}}">
                        </div>
                        <div class="mycomment">
                            <textarea name="txtComment" placeholder="Write a public comment here..."
                                (keydown.enter)="onKeydown($event)" (keyup.enter)="saveComment(comment)"
                                [(ngModel)]="comment"> </textarea>

                        </div>
                    </div>
                </div> -->

                <div class="self-comment">
                    <div class="public-comments">
                        <div class="public-img">
                            <img src="{{imageUrl}}/{{userDetails.imageURL.original}}">
                        </div>
                        <div class="mycomment">
                            <div class="textarea-container">
                                <textarea name="txtComment" placeholder="Write a public comment here..."
                                    (keydown.enter)="onKeydown($event)" (keyup.enter)="saveComment(comment)"
                                    [(ngModel)]="comment"></textarea>
                                <div class="submit-arrow" (click)="saveComment(comment)">
                                    <i data-visualcompletion="css-img" class="x1b0d499 xi3auck" style="background-image: url(&quot;https://static.xx.fbcdn.net/rsrc.php/v3/y3/r/caU4XPxdXgn.png&quot;); background-position: 0px -381px; background-size: auto; width: 16px; height: 16px; background-repeat: no-repeat; display: inline-block;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
</mat-dialog-content>