import { Component, OnInit } from '@angular/core';
import { DataService } from '../../public/service/data.service';
import { FriendRequest } from '../../models/friend.model';
import { AuthService } from 'src/app/shared/api/auth.service';

import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-schedule-meeting',
  templateUrl: './schedule-meeting.component.html',
  styleUrls: ['./schedule-meeting.component.css']
})
export class ScheduleMeetingComponent implements OnInit {

  isAddMore: boolean = false;
  chatList: Array<any> = [];
  usersInCall:Array<string> = [];
  userNameInCall = {};
  mydate:string;
  type:string = "video";
  titleSchedule:string = "";
  scheduleLink:string = "";
  user;
  /*client: branchio = { 
    appId: "1299425169005105887",
    key: "key_test_azpMkkUgw4ZlraXTC7PM0difFBbGXyX1",
    secret: "secret_test_GcyFhGznXP2jffm0l7fXEmBq92TewlQG"
  };*/

  constructor(private dataService: DataService,
    private notifyService: NotificationService, private authService: AuthService) { 
    //this.addMore();
    this.mydate = new Date().toISOString().slice(0, 16);
      this.user = authService.getloggedUserDetails();
  }

  ngOnInit(): void {
    this.addMore();
  }

  

addMore(){
  if(!this.chatList || this.chatList.length == 0){
    this.loadChatList();
  }
  else{
    this.isAddMore = true;
  }
}

  closeAddMore(){
    this.isAddMore = false;
  }

  AddUser(frd){
    if(this.usersInCall.indexOf(frd.email) < 0){
      this.userNameInCall[frd.email] = frd.fullName;
      this.usersInCall.push(frd.email);
    }
  }

  RemoveUser(email){
    this.usersInCall.splice(this.usersInCall.indexOf(email), 1);
  }

  
  async SaveMeeting(){
   /* const url =  await this.client.link({ 
      alias: '',
      stage: 'new user',
      channel: 'facebook',
      feature: 'dashboard',
      campaign: 'content 123',
      tags: [ 'tag1', 'tag2', 'tag3' ],
      data: {
        'custom_bool': true,
        '$og_title': 'Title',
        '$og_description': 'Description',
        '$og_image_url': 'https://lorempixel.com/400/400'
      }
    });*/
    
    var data = {
      emails: this.usersInCall.join(',')+","+this.user.email,
      channelId: "Meeting-"+ new Date().getTime(),
      scheduleDate: this.mydate+":00Z",
      //scheduleTime:this.mydate.toISOString(),
      callType: this.type,
      //inviteLink:this.scheduleLink,
      hostId:this.user._id,
      title: this.titleSchedule,
      names: this.usersInCall.join(',')+","+this.user.email
    };

    this.dataService.scheduleMeeting(data).subscribe((res) => {
      this.notifyService.showSuccess("Meeting Scheduled Successfully", "Success");
    });
  }

loadChatList(){
  const searchFriend = new FriendRequest();
    searchFriend.limit = 0;
    searchFriend.skip = 0;
    searchFriend.status = 'Accepted';
  this.dataService.getFriendRequest(searchFriend).subscribe((result: any) => {
    if (result && result.statusCode === 200) {
      this.chatList = result.data.friendList;

      this.isAddMore = true;
    }
  });
}
}
