import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-portfolio-modal',
  templateUrl: './portfolio-modal.component.html',
  styleUrls: ['./portfolio-modal.component.css'],
})
export class PortfolioModalComponent implements OnInit {
  imageUrl: string;
  imageUrlSource: string;
  constructor(
    private dialogRef: MatDialogRef<PortfolioModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.imageUrl = data;
  }

  ngOnInit(): void {}
  closeComment() {
    this.dialogRef.close();
  }
}
