<div class="custom-dialog-container">
  <div class="dialog-header">
    <h2 *ngIf="isPhotoVideo==='Add video'">Update profile video</h2>
    <h2 *ngIf="isPhotoVideo==='Add Top 4 Images'">Update Top 4 Profile Images</h2>

    <button class="close-button" (click)="closeDialog()">X</button>

  </div>

  <!-- <form (ngSubmit)="onUpload()">
    <input type="file" (change)="onFileSelected($event)" accept="image/*, video/*">
    <button type="submit" class="update-button" [disabled]="!fileSelected">Upload</button>
  </form> -->
  <!-- <div *ngIf="thumbnail" class="thumbnail">
    <img *ngIf="isImage" [src]="thumbnail" alt="Uploaded Image Thumbnail">
    <video *ngIf="isVideo" [src]="thumbnail" controls autoplay muted></video>
  </div> -->

  <div class="row">
    <div class="column thumbnail" *ngFor="let item of getFilteredPortfolio(customerPortfolio)">
      <ng-container *ngIf="isImage(item.imageURL); else videoTemplate">
        <img src="{{imageUrl}}/{{item.imageURL}}" style="width:200px;height:200px" >
      </ng-container>
      <ng-template #videoTemplate>
        <video  style="width:200px;height:200px;"  controls muted loop>
          <source src="{{imageUrl}}/{{item.imageURL}}" type="video/mp4">
        </video>
      </ng-template>
      <input type="checkbox" [(ngModel)]="item.isSelected"  (change)="onCheckboxChange(item, $event)">
      <a class="delete-Icon" (click)="delete(item)">
        <span class="delete-Icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
      </a>
    </div>
  </div>


  <div *ngIf="error" class="thumbnail">
    <p>{{error}}</p>
  </div>


</div>