<div class="login signup">
	<div class="container">
		<div class="row" *ngIf="isProcessing">
			<i style="margin: auto;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
		</div>
		<div class="row" *ngIf="!isProcessing">
			<div class="col-md-12 col-12">
				<div class="login-inner">
					<!-- ADDED FOR DISPLAYING PLANS -->
					<div *ngIf="showPlans">
						<div class="row">
							<div class="col-md-12 col-12" style="font-size: 30px;">
								<B>Flirting/Dating Social Media Plan</B>
							</div>
						</div>
						<div class="row" style="color: green; margin-top: 10px;">
							<div class="col-md-12 col-12">
								<span style="font-size: 20px;">
									<i class="fa fa-gift" aria-hidden="true"></i>
									Limited Time Offer Save over 60% OFF!
								</span>
							</div>
						</div>

						<!-- Plan Options Section -->
						<div class="row" style="margin-top: 10px;">
							<div class="col-md-12 col-12">
								<b>Plan options</b>
								<div style="margin-top: 5px;">
									<p>Monthly Plan: $2.99/month, Billed monthly at $2.99</p>
									<p>Annual Plan: $0.99/month, Billed annually at $11.88. <span
											style="color: green;">Save 66% compared to monthly billing</span></p>
									<p style="color: green;">*Save 66% compared to monthly billing</p>
								</div>
							</div>
						</div>

						<!-- Special Offer Timer Section -->
						<div class="row" style="margin-top: 10px;">
							<div class="col-md-12 col-12">
								<b>Your Special $0.99 Offer ends in:</b>
								<div style="font-size: 25px; color: red;">
									23:59:53 <!-- You can replace this with a dynamic timer -->
								</div>
							</div>
						</div>

						<!-- Lock In Rate and Free Trial Section -->
						<div class="row" style="margin-top: 10px;">
							<div class="col-md-12 col-12">
								<button class="btn btn-success" style="margin-top: 10px; border-radius: 30px;">
									Lock in this Special Annual Rate
								</button>
								<p style="margin-top: 5px; color: green;">Try free for 7 days</p>
							</div>
						</div>

						<div class="row" style="margin-top: 20px;">
							<div class="col-md-12 col-12">
								<h2 class="comparison-heading text-center">Feature Comparison Table</h2>
						
								<div class="comparison-section" style="text-align: left; margin-left: 20px;">
									<h3>Free Social</h3>
									<p>Profile Custom Creation:</p><br>
									<p>Messaging: Limited</p><br>
									<p>Photo Uploads: 10 max</p><br>
									<p>Groups: ✓</p><br>
									<p>Notifications: Limited</p><br>
								</div>
						
								<div class="comparison-section" style="text-align: left; margin-left: 20px;">
									<h3>Premium Flirting/Dating</h3>
									<p>Profile Custom Creation: ✓</p><br>
									<p>Messaging: Unlimited</p><br>
									<p>Photo Uploads: Unlimited</p><br>
									<p>Video Uploads: Unlimited</p><br>
									<p>Groups: ✔</p><br>
									<p>Notifications: Unlimited</p><br>
									<p>Voice/Video Calls: Unlimited</p><br>
									<p>Switching from Social to Flirting: ✓</p><br>
								</div>
								
								<p style="color: green; margin-top: 10px;">
									Limited Time: Get all the premium features for a full year for only $11.88.<br>
								</p>
								<p style="margin-top: 10px;">
									Annual subscription is paid in full at the beginning of the billing cycle. Monthly
									rate shown for comparison. Offer valid for new subscribers only. Regular annual
									price of $2.99 per month will apply after the first year.
								</p>
								<button class="btn btn-success" style="margin-top: 10px; border-radius: 30px;"(click)="CompletePurchase(allplans[1])">Choose
									the Special Annual Plan</button><br>
								<button class="btn btn-primary" style="margin-top: 10px; border-radius: 30px;"(click)="CompletePurchase(allplans[0])">Choose
									the Monthly Plan</button>
							</div>
						</div>
<!-- 
						<div class="row">

							<div class="col-md-6 col-6" *ngFor="let item of allplans">
								<label>
									<input type="checkbox" (change)="onCheckboxChange(item, $event)"
										[checked]="isChecked(item)" value="item._id" />
									{{ item.Name }}
								</label>

							</div>
						</div> -->
						<!-- <div class="row">
							<div class="col-md-3 col-3"></div>
							<div class="col-md-6 col-6">
								<div *ngIf="selectedItem" style="color:green ; box-shadow: 10px 10px 10px grey;">

									<p>{{ selectedItem.Description}}</p>
									<div style="background-color: lightgrey; font-size: 35px;">
										<i class="fa fa-check-circle"></i>
										Free trail
									</div>

								</div>
								<div class="col-md-3 col-3"></div>
							</div>

						</div> -->
						<div class="row">
							<div class="col-md-12 col-12">
								<button style="margin-top: 20px;
							border-radius: 30px;
						" (click)="CompletePurchase(allplans[0])">Complete purchase</button>
							</div>
						</div>
					</div>

					<div [ngStyle]="{ 'display': showPaymentDetails ? 'block' : 'none' }">
						<div class="payment-form">
							<form (submit)="handlePayment()">
								<div class="form-group">
									<label for="card-number-element">Card Number</label>
									<div id="card-number-element" class="stripe-element"></div>
								</div>

								<div class="form-group">
									<label for="card-expiry-element">Expiration Date</label>
									<div id="card-expiry-element" class="stripe-element"></div>
								</div>

								<div class="form-group">
									<label for="card-cvc-element">CVC</label>
									<div id="card-cvc-element" class="stripe-element"></div>
								</div>

								<button type="submit" class="btn btn-primary">Submit Payment</button>
							</form>

							<div *ngIf="errorMessage" class="error-message">
								{{ errorMessage }}
							</div>
						</div>
					</div>

					<div *ngIf="(socialFormStatus && !datingstatus)">
						<form *ngIf="!emailVerified" [formGroup]="signupForm">
							<h6 style="font-weight: bolder;">Complete Your Social Media Profile</h6>
							<div class="form-group">
								<h6>Upload Your Profile Picture</h6>
								<div class="upload-container">
									<input type="file" id="profilePicture" (change)="onFileChange($event)" />
									<label for="profilePicture" class="upload-icon"
										[ngStyle]="{'background-image': imgURL ? 'url(' + imgURL + ')' : 'none'}">
										<i *ngIf="!imgURL" class="fa fa-user" aria-hidden="true"></i>
									</label>
								</div>

								<!-- Display the image preview in the same icon -->
								<div *ngIf="imgURL" class="file-name">{{ selectedFileName }}</div>
							</div>
							<div class="form-group">
								<input type="text" placeholder="Name" name="name" formControlName="name">
								<span class="required-asterisk">*</span>
								<div class="text-danger" *ngIf="
                                        signupForm.get('name')?.invalid &&
                                        (signupForm.get('name')?.touched ||
                                            signupForm.get('name')?.dirty)
                                    ">
									Name is required.
								</div>
							</div>

							<div class="form-group">
								<select name="age" formControlName="age">
									<option value="">Age(required)</option>
									<option *ngFor="let age of ageList" [ngValue]="age">
										{{age}}
									</option>
								</select>
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('age').invalid && (signupForm.get('age').touched || signupForm.get('age').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('age').errors?.required">Age is required.</span>
								</div>
							</div>
							<div class="form-group">
								<div>
									<label for="dob">DOB</label>
								</div>
								<input type="date" id="dob" placeholder="DOB" name="dob" formControlName="dob"
									min="1900-01-01" max="2099-12-31">
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('dob').invalid && (signupForm.get('dob').touched || signupForm.get('dob').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('dob').errors?.required">DOB is required.</span>
								</div>
							</div>
							<div class="form-group">
								<select name="gender" formControlName="gender">
									<option value="">Select your Gender</option>
									<option *ngFor="let gender of genderList" [ngValue]="gender">
										{{gender}}
									</option>
								</select>
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('gender').invalid && (signupForm.get('gender').touched || signupForm.get('gender').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('gender').errors?.required">Gender is required.</span>
								</div>
							</div>

							<div class="form-group">
								<input type="text" placeholder="Phone Number" name="phoneNumber"
									formControlName="phoneNumber">
								<span class="required-asterisk">*</span>
							</div>


							<div class="form-group">
								<input type="email" placeholder="Email" name="email" formControlName="email">
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('email').invalid && (signupForm.get('email').touched || signupForm.get('email').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('email').errors?.required">Email is required.</span>
									<span *ngIf="signupForm.get('email').errors?.email">Please enter a valid email
										address.</span>
								</div>
							</div>

							<div class="form-group">
								<input type="text" placeholder="About Me" name="about" formControlName="about">
								<span class="required-asterisk">*</span>
							</div>

							<div class="form-group">
								<select name="country" formControlName="country">
									<option value="">Choose your Country</option>
									<option *ngFor="let country of countryList" [ngValue]="country.name">
										{{country.name}}
									</option>
								</select>
								<span class="required-asterisk">*</span>
								<div class="text-danger" *ngIf="
                                        signupForm.get('country')?.invalid &&
                                        (signupForm.get('country')?.touched ||
                                            signupForm.get('country')?.dirty)
                                    ">
									Country is required.
								</div>
							</div>

							<div class="form-group">
								<input type="text" placeholder="Street Address" name="streetAddress"
									formControlName="streetAddress">
							</div>


							<div class="form-group">
								<input type="text" placeholder="Other Address" name="otherAddress"
									formControlName="otherAddress">
								<span class="required-asterisk">*</span>
							</div>
							<div class="form-group">
								<input type="text" placeholder="City" name="city" formControlName="city">
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('city').invalid && (signupForm.get('city').touched || signupForm.get('city').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('city').errors?.required">City is required.</span>
								</div>
							</div>

							<div class="form-group">
								<input type="text" placeholder="Postal Code" name="postalCode"
									formControlName="postalCode">
								<span class="required-asterisk">*</span>
							</div>


							<div class="row">
								<div class="col-md-12 col-12">
									<a class="btn btn-primary" style="margin-top: 20px; border-radius: 30px;"
										(click)="signup(signupForm)">
										Update My Social Account
									</a>
								</div>
							</div>

						</form>
					</div>

					<div *ngIf="datingFormStatus">
						<form *ngIf="!emailVerified" [formGroup]="signupForm">
							<h1>Sign Up</h1>
							<div class="form-group">
								<h6>Upload Your Profile Picture</h6>
								<div class="upload-container">
									<input type="file" id="profilePicture" (change)="onFileChange($event)" />
									<label for="profilePicture" class="upload-icon"
										[ngStyle]="{'background-image': imgURL ? 'url(' + imgURL + ')' : 'none'}">
										<i *ngIf="!imgURL" class="fa fa-user" aria-hidden="true"></i>
									</label>
								</div>
								<input type="text" placeholder="Name" name="name" formControlName="name">
								<span class="required-asterisk">*</span>

								<div *ngIf="signupForm.get('name').invalid && (signupForm.get('name').touched || signupForm.get('name').dirty)"
									class="text-danger">

									<span *ngIf="signupForm.get('name').errors?.required">Name is required.</span>
								</div>

							</div>
							<div class="form-group">
								<input type="email" placeholder="Email" name="email" formControlName="email">
								<span class="required-asterisk">*</span>

								<div *ngIf="signupForm.get('email').invalid && (signupForm.get('email').touched || signupForm.get('email').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('email').errors?.required">Email is required.</span>
									<span *ngIf="signupForm.get('email').errors.email">Please enter a valid email
										address.</span>
								</div>

							</div>
							<div class="form-group">
								<select name="age" formControlName="age">
									<option value="">Age(required)</option>
									<option *ngFor="let age of ageList" [ngValue]="age">
										{{age}}
									</option>
								</select>
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('age').invalid && (signupForm.get('age').touched || signupForm.get('age').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('age').errors?.required">Age is required.</span>
								</div>
							</div>
							<div class="form-group">
								<div>
									<label for="dob">DOB</label>
								</div>
								<input type="date" id="dob" placeholder="DOB" name="dob" formControlName="dob"
									min="1900-01-01" max="2099-12-31">
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('dob').invalid && (signupForm.get('dob').touched || signupForm.get('dob').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('dob').errors?.required">DOB is required.</span>
								</div>
							</div>
							<div class="form-group">
								<select name="gender" formControlName="gender">
									<option value="">Select your Gender</option>
									<option *ngFor="let gender of genderList" [ngValue]="gender">
										{{gender}}
									</option>
								</select>
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('gender').invalid && (signupForm.get('gender').touched || signupForm.get('gender').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('gender').errors?.required">Gender is required.</span>
								</div>
							</div>
							<div class="form-group">
								<input type="text" placeholder="Phone Number" name="phoneNumber"
									formControlName="phoneNumber">
								<span class="required-asterisk">*</span>
							</div>
							<div class="form-group">
								<select name="country" formControlName="country"
									(change)="onCountryChange($event.target.value)">
									<option value="">Choose your Country</option>
									<option *ngFor="let country of countryList" [ngValue]="country.name">
										{{country.name}}
									</option>
								</select>
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('country').invalid && (signupForm.get('country').touched || signupForm.get('country').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('country').errors?.required">Country is required.</span>
								</div>
							</div>

							<div class="form-group" *ngIf="states.length > 0">
								<select name="state" formControlName="state">
									<option value="">Choose your State</option>
									<option *ngFor="let state of states" [ngValue]="state.name">
										{{ state.name }}
									</option>
								</select>
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('state').invalid && (signupForm.get('state').touched || signupForm.get('state').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('state').errors?.required">State is required.</span>
								</div>
							</div>

							<div class="form-group">
								<input type="text" placeholder="Street Address" name="streetAddress"
									formControlName="streetAddress">
							</div>
							<div class="form-group">
								<input type="text" placeholder="Other Address" name="otherAddress"
									formControlName="otherAddress">
								<span class="required-asterisk">*</span>
							</div>

							<div class="form-group">
								<input type="text" placeholder="City" name="city" formControlName="city">
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('city').invalid && (signupForm.get('city').touched || signupForm.get('city').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('city').errors?.required">City is required.</span>
								</div>
							</div>
							<div class="form-group">
								<input type="text" placeholder="Occupation" name="occupation"
									formControlName="occupation">
							</div>
							<div class="form-group">
								<select name="education" formControlName="education">
									<option value="">Education level</option>
									<option *ngFor="let education of educationLevelList" [ngValue]="education">
										{{education}}
									</option>
								</select>
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('education').invalid && (signupForm.get('education').touched || signupForm.get('education').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('education').errors?.required">Education is
										required.</span>
								</div>
							</div>
							<div class="form-group">
								<select name="personality" formControlName="personality">
									<option value="">Personality</option>
									<option *ngFor="let personality of personalityList" [ngValue]="personality">
										{{personality}}
									</option>
								</select>
							</div>
							<div class="form-group">
								<select name="lookingFor" formControlName="lookingFor">
									<option value="">Looking for(required)</option>
									<option *ngFor="let lookingFor of lookingForList" [ngValue]="lookingFor">
										{{lookingFor}}
									</option>
								</select>
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('lookingFor').invalid && (signupForm.get('lookingFor').touched || signupForm.get('lookingFor').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('lookingFor').errors?.required">Looking for is
										required.</span>
								</div>
							</div>

							<div class="form-group">
								<input type="text" placeholder="Interests and hobbies" name="interestsHobbies"
									formControlName="interestsHobbies">
							</div>
							<div class="form-group">
								<select name="exercise" formControlName="exercise">
									<option value="">Exercise habits</option>
									<option *ngFor="let exercise of exerciseHabitsList" [ngValue]="exercise">
										{{exercise}}
									</option>
								</select>
							</div>
							<div class="form-group">
								<select name="alcohol" formControlName="alcohol">
									<option value="">Alcohol use</option>
									<option *ngFor="let alcohol of alcoholUseList" [ngValue]="alcohol">
										{{alcohol}}
									</option>
								</select>
							</div>
							<div class="form-group">
								<select name="drug" formControlName="drug">
									<option value="">Drug use</option>
									<option *ngFor="let drug of drugUseList" [ngValue]="drug">
										{{drug}}
									</option>
								</select>
							</div>
							<div class="form-group">
								<select name="politicalViews" formControlName="politicalViews">
									<option value="">Political views</option>
									<option *ngFor="let politicalViews of politicalViewsList"
										[ngValue]="politicalViews">
										{{politicalViews}}
									</option>
								</select>
							</div>
							<div class="form-group">
								<select name="religion" formControlName="religion">
									<option value="">Religion</option>
									<option *ngFor="let religion of religionList" [ngValue]="religion">
										{{religion}}
									</option>
								</select>
							</div>

							<div class="form-group">
								<input type="password" placeholder="Password" name="password"
									formControlName="password">
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('password').invalid && (signupForm.get('password').touched || signupForm.get('password').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('password').errors?.required">Password is
										required.</span>
								</div>
							</div>
							<div class="form-group">
								<input type="password" placeholder="Confirm Password" name="confirmpassword"
									formControlName="confirmpassword">
								<span class="required-asterisk">*</span>
								<div *ngIf="signupForm.get('confirmpassword').invalid && (signupForm.get('confirmpassword').touched || signupForm.get('confirmpassword').dirty)"
									class="text-danger">
									<span *ngIf="signupForm.get('confirmpassword').errors?.required">Confirm Password is
										required.</span>
								</div>
								<div class="text-danger" *ngIf="passwordNotMatch">Passwords must match</div>
							</div>

							<div class="form-group button-div" style="margin-top: 70px;">
								<button class="btn btn-primary" (click)="signup(signupForm)"
									[disabled]="!signupForm.valid">Update My Flirting Account
								</button>
							</div>
						</form>
					</div>

					<!-- <div class="extra-text">
						<p>Already have an account? <span><a routerLink="/login">Login</a></span></p>
					</div> -->
					<!-- <div>
						<label>
							<input style="width: 20px;" type="checkbox" [checked]="acceptTerms"
								(change)="acceptTerms = !acceptTerms" />
							By continuing, I agree to <a target="terms-and-conditions"
								href="#/terms-and-conditions">Terms of Use</a> and <a target="privacy-policy"
								href="#/privacy-policy">Privacy Policy</a>
						</label>
					</div> -->
				</div>

			</div>
		</div>
	</div>
</div>