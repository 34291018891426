import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../public/service/data.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/services/notification.service';

import { AuthService } from 'src/app/shared/api/auth.service';
import { SightengineService } from 'src/app/shared/services/sightengine.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {
  @ViewChild('attachments', { static: false }) attachments: ElementRef;
  @ViewChild('videoattachment', { static: false }) videoattachment: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  uploadedFile: any;
  uploadedPrivateFile: any;
  imageUrl: string;
  imageUrlPrivate: string;
  showLoader: boolean;
  myPortfolio: any;
  myPrivatePortfolio: any;
  selectedPort: string = 'public';
  showValidation: boolean = false;
  //added by poonam
  selectedMediaType: string = 'image'; 
  frames: string[] = []; // This will store base64 strings of the frames

  //added by poonam

  constructor(
    private dataService: DataService,
    private notifyService: NotificationService,
    private authService: AuthService,
    private sightengineService: SightengineService
  ) {
    this.imageUrl = environment.rearGuardImageUri;
    this.imageUrlPrivate = environment.rearGuradNetUri;
    this.getPortfolio();
    this.getPrivatePortfolio();
  }

  ngOnInit(): void {}

  // tslint:disable-next-line:typedef
  onClick() {
    console.log("hello image");
    const fileUpload = this.attachments.nativeElement;
    this.uploadedFile = [];
    fileUpload.onchange = () => {
      if (fileUpload.files.length > 5) {
        this.notifyService.showInfo(
          'You can upload maximum 5 pictures at one time.',
          ''
        );
        return;
      }
      let istrue:Boolean=true;
      for (let index = 0; index < fileUpload.files.length; index++) {
        this.sightengineService.checkImage(fileUpload.files[index], 'nudity-2.1').subscribe(
          response => {
            console.log('success');
            console.log(response);
            if(response.nudity.none>='0.90'){
            this.uploadedFile.push(fileUpload.files[index]);
            console.log("uploadedFile value")
            console.log(this.uploadedFile)
          }
          else{
            istrue=false;
            this.notifyService.showInfo(
              'This image contains inappropriate content. Please select image again.',
              ''
            );
          }
        if(istrue) {
        this.uploadPortfolio();
        }
          },
          error => {
            console.log('failed');
            console.log(error);
            this.notifyService.showInfo(
              'A image cannot be uploaded',
              ''
            );
          }       
      )
      }


    };
    fileUpload.click();
  }

// Added by poonam
  
async onVideoClick() {
  this.selectedMediaType = 'video';
  const fileUpload = this.videoattachment.nativeElement;
  this.uploadedFile = [];
  let framecheck = true;

  fileUpload.onchange = async () => {
    if (fileUpload.files.length > 5) {
      this.notifyService.showInfo('You can upload a maximum of 5 videos at one time.', '');
      return;
    }

    let isTrue: Boolean = false;

    for (let index = 0; index < fileUpload.files.length; index++) {
      const file = fileUpload.files[index];
      if (!this.isVideo(file.name)) {
        this.notifyService.showInfo('Only video files are allowed.', '');
        return;
      }

      console.log("File Upload Response");
      console.log(file);

      const videoDuration = await this.getVideoDuration(file);
      console.log(videoDuration);

      if (videoDuration > 60) {
      const frames = await this.extractFramesFromVideo(file, 1); // Extract frames at 24 FPS
      console.log(frames);

      for (let frame of frames) {
        this.sightengineService.checkImage(this.base64ToFile(frame,'xyz.png'), 'nudity-2.1').subscribe(
          response => {
            if(response.nudity.none>='0.90'){
            this.uploadedFile.push(fileUpload.files[index]);
          }
          else{
            framecheck=false;           
          }       
          },
          error => {
            framecheck=false;
          }       
      )
     if(!framecheck ){
      break;
     }
    }
    if(!framecheck){
      this.notifyService.showInfo('Video contains inappropriate content', '');
    }
    else{
      console.log('Video uploading');
      this.uploadedFile.push(file);
      this.uploadPortfolio();
    }
  }
  else{
    console.log('less than 60');
      this.sightengineService.checkvideo(file, 'nudity-2.1').subscribe(
        response => {
          console.log(response);
          for (let i = 0; i < response.data.frames.length; i++) {
            const frame = response.data.frames[i];
            let nudity = frame.nudity.none;
            if (nudity < '0.90') {
              isTrue = true;
              break;
            }
          }
          console.log(isTrue);

          if (!isTrue) {
            console.log('Video uploading');
            this.uploadedFile.push(file);
            this.uploadPortfolio();
          } else {
            this.notifyService.showInfo('A video cannot be uploaded', '');
          }
        },
        error => {
          console.log('Failed');
          console.log(error);
          this.notifyService.showInfo('A video cannot be uploaded', '');
        }
      );
    }
    }
  };
  fileUpload.click();
}

base64ToFile(base64: string, filename: string): File {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
async getVideoDuration(file: File): Promise<number> {
  return new Promise<number>((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.src = URL.createObjectURL(file);
  });
}
async extractFramesFromVideo(file: File, frameRate: number): Promise<string[]> {
  console.log("extractFramesFromVideo");
  return new Promise<string[]>((resolve) => {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const frames: string[] = [];

    video.src = URL.createObjectURL(file);
    video.muted = true;
    video.play();

    video.onloadeddata = () => {
      const interval = 1 / frameRate;
      const duration = video.duration;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      let currentTime = 0;

      const captureFrame = () => {
        if (currentTime < duration) {
          video.currentTime = currentTime;
          currentTime += interval;
        } else {
          video.pause();
          resolve(frames);
          return;
        }
      };

      video.ontimeupdate = () => {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataURL = canvas.toDataURL('image/png');
        frames.push(dataURL);
        captureFrame();
      };

      captureFrame();
    };
  });
}

  isImage(filePath: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff'];
    const extension = filePath.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
  }
  setSelectedMediaType(type: string) {
    this.selectedMediaType = type;
  }
  getFilteredPortfolio(portfolio: any[]): any[] {
    if (this.selectedMediaType === 'image' && portfolio && portfolio.length > 0) {
      return portfolio.filter(item => !this.isVideo(item.imageURL));
    } 
    if(this.selectedMediaType === 'video' && portfolio && portfolio.length > 0) {
      return portfolio.filter(item => this.isVideo(item.imageURL));
    }
  }
  isVideo(filePath: string): boolean {
    const videoExtensions = ['mp4', 'm4v', 'avi', 'mov', 'wmv', 'flv', 'webm'];    
    const extension = filePath.toString().split('.').pop().toLowerCase();
    return videoExtensions.includes(extension);
  }
//Added by poonam

  onPrivatePortfolioClick() {

    const fileUpload = this.attachments.nativeElement;
    this.uploadedPrivateFile = [];
    fileUpload.onchange = () => {
      if (fileUpload.files.length > 5) {
        this.notifyService.showInfo(
          'You can upload maximum 5 pictures at one time.',
          ''
        );
        return;
      }

      for (let index = 0; index < fileUpload.files.length; index++) {
        this.uploadedPrivateFile.push(fileUpload.files[index]);
      }

      this.uploadPrivatePortfolio();
    };
    fileUpload.click();
  }

  uploadPrivatePortfolio() {
    this.showLoader = true;
    const formData = new FormData();
    let index = 0;
    var totalSize = 0;
    this.uploadedPrivateFile.forEach((element) => {
      totalSize += element.size/(1024 * 1024);
      const str = 'File' + index;
      formData.append(str, element);
      index = index + 1;
    });

    if(totalSize >= 5){
      this.showLoader = false;
      this.showValidation = true;
      setTimeout(function(){
        this.showValidation = false;
      }.bind(this), 4000);

      return;
    }

    var user = this.authService.getloggedUserDetails();
    if(user && user.accessToken){
      formData.append("authorization", user.accessToken);
    }
   

    this.dataService.createPrivatePortfolio(formData).subscribe((result: any) => {
      if (result && result.Message === 'Success') {
        if(this.selectedMediaType === 'video'){
        this.notifyService.showSuccess(
          'New videos Added to your Private Portfolio',
          ''
        );}
        if(this.selectedMediaType === 'image'){
          this.notifyService.showSuccess(
            'New Pictures Added to your Private Portfolio',
            ''
          );}
        this.getPrivatePortfolio();
        this.showLoader = false;
      }
    });
  }

  getPrivatePortfolio(){
    var user = this.authService.getloggedUserDetails();
      this.showLoader = true;
      this.dataService.getPrivatePortfolio({
        authorization: user["accessToken"]
      }).subscribe((result: any) => {
        if (result && result.Message === 'Success') {
          this.myPrivatePortfolio = result.Data;  
          for(var i = 0; i < this.myPrivatePortfolio.length; i++){
            this.myPrivatePortfolio[i].isVideo = this.myPrivatePortfolio[i].fileType.indexOf('video') > -1;
          }
          if (result.Data === 0) {
            this.notifyService.showSuccess(
              'No Pictures Found. Please add new picture to your portfolio.',
              ''
            );
          }
        }

        this.showLoader = false;
      });
  }

  generateThumbnail(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      video.style.display = 'none';
      video.currentTime = 5; // Capture thumbnail at 5 seconds
  
      video.addEventListener('loadeddata', () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
  
        if (context) {
          video.addEventListener('seeked', () => {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            URL.revokeObjectURL(video.src); // Clean up object URL
  
            // Convert the canvas to a Base64 string
            const base64Image = canvas.toDataURL('image/png');
  
            // Convert Base64 string to Blob
            const blob = this.base64ToBlob(base64Image, 'image/png');
  
            // Create a File object from the Blob
            const thumbnailFile = new File([blob], 'thumbnail.png', { type: 'image/png' });
  
            resolve(thumbnailFile);
          });
          video.currentTime = 5; // Ensure the video is seeked
        } else {
          reject('Failed to get canvas context');
        }
      });
  
      video.addEventListener('error', (error) => {
        reject('Failed to load video file');
      });
    });
  }
  
  // Helper function to convert Base64 to Blob
  base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  }

  uploadPortfolio() {
    console.log('here');
    this.showLoader = true;
    let index = 0;
    const formData = new FormData();
    const thumbnailPromises: Promise<void>[] = [];
    
    this.uploadedFile.forEach((element) => {
      const str = 'mediaFile' + index;
      formData.append(str, element);   
    
      console.log(str);   
      console.log(element);
    
      if (this.isVideo(element.name)) {
        console.log("Form Data 1");
    
        const thumbnailPromise = this.generateThumbnail(element).then((thumbnailFile) => {
          const thumbnailStr = 'thumbnailFile' + index;
          formData.append(thumbnailStr, thumbnailFile);
    
          console.log(thumbnailStr);
          console.log(thumbnailFile);
          index = index + 1;
        }).catch((error) => {
          console.error('Error generating thumbnail:', error);
        });
    
        thumbnailPromises.push(thumbnailPromise);
      } else {
        // If it's not a video, increment index immediately
        index = index + 1;
      }
    });
    
    // Wait for all thumbnail promises to resolve before making the API call
    Promise.all(thumbnailPromises).then(() => {
      console.log(formData.getAll);
      
      this.dataService.createPortfolio(formData).subscribe((result: any) => {
        if (result && result.message === 'Success') {
          if (this.selectedMediaType === 'video') {
            this.notifyService.showSuccess('New videos Added to your Portfolio', '');
          }
          if (this.selectedMediaType === 'image') {
            this.notifyService.showSuccess('New Pictures Added to your Portfolio', '');
          }
          this.getPortfolio();
          this.showLoader = false;
        }
      });
    }).catch((error) => {
      console.error('Error processing thumbnails:', error);
      this.showLoader = false;
    });
    
  }
  getPortfolio() {
    this.showLoader = true;
    this.dataService.getMyPortfolio().subscribe((res: any) => {
      if (res && res.message === 'Success') {
        this.myPortfolio = res.data.myPortolio.reverse();
        console.log(this.myPortfolio);
        if (res.data.myPortolio.length === 0) {
          this.notifyService.showSuccess(
            'No Pictures Found. Please add new picture to your portfolio.',
            ''
          );
        }
        this.showLoader = false;
      }
    });
  }
  delete(item: any) {
    this.showLoader = true;
    this.dataService.deletePortfolio(item._id).subscribe((res: any) => {
      if (res && res.message === 'Success') {
        // this.notifyService.showSuccess(
        //   'Picture removed from your Portfolio',
        //   ''
        // );
        if(this.selectedMediaType === 'video'){
          this.notifyService.showSuccess(
            'Video removed from your Portfolio',
            ''
          );}
          if(this.selectedMediaType === 'image'){
            this.notifyService.showSuccess(
              'Picture removed from your Portfolio',
              ''
            );}
        this.getPortfolio();
        this.showLoader = false;
      }
    });
  }

  
  deletePrivate(item: any) {
    this.showLoader = true;
    var user = this.authService.getloggedUserDetails();
    this.dataService.deletePrivatePortfolio({
      authorization: user["accessToken"],
      portfolioId: item._id
    }).subscribe((res: any) => {
      if (res && res.Message === 'Success') {
        this.notifyService.showSuccess(
          'Picture removed from your Portfolio',
          ''
        );
        this.getPrivatePortfolio();
        this.showLoader = false;
      }
    });
  }

  selectPrivate(typeName){
    this.selectedPort = typeName;
  }
}
