export const environment = {
  production: true,
  rearGuardApiBaseUri:
    'https://natural.selectnaturally.com/api/v1/user/',
  rearGuardBaseUri: 'https://natural.selectnaturally.com/api/user/',
  rearGuardBaseChatUri:
    'https://natural.selectnaturally.com/api/v1/chat/',

  rearGuradNetBaseUri: 'https://unpokedfolks.com/api/',
  rearGuradNetUri: 'https://unpokedfolks.com',
  rearGuradSocketBaseUri: 'https://rearguardwebsocket.devbinfotech.in/',
  rearGuardImageUri: 'https://natural.selectnaturally.com',
  rearGuardBasePostUri: 'https://natural.selectnaturally.com/api/post/',
  rearGuardBasePostVersionUri:
    'https://natural.selectnaturally.com/api/v1/post/',
  rearGuardBaseGroupVersionUri:
    'https://natural.selectnaturally.com/api/v1/postGroup/',
  rearGuardBaseGroupUri:
    'https://natural.selectnaturally.com/api/postGroup/',
  rearGuardBaseNotificationUri:
    'https://natural.selectnaturally.com/api/v1/notification/',
  randomCallBaseUri: 'https://devdevelopment.net/api/',

  firebase: {
    apiKey: "AIzaSyComE0NsiEBlxMNp-oKv0aY-__yNE48HIQ",
    authDomain: "friendlinq-aa227.firebaseapp.com",
    projectId: "friendlinq-aa227",
    storageBucket: "friendlinq-aa227.appspot.com",
    messagingSenderId: "355260192132",
    appId: "1:355260192132:web:0b422929f0c66db81d79d1"
},

  stripePublicKey: 'pk_test_51OeCKVBOfCO7ilUokdzDowOyC7C55g5LhV5neUfmTg9nJLif4BUOz7sen8KVGdv8B7fgAg858ybAPGR3soA30NhI00BC2IwEeSsk_test_51OeCKVBOfCO7ilUod33PvDJexmWAzLLHbuFtWhYhZ9a3mkorcsUWYpAaqwddn8D3uIpOb26olhOuCSoqU4UVFqk700S6uwWYin'

};
