import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { NavigationEnd, Router,ActivatedRoute } from '@angular/router';
import { io } from 'socket.io-client';
import { AuthService } from 'src/app/shared/api/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from 'src/environments/environment';
import { AgoraStreamService, IUser } from '../../../shared/services/agora.stream.service';
import { AgoraCommonService } from '../../../shared/services/agora.common.service';
import { ApiService } from '../../../shared/services/api.service';
import AgoraRTC, { EventCustomReportParams, IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import { MessagingService } from '../../../shared/services/messaging.service';
import { DataService } from '../../public/service/data.service';
import { data } from 'jquery';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FriendRequest } from '../../models/friend.model';

import { AddMoreDialog } from './add-more-dialog';
import { resourceUsage } from 'process';


@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
})
export class VideoComponent implements OnInit {
  @ViewChild('videoLocalElement') videoElement: any;
  @ViewChild('videoRemoteElement') videoRemoteElement: any;
  @ViewChild('audioOption') audioPlayerRef: ElementRef;

  isNotify: boolean;
  showLoader;
  channelName;
  
  audio = new Audio();

  isVideoStreaming = true;
  toggleCamera = true;
  toggleAudio = true;
  @ViewChild('streamVideo') video;
  message:any;
  hideBtns = true;
  callingTo;
  isMuteVideo =false;
  isMuteVolume =false;
  muteText = '<i class="fa fa-video-camera" aria-hidden="true"></i>';
  muteVolumeText = '<i class="fa fa-microphone" aria-hidden="true"></i>';

  isScreenShare= false;
  screenShareText = "Share Screen";

  isCallStarted = false;
  groupId;
  chatList: Array<any> = [];

  friendsAlreadyInTalk:Array<any>=[];

  maximizedUser:IAgoraRTCRemoteUser;
  usersInCall:Array<string> = [];
  isAddMore: boolean = false;

  isGroupCall: boolean = false;

  currentUser;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifyService: NotificationService,
    private authService: AuthService,
    public stream: AgoraStreamService,
    public api: ApiService,
    private dialog: MatDialog,
    private common: AgoraCommonService,
    private messagingService: MessagingService,
    private dataService: DataService
  ) {
    this.isNotify = false;
    this.showLoader = true;
  }

  ngOnDestroy(){
    this.logout().then((result)=>{});
  }

   ngOnInit() {
    this.currentUser = this.authService.getloggedUserDetails();
    console.log(this.route.snapshot.params['id']);
    this.isCallStarted = false;
    var friendId = this.route.snapshot.params['id'];
    this.isGroupCall = false;
    if(friendId && friendId.indexOf('chname-') > -1){
      this.channelName = friendId.replace('chname-', '');
      //this.startCall(friendId.replace('chname-', ''));
      this.isNotify = true;
      this.playSound();
    }
    else if(friendId && friendId.indexOf('schedulecall-') > -1){
      var schedule = friendId.split('l-');
      this.dataService.validateMeeting({ email: this.currentUser.email, channelId: schedule[1] }).subscribe((res) => {
        this.channelName = schedule[1];
        if(!this.isCallStarted){
          this.startCall(channelName).then(result => {
            this.showLoader = false;
          });
        }
      });
    }
    else if(friendId && friendId.indexOf('groupcall-') > -1){
      this.isGroupCall = true;
      this.groupId = friendId.replace('groupcall-', '');
      //this.startCall(friendId.replace('chname-', ''));
      this.channelName = this.groupId;
      console.log('groupcall',this.groupId);
      let requestData = [];
      if(friendId.indexOf('groupcall-join-') > -1){
        this.groupId = friendId.replace('groupcall-join-', '');
        this.channelName = this.groupId;
        if(!this.isCallStarted){
          this.startCall(channelName).then(result => {

          });
        }
      }
      else {
        const data = { groupId: this.groupId, skip: 0, limit: 0 };
        this.dataService.getGroupMembers(data).subscribe((result: any) => {
          if (result && result.message === 'Success') {
            requestData = result.data.groupMemberList;
            console.log(requestData);
            var channelName = this.groupId;

            for(var i = 0; i < requestData.length; i++){
              this.sendCallNotification(channelName, requestData[i].groupMemberId.imageURL.original, requestData[i].groupMemberId._id, requestData[i].groupMemberId.email, requestData[i].groupMemberId.deviceToken)
              .then(result => {
                console.log(result);
              });
            }
          } else {
            this.notifyService.showInfo('No Group Members............', '');
          }
        });
        
        this.updateCallStatus(true);
        this.isNotify = true;
      }
    }
    else if(friendId && friendId.indexOf('chname-') < 0){
      var channelName = 'mychannel'+new Date().getTime();
      if(friendId.indexOf(',') > -1){
        this.channelName = channelName;
        var friends = friendId.split(',');
        this.makeCallToFriend(friends, channelName);
      }
      else {
        
        this.callingTo = friendId;
        this.channelName = channelName;
        this.dataService.getFriendProfile(friendId).subscribe((res) =>{

            this.sendCallNotification(channelName, res.data.customerData.imageURL.original, friendId, res.data.customerData.email, res.data.customerData.deviceToken)
            .then(result => {
              console.log(result);
            });
            
        });
      }
    }
    //await this.navigateToVideo();
  }

  makeCallToFriend(friends, channelName){
      if(friends.length > 0){
        this.dataService.getFriendProfile(friends[0]).subscribe((res) =>{

            this.sendCallNotification(channelName, res.data.customerData.imageURL.original, friends[0], res.data.customerData.email, res.data.customerData.deviceToken)
            .then(result => {
              friends.splice(0, 1);
              this.makeCallToFriend(friends, channelName);
            });
            
        });
      }
  }

  async sendCallNotification(channelName, userDp, friendId, email, deviceToken){
    if(this.currentUser._id == friendId){
      return true;
    }

    this.friendsAlreadyInTalk.push(friendId);
      var data = {
        "notification":{
            "title": "Calling....",
            "body": this.currentUser.email
        },
        "data": {
        "agoraToken": "",
        "channelName": channelName,
        "userDP": userDp,
        "CallReceiverId": friendId,
        "calltype": 'video',
        "whoCall": '',
        "name": email,
        "deviceToken": deviceToken,
        "isCall": true
      },
        "to": deviceToken
    };

    if(this.usersInCall.indexOf(email) < 0){
      this.usersInCall.push(email);
    }
    this.dataService.sendFirebaseMessage(data).subscribe(async (response) => {
      if(!response["success"]){
        this.usersInCall.splice(this.usersInCall.indexOf(email), 1);
      }
    });
    
    if(!this.isCallStarted){
      await this.startCall(channelName);
    }
    return true;
  }

  async startCall(channelName) {
    this.audio.pause();
    if(!channelName){
      channelName =this.channelName;
    }
    this.isCallStarted = true;
    this.isNotify = false;
    const uid = this.generateUid();
    //const rtcDetails = await this.generateTokenAndUid(uid);
    this.stream.createRTCClient();
    this.stream.agoraServerEvents(this.stream.rtc);
    //var user = this.authService.getloggedUserDetails();

    await this.stream.localUser(null, uid, channelName);
    this.stream.rtc.client.on("user-left", user => {
      if(this.maximizedUser && user.uid == this.maximizedUser.uid){
        this.maximizedUser = null;
      }
    });
    this.hideBtns = false;
    this.stream.isConnecting = false;
}

async muteVideo(){
  if(!this.isMuteVideo){
    this.stream.rtc.localVideoTrack.setEnabled(false);
    this.isMuteVideo = true;
    //this.muteText = ``;
  }
  else{
      this.stream.rtc.localVideoTrack.setEnabled(true);
      // Update the button text.
      this.isMuteVideo = false;
      //this.muteText = `<i class="fa fa-video-camera" aria-hidden="true"></i>`;
    }
}
maximizeClose(){
  if(this.maximizedUser){
    this.maximizedUser.videoTrack.stop();
    this.maximizedUser.videoTrack.play('remote-playerlist' + this.maximizedUser.uid);
    this.maximizedUser = null;
  }
}
maximize(u){
  if(this.maximizedUser){
    this.maximizeClose();
  }
  var usr = this.stream.rtc.client.remoteUsers.filter((x) => { return x.uid == u.uid }); 
  if(usr && usr.length > 0){
    this.maximizedUser = usr[0];
    setTimeout(function(){
      usr[0].videoTrack.play('ScreenShare');
    }, 500);
  }
}

async muteUnmuteVolumn(){
  if(!this.isMuteVolume){
    this.stream.rtc.localAudioTrack.setEnabled(false);
    this.isMuteVolume = true;
    this.muteVolumeText = `<i class="fa fa-microphone-slash" aria-hidden="true"></i>`;
  }
  else{
      this.stream.rtc.localAudioTrack.setEnabled(true);
      // Update the button text.
      this.isMuteVolume = false;
      this.muteVolumeText = `<i class="fa fa-microphone" aria-hidden="true"></i>`;
    }
}

addMore(){
  if(!this.chatList || this.chatList.length == 0){
    this.loadChatList();
  }
  else{
    this.isAddMore = true;
  }
}

closeAddMore(){
  this.isAddMore = false;
}

AddUser(frd){
  if(this.usersInCall.indexOf(frd.email) < 0){
    this.usersInCall.push(frd.email);
  }
  this.sendCallNotification(this.channelName, frd.imageURL.original, frd._id, frd.email, frd.deviceToken);
}

loadChatList(){
  this.chatList = [];
  if(this.isGroupCall){
    const data = { groupId: this.groupId, skip: 0, limit: 0 };
    this.dataService.getGroupMembers(data).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.chatList = result.data.groupMemberList.map((g) => {
          return g.groupMemberId;
        }).filter((f) => { return f._id != this.currentUser._id; });

        this.isAddMore = true;
    }
    });
  }
  else{
    const searchFriend = new FriendRequest();
      searchFriend.limit = 0;
      searchFriend.skip = 0;
      searchFriend.status = 'Accepted';
    this.dataService.getFriendRequest(searchFriend).subscribe((result: any) => {
      if (result && result.statusCode === 200) {
        this.chatList = result.data.friendList.filter((f) => { return f._id != this.currentUser._id; });

        this.isAddMore = true;
      }
    });
  }
}

playSound(){
  this.audio.src = '../../../../assets/sound/Ring.mp3';
  this.audio.load();
  this.audio.play();
  
  setTimeout(function(){
    this.audio.pause();
  }, 10000);
}

async startScreenShare(){
  if(!this.isScreenShare){
    this.isScreenShare = true;
    await this.stream.startScreenShare();
    if (this.stream.rtc.shareScreen) {
      this.stream.rtc.shareScreen.on('track-ended', async () => {
        this.isScreenShare = false;
        await this.stream.stopScreenShare();
      })
    }
  }
  else{
    this.isScreenShare = false;
    await this.stream.stopScreenShare();
  }
}

async startCall1(){
  await this.startCall(this.channelName);
}

// rtc token
async generateTokenAndUid(uid) {
  // https://test-agora.herokuapp.com/access_token?channel=test&uid=1234
  let url = 'https://agora.herokuapp.com/access_token?';
  const opts = { params: new HttpParams({ fromString: "channel=test&uid=" + uid }) };
  const data = await this.api.getRequest(url, opts.params).toPromise();
  return { 'uid': uid, token: data['token'] }

}

generateUid() {
  var uid = localStorage.getItem("uid");
  if(uid){
    return parseInt(uid);
  }
  const length = 5;
  const randomNo = (Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)));
  return randomNo;
}

checksum(s)
{
  var chk = 0x12345678;
  var len = s.length;
  for (var i = 0; i < len; i++) {
      chk += (s.charCodeAt(i) * (i + 1));
  }

  return (chk & 0xffffffff).toString(16);
}




async logout() {
  this.audio.pause();
    this.showLoader = false;
    this.isNotify = false;
    this.isCallStarted = false;
    if(this.stream.remoteUsers.length == 0 && this.groupId){
      this.updateCallStatus(false);
    }

    try{
    await this.stream.leaveCall();
    }
    catch{

    }
    
    setTimeout(function(){
      if(this.callingTo){
        this.router.navigate(['chat', this.callingTo]);
        }
        else{
          this.router.navigate(['dashboard']);
        }
    }.bind(this), 500);
  }

  updateCallStatus(isActive){
    var body = {
      groupId: this.groupId,
      isActive: isActive,
      channelId: this.channelName,
      callType: '0'
    };
    this.dataService.updateGroupChannel(body).subscribe((res) =>{
        
      });
  }

  
}
