import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/components/public/service/data.service';
import { AuthService } from 'src/app/shared/api/auth.service';

import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-payment-group',
  templateUrl: './payment-group.component.html',
  styleUrls: ['./payment-group.component.css']
})
export class PaymentGroupComponent implements OnInit {
  public userDetails: any;
  paymentHandler: any = null;
  groupId: string;
  groupDetails: any = {
    groupAdmin: "",
    groupDescription:"",
    groupId: "",
    groupName: "",
    publicKey: "",
    rate: 2
  };

  isLoaded: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private notifyService: NotificationService
  ) {
    this.userDetails = this.authService.getloggedUserDetails();
    this.groupId = this.route.snapshot.params['id'];
  }
  ngOnInit() {
    
    this.groupId = this.route.snapshot.params['id'];
    var data = {
      authorization: this.userDetails.accessToken,
      groupId:this.groupId
    }
    this.dataService
      .getGroupPaymentDetails(data)
      .subscribe((result: any) => {
        if (result && result.Message === 'Success') {
          console.log(result);
          this.groupDetails = result.Data;

          this.invokeStripe();
          this.isLoaded = true;
        }
      });
   // this.invokeStripe();
  }
  makePayment(amount: any) {
    var me = this;
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: this.groupDetails.publicKey,
      locale: 'auto',
      token: function (stripeToken: any) {
        
        var data = {
          "authorization": me.userDetails.accessToken,
          "groupAdminId": me.groupDetails.groupAdmin,
          "groupId": me.groupDetails.groupId,
          "joineeId": me.userDetails._id,
          "charges": me.groupDetails.rate,
          "stripeToken": stripeToken.id,
          "stripeEmail": stripeToken.email
        };
        me.dataService.submitGroupPaymentDetails(data)
        .subscribe((result: any) => {
          if (result && result.Message === 'Success') {
            me.notifyService.showSuccess('Group Payment Completed Successfully.', '');
          }
          else if (result && result.Message === 'Failed') {
            me.notifyService.showError('Group Payment Failed.', 'Error');
          }
          else{
            me.notifyService.showError('Group Payment is not completed.', 'Error');
          }

          
          me.router.navigate(['group']);
        });
      },
    });
    paymentHandler.open({
      name: 'Positronx',
      description: '3 widgets',
      amount: amount * 100,
    });
  }

  cancel(){
    this.router.navigate(['group']);
  }
  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: this.groupDetails.publicKey,
          locale: 'auto',
          token: function (stripeToken: any) {
            console.log(stripeToken);
            alert('Payment has been successfull!');
          },
        });
      };
      window.document.body.appendChild(script);
    }
  }
}
