<div class="container">
    <div class="row">
        <div class="friends-list" *ngIf="chatList && chatList.length > 0">

            <div class="friends-list-inner" style="cursor: pointer;" *ngFor="let item of chatList" (click)="navigateToChat(item.friendId)">
                <div class="friends-list-image">
                    <img src="{{imageUrl}}/{{item.imageURL.thumbnail}}">
                    
                
                    <img *ngIf="item.isOnline" src="../../../../assets/images/greenststus.png" style="height: 15px;width: 15px;margin-left: -30px;margin-top: 40px;">
                    <img *ngIf="!item.isOnline" src="../../../../assets/images/greyofflinestatus.jpg" style="height: 15px;width: 15px;margin-left: -30px;margin-top: 40px;">
                </div>

                <div class="friends-list-details">
                    <p>{{item.name}}
                        <span class="emailid">{{item.email}}</span>
                    </p>
                    <span style="font-style: italic;color: gray;">{{item.LastMessage}}</span>
                </div>

                <p
                    style=" cursor: pointer;margin-left: 750px;padding: 5px;position:absolute; font-size: 36px">
                    <a (click)="navigateToChat(item.friendId)"><i class="fa fa-comment"
                            aria-hidden="true"></i></a>
                            <span (click)="navigateToChat(item.friendId)" class="notification">{{item.count}}</span>
                </p>




            </div>
        </div>
        <div *ngIf="chatList.length == 0">
            No Pending Chat Found!!
        </div>
    </div>
</div>