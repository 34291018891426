import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DataService } from '../../public/service/data.service';
import { io } from 'socket.io-client';

@Component({
  selector: 'app-random-call',
  templateUrl: './random-call.component.html',
  styleUrls: ['./random-call.component.css'],
})
export class RandomCallComponent implements OnInit {
  @ViewChild('videoLocalElement') videoElement: any;
  @ViewChild('videoRemoteElement') videoRemoteElement: any;

  public randomData: any;
  public socket;
  public ipAddress;
  public dynamicClass: string;
  public mediaConstraints = {
    audio: { echoCancellation: false },
    video: { width: 1280, height: 720 },
  };
  public localStream;
  public remoteStream;
  public isRoomCreator;
  public rtcPeerConnection; // Connection between the local device and the remote peer.
  public roomId;
  public iceServers = {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:stun1.l.google.com:19302' },
      { urls: 'stun:stun2.l.google.com:19302' },
      { urls: 'stun:stun3.l.google.com:19302' },
      { urls: 'stun:stun4.l.google.com:19302' },
    ],
  };
  public remoteSrc;
  public localSrc;
  public showRemoteVideo;
  public showNextButton;
  public callDuration;
  public isVideoDirect;

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    location: PlatformLocation,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.socket = io('https://rearguardwebsocket.devbinfotech.in/');

    const id = localStorage.getItem('Id');
    this.dataService
      .updateUserStatus(+id, 'Active')
      .subscribe((res: any) => {});
    this.initCamera(this.mediaConstraints);
    this.getRandomUser();

    this.showNextButton = true;
    this.socket.on('room_created', async (data) => {
      this.isRoomCreator = true;
    });

    this.socket.on('call-request-received1', (result) => {
      localStorage.setItem('roomId', result.roomId);
      const myIpAddress = +localStorage.getItem('Id');

      if (result.userid === myIpAddress) {
        this.randomData = [{ id: result.intiateCallingId }];
        localStorage.setItem('previousUserId', result.intiateCallingId);
        localStorage.setItem('callingUserId', '');
        this.isRoomCreator = false;
        this.createRoom();
      } else {
        setTimeout(() => {
          this.showNextButton = true;
        }, 3000);
      }
    });

    this.socket.on('room_joined', async () => {
      this.socket.emit('start_call', this.roomId);
    });

    this.socket.on('full_room', () => {
      console.log('Socket event callback: full_room');
    });

    this.socket.on('start_call', async () => {
      if (this.isRoomCreator) {
        const _id = localStorage.getItem('Id');
        console.log('Call Declined Success true');
        this.dataService.updateRandomUser(+_id, true).subscribe((res: any) => {
          console.log('Call Declined Success true');
        });
        this.rtcPeerConnection = new RTCPeerConnection(this.iceServers);
        this.addLocalTracks(this.rtcPeerConnection);
        this.rtcPeerConnection.ontrack = (event) => {
          this.videoRemoteElement.nativeElement.srcObject = event.streams[0];
          this.showRemoteVideo = true;
          this.remoteStream = event.stream;
        };
        this.rtcPeerConnection.onicecandidate = (e) => {
          if (e.candidate) {
            const roomId = this.roomId;
            this.socket.emit('webrtc_ice_candidate', {
              roomId,
              label: e.candidate.sdpMLineIndex,
              candidate: e.candidate.candidate,
            });
          }
        };
        await this.createOffer(this.rtcPeerConnection);
      }
    });

    this.socket.on('webrtc_offer', async (event) => {
      if (!this.isRoomCreator) {
        this.rtcPeerConnection = new RTCPeerConnection(this.iceServers);
        this.addLocalTracks(this.rtcPeerConnection);
        this.rtcPeerConnection.ontrack = (ev) => {
          this.videoRemoteElement.nativeElement.srcObject = ev.streams[0];
          this.showRemoteVideo = true;
          this.remoteStream = ev.stream;
        };
        this.rtcPeerConnection.onicecandidate = (e) => {
          if (e.candidate) {
            const roomId = this.roomId;
            this.socket.emit('webrtc_ice_candidate', {
              roomId,
              label: e.candidate.sdpMLineIndex,
              candidate: e.candidate.candidate,
            });
          }
        };
        this.rtcPeerConnection.setRemoteDescription(
          new RTCSessionDescription(event)
        );
        await this.createAnswer(this.rtcPeerConnection);
      }
    });

    this.socket.on('webrtc_answer', (event) => {
      this.rtcPeerConnection.setRemoteDescription(
        new RTCSessionDescription(event)
      );
    });

    this.socket.on('webrtc_ice_candidate', (event) => {
      // ICE candidate configuration.
      const candidate = new RTCIceCandidate({
        sdpMLineIndex: event.label,
        candidate: event.candidate,
      });

      this.rtcPeerConnection.addIceCandidate(candidate);
      this.showNextButton = true;
      const id = localStorage.getItem('Id');
      // console.log('Call Declined Success true');
      // this.dataService.updateRandomUser(+id, true).subscribe((res: any) => {
      //   console.log('Call Declined Success true');
      // });
    });

    this.socket.on('call-declined1', async (data) => {
      const id = localStorage.getItem('Id');

      if (+id === +data.userid) {
        if (this.localStream) {
          // tslint:disable-next-line:only-arrow-functions
          // tslint:disable-next-line:typedef
          this.videoRemoteElement.nativeElement.srcObject = null;
          this.randomData = undefined;
          // const id = localStorage.getItem('Id');
          this.dataService
            .updateRandomUser(+id, false)
            .subscribe((res: any) => {
              console.log(
                'Call Declined Success False',
                data + '>>>>>>>>>>>>',
                id
              );
            });
        }
        this.isRoomCreator = undefined;
      }
    });

    location.onPopState(() => {
      console.log('>>>>>>>>>>>poppoppoppop');

      const _id = localStorage.getItem('Id');
      this.dataService.updateUserStatus(+_id, null).subscribe((res: any) => {});
      this.dataService
        .updateRandomUser(+_id, false)
        .subscribe((res: any) => {});
    });
  }

  ngOnInit(): void {}
  getRandomUser() {
    this.showNextButton = false;

    if (this.randomData !== undefined) {
      const room1 = localStorage.getItem('roomId');
      const json = {
        userid: this.randomData[0].id,
        callingUsername: 'Random',
        roomId: room1,
      };
      this.videoRemoteElement.nativeElement.srcObject = null;
      const ID = localStorage.getItem('Id');
      this.dataService.updateRandomUser(+ID, false).subscribe((res: any) => {});
      this.showRemoteVideo = false;
      this.socket.emit('call-decline1', json);
      if (this.callDuration !== undefined) {
        const currentDateTime = new Date();
        const difference =
          currentDateTime.getTime() - this.callDuration.getTime();

        const resultInMinutes = difference / 60000;

        const durationJson = {
          Id: +ID,
          ConnectId: +this.randomData[0].id,
          Duration: resultInMinutes,
          DateofCall: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
        this.dataService
          .updateCallDuration(durationJson)
          .subscribe((res: any) => {
            this.callDuration = undefined;
          });
      }
    }

    const id = localStorage.getItem('Id');
    const prevId = localStorage.getItem('previousUserId');
    this.dataService
      .updateUserStatus(+id, 'Active')
      .subscribe((res: any) => {});
    this.dataService.getRandomuserWeb(+id, +prevId).subscribe((res: any) => {
      if (res.length > 0) {
        if (res[0].socketID === 'Active') {
          this.randomData = res;
          localStorage.setItem('previousUserId', res[0].id);

          const room = this.getroomId();
          const json = {
            userid: this.randomData[0].id,
            type: 'Video',
            callingUsername: 'Random',
            roomId: room,
            intiateCallingId: id,
          };
          localStorage.setItem('callingUserId', this.randomData[0].id);
          localStorage.setItem('roomId', room);
          this.createRoom();
          this.socket.emit('call-request1', json);
        } else {
          this.showNextButton = true;
        }
      } else {
        this.showNextButton = true;
        localStorage.setItem('previousUserId', '0');
        this.randomData = undefined;
      }
    });
  }

  getroomId() {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  createRoom() {
    const userId = localStorage.getItem('callingUserId');
    const randomId = localStorage.getItem('roomId');
    this.roomId = randomId;
    if (userId === '') {
      this.joinRoom(this.roomId);
    } else {
      this.socket.emit('join', this.roomId);
      this.showVideoConference();
    }
  }

  joinRoom(room) {
    if (room === '') {
      alert('Please type a room ID');
    } else {
      this.roomId = room;
      this.socket.emit('join', room);
      this.showVideoConference();
    }
  }

  showVideoConference() {
    // roomSelectionContainer.style = 'display: none';
    // videoChatContainer.style = 'display: block';
  }

  setRemoteStream(event) {
    this.videoRemoteElement.nativeElement.srcObject = event.streams[0];
    this.showRemoteVideo = true;
    this.remoteStream = event.stream;
  }

  sendIceCandidate(event) {
    if (event.candidate) {
      const rid = this.roomId;
      this.socket.emit('webrtc_ice_candidate', {
        rid,
        label: event.candidate.sdpMLineIndex,
        candidate: event.candidate.candidate,
      });
    }
  }

  addLocalTracks(rtcPeerConnection) {
    this.localStream.getTracks().forEach((track) => {
      rtcPeerConnection.addTrack(track, this.localStream);
    });
  }

  async setLocalStream(mediaConstraints) {
    let stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
    } catch (error) {
      console.error('Could not get user media', error);
    }

    this.localStream = stream;
    this.videoElement.nativeElement.srcObject = stream;
    this.videoElement.nativeElement.muted = 'muted';
  }

  async createOffer(rtcPeerConnection) {
    let sessionDescription;
    try {
      sessionDescription = await rtcPeerConnection.createOffer();
      rtcPeerConnection.setLocalDescription(sessionDescription);
    } catch (error) {
      console.error(error);
    }
    const roomId = this.roomId;
    this.callDuration = new Date();
    this.socket.emit('webrtc_offer', {
      type: 'webrtc_offer',
      sdp: sessionDescription,
      roomId,
    });
  }

  async createAnswer(rtcPeerConnection) {
    let sessionDescription;
    try {
      sessionDescription = await rtcPeerConnection.createAnswer();
      rtcPeerConnection.setLocalDescription(sessionDescription);
    } catch (error) {
      console.error(error);
    }
    const roomId = this.roomId;
    const id = localStorage.getItem('Id');
    this.callDuration = new Date();
    this.dataService.updateRandomUser(+id, true).subscribe((res: any) => {});
    this.socket.emit('webrtc_answer', {
      type: 'webrtc_answer',
      sdp: sessionDescription,
      roomId,
    });
  }

  initCamera(config) {
    navigator.mediaDevices.getUserMedia(config).then((mediaStream) => {
      this.videoElement.nativeElement.srcObject = mediaStream;
      this.videoElement.nativeElement.muted = 'muted';
      this.localStream = mediaStream;
    });
  }
  Disconnect() {
    if (this.randomData !== undefined) {
      const room1 = localStorage.getItem('roomId');
      const json = {
        userid: this.randomData[0].id,
        callingUsername: 'Random',
        roomId: room1,
      };
      this.videoRemoteElement.nativeElement.srcObject = null;
      const ID = localStorage.getItem('Id');
      this.dataService.updateRandomUser(+ID, false).subscribe((res: any) => {});
      this.dataService.updateUserStatus(+ID, null).subscribe((res: any) => {});
      this.socket.emit('call-decline1', json);
      this.localStream.getTracks().forEach((track) => {
        track.stop();
      });

      if (this.callDuration !== undefined) {
        const currentDateTime = new Date();
        const difference =
          currentDateTime.getTime() - this.callDuration.getTime();
        const resultInMinutes = difference / 60000;
        localStorage.setItem('isFromVideo', 'true');
        localStorage.setItem('previousUserId', '0');
        const durationJson = {
          Id: +ID,
          ConnectId: +this.randomData[0].id,
          Duration: resultInMinutes,
          DateofCall: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
        this.dataService
          .updateCallDuration(durationJson)
          .subscribe((res: any) => {
            this.callDuration = undefined;
          });
      }
      localStorage.setItem('navigateTovideo', 'false');
      this.router.navigateByUrl('/dashboard');
    } else {
      this.videoElement.nativeElement.srcObject = null;
      this.localStream.getTracks().forEach((track) => {
        track.stop();
      });
      localStorage.setItem('isFromVideo', 'true');
      localStorage.setItem('previousUserId', '0');
      this.randomData = undefined;
      const ID = localStorage.getItem('Id');
      this.dataService.updateRandomUser(+ID, false).subscribe((res: any) => {});
      this.dataService.updateUserStatus(+ID, null).subscribe((res: any) => {});

      this.router.navigateByUrl('/dashboard');
    }
  }
}
