import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FriendRequest } from 'src/app/components/models/friend.model';
import { ReportPost } from 'src/app/components/models/sharePost.model';
import { DataService } from 'src/app/components/public/service/data.service';
import { AuthService } from 'src/app/shared/api/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from 'src/environments/environment';
import { CommentComponent } from '../../comment/comment.component';
import { ShareComponent } from '../../share/share.component';
import { TagfriendComponent } from '../../tagfriend/tagfriend.component';
import { GroupMemberComponent } from '../group-member/group-member.component';
import { GroupRequestComponent } from '../group-request/group-request.component';

@Component({
  selector: 'app-group-dashboard',
  templateUrl: './group-dashboard.component.html',
  styleUrls: ['./group-dashboard.component.css'],
})
export class GroupDashboardComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('groupIconUpload', { static: false }) groupIconUpload: ElementRef;
  @ViewChild('fileUploadVideo', { static: false }) fileUploadVideo: ElementRef;

  @ViewChild('myPublicElement', { static: true }) MyPublicElement: ElementRef;
  @ViewChild('myFriendElement', { static: true }) MyFriendElement: ElementRef;

  public userDetails: any;
  public friendPostList: any;
  public publicPostList: any;
  public isFriendPost: boolean;
  public friendsList: any;
  public groupMembers: any;
  imageSrc: string;
  format: string;
  preview: string;
  uploadedFile: any;
  imageUrl: string;
  postContent: string;
  showLoader: boolean;
  previewClass: string;
  previewDeleteClass: string;
  public taggedFriend: any;
  public taggedList: any;
  genderForm = this.initForm();
  clickIndex: number;
  clickBlurIndex: number;
  postDisabled: boolean;
  postTitle: string;
  groupId: string;
  groupData: any;
  groupPosts: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private notifyService: NotificationService,
    private route: ActivatedRoute
  ) {
    this.postDisabled = false;
    this.friendPostList = [];
    this.publicPostList = [];
    this.isFriendPost = true;
    this.taggedFriend = [];
    this.friendsList = [];
    this.previewClass = 'as';
    this.previewDeleteClass = 'deleteGroupIconhide';
    this.clickBlurIndex = -1;
    this.imageUrl = environment.rearGuardImageUri;
    this.groupData = JSON.parse(localStorage.getItem('groupData'));
    this.postTitle = this.groupData.groupName;
    this.userDetails = this.authService.getloggedUserDetails();
    this.groupId = this.route.snapshot.params['id'];
    this.getGroupPost();
    this.seeGroupMembers(true);
  }
  private initForm() {
    return this.fb.group({
      postContent: ['', Validators.required],
      postType: ['Friend_Only', Validators.required],
    });
  }
  toggleClass(className, i) {
    if (className === 'as') {
      this.previewClass = 'ash';
    } else {
      this.previewClass = 'as';
    }
    this.clickIndex = i;
  }
  toggleDeleteGroup(className) {
    if (className === 'deleteGroupIconhide') {
      this.previewDeleteClass = 'deleteGroupIcon';
    } else {
      this.previewDeleteClass = 'deleteGroupIconhide';
    }
  }

  removeBlur(i) {
    this.clickBlurIndex = i;
  }

  ngOnInit(): void {}

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      this.uploadedFile = file;

      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (file.type.indexOf('video') > -1) {
        this.format = 'video';
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };

      reader.readAsDataURL(file);
      // this.sendFile(file);
    };
    fileUpload.click();
  }
  onGroupIconClick() {
    const fileUpload = this.groupIconUpload.nativeElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      this.uploadedFile = file;
      this.sendFile(file);
    };
    fileUpload.click();
  }

  sendFile(file) {
    const formData = new FormData();
    formData.append('groupIcon', file);
    formData.append('id', this.groupData._id);

    this.dataService.uploadGroupIcon(formData).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Group Icon Upload Succesfully', '');
        this.groupData.ExtraElements.groupIcon = result.data.group.groupIcon;
        localStorage.setItem('groupData', JSON.stringify(this.groupData));
      } else {
        this.notifyService.showWarning(result.message, '');
      }
    });
  }

  onClickVideo() {
    const fileUploadVideo = this.fileUploadVideo.nativeElement;
    fileUploadVideo.onchange = () => {
      const file = fileUploadVideo.files[0];
      this.uploadedFile = file;

      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (file.type.indexOf('video') > -1) {
        this.format = 'video';
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };

      reader.readAsDataURL(file);
      // this.sendFile(file);
    };
    fileUploadVideo.click();
  }
  seeGroupRequest() {
    let requestData = [];
    this.dataService.getGroupRequest(this.groupId).subscribe((result: any) => {
      if (result && result.length > 0) {
        requestData = result;
        const dialogRef = this.dialog.open(GroupRequestComponent, {
          height: '500px',
          width: '700px',
          autoFocus: false,
          disableClose: true,
          data: requestData,
        });
        dialogRef.afterClosed().subscribe((data) => {});
      } else {
        this.notifyService.showInfo('No Group requests............', '');
      }
    });
  }
  seeGroupMembers(donotShow = false) {
    let requestData = [];
    const data = { groupId: this.groupId, skip: 0, limit: 0 };
    this.dataService.getGroupMembers(data).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        requestData = result.data.groupMemberList;
        this.groupMembers = result.data.groupMemberList;
        if(!donotShow){
          const dialogRef = this.dialog.open(GroupMemberComponent, {
            height: '600px',
            width: '800px',
            autoFocus: false,
            disableClose: true,
            data: requestData,
          });
          dialogRef.afterClosed().subscribe((data) => {});
        }
      } else {
        this.notifyService.showInfo('No Group Members............', '');
      }
    });
  }

  getGroupPost() {
    this.showLoader = true;
    this.dataService.getGroupPost(this.groupId).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.groupPosts = result.data.myPost.reverse();
      } else {
        this.notifyService.showInfo('No Posts', '');
      }
      this.showLoader = false;
    });
  }

  CreatePost() {
    if (this.genderForm.invalid) {
      return;
    }
    const formData = new FormData();
    this.postDisabled = true;
    const postTitleContent = this.genderForm.value.postContent;
    formData.append('postTitle', this.genderForm.value.postContent);
    formData.append('groupId', this.groupId);
    formData.append('postContent', this.genderForm.value.postContent);

    if (this.format !== 'image' && this.format !== 'video') {
      formData.append('isMediaFileUploaded', 'false');
      formData.append('isMediaTypeVideo', 'false');
    }
    if (this.format === 'image') {
      formData.append('isMediaFileUploaded', 'true');
      formData.append('isMediaTypeVideo', 'false');
      formData.append('mediaFile', this.uploadedFile);
    }
    if (this.format === 'video') {
      formData.append('isMediaFileUploaded', 'true');
      formData.append('isMediaTypeVideo', 'true');
      formData.append('mediaFile', this.uploadedFile);
    }
    if (this.taggedFriend.length > 0) {
      formData.append('tagFriendList', JSON.stringify(this.taggedFriend));
    }

    this.dataService.createGroupPost(formData).subscribe((result: any) => {
      this.taggedFriend = [];
      if (result && result.message === 'Success') {
        this.genderForm = this.fb.group({
          postContent: [''],
        });
        this.format = '';
        this.notifyService.showSuccess('Post Created Successfully.', '');
        if (this.taggedList && this.taggedList.length > 0) {
          result.data.taggedFriend = this.taggedList;
        }
        // this.taggedList = [];
        this.getGroupPost();

        const searchFriend = new FriendRequest();
        searchFriend.limit = 0;
        searchFriend.skip = 0;
        searchFriend.status = 'Accepted';
        this.dataService
          .getFriendRequest(searchFriend)
          .subscribe((result: any) => {
            if (result && result.message === 'Success') {
              this.friendsList = result.data.friendList;
              if (
                this.taggedList === undefined ||
                this.taggedList.length === 0
              ) {
                const userEmails = this.friendsList.map((d) => d.email);

                const emailJson = {
                  email: userEmails,
                  message:
                    this.userDetails.fullName +
                    ' has created a post (' +
                    postTitleContent +
                    ')',
                  Subject: 'GenderFreaks Post Creation Notification',
                };
                this.dataService
                  .notifyUserByEmail(emailJson)
                  .subscribe((result: any) => {
                    console.log('Post Created', result);
                  });
                this.friendsList.forEach((element) => {
                  const json = {
                    isDeleted: false,
                    isView: false,
                    isRead: false,
                    senderId: this.userDetails._id,
                    receiverId: element._id,
                    textMessage:
                      this.userDetails.fullName +
                      ' has created a post (' +
                      postTitleContent +
                      ')',
                    notificationType: 'New_Post',
                  };
                  this.dataService.notifyUser(json).subscribe((result: any) => {
                    console.log('Post Created', result);
                  });
                });

                if(this.groupMembers){
                  this.groupMembers.forEach((element) => {
                    if(this.friendPostList.filter((f) => { return element._id == f._id }).length == 0){
                      const json = {
                        isDeleted: false,
                        isView: false,
                        isRead: false,
                        senderId: this.userDetails._id,
                        receiverId: element._id,
                        textMessage:
                          this.userDetails.fullName +
                          ' has created a post (' +
                          postTitleContent +
                          ')',
                        notificationType: 'New_Post',
                      };
                      this.dataService.notifyUser(json).subscribe((result: any) => {
                        console.log('Post Created', result);
                      });
                    }
                  });
                }
              }
              if (this.taggedList !== undefined && this.taggedList.length > 0) {
                result.data.taggedFriend = this.taggedList;
                console.log('>this.taggedList', this.taggedList);
                const tagFriends = this.taggedList.map((d) => d.fullname);
                let tagMessage = '';
                tagFriends.forEach((element) => {
                  tagMessage = tagMessage + ' ' + element;
                });
                console.log('>>>>this.friendsList>>>>>>>', this.friendsList);
                const userEmails = this.friendsList.map((d) => d.email);

                const emailJson = {
                  email: userEmails,
                  message:
                    this.userDetails.fullName +
                    ' has created a post (' +
                    postTitleContent +
                    ') by tagging ' +
                    tagMessage,
                  Subject: 'GenderFreaks Post Tag Notification',
                };
                this.dataService
                  .notifyUserByEmail(emailJson)
                  .subscribe((result: any) => {
                    console.log('Post Created', result);
                  });

                this.friendsList.forEach((element) => {
                  const json = {
                    isDeleted: false,
                    isView: false,
                    isRead: false,
                    senderId: this.userDetails._id,
                    receiverId: element._id,
                    textMessage:
                      this.userDetails.fullName +
                      ' has created a post (' +
                      postTitleContent +
                      ') by tagging ' +
                      tagMessage,
                    notificationType: 'New_Tag_Post',
                  };
                  this.dataService.notifyUser(json).subscribe((result: any) => {
                    console.log('Post Created Tag', result);
                  });
                });
                this.taggedList = [];
              }
            }
          });
      }
    });
  }

  notifyOtherUsers(receiverId, message){
    const json = {
      isDeleted: false,
      isView: false,
      isRead: false,
      senderId: this.userDetails._id,
      receiverId: receiverId,
      textMessage: message,
      notificationType: 'Friends_Post',
    };
    this.dataService.notifyUser(json).subscribe((result: any) => {
      console.log('Post Created', result);
    });
  }

  UpdateLikeUnlikeStatus(id, status, index, type, postData) {
    let likeStatus: boolean;
    if (status) {
      likeStatus = false;
    } else {
      likeStatus = true;
    }
    this.dataService.postLike(id, likeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        if (likeStatus === false) {
          this.groupPosts[index].totalLike =
            this.groupPosts[index].totalLike === 0
              ? 0
              : this.groupPosts[index].totalLike - 1;
        } else {
          this.groupPosts[index].totalLike =
            this.groupPosts[index].totalLike === 0
              ? 1
              : this.groupPosts[index].totalLike + 1;
        }
        this.groupPosts[index].isLike = likeStatus;
      }
      const searchFriend = new FriendRequest();
      searchFriend.limit = 0;
      searchFriend.skip = 0;
      searchFriend.status = 'Accepted';
      this.dataService
        .getFriendRequest(searchFriend)
        .subscribe((result: any) => {
          if (result && result.message === 'Success') {
            const userEmails = result.data.friendList.map((d) => d.email);
            const emailJson = {
              email: userEmails,
              message:
                this.userDetails.fullName +
                ' has ' +
                (likeStatus === true ? ' liked ' : ' unliked ') +
                (postData.postAuthor.fullName === 'undefined'
                  ? postData.Author.fullName
                  : postData.postAuthor.fullName) +
                ' post(' +
                postData.postTitle +
                ').',
              Subject: 'Friendlinq Like Notification',
            };
            this.dataService
              .notifyUserByEmail(emailJson)
              .subscribe((result: any) => {
                console.log('Post Created', result);
              });

            result.data.friendList.forEach((element) => {
              const json = {
                isDeleted: false,
                isView: false,
                isRead: false,
                senderId: this.userDetails._id,
                receiverId: element._id,
                textMessage:
                  this.userDetails.fullName +
                  ' has ' +
                  (likeStatus === true ? ' liked ' : ' unliked ') +
                  (postData.postAuthor.fullName === 'undefined'
                    ? postData.Author.fullName
                    : postData.postAuthor.fullName) +
                  ' post(' +
                  postData.postTitle +
                  ').',
                notificationType: 'Friends_Post_Like',
              };
              this.dataService.notifyUser(json).subscribe((result: any) => {
                console.log('Post Created', result);
              });
            });
          }
        });
    });
  }

  openDialog(postId, item, index, type) {
    this.dataService.getPostComment(postId).subscribe((commentResult: any) => {
      if (commentResult && commentResult.message === 'Success') {
        if (
          commentResult &&
          commentResult.data &&
          commentResult.data.comments
        ) {
          const json = { postDetail: [], commentDetail: [], index: 0 };
          json.postDetail = item;
          json.commentDetail = commentResult.data.comments;
          json.index = index;

          const dialogRef = this.dialog.open(CommentComponent, {
            data: json,
            height: '600px',
            width: '700px',
            autoFocus: false,
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe((data) => {
            this.groupPosts[index].totalComment = data.count;
            this.groupPosts[index].comment = data.comment;
          });
        }
      }
    });
  }
  reportPost(id, postType, i) {
    const reportPost = new ReportPost();
    reportPost.authorization = this.userDetails.accessToken;
    reportPost.postId = id;
    reportPost.userId = this.userDetails._id;
    reportPost.sensitive = true;
    reportPost.admin = false;
    this.previewClass = 'as';
    this.dataService.reportPost(reportPost).subscribe((commentResult: any) => {
      this.notifyService.showSuccess('Post Reported Successfully.', '');

      this.groupPosts[i].sensitive = true;
    });
  }

  openShareDialog(item) {
    const json = { request: item, isGroup: true };
    const dialogRef = this.dialog.open(ShareComponent, {
      height: '400px',
      width: '700px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom',
      data: json,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getGroupPost();
    });
  }
  deleteGroup() {
    this.dataService
      .deleteGroup(this.groupId)
      .subscribe((commentResult: any) => {
        this.notifyService.showSuccess('Group Deleted Successfully.', '');
        this.router.navigateByUrl('/group');
      });
  }
  tagFriend() {
    const dialogRef = this.dialog.open(TagfriendComponent, {
      height: '600px',
      width: '700px',
      autoFocus: false,
      disableClose: true,
      data: this.taggedList,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.taggedFriend = [];
      this.taggedList = [];
      res.taggedFriend.forEach((element) => {
        this.taggedFriend.push(element.id);

        this.taggedList.push(element);
      });
    });
  }
}
