import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PublicContainerComponent } from './components/public/container/public-container/public-container.component';
import { LoginComponent } from './components/public/login/login.component';
import { SignupComponent } from './components/public/signup/signup.component';
import { ForgotPasswordComponent } from './components/public/forgot-password/forgot-password.component';
import { PrivateContainerComponent } from './components/private/container/private-container/private-container.component';
import { DashboardComponent } from './components/private/dashboard/dashboard.component';
import { ProfileComponent } from './components/private/profile/profile.component';
import { AddFriendComponent } from './components/private/add-friend/add-friend.component';
import { ChatListComponent } from './components/private/chat-list/chat-list.component';
import { FriendProfileComponent } from './components/private/friend-profile/friend-profile.component';
import { ChatComponent } from './components/private/chat/chat.component';
import { VideoComponent } from './components/private/video/video.component';
import { AudioComponent } from './components/private/audio/audio.component';
import { GroupComponent } from './components/private/group/group.component';
import { ScheduleMeetingComponent } from './components/private/schedule-meeting/schedule-meeting.component';
import { PaymentGroupComponent } from './components/private/group/payment-group/payment-group.component';
import { GroupDashboardComponent } from './components/private/group/group-dashboard/group-dashboard.component';
import { NotificationComponent } from './components/private/notification/notification.component';
import { RandomCallComponent } from './components/private/random-call/random-call.component';
import { PortfolioComponent } from './components/private/portfolio/portfolio.component';
import { MainPageComponent } from './components/public/main-page/main-page.component';
import { PrivacyPolicyComponent } from './components/public/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/public/terms-and-conditions/terms-and-conditions.component';
import { inviteuserComponent } from './components/private/inviteuser/inviteuser.component';
import { SignupImageComponent } from './components/public/signup/signup-image/signup-image.component';
import { PostComponent } from './components/private/post/post.component';
import { SignupMainComponent } from './components/public/signup-main/signup-main.component';
import { SupportComponent } from './components/private/options/support/support.component';
import { SettingComponent } from './components/private/options/setting/setting.component';
const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'main' },
  {
    path: '',
    component: PublicContainerComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'main' },
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'signup-main', component: SignupMainComponent },
      { path: 'signup-image', component: SignupImageComponent },
      { path: 'forgotpassword', component: ForgotPasswordComponent },
      {path: 'privacy-policy', component: PrivacyPolicyComponent}
    ],
  },

  {
    path: '',
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'main' },
      { path: 'main', component: MainPageComponent },
      {path: 'terms-and-conditions', component: TermsAndConditionsComponent}
    ],
  },

  // App routes goes here here
  {
    path: '',
    component: PrivateContainerComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'profile/:id', component: FriendProfileComponent },
      { path: 'friend', component: AddFriendComponent },
      { path: 'chatlist', component: ChatListComponent },
      { path: 'chat/:id', component: ChatComponent },
      { path: 'video/:id', component: VideoComponent },
      { path: 'audio', component: AudioComponent },
      { path: 'group', component: GroupComponent },
      { path: 'paymentgroup/:id', component: PaymentGroupComponent },
      { path: 'group/:id', component: GroupDashboardComponent },
      { path: 'notification', component: NotificationComponent },
      { path: 'randomcall', component: RandomCallComponent },
      { path: 'portfolio', component: PortfolioComponent },
      { path: 'schedule-meeting', component: ScheduleMeetingComponent },
      { path: 'inviteuser', component: inviteuserComponent },
      { path: 'post/:id', component: PostComponent },
      { path: 'support', component: SupportComponent },  
      { path: 'settings', component: SettingComponent },
    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'public' },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
