import { Component, OnInit } from '@angular/core';
import { DataService } from '../../service/data.service';

@Component({
  selector: 'app-signup-image',
  templateUrl: './signup-image.component.html',
  styleUrls: ['./signup-image.component.css']
})
export class SignupImageComponent implements OnInit {
  constructor() {
   }

  ngOnInit(): void {
  }
  isSecondImageVisible: boolean = false;
  headerText: string = 'Diverse faces, unique stories, waiting to unfold. Find your perfect match today!';
  currentImageSrc: string = "../../../../../assets/images/signup-img1.png"; 

  showSecondImage(): void {
    this.isSecondImageVisible = true;
    this.headerText = "Life's a beach, but it's better shared. Find your perfect wave.";
    this.currentImageSrc = "../../../../../assets/images/signup-img2.png";
  }
}

