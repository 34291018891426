import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/shared/api/auth.service';
import { Router } from '@angular/router';


@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  currentToken = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private authService: AuthService, private router: Router) {
      
      this.receiveMessage();
    /*this.angularFireMessaging.onMessage(
      (messaging) => {
        console.log(messaging);
        messaging.onMessage = messaging.onMessage.bind(messaging);
        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      }
    )*/
  }

  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
       // this.updateToken(this.authService.getloggedUserDetails()["accessToken"], token);
        this.currentToken.next(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /*async getToken(guid: string) {
      let counter = 0;
      let token = null;
      // 3 Mal mit 3 Sekunden Abstand wiederholen --> Token zu Beginn oft null
      while (counter < 3 && token == null) {
          counter++;
          await this.delay(3000);
          this.angularFireMessaging.getToken.subscribe(
              async (firebaseToken) => {
                  token = firebaseToken;
                  await this.httpService.createFirebaseToken(guid, firebaseToken); // token + guid in DB speichern
                  await this.httpService.log('getToken', 'push-notification.service', guid + ' - ' + JSON.stringify(token), '');
              },
              (err) => {
                  console.error('Fehler bei getToken.', err);
              }
          );
      }
  }*/

  requestPermissionToken() {
    return this.angularFireMessaging.requestToken;
  }

  receiveMessage() {
    this.angularFireMessaging.onMessage(
      (messaging) => {
        console.log(messaging);
        messaging.onMessage = messaging.onMessage.bind(messaging);
        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      }
    )

    //this.angularFireMessaging.onMessage(this.currentMessage);
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log('Foreground message: ', payload);
        this.router.navigateByUrl('/video/chname-'+payload["data"]["channelName"]);
        this.currentMessage.next(payload['notification']['body']);
      });
  }
}