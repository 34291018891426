<a class="close-button" style="cursor: pointer;" (click)="closeDialog()"><i class="fa fa-close"
        style="color: #0041c1"></i></a>
<h2 mat-dialog-title class="title">{{title}}</h2>
<div *ngIf="showLoader" style="margin-top: 100px;margin-left: 220px;"> <img
        src=" ../../../../assets/images/loading.gif">
</div>

<div class="container">
    <div class="row">
        <div class="col-md-12 col-12">
            <div class="add-friends">
                <div class="tab-content">
                    <div id="home">
                        <div class="friends-list"
                            [ngStyle]="{'margin-top': (randomSuggestion.length>0) ? '10px' : '10px'}">
                            <div class="friends-list-inner" *ngFor="let item of randomSuggestion;let i = index">
                                <div class="friends-list-image" style="cursor: pointer;">
                                    <a (click)="openProfile(item)"> <img
                                            src="{{imageUrl}}/{{item.imageURL.thumbnail}}"></a>
                                </div>
                                <div class="friends-list-details" style="cursor: pointer; margin-top: 4px;
                            font-size: 18px;" (click)="addTag(item)">
                                    {{item.fullName}}


                                </div>
                                <div class="friends-list-add">
                                    <button (click)="AddFriend(item)">Add Friend</button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>