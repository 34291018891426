<div class="container">
    <h2 style="
    color: #007bff;
    text-align: center;">{{postTitle}} <a class="deleteGroup" (click)="toggleDeleteGroup(previewDeleteClass)"><i
                class=" fa fa-ellipsis-v delete" aria-hidden="true">
            </i></a>
        <span *ngIf="previewDeleteClass==='deleteGroupIcon'" class=" deleteGroupIcon"><a (click)="deleteGroup()">Leave the Group</a></span>
    </h2>
    <form [formGroup]="genderForm">
        <div class="row">
            <div class="col-md-12 col-12">

                <div class="post">
                    <div (click)="onGroupIconClick()" *ngIf="groupData && groupData.ExtraElements && groupData.ExtraElements.groupIcon && groupData.ExtraElements.groupIcon.original !== null">
                        <img src="{{imageUrl}}/{{groupData.ExtraElements.groupIcon.original}}">
                    </div>
                    <div (click)="onGroupIconClick()" *ngIf="groupData && groupData.ExtraElements && groupData.ExtraElements.groupIcon && groupData.ExtraElements.groupIcon.original === null"
                        style="cursor: pointer;">
                        <img src="../../../../../assets/images/camera.jpg">

                    </div>
                    <input type="file" #groupIconUpload id="groupIconUpload" (click)="onGroupIconClick()"
                        name="groupIconUpload" accept="image/*" style="display:none;" />
                    <div class="fake-image" *ngIf="format==='image'">
                        <img src="{{imageSrc}}">
                    </div>
                    <div class="fake-image" *ngIf="format==='video'">
                        <video src="{{imageSrc}}" controls></video>
                    </div>
                    <div class="post-area" *ngIf="format==='image' || format==='video'">
                        <textarea formControlName="postContent"></textarea>

                    </div>
                    <div class="post-area-nomargin" *ngIf="format!=='image' && format!=='video'">
                        <textarea formControlName="postContent"></textarea>

                    </div>

                    <div class="post-button" (click)="CreatePost()">
                        <a [attr.disabled]="!genderForm.valid" style="cursor: pointer;">POST</a>
                    </div>
                </div>




                <div class="public-post">
                    <p style="cursor: pointer;" (click)="onClick()"><img src="../../../../assets/images/photo.png">
                        <input type="file" #fileUpload id="fileUpload" (click)="onClick()" name="fileUpload"
                            accept="image/*" style="display:none;" />
                        Photo
                    </p>
                    <p style="cursor: pointer;" (click)="tagFriend()">
                        <img src="../../../../assets/images/tagfriend.png">

                        Tag Friend
                    </p>
                    <p style="cursor: pointer;" (click)="onClickVideo()"><i class="fa fa-video-camera"
                            aria-hidden="true"></i> <input type="file" #fileUploadVideo id="fileUploadVideo"
                            (click)="onClickVideo()" name="fileUploadVideo" accept="video/*" style="display:none;" />
                        Video</p>
                    <p style="cursor: pointer;" (click)="seeGroupMembers()"><i _ngcontent-rli-c83="" aria-hidden="true"
                            class="fa fa-users"></i>

                        Members
                    </p>
                    <p style="cursor: pointer;" (click)="seeGroupRequest()"><i _ngcontent-rli-c83="" aria-hidden="true"
                            class="fa fa-users"></i>
                        Request</p>
                </div>

            </div>
        </div>
    </form>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-12 col-12">

            <div *ngIf="showLoader" style="margin-top: 100px;margin-left: 450px;"> <img
                    src=" ../../../../assets/images/loading.gif">
            </div>

            <div *ngIf="groupPosts && groupPosts.length>0" class="people-all-comment">
                <div *ngFor="let item of groupPosts;let i = index">
                    <div class="person-post">
                        <p><img src="{{imageUrl}}/{{item.postAuthor.imageURL.original}}"></p>
                        <p>{{item.postAuthor.fullName}}

                            <span style="display: inline;"
                                *ngIf="item.taggedFriendList &&  item.taggedFriendList.length>0">
                                is
                                with
                                <span *ngFor="let tagItme of item.taggedFriendList" style="display: inline;">
                                    <span *ngIf="tagItme.tagAuthor" style="display: inline;">
                                        {{tagItme.tagAuthor.fullName}}</span></span>

                            </span>
                            <span style="display: inline;" *ngIf="item.isShared">

                                <span style="display: inline;">
                                    shared {{item.sharedPostOwner}} post</span>

                            </span>
                            <span>{{ item.createdAt | date :'h:mm a dd-MMM-yy'
                                }}</span>
                        </p>

                        <p>
                            <a (click)="toggleClass(previewClass,i)"><i class=" fa fa-ellipsis-v delete"
                                    aria-hidden="true">
                                </i></a>

                            <span *ngIf="previewClass==='ash' && clickIndex === i" class="ash"><a
                                    (click)="reportPost(item._id,'Friend',i)">Report this
                                    Post</a></span>
                        </p>
                    </div>
                    <div class="latest-post" *ngIf="!item.sensitive">
                        <p>{{item.postContent}}</p>
                        <p *ngIf="item.imageURL.original !== null"><img class="timelineImage"
                                src="{{imageUrl}}/{{item.imageURL.original}}">
                        <p *ngIf="item.videoURL !== null"> <video width="420" height="345" controls>
                                <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                            </video>
                        </p>
                    </div>
                    <div class="latest-post" *ngIf="item.sensitive">
                        <p>{{item.postContent}}</p>
                        <p *ngIf="item.imageURL.original !== null && clickBlurIndex !== i"><img
                                class="blurPost timelineImage" src="{{imageUrl}}/{{item.imageURL.original}}">
                        </p>

                        <p *ngIf="item.imageURL.original !== null && clickBlurIndex === i"><img class="timelineImage"
                                src="{{imageUrl}}/{{item.imageURL.original}}">
                        </p>
                        <p *ngIf="item.videoURL !== null &&  clickBlurIndex !== i">
                            <video width="420" height="345" controls class="blurPost">
                                <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                            </video>
                        </p>
                        <p *ngIf="item.videoURL !== null &&  clickBlurIndex === i"> <video width="420" height="345"
                                controls>
                                <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                            </video>
                        </p>
                        <p>This post is bad content.
                            <strong> <a style="cursor: pointer;" style="color: #0041c1;cursor: pointer;"
                                    (click)="removeBlur(i)">See this
                                    post</a></strong>
                        </p>
                    </div>
                    <div class="likecomment-section">
                        <div class="like-part">
                            <p><span><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></span> {{item.totalLike}}
                            </p>
                        </div>
                        <div class="comment-part">
                            <p>{{item.totalComment}} Comments</p>
                        </div>
                    </div>
                    <div class="share">
                        <div class="like-part">
                            <p *ngIf="item.isLike" style="color: #0041c1;cursor: pointer"
                                (click)="UpdateLikeUnlikeStatus(item._id,item.isLike,i,'Friend,item')"><i
                                    class="fa fa-thumbs-o-up" aria-hidden="true"
                                    style="color: #0041c1;cursor: pointer"></i>
                                Like</p>
                            <p style="cursor: pointer;" *ngIf="!item.isLike"
                                (click)="UpdateLikeUnlikeStatus(item._id,item.isLike,i,'Friend',item)"><i
                                    class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                Like</p>
                        </div>
                        <div class="comment-part">
                            <p style="cursor: pointer;" style="cursor: pointer;"
                                (click)="openDialog(item._id,item,i,'Friend')"><i class="fa fa-comment-o"
                                    aria-hidden="true"></i>
                                Comment</p>
                        </div>
                        <div class="comment-part">
                            <p style="cursor: pointer;" (click)="openShareDialog(item)"><i class="fa fa-share"
                                    aria-hidden="true"></i> Share</p>
                        </div>
                    </div>
                    <div class="public-comments-main">
                        <div *ngFor="let comment of item.comment">
                            <div class="public-comments">
                                <div class="public-img">
                                    <img src="{{imageUrl}}/{{comment.commentAuthor.imageURL.original}}">
                                </div>
                                <div class="publiccommentInner">
                                    <h5>{{comment.commentAuthor.fullName}}</h5>
                                    <p class="comment">{{comment.commentText}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="self-commnet" *ngIf="item.totalComment <2">
                        <div class="public-comments">
                            <div class="public-img">
                                <img src="{{imageUrl}}/{{userDetails.imageURL.original}}">
                            </div>
                            <div class="mycomment">
                                <textarea #myFriendElement name="txtComment{{i}}"
                                    placeholder="Write a public comment here..." (keydown.enter)="onKeydown($event)"
                                    (keyup.enter)="saveComment($event.target.value,i,'Friend',item._id)"></textarea>
                            </div>
                        </div>
                    </div> -->
                    <div class="load-commnet">
                        <p style="cursor: pointer;" (click)="openDialog(item._id,item,i,'Friend')">More Comments</p>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>