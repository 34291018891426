import { Component, OnInit } from '@angular/core';
import { DataService } from '../service/data.service'

@Component({
    selector: 'terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css']
  })
  export class TermsAndConditionsComponent implements OnInit {
    ngOnInit(): void {
    }
  }