<div class="container">
    <div class="row">
        <div class="col-md-12 col-12">
            <div class="friends-heading" >
                <div class="row">
                    <div (click)="selectPrivate('public')" class="col-md-6">
                        <h1 [ngClass]="{'active-header': selectedPort==='public'}" class="normal-header" style="cursor: pointer;">Public Portfolio</h1>
                    </div>
                    <div (click)="selectPrivate('private')" class="col-md-6">
                        <h1 [ngClass]="{'active-header': selectedPort==='private'}" class="normal-header"  style="cursor: pointer;">Private Portfolio</h1>
                    </div>
                    
                </div>
            </div>
            <div *ngIf="selectedPort==='public'" class="add-friends new-groups">
                <ul class="nav nav-pills">
                    <li class="active"><a data-toggle="pill" style="cursor: pointer;" (click)="fileInput.click()"><i
                                class="fa fa-picture-o" aria-hidden="true"></i>
                            Select Pictures to Create Portfolio</a>
                        <input style="display: none" accept="image/*" #attachments type="file" (click)="onClick()"
                            #fileInput multiple="true"/>
                    </li>

                <!-- Added by Poonam for uploading video -->
                <li class="active">
                    <a data-toggle="pill" style="cursor: pointer;" (click)="fileInputVideo.click()">
                        <i class="fa fa-video-camera" aria-hidden="true"></i>
                        Select Videos to Create Portfolio
                    </a>
                    <input style="display: none" accept="video/*" #videoattachment type="file" (click)="onVideoClick()" 
                    #fileInputVideo multiple="true"/>
                </li>
                <!-- Added by Poonam for uploading video -->

                </ul>


                <!-- Added by poonam for create section for images and videos -->

                <div *ngIf="selectedPort==='public'" class="media-selection">
                    <div class="media-selection-bar">
                        <div class="media-section" (click)="setSelectedMediaType('image')" [class.active]="selectedMediaType === 'image'">
                            Images
                        </div>
                        <div class="media-section" (click)="setSelectedMediaType('video')" [class.active]="selectedMediaType === 'video'">
                            Videos
                        </div>
                    </div>
                  </div>

                <!-- Added by Poonam for uploading video -->

            </div>
            <div *ngIf="selectedPort==='public'" class="tab-content">

                <div *ngIf="showLoader" style="margin-top: 100px;margin-left: 450px;"> <img
                        src=" ../../../../assets/images/loading.gif">
                </div>

                <!-- The four columns -->
                <div class="row">
                    <div class="column" *ngFor="let item of getFilteredPortfolio(myPortfolio)">
                      <ng-container *ngIf="isImage(item.imageURL); else videoTemplate">
                        <img src="{{imageUrl}}/{{item.imageURL}}" style="width:100%" height="400px">
                      </ng-container>
                      <ng-template #videoTemplate>
                        <video  style="width:100%; height:300px;"  controls  muted loop>
                          <source src="{{imageUrl}}/{{item.imageURL}}" type="video/mp4">
                        </video>
                      </ng-template>
                      <a class="delete-Icon" (click)="delete(item)">
                        <span class="delete-Icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                      </a>
                    </div>
                  </div>

                <!-- <div class="row">
                    <div class="column" *ngFor="let item of getFilteredPortfolio(myPortfolio)">

                        <img src="{{imageUrl}}/{{item.imageURL}}" style="width:100%" height="100%">
                        <a class="delete-Icon" (click)="delete(item)"><span class="delete-Icon"><i class="fa fa-trash-o"
                                    aria-hidden="true"></i></span>
                        </a>
                    </div>

                </div>
            </div> -->

            
            <div *ngIf="selectedPort==='private'" class="add-friends new-groups">
                <ul class="nav nav-pills">
                    <li class="active"><a data-toggle="pill" style="cursor: pointer;" (click)="fileInputPrivate.click()"><i
                                class="fa fa-picture-o" aria-hidden="true"></i>
                            Select Pictures/Videos to Create Portfolio</a>
                        <input style="display: none" accept="image/*,video/mp4,video/x-m4v,video/*" #attachments type="file" (click)="onPrivatePortfolioClick()"
                            #fileInputPrivate multiple="true">


                    </li>

                </ul>
                
                <span *ngIf="showValidation" class="validation">Total file size should be less than 5MB.</span>
            </div>
            <div *ngIf="selectedPort==='private'" class="tab-content">

                <div *ngIf="showLoader" style="margin-top: 100px;margin-left: 450px;"> <img
                        src=" ../../../../assets/images/loading.gif">
                </div>

                <!-- The four columns -->
                <div class="row">
                    <div class="column" *ngFor="let item of myPrivatePortfolio">
                        <span *ngIf="item.isVideo == true" style="width:100%">
                            
                            <video autoplay="autoplay" style="width:100%" controls="controls" muted="muted">
                                <source src="{{imageUrlPrivate}}/{{item.filePath}}" type="{{item.fileType}}">
                            </video>
                        </span>
                        <img *ngIf="item.isVideo == false" src="{{imageUrlPrivate}}/{{item.filePath}}" style="width:100%" height="100%">
                        <a class="delete-Icon" (click)="deletePrivate(item)"><span class="delete-Icon"><i class="fa fa-trash-o"
                                    aria-hidden="true"></i></span>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>