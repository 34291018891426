import { Component, OnInit } from '@angular/core';
import { DataService } from '../../public/service/data.service';
import { FriendRequest } from '../../models/friend.model';
import { AuthService } from 'src/app/shared/api/auth.service';

import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-inviteuser',
  templateUrl: './inviteuser.component.html',
  styleUrls: ['./inviteuser.component.css']
})
export class inviteuserComponent implements OnInit {

 
   email:string = "";
  message:string = "";
  user;

  constructor(private dataService: DataService,
    private notifyService: NotificationService, private authService: AuthService) { 
      this.user = authService.getloggedUserDetails();
  }

  ngOnInit(): void {
    
  }
  
  async inviteuser(){

  if(this.email=='')
    {
      
      this.notifyService.showWarning("Email can not be  empty","Failed");
      return;
    }
    var data = {
      sender:this.user.email,
      email: this.email,
      message:this.message
    };

    

    this.dataService.inviteuser(data).subscribe((res) => {
      this.notifyService.showSuccess("Invitation sent  successfully", "Success");
      this.email = "";
      this.message = "";
    });
  }


}