<h2 mat-dialog-title class="title">Notifications</h2>
<div class="container">
    <div class="row">
        <div class="col-md-12 col-12">
            <div class="add-friends">
                <div class="tab-content">
                    <div class="friends-list" *ngFor="let item of notificationList;let i = index">
                        <div class="friends-list-inner">

                            <div class="friends-list-details" style="cursor: pointer;
                            width: 100%;">
                                <p class="notify" *ngIf="!item.isRead" (click)="readNotification(item._id,i)">
                                    <a> <i class="fa fa-bell" aria-hidden="true"></i>
                                        {{item.textMessage}}</a>
                                </p>
                                <p *ngIf="item.isRead" (click)="readNotification(item._id,i)">
                                    <a> <i class="fa fa-bell" aria-hidden="true"></i>
                                        {{item.textMessage}}</a>
                                </p>

                            </div>


                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>