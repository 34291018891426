import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/api/auth.service';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DataService } from '../../public/service/data.service';
import { ShareGroupPost, SharePost } from '../../models/sharePost.model';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FriendRequest } from '../../models/friend.model';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css'],
})
export class ShareComponent implements OnInit {
  imageUrl: string;
  userDetails: any;
  postDetail: any;
  isGroup: boolean;
  postComment: any;
  genderForm = this.initForm();
  constructor(
    private authService: AuthService,
    private dialogRef: MatDialogRef<ShareComponent>,
    private dataService: DataService,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.imageUrl = environment.rearGuardImageUri;
    this.userDetails = this.authService.getloggedUserDetails();
    this.postDetail = data.request;
    this.postComment = data.request.postContent;
    this.isGroup = data.isGroup;
  }
  private initForm() {
    return this.fb.group({
      postType: ['Friend_Only', Validators.required],
    });
  }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }
  sharePost(data) {
    const _sharePost = new SharePost();
    _sharePost.authorization = this.userDetails.accessToken;
    _sharePost.imageThumbnail = data.imageURL.original;
    _sharePost.imageUrl = data.imageURL.original;
    _sharePost.videoUrl = data.videoURL;
    _sharePost.videoThumbnail = data.videoURL;
    _sharePost.postContent = this.postComment;
    _sharePost.postTitle = this.postComment;
    _sharePost.postType = this.genderForm.value.postType;
    _sharePost.postOwnerId =
      data.postType === 'Public' ? data.Author._id : data.postAuthor._id;
    _sharePost.postOwnerName =
      data.postType === 'Public'
        ? data.Author.fullName
        : data.postAuthor.fullName;
    _sharePost.sharedBy = this.userDetails.fullName;

    this.dataService.sharePost(_sharePost).subscribe((result: any) => {
      this.notifyService.showSuccess('Post shared to Timeline.', '');
      this.dialogRef.close(this.genderForm.value.postType);
    });
    const searchFriend = new FriendRequest();
    searchFriend.limit = 0;
    searchFriend.skip = 0;
    searchFriend.status = 'Accepted';
    this.dataService.getFriendRequest(searchFriend).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        const userEmails = result.data.friendList.map((d) => d.email);
        const emailJson = {
          email: userEmails,
          message:
            this.userDetails.fullName +
            ' has shared ' +
            (this.postDetail.postAuthor.fullName === 'undefined'
              ? this.postDetail.Author.fullName
              : this.postDetail.postAuthor.fullName) +
            ' post(' +
            this.postDetail.postTitle +
            ').',
          Subject: 'GenderFreaks Share Notification',
        };
        this.dataService
          .notifyUserByEmail(emailJson)
          .subscribe((result: any) => {
            console.log('Post Created', result);
          });
        result.data.friendList.forEach((element) => {
          const json = {
            isDeleted: false,
            isView: false,
            isRead: false,
            senderId: this.userDetails._id,
            receiverId: element._id,
            textMessage:
              this.userDetails.fullName +
              ' has shared ' +
              (this.postDetail.postAuthor.fullName === 'undefined'
                ? this.postDetail.Author.fullName
                : this.postDetail.postAuthor.fullName) +
              ' post(' +
              this.postDetail.postTitle +
              ').',
            notificationType: 'Friends_Post_Share',
          };
          this.dataService.notifyUser(json).subscribe((result: any) => {
            console.log('Post Created', result);
          });
        });
      }
    });
  }
  shareGroupPost(data) {
    const _sharePost = new ShareGroupPost();
    _sharePost.authorization = this.userDetails.accessToken;
    _sharePost.imageThumbnail = data.imageURL.original;
    _sharePost.imageUrl = data.imageURL.original;
    _sharePost.videoUrl = data.videoURL;
    _sharePost.videoThumbnail = data.videoURL;
    _sharePost.postContent = this.postComment;
    _sharePost.postTitle = this.postComment;
    _sharePost.groupId = this.postDetail.groupId;
    _sharePost.postOwnerId = data.postAuthor._id;
    _sharePost.postOwnerName = data.postAuthor.fullName;
    _sharePost.sharedBy = this.userDetails.fullName;

    this.dataService.shareGroupPost(_sharePost).subscribe((result: any) => {
      this.notifyService.showSuccess('Post shared to Groups.', '');
      this.dialogRef.close();
    });
    const searchFriend = new FriendRequest();
    searchFriend.limit = 0;
    searchFriend.skip = 0;
    searchFriend.status = 'Accepted';
    this.dataService.getFriendRequest(searchFriend).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        const userEmails = result.data.friendList.map((d) => d.email);
        const emailJson = {
          email: userEmails,
          message:
            this.userDetails.fullName +
            ' has shared ' +
            (this.postDetail.postAuthor.fullName === 'undefined'
              ? this.postDetail.Author.fullName
              : this.postDetail.postAuthor.fullName) +
            ' post(' +
            this.postDetail.postTitle +
            ').',
          Subject: 'GenderFreaks Share Group Post Notification',
        };
        this.dataService
          .notifyUserByEmail(emailJson)
          .subscribe((result: any) => {
            console.log('Post Created', result);
          });
        result.data.friendList.forEach((element) => {
          const json = {
            isDeleted: false,
            isView: false,
            isRead: false,
            senderId: this.userDetails._id,
            receiverId: element._id,
            textMessage:
              this.userDetails.fullName +
              ' has shared ' +
              (this.postDetail.postAuthor.fullName === 'undefined'
                ? this.postDetail.Author.fullName
                : this.postDetail.postAuthor.fullName) +
              ' post(' +
              this.postDetail.postTitle +
              ').',
            notificationType: 'Friends_Post_Share',
          };
          this.dataService.notifyUser(json).subscribe((result: any) => {
            console.log('Post Created', result);
          });
        });
      }
    });
  }
}
