<!-- <div class="profile" style="margin-top: -10px;">
    <div class="profile-banner">
        <img src="{{isPicExists}}">
        <div style="height: 20px;">
        </div>
    </div>
</div> -->
<div class="profile-content">
<div class="profile">
    <div class="profile-banner">

        <img src="{{isPicExists}}">

        <div class="photo">
            <img src="{{profileImage}}">            
        </div>


    </div>
</div>

<div class="general-setting profile-data">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="profile-details" style="border-bottom: 2px solid #fff;padding-bottom: 10px;" *ngIf="isFriend === 'Yes'">
                    <div class="calling-option" *ngIf="isFriend === 'Yes'">
                        <p style=" cursor: pointer;">
                            <a (click)="navigateToChat()"><i class="fa fa-comment" aria-hidden="true"></i></a>
                            <a (click)="navigateToAudio()"> <i class="fa fa-phone" aria-hidden="true"></i></a>
                            <a (click)="navigateToVideo()"> <i class="fa fa-video-camera"
                                    aria-hidden="true"></i></a>
                        </p>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<div class="change-background" *ngIf="friendsPrivatePortfolio && friendsPrivatePortfolio.length>0">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="heading" style="margin-top: 25px;border-bottom: 2px solid #fff;">
                    <h1>Private Portfolio</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="column" *ngFor="let item of friendsPrivatePortfolio">
                <span *ngIf="item.isVideo == true" style="width:100%">
                            
                    <video autoplay="autoplay" style="width:100%" controls="controls" muted="muted">
                        <source src="{{imageUrlPrivate}}/{{item.filePath}}" type="{{item.fileType}}">
                    </video>
                </span>
                <img *ngIf="item.isVideo == false" src="{{imageUrlPrivate}}/{{item.filePath}}" (click)="openPrivatePortfolioModal(item.filePath)" style="width:100%" height="100%">
                
            </div>

        </div>
    </div>
</div>

<div class="change-background" *ngIf="friendsPortfolio && friendsPortfolio.length>0">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="heading" style="margin-top: 25px;border-bottom: 2px solid #fff;">
                    <h1>Public Portfolio</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="column" *ngFor="let item of friendsPortfolio">

                <img src="{{imageUrl}}/{{item.imageURL}}" (click)="openPortfolioModal(item.imageURL)" style="width:100%"
                    height="100%">


            </div>

        </div>
    </div>
</div>

<div class="general-setting">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="profile-inner">
                    <h1>
                        General Account Settings
                        <span *ngIf="totalFriends > 0">({{totalFriends}} Friends)</span>
                    </h1>
                    <div class="heading-border"></div>
                </div>

                <div class="account-details">
                    <form *ngIf="friendsProfile">
                        <div class="profile-details">

                            <div class="profile-label">
                                <label>Name</label>
                            </div>
                            <div class="profile-name">
                                <p>{{this.friendsProfile.fullName}}</p>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                                <label>Location</label>
                            </div>
                            <div class="profile-name">
                                <p>{{this.friendsProfile.country}}</p>
                            </div>
                        </div>


                        <div class="profile-details">
                            <div class="profile-label">
                                <label>About</label>
                            </div>
                            <div class="profile-name">
                                <p> {{this.friendsProfile.about}}</p>
                            </div>
                        </div>


                        <div class="profile-details">
                            <div class="profile-label">
                                <label>I identify as</label>
                            </div>
                            <div class="profile-name">
                                <p> {{this.friendsProfile.gender}}</p>
                            </div>
                        </div>


                        <div class="profile-details">
                            <div class="profile-label">
                                <label>State</label>
                            </div>
                            <div class="profile-name">
                                <p>{{this.friendsProfile.state}}</p>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                                <label>City</label>
                            </div>
                            <div class="profile-name">
                                <p>{{this.friendsProfile.city}}</p>
                            </div>
                        </div>

                       <!-- <div class="profile-details">
                            <div class="profile-label">
                                <label>Email</label>
                            </div>
                            <div class="profile-name">
                                <p>{{this.friendsProfile.email}}</p>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                                <label>Phone Number</label>
                            </div>
                            <div class="profile-name">
                                <p>{{this.friendsProfile.phoneNumber}}</p>
                            </div>
                        </div>-->
                        
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="change-background" *ngIf="myPosts && myPosts.length>0">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="heading">
                    <h1>Post</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="people-all-comment">
                    <div *ngFor="let item of myPosts;let i = index">
                        <div class="person-post">
                            <p><img src="{{profileImage}}"></p>
                            <p>{{friendsProfile.fullName}}
                                <span style="display: inline;"
                                    *ngIf="item.taggedFriendList &&  item.taggedFriendList.length>0">
                                    is
                                    with
                                    <span *ngFor="let tagItme of item.taggedFriendList" style="display: inline;">
                                        <span *ngIf="tagItme.tagAuthor" style="display: inline;">
                                            {{tagItme.tagAuthor.fullName}}</span></span>

                                </span>
                                <span style="display: inline;" *ngIf="item.isShared">

                                    <span style="display: inline;">
                                        shared {{item.sharedPostOwner}} post</span>

                                </span>

                                <span>{{ item.createdAt | date :'h:mm a dd-MMM-yy'
                                    }}</span>
                            </p>
                            <p>
                                <a (click)="toggleClass(previewClass,i)"><i class=" fa fa-ellipsis-v delete"
                                        aria-hidden="true">
                                    </i></a>
                                <span *ngIf="previewClass==='as'" class="as"><a (click)="deletePost(item._id,i)">Delete
                                        Post</a></span>
                                <span *ngIf="previewClass==='ash' && clickIndex === i" class="ash"><a
                                        (click)="reportPost(item._id,'Friend')">Report this
                                        Post</a></span>
                            </p>
                        </div>
                        <div class="latest-post" *ngIf="!item.sensitive">
                            <p>{{item.postContent}}</p>
                            <p *ngIf="item.imageURL.original !== null"><img class="timelineImage"
                                    src="{{imageUrl}}/{{item.imageURL.original}}">
                            <p *ngIf="item.videoURL !== null"> <video width="420" height="345" controls>
                                    <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                                </video>
                            </p>
                        </div>
                        <div class="latest-post" *ngIf="item.sensitive">
                            <p>{{item.postContent}}</p>
                            <p *ngIf="item.imageURL.original !== null && clickBlurIndex === -1"><img
                                    class="blurPost timelineImage" src="{{imageUrl}}/{{item.imageURL.original}}">
                            </p>

                            <p *ngIf="item.imageURL.original !== null && clickBlurIndex === i"><img
                                    class="timelineImage" src="{{imageUrl}}/{{item.imageURL.original}}">
                            </p>
                            <p *ngIf="item.videoURL !== null &&  clickBlurIndex === -1">
                                <video width="420" height="345" controls class="blurPost">
                                    <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                                </video>
                            </p>
                            <p *ngIf="item.videoURL !== null &&  clickBlurIndex === i"> <video width="420" height="345"
                                    controls>
                                    <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                                </video>
                            </p>
                            <p>This post is bad content.
                                <strong> <a style="color: #0041c1;cursor: pointer;" (click)="removeBlur(i)">See this
                                        post</a></strong>
                            </p>
                        </div>
                        <div class="likecomment-section">
                            <div class="like-part">
                                <p><span><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></span> {{item.totalLike}}
                                    <span class="ml-4"><i class="fa fa-thumbs-down" aria-hidden="true"></i></span>
                                    {{item.totalDislike}}
                                </p>
                            </div>
                            <div class="comment-part">
                                <p>{{item.totalComment}} Comments</p>
                            </div>
                        </div>
                        <div class="share">
                            <div class="like-part">
                                <p *ngIf="item.isLike" style="color: white; cursor: pointer;"
                                    (click)="UpdateLikeStatus(item._id,item.isLike,i)"><i
                                        class="fa fa-thumbs-o-up" aria-hidden="true"
                                        style="color: white;cursor: pointer;"></i>
                                    Like</p>
                                <p style=" color: grey; cursor: pointer;" *ngIf="!item.isLike"
                                    (click)="UpdateLikeStatus(item._id,item.isLike,i)"><i
                                        class="fa fa-thumbs-o-up" aria-hidden="true"
                                        style="color: grey;cursor: pointer;"></i>
                                    Like</p>
                                 </div>
                                 
                                    <div class="like-part">
                                        <p *ngIf="item.isDislike" style="color: white;cursor: pointer;"
                                            (click)="UpdateDislikeStatus(item._id,item.isDislike,i)"><i
                                                class="fa fa-thumbs-o-up" aria-hidden="true"
                                                style="color: white;cursor: pointer;"></i>
                                            Dislike</p>
                                        <p style=" color: grey; cursor: pointer;" *ngIf="!item.isDislike"
                                            (click)="UpdateDislikeStatus(item._id,item.isDislike,i)"><i
                                                class="fa fa-thumbs-o-up" aria-hidden="true"
                                                style="color: grey;cursor: pointer;"></i>
                                                Dislike</p>
                                         </div>
                                         
                            
                            <div class="comment-part">
                                <p style="cursor: pointer;" (click)="openDialog(item._id,item,i,'Friend')"><i
                                        class="fa fa-comment-o" aria-hidden="true"></i>
                                    Comment</p>
                            </div>
                            <div class="comment-part">
                                <p style="cursor: pointer;" (click)="openShareDialog(item)"><i class="fa fa-share"
                                        aria-hidden="true"></i> Share
                                </p>
                            </div>
                        </div>
                        <div class="public-comments-main">
                            <div *ngFor="let comment of item.comment">
                                <div class="public-comments">
                                    <div class="public-img">
                                        <img src="{{imageUrl}}/{{comment.commentAuthor.imageURL.original}}">
                                    </div>
                                    <div class="publiccommentInner">
                                        <h5>{{comment.commentAuthor.fullName}}</h5>
                                        <p class="comment">{{comment.commentText}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </div>
        </div>
    </div>
</div>
</div>