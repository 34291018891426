import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaticData } from 'src/app/shared/constants/constant';
import { PyamentMethodImpl, RequestToAdmin, SignUp } from '../../models/signup.model';
import { Guid } from 'guid-typescript';
import { DataService } from '../service/data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { loadStripe, Stripe, StripeElements, StripeCardNumberElement } from '@stripe/stripe-js';

import { throwError } from 'rxjs';
import { Login } from '../../models/login.model';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']

})
export class SignupComponent implements OnInit {
  datingStatus: boolean = false;
  socialFormStatus: boolean =true;
  datingFormStatus = false;
  signupForm = this.initForm(this.datingStatus); 
  acceptTerms: boolean = true;
  userEmail:any;
  userPassword:any;
  public countryList: any[] = [];
  public states: any[] = [];
  public genderList: any[];
  public ageList: any[];
  public educationLevelList: any[];
  public personalityList: any[];
  public lookingForList: any[];
  public exerciseHabitsList: any[];
  public alcoholUseList: any[];
  public drugUseList: any[];
  public politicalViewsList: any[];
  public religionList: any[];
  public passwordNotMatch: boolean = false;
  public emailVerified: boolean = false;
  public showOTP: boolean = false;
  public sentOTP: boolean = false;
  public otpMessageText:string = "Send OTP";
  public emailToVerify:string = "";
  public otpToVerify:string = "";
  public otp:string = "";
  public generatingEmail:boolean = false; 
  isProcessing: boolean = false;
  public allplans:any;
  public showPlans:boolean=false;
  public showPaymentDetails:boolean=false;
  selectedItem: any | null = null;
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  cardNumber: StripeCardNumberElement | null = null;
  public accesstoken:string="";
  selectedFileName: string | null = null;
  imgURL: any;

  constructor(
    private fb: FormBuilder,
    private dataservice: DataService,
    private notifyService: NotificationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.userEmail = localStorage.getItem('email');
    
    this.userPassword = localStorage.getItem('password');
    this.countryList = StaticData.countries;
    this.genderList = StaticData.Gender;
    this.ageList = StaticData.age;
    this.educationLevelList = StaticData.educationLevel;
    this.personalityList = StaticData.personality;
    this.lookingForList = StaticData.lookingFor;
    this.exerciseHabitsList = StaticData.exerciseHabits;
    this.alcoholUseList = StaticData.alcohalUse;
    this.drugUseList = StaticData.drugUse;
    this.politicalViewsList = StaticData.politicalViews;
    this.religionList = StaticData.religion;
    this.initializeStripe();

    this.dataservice.GetStripePlans().subscribe(
      (data: any) => {
        this.allplans=data.data;
        this.selectedItem = this.allplans[0];           
      },
      (error) => {
        console.error(error);
      }
    );

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.datingStatus = params['datingstatus'] || false;
      // Use the datingStatus value
      this.signupForm = this.initForm(this.datingStatus);
      if(this.datingStatus){
        this.showPlans=true;
        this.datingFormStatus = false;
        this.socialFormStatus = false;
      }
    });

  } 
  async initializeStripe() {
    this.stripe = await loadStripe('pk_test_51OeCKVBOfCO7ilUokdzDowOyC7C55g5LhV5neUfmTg9nJLif4BUOz7sen8KVGdv8B7fgAg858ybAPGR3soA30NhI00BC2IwEeS');
    this.elements = this.stripe.elements();

    const style = {
      base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      }
    };

    const loginWrapper = new Login();
    loginWrapper.email = this.userEmail;
    loginWrapper.deviceType = StaticData.DeviceType;
    loginWrapper.password = this.userPassword;
    loginWrapper.longitude = +localStorage.getItem('latitude');
    loginWrapper.latitude = +localStorage.getItem('latitude');

    loginWrapper.deviceToken = Guid.create().toString();

    console.log(loginWrapper);
  
        this.dataservice.login(loginWrapper).subscribe((result) => {
          if (result && result.message === 'Success') {
            console.log(result.data.customerData);
          this.accesstoken=result.data.customerData.accessToken;
          } else {
            this.notifyService.showWarning(result.message, '');
          }
        });
  

    this.cardNumber = this.elements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number-element');

    const cardExpiry = this.elements.create('cardExpiry', { style });
    cardExpiry.mount('#card-expiry-element');

    const cardCvc = this.elements.create('cardCvc', { style });
    cardCvc.mount('#card-cvc-element');
  }


  async handlePayment() {
    console.log("Hello payment handler")
    console.log(this.stripe)
    console.log(this.cardNumber)

    if (!this.stripe || !this.cardNumber) {
      return;
    }

    const { token, error } = await this.stripe.createToken(this.cardNumber);
    if (error) {
      this.notifyService.showError('Payment not completed..','Payment Failed'); 
    } else {
      const servicecall=new PyamentMethodImpl();

      console.log("service call before");
      servicecall.authorization=this.accesstoken;
      servicecall.packageId= this.selectedItem._id;
      servicecall.charges= this.selectedItem.Price;
      servicecall.stripeToken= token.id;
      servicecall.stripeEmail=this.userEmail;
      console.log(servicecall);

      this.dataservice.CompletePayment(servicecall).subscribe(
       
        (data: any) => {
          if(data.StatusCode==200){
          this.notifyService.showSuccess("Payment is processed Successfully!!","");
          this.showPaymentDetails = false;
          this.showPlans = false;
          this.datingFormStatus = true;
        }
        else{
          this.notifyService.showError("Payment Failed!!","");
        }
        },
        (error) => {
          this.notifyService.showError("Payment Failed!!","");
        }

      );

     
    }
  }

    isChecked(item:any): boolean {
    return this.selectedItem?._id === item._id;
  }
  onCheckboxChange(item: any, event: any) {
    if (event.target.checked) {
      this.selectedItem = item;
      this.uncheckOtherItems(item._id);  // Uncheck other items
    } else {
      this.selectedItem = null;
    }
  }
  uncheckOtherItems(selectedItemId: any) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      if (+checkbox.value !== selectedItemId) {
        checkbox.checked = false;
      }
    });
  }


  // tslint:disable-next-line:typedef
  private initForm(datingStatus) {
    if(!datingStatus){
      return this.fb.group({
        name: ['', Validators.required],
        age:['',Validators.required],
        dob:['', Validators.required],
        gender:['', Validators.required],
        phoneNumber: [''],
        email:[this.userEmail, [Validators.required, Validators.email]],
        aboutMe: [''],
        // state:['', Validators.required],
        country: ['', Validators.required],
        streetAddress: [''],
        otherAddress: [''],
        city: ['', Validators.required],
        postalCode: [''],
        
      
      });
    }else {
      return this.fb.group({
        name: ['', Validators.required],
        age:['', Validators.required],
        dob:['', Validators.required],
        gender:['', Validators.required],
        phoneNumber: [''],
        email: [this.userEmail, [Validators.required, Validators.email]],
        country: ['', Validators.required],
        state:['', Validators.required],
        streetAddress: [''],
        otherAddress: [''],
        city: ['', Validators.required],
        occupation: [''],
        education: ['', Validators.required],
        personality: [''],
        lookingFor: ['',Validators.required],
        interestsHobbies: [''],
        exercise: [''],
        alcohol: [''],
        drug: [''],
        politicalViews: [''],
        religion: [''],
        
        
      });

      
    }
    
  }

//   sendOTP(event){
//     if(!this.signupForm.valid){
//       this.notifyService.showWarning("Please Enter valid values.", '');
//       return;
//     }
//     this.emailToVerify = this.signupForm.value.email;

//     console.log(this.emailToVerify);
//     // this.signup();

//     const emailJson = {
//       email: this.emailToVerify,
    
//     };
//     this.dataservice.GetUserByEmail(emailJson).subscribe(
//       (data) => {
//         if (Array.isArray(data) && data.length > 0) {
//           console.log(data);
         
//         } else {
//           console.log('No data found');
//           // Handle the case where no user is found
//         }
//       },
//       (error) => {
//         console.error('Error fetching user data:', error.error.message);
//         // Handle error appropriately
//         if( error.error.message=="rev is not defined"){
//           this.notifyService.showError("Email already exists","");
//         }
//         else{
//           this.generatingEmail = true;
//               this.emailVerified = true;
//         }
//       }
//     );
//   }

//   verifyEmail(){

//     if(this.otp == this.otpToVerify){
//       this.signup();
//       if(this.datingStatus)
//       {
//       this.dataservice.GetStripePlans().subscribe(
//         (data: any) => {
//           this.allplans=data.data;
//           this.selectedItem = this.allplans[0];
//            this.showPlans=true;
//           this.emailVerified = true;
//           this.showOTP=false;
//           this.isProcessing = false;
//         },
//         (error) => {
//           console.error(error);
//         }
//       );
//   }
//   else{
//     this.router.navigateByUrl('/login');
//   }
// }
   
//     else{
//       alert("Invalid OTP");
//     }
//   }
  // tslint:disable-next-line:typedef
 
  onCountryChange(countryName: string) {
    const extractedCountryName = countryName.split(':')[1]?.trim() || '';

    const selectedCountry = this.countryList.find((country: any) =>
      country.name === extractedCountryName);

    if (selectedCountry) {
      this.states = selectedCountry.states;
    } else {
      this.states = [];
      console.error('Selected country not found in countryList:', extractedCountryName);
    }
    // Reset the state selection when the country changes
    this.signupForm.get('state')?.setValue('');
  }

  CompletePurchase(plan:any){
    console.log("complete purchase test");
    console.log(plan);
    this.showPlans=false;
    console.log(this.selectedItem);
    this.showPaymentDetails=true;
    this.selectedItem=plan;
    // this.dataservice.GetStripePlans().subscribe(
    //       (data: any) => {
    //         this.allplans=data.data;
    //         this.selectedItem = this.allplans[0];           
    //       },
    //       (error) => {
    //         console.error(error);
    //       }
    // );

    console.log("Initialize stripe");
    this.initializeStripe();

  }
  signup(event) {
    this.markFormGroupTouched(this.signupForm);
    if (this.signupForm.invalid) {
      this.notifyService.showWarning("Please Enter valid values.", '');
      return;
    }
    if (
      this.signupForm.value.password !== this.signupForm.value.confirmpassword
    ) {
      this.passwordNotMatch = true;
      return;
    }
    
    if(!this.acceptTerms){
      this.notifyService.showWarning('Please agree to Terms of Use and Privacy Policy', '');
    }
    this.isProcessing = true;
    const signupWrapper = new SignUp();
    if(!this.datingStatus){
    signupWrapper.fullName = this.signupForm.value.name;
    signupWrapper.email = this.signupForm.value.email;
    signupWrapper.about = this.signupForm.value.about;
    signupWrapper.age = this.signupForm.value.age;
    signupWrapper.dob = this.signupForm.value.dob;
    signupWrapper.phoneNumber = this.signupForm.value.phoneNumber;
    signupWrapper.gender = this.signupForm.value.gender;
    signupWrapper.state = '';
    signupWrapper.country = this.signupForm.value.country;
    signupWrapper.streetAddress=this.signupForm.value.streetAddress;
    signupWrapper.otherAddress=this.signupForm.value.otherAddress;
    signupWrapper.city = this.signupForm.value.city;
    signupWrapper.deviceToken = Guid.create().toString();
    signupWrapper.deviceType = StaticData.DeviceType;
    signupWrapper.usertype = '0';
    signupWrapper.password = this.userPassword;
    console.log(signupWrapper);
    }else{
    signupWrapper.fullName = this.signupForm.value.name;
    signupWrapper.email = this.signupForm.value.email;
    signupWrapper.phoneNumber = this.signupForm.value.phoneNumber;
    signupWrapper.age = this.signupForm.value.age;
    signupWrapper.dob = this.signupForm.value.dob;
    signupWrapper.country = this.signupForm.value.country;
    signupWrapper.state = this.signupForm.value.state;
    signupWrapper.streetAddress=this.signupForm.value.streetAddress
    signupWrapper.otherAddress=this.signupForm.value.otherAddress
    signupWrapper.city = this.signupForm.value.city;
    signupWrapper.gender = this.signupForm.value.gender;
    signupWrapper.occupation = this.signupForm.value.occupation;
    signupWrapper.education_level = this.signupForm.value.education;
    signupWrapper.personality = this.signupForm.value.personality;
    signupWrapper.looking_for = this.signupForm.value.lookingFor;
    signupWrapper.interests = this.signupForm.value.interestsHobbies;
    signupWrapper.exercise_habits = this.signupForm.value.exercise;
    signupWrapper.alcohol_use = this.signupForm.value.alcohol;
    signupWrapper.drug_use = this.signupForm.value.drug;
    signupWrapper.political_views = this.signupForm.value.politicalViews;
    signupWrapper.religion = this.signupForm.value.religion;
    signupWrapper.password = this.userPassword;
    signupWrapper.usertype = '1';
    signupWrapper.deviceToken = Guid.create().toString();
    signupWrapper.deviceType = StaticData.DeviceType;
    console.log(signupWrapper);
    }

    this.dataservice.signup(signupWrapper).subscribe((result) => {
      this.isProcessing = false;
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess(
          'You have successfully saved your details with friendlinq',
          'Congratulations......'
        );
        const msg =
          'Hi MK ,' +
          this.signupForm.value.name +
          ' (' +
          this.signupForm.value.email +
          ') ' +
          'Signup in our friendlinq. please contact this new user';
        const emailArr = [];
        emailArr.push('friendlinq@gmail.com');
       // emailArr.push('email@genderfreaks.com');
        //emailArr.push('vimalynrsharma@gmail.com');

        const emailJson = {
          email: emailArr,
          message: msg,
          Subject: 'Friendlinq SignUp Notification',
        };
        // this.dataservice.notifyUserByEmail(emailJson).subscribe((rlt: any) => {
        //   console.log('Post Created', rlt);
        // });

        // const requestToAdmin = new RequestToAdmin();
        // requestToAdmin.UserId = result.data.customerData._id;
        // this.dataservice
        //   .MakeUserFriendWithAdmin(requestToAdmin)
        //   .subscribe((res) => {
        //     console.log('result', res);
        //   });

        this.router.navigateByUrl('dashboard');
  }});
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  JoinFriendlinkSocial(){}

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFileName = file.name;

      // File preview
      const reader = new FileReader();
      reader.readAsDataURL(file); 
      reader.onload = (e) => { 
        this.imgURL = reader.result; 
      }
    }
  
}

}
