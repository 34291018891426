<div class="header">
  <h3>{{ headerText }}</h3>
</div>
<div class="body">
  <img [src]="currentImageSrc" alt="Image" />
</div>
<div class="footer">
  <ng-container *ngIf="!isSecondImageVisible; else secondButton">
    <a (click)="showSecondImage()" class="btn btn-primary  text-white">Continue to Signup</a>
  </ng-container>
  <ng-template #secondButton>
    <a [routerLink]="['/signup']" [queryParams]="{datingstatus: 'true'}" class="btn btn-primary">Continue to Signup</a>
  </ng-template>
</div>





  