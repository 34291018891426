import { Injectable } from '@angular/core';
import { DataService } from '../../components/public/service/data.service';
import AgoraRTC, { IAgoraRTCClient, LiveStreamingTranscodingConfig, ICameraVideoTrack, IMicrophoneAudioTrack, ScreenVideoTrackInitConfig, VideoEncoderConfiguration, AREAS, IRemoteAudioTrack, ClientRole, ILocalVideoTrack, ILocalTrack } from "agora-rtc-sdk-ng"
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AgoraStreamService {
  rtc: IRtc = {
    // For the local client.
    client: null,
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null,
    shareScreen: null
  };

  isConnecting: boolean = false;z
  options = {
    appId: "dd6dbba5e50d42c7880152591687410d",  // set your appid here
    channel: "test", // Set the channel name.
    // token: '', // Pass a token if your project enables the App Certificate.
    // uid: null
  };
  remoteUsers: IUser[] = [];       // To add remote users in list
  remoteUsersObj:any = {};
  remoteUsersEmail = {};
  updateUserInfo = new BehaviorSubject<any>(null); // to update remote users name

  constructor(public dataService: DataService) { }

  createRTCClient() {
    this.rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
    // this.rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" , role: 'audience'});
  }
  // comment it if you don't want virtual camera
  async switchCamera(label, localTracks) {
    let cams = await AgoraRTC.getCameras(); //  all cameras devices you can use
    let currentCam = cams.find(cam => cam.label === label);
    await localTracks.setDevice(currentCam.deviceId);
  }

  // To join a call with tracks (video or audio)
  async localUser(token, uuid, channelName) {
    const uid = await this.rtc.client.join(this.options.appId, channelName,
      token, null);
    console.log(uid);
    this.dataService.updateDeviceToken({ "DeviceTokenString": localStorage.getItem("deviceToken"), "uid": uid }).subscribe((res) =>{

    });

    // Create an audio track from the audio sampled by a microphone.
    this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // Create a video track from the video captured by a camera.
    this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
      encoderConfig: "120p"
    });

    // comment it if you want to use your camera
    //this.switchCamera('OBS Virtual Camera', this.rtc.localVideoTrack)
    //this.rtc.localVideoTrack.play('local-player');


    // Publish the local audio and video tracks to the channel.
    //this.rtc.localAudioTrack.play();
    this.rtc.localVideoTrack.play("local-player");
    // channel for other users to subscribe to it.
    await this.rtc.client.publish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]);
  }

  async startScreenShare(){
    this.rtc.shareScreen = await AgoraRTC.createScreenVideoTrack({}, 'auto');
    await this.rtc.client
    .unpublish([this.rtc.localVideoTrack]);
    this.rtc.localVideoTrack.close();
    // Replace the video track with the screen track.
    //var isAudo = this.rtc.localAudioTrack.enabled;
    //this.rtc.localAudioTrack.setEnabled(true);

    await this.rtc.client
    .publish([this.rtc.shareScreen]);
    
    // Play the screen track.
    //this.rtc.shareScreen.play("ScreenShare");
  }

  async stopScreenShare(){
    await this.rtc.client
    .unpublish([this.rtc.shareScreen])
    this.rtc.shareScreen.close();
    this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        await this.rtc.client
        .publish([this.rtc.localVideoTrack]);
        // Play the video track.
        this.rtc.localVideoTrack.play("local-player");
  }

  agoraServerEvents(rtc) {
    this.isConnecting  = true;
    var me =this;
    rtc.client.on("user-published", async (user, mediaType) => {
      console.log(user, mediaType, 'user-published');

      await rtc.client.subscribe(user, mediaType);
      console.log(user);
      
      /*if(this.remoteUsers.filter((rm) => { return rm.uid == user.uid; }).length == 0){
        this.remoteUsers.push({ 'uid': user.uid });
      }*/
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        this.dataService.GetUserByUid({"Uid": user.uid}).subscribe((res) => {
          if(res && res["user"]){
            this.remoteUsersObj[user.uid] = res["user"]["fullName"];
            this.remoteUsersEmail[res["user"]["email"]] = user.uid;
          }
          else{
            this.remoteUsersObj[user.uid] = "visitor";
          }

          setTimeout(function(){
            remoteVideoTrack.play('remote-playerlist' + user.uid);
            this.isConnecting = false;
          }.bind(this), 500);
        });
      }

      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
    });

    rtc.client.on("StreamMessage", async (uid, streamId, data) => {
      let res = JSON.parse(data?.toString());
      let userId = res?.userId;
      let status = res?.status;
      let name = res?.name;
    });

    rtc.client.on("user-unpublished", user => {
    });

    rtc.client.on("user-left", user => {
      console.log(user, 'user-unpublished');
      this.remoteUsersObj[user.uid] = null;
      
      var ff = Object.keys(this.remoteUsersEmail);

      for(var i = 0; i < ff.length; i++){
          if(this.remoteUsersEmail[ff[i]] == user.uid)
          {
            this.remoteUsersEmail[ff[i]] = null;
          }
      }

      var index = this.remoteUsers.findIndex((u) => { return u.uid == user.uid });
      if(index > -1){
        this.remoteUsers.splice(index, 1);
      }
    });


    rtc.client.on("user-joined", (user) => {
      let id = user.uid;


      var exist = this.remoteUsers.filter((u) => { return u.uid == user.uid }).length;
      if(exist == 0){
        this.remoteUsers.push({ 'uid': id });
      }
      
      this.updateUserInfo.next(id);
    });
  }
  // To leave channel-
  async leaveCall() {
    this.remoteUsersObj={}; 
    //this.remoteUsers = [];
    // Destroy the local audio and video tracks.
    await this.rtc.client.leave();
    this.rtc.localAudioTrack.close();
    this.rtc.localVideoTrack.close();
    this.rtc.shareScreen.close();
    await this.rtc.client
    .unpublish([this.rtc.shareScreen])
    // Traverse all remote users.
    /*this.rtc.client.remoteUsers.forEach(user => {
      // Destroy the dynamically created DIV container.
      
      this.remoteUsers.splice(this.remoteUsers.findIndex(a => a.uid === user.uid) , 1);
      //const playerContainer = document.getElementById('remote-playerlist' + user.uid.toString());
      //playerContainer && playerContainer.remove();
    });*/
    // Leave the channel.
    await this.rtc.client.leave();

  }



}
export interface IUser {
  uid: number;
  name?: string;
}
export interface IRtc {
  client: IAgoraRTCClient,
  localAudioTrack: IMicrophoneAudioTrack,
  localVideoTrack: ICameraVideoTrack,
  shareScreen: any
}