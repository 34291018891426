import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/components/public/service/data.service';
import { AuthService } from 'src/app/shared/api/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { GroupRequestComponent } from '../group-request/group-request.component';
import { environment } from 'src/environments/environment';
import { FriendRequest } from 'src/app/components/models/friend.model';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-group-member',
  templateUrl: './group-member.component.html',
  styleUrls: ['./group-member.component.css'],
})
export class GroupMemberComponent implements OnInit {
  public groupMembers: any;
  public imageUrl: string;
  previewClass: string;
  clickIndex: number;
  title: string;
  isGroupMember: boolean;
  friendsList: any;
  friendsDefaultList: any;
  groupId: string;
  searchFriendForm = this.initForm();
  excludedIds: [];
  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private notifyService: NotificationService,
    private dialogRef: MatDialogRef<GroupMemberComponent>,
    private fb: FormBuilder,

    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.title = 'Group Members';

    this.groupMembers = data;
    this.groupId = data[0].groupId;
    this.excludedIds = this.groupMembers.map((d) => d.groupMemberId ? d.groupMemberId._id : '');
    this.previewClass = 'as';
    this.imageUrl = environment.rearGuardImageUri;
    this.isGroupMember = true;
  }
  toggleClass(className, i) {
    if (className === 'as') {
      this.previewClass = 'ash';
    } else {
      this.previewClass = 'as';
    }
    this.clickIndex = i;
  }
  private initForm() {
    return this.fb.group({
      searchValue: ['', Validators.required],
    });
  }

  ngOnInit(): void {}
  closeComment() {
    this.dialogRef.close();
  }
  deleteMember(item, index) {
    this.previewClass = 'as';
    const data = {
      groupId: item.groupId,
      groupMemberId: item.groupMemberId._id,
    };
    this.dataService.deleteGroupMembers(data).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Member Deleted Successfully.', '');
        this.groupMembers.splice(index, 1);
      }
    });
  }
  showAddMember() {
    this.title = 'Add Members';
    this.isGroupMember = false;
    this.getFriendList();
  }
  public getFriendList() {
    this.friendsList = [];
    const searchFriend = new FriendRequest();
    searchFriend.limit = 0;
    searchFriend.skip = 0;
    searchFriend.status = 'Accepted';

    this.dataService.getFriendRequest(searchFriend).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        if (result.data.totalCount > 0) {
          const data = [];
          const excludedData = this.excludedIds;
          result.data.friendList.forEach((element) => {
            const id: string = element._id;
            if (excludedData.findIndex((d) => d === id) < 0) {
              data.push(element);
            }
          });
          this.friendsList = data;
          this.friendsDefaultList = data;
        } else {
          this.notifyService.showWarning('No Records Found.', '');
        }
      } else {
        this.notifyService.showWarning(result.message, '');
      }
    });
  }
  public searchFriend() {
    const a = this.searchFriendForm.value.searchValue;
    const defaultList = this.friendsDefaultList;
    if (a !== null && a !== '') {
      const rsult = this.search(a, this.friendsList);
      this.friendsList = rsult;
    } else {
      this.friendsList = defaultList;
    }
  }
  search(nameKey, myArray): any {
    const arr = [];
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].fullName.indexOf(nameKey) > -1) {
        arr.push(myArray[i]);
      }
    }
    return arr;
  }
  AddFriend(item, index) {
    const data = { groupId: this.groupId, memberId: item._id };
    this.dataService.AddGroupMembers(data).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.friendsList.splice(index, 1);
        this.notifyService.showInfo('Member Added to Group.', '');
      } else {
        this.notifyService.showWarning(result.message, '');
      }
    });
  }
  goback() {
    this.title = 'Group Members';
    this.isGroupMember = true;
    const data = { groupId: this.groupId, skip: 0, limit: 0 };
    this.dataService.getGroupMembers(data).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.groupMembers = result.data.groupMemberList;
        this.excludedIds = this.groupMembers.map((d) => d.groupMemberId._id);
      } else {
        this.notifyService.showInfo('No Group Members............', '');
      }
    });
  }
}
