import { Component, OnInit } from '@angular/core';
import { DataService } from '../../public/service/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.css']
})
export class ChatListComponent implements OnInit {

  public chatList: Array<any>;
  public imageUrl: string;

  constructor(private dataService: DataService, private router: Router) {
    this.chatList = [];
    this.imageUrl = environment.rearGuardImageUri;
    this.loadChatList()
  }

  loadChatList(){
    this.dataService.getChatList().subscribe((result: any) => {
      if (result && result.StatusCode === 200) {
        if(this.chatList.length == 0){
          this.chatList = result.Data;
        }
        else{
          var isChanged = false;
          for(var i = 0; i< this.chatList.length; i++){
            var frd = result.Data.filter((x: any) => { return x.friendId == this.chatList[i]["friendId"]; });
            if(frd.length > 0 && this.chatList[i]["isOnline"] != frd[0]["isOnline"]){
              //isChanged = true;
              //break;
              this.chatList[i] = frd[0];
            }
          }

          /*if(isChanged){
            this.chatList = result.Data;
          }*/
        }
        setTimeout(function(){
          this.loadChatList();
        }.bind(this), 60000);
      }
    });
  }

  ngOnInit(): void {
  }

  navigateToChat(id) {
    this.router.navigateByUrl('/chat/' + id);
  }
}
