import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../public/service/data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from 'src/environments/environment';
import { UserProfileImageUpdate, UserProfileVideoUpdate } from '../../models/signup.model';


@Component({
  selector: 'app-photo-video-upload',
  templateUrl: './photo-video-upload.component.html',
  styleUrls: ['./photo-video-upload.component.css']
})
export class PhotoVideoUploadComponent implements OnInit {

  isPhotoVideo: any;
  IsPhotoUpload: boolean;
  IsVideoUpload: boolean;
  thumbnail: string | null = null;
  selectedFile: File | null = null;
  fileSelected: boolean = false;
  error: string = "";
  base64video: string = "";
  customerPortfolio: any;
  imageUrl: string;
  profile: any;



  constructor(
    private dialogRef: MatDialogRef<PhotoVideoUploadComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dataService: DataService,
    private notifyService: NotificationService
  ) {
    this.imageUrl = environment.rearGuardImageUri;
    this.isPhotoVideo = data.request;
    this.profile = data.data;
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.dataService.getMyPortfolio().subscribe((result: any) => {
      this.customerPortfolio = result.data.myPortolio;
      
      this.customerPortfolio.forEach(item => {
        item.isSelected = this.profile.topFourImage.includes("https://natural.selectnaturally.com/" + item.imageURL) || this.profile.userVideo == "https://natural.selectnaturally.com/" +item.imageURL;
      });
      console.log("Portfolio ");
      console.log(this.profile);
      console.log(this.customerPortfolio);
    });
  }

  delete(item: any) {

    this.dataService.deletePortfolio(item._id).subscribe((res: any) => {
      if (res && res.message === 'Success') {
        if (this.isPhotoVideo === 'Add video') {
          this.notifyService.showSuccess(
            'Video removed from your Portfolio',
            ''
          );
        }
        if (this.isPhotoVideo === 'Add Top 4 Images') {
          this.notifyService.showSuccess(
            'Picture removed from your Portfolio',
            ''
          );
        }
        this.getFilteredPortfolio(this.customerPortfolio);

      }
    });
    this.dataService.getMyPortfolio().subscribe((result: any) => {
      this.customerPortfolio = result.data.myPortolio.reverse();
      this.customerPortfolio.forEach(item => {
        item.isSelected = this.profile.topFourImage.includes(environment.rearGuardImageUri + "/" + item.imageURL) || this.profile.userVideo == item.imageURL;
      });
    })
  }
 
  onFileSelected(event: Event): void {

    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const fileType = file.type;

      if (this.isPhotoVideo === 'Add video' && !fileType.startsWith('video/')) {
        this.selectedFile = null;
        return;
      }

      if (this.isPhotoVideo === 'Add Top 4 Images' && !fileType.startsWith('image/')) {
        this.selectedFile = null;
        return;
      }

      this.selectedFile = file;
      this.fileSelected = true;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.thumbnail = e.target.result;
        this.base64video = e.target.result as string;
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  onUpload(): void {
    const file = this.selectedFile;
    const reader = new FileReader();
    const base64String = reader.result as string;


    const formData = new FormData();
    formData.append('mediaFile0', this.selectedFile);
    this.dataService.UpdateProfileVideo(formData).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Video uploaded successfully.', '');
      }
      else {
        this.error = "Try again";
      }
    });
  }

  getFilteredPortfolio(portfolio: any[]): any[] {
    if (this.isPhotoVideo === 'Add Top 4 Images' && portfolio && portfolio.length > 0) {
      return portfolio.filter(item => !this.isVideo(item.imageURL));
    }
    if (this.isPhotoVideo === 'Add video' && portfolio && portfolio.length > 0) {
      return portfolio.filter(item => this.isVideo(item.imageURL));
    }
  }
  isVideo(filePath: string): boolean {
    const videoExtensions = ['mp4', 'm4v', 'avi', 'mov', 'wmv', 'flv', 'webm'];
    const extension = filePath.split('.').pop().toLowerCase();
    return videoExtensions.includes(extension);
  }
  isImage(filePath: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff'];
    const extension = filePath.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
  }

  onCheckboxChange(item: any, event: Event): void {
    // item.thumbnailURL=item.imageURL;
    const checkbox = event.target as HTMLInputElement;
    if (this.isPhotoVideo === 'Add video') {
      console.log(item);
      const profile = new UserProfileVideoUpdate();
      if(item.isSelected){
        profile.userVideo = "https://natural.selectnaturally.com/"+item.imageURL;
        profile.userVideoThumbnail="https://natural.selectnaturally.com/"+item.thumbnailURL;

      }
      else{
        profile.userVideo=" ";
        profile.userVideoThumbnail="https://natural.selectnaturally.com/";
      }
      console.log(profile);
      this.dataService.UpdateProfileVideo(profile).subscribe((result: any) => {
        if (result && result.message === 'Success') {
          if(item.isSelected){
          this.notifyService.showSuccess('Video updated successfully.', '');
        }
        else{
          this.notifyService.showSuccess('Video deleted successfully.', '');
        }
        }
        else {
          this.error = "Try again";
        }
      });
    }

    if (this.isPhotoVideo === 'Add Top 4 Images') {


      // error occur here
      const profile = new UserProfileImageUpdate();

      let imageArray: string[] = [];
      // Add all items except the last one to the new array
      imageArray = this.profile.topFourImage;
      console.log(imageArray);
      console.log(item);

      if (imageArray.length >= 4) {
        // If it does, remove the oldest image (the first item in the array)
        imageArray.shift();
      }
      
      if(!item.isSelected){
        let j:number=0;
        for( j=0;j<=imageArray.length;j++){
          if(imageArray[j]=="https://natural.selectnaturally.com/"+item.imageURL){
            console.log("Deleted item: "+imageArray[j])
            imageArray.splice(j);
          }
        }
      }
      else{
      // Add the new selected image to the array
      imageArray.push("https://natural.selectnaturally.com/"+item.imageURL);
      }
      profile.image=JSON.stringify(imageArray);

      console.log(JSON.stringify(imageArray));
      this.dataService.UpdateProfileTop4Images(profile).subscribe((result: any) => {
        if (result && result.message === 'Success') {
          if(item.isSelected){
          this.notifyService.showSuccess('Image updated successfully.', '');
        }
        else{
          this.notifyService.showSuccess('Image deleted successfully.', '');
        }
        }
        // ----------
        else {

          this.error = "Try again";
        }
      });
    }

  }

}
