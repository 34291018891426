import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  Renderer2
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/api/auth.service';
import { DataService } from '../../public/service/data.service';
import { environment } from 'src/environments/environment';
import { PostMedia } from '../../models/post.model';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommentComponent } from '../comment/comment.component';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { ShareComponent } from '../share/share.component';
import { ReportPost } from '../../models/sharePost.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TagfriendComponent } from '../tagfriend/tagfriend.component';
import { FriendRequest } from '../../models/friend.model';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { RandomsuggestionComponent } from '../randomsuggestion/randomsuggestion.component';
import { Subject } from 'rxjs';
import { SightengineService } from 'src/app/shared/services/sightengine.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('fileUploadVideo', { static: false }) fileUploadVideo: ElementRef;

  @ViewChild('myPublicElement', { static: true }) MyPublicElement: ElementRef;
  @ViewChild('myFriendElement', { static: true }) MyFriendElement: ElementRef;

  inViewportChange: Subject<boolean>;
  public userDetails: any;
  public friendPostList: any;
  public publicPostList: any;
  public isFriendPost: boolean;
  public taggedFriend: any;
  public taggedList: any;
  public friendsList: any;
  public randomSuggestionList: [];
  public pageIndex: number = 0;
  containsBannedWords: boolean = false;

  imageSrc: string;
  format: string;
  preview: string;
  uploadedFile: any;
  imageUrl: string;
  postContent: string;
  showLoader: boolean;
  previewClass: string;
  genderForm = this.initForm();
  clickIndex: number;
  clickBlurIndex: number;
  postDisabled: boolean;
  skipCount: number;
  limitCount: number;
  isScrolling: boolean;
  publicPostCount: number;
  skipFriendCount: number;
  limitFriendCount: number;
  isFriendScrolling: boolean;
  randomSuggestion: [];
  friendPostCount: number;
  randomSuggestionLoaded: boolean;
  randomAdvertisement: [];
  displayedAdvertisement: any = {};
  newpostId:any="";
  uploadPost: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private notifyService: NotificationService,
    private scrollDispatcher: ScrollDispatcher,
    private ref: ChangeDetectorRef,
    private sightengineService: SightengineService
  ) {
    this.randomSuggestionLoaded = false;
    this.postDisabled = false;
    this.taggedFriend = [];
    this.friendPostList = [];
    this.publicPostList = [];
    this.isFriendPost = true;
    this.friendsList = [];
    this.previewClass = 'as';
    this.clickBlurIndex = -1;
    this.skipCount = 0;
    this.limitCount = 5;
    this.isScrolling = false;
    this.skipFriendCount = 0;
    this.limitFriendCount = 5;
    this.isFriendScrolling = false;
    this.imageUrl = environment.rearGuardImageUri;
    this.userDetails = this.authService.getloggedUserDetails();

    if (this.userDetails && this.userDetails.accessToken) {
      this.getPublicPost();
    }
    this.getAdvertisement();
    this.scrollDispatcher.scrolled().subscribe(() => {
      if (!this.isScrolling && !this.isFriendPost) {
        this.isScrolling = true;
        this.skipCount = this.skipCount + 5;
        this.limitCount = 5;

        if (this.skipCount <= this.publicPostCount) {
          this.showLoader = true;
          this.dataService
            .getPublicPost(this.skipCount, this.limitCount)
            .subscribe((result: any) => {
              if (result && result.message === 'Success') {
                if (result.data.totalMyPost > 0) {
                  result.data.myPost.forEach((element) => {
                    this.publicPostList.push(element);
                  });
                  this.ref.detectChanges();
                  this.isFriendPost = false;
                  this.showLoader = false;
                  this.isScrolling = false;
                }
              }
            });
        }
      }
      if (!this.isFriendScrolling && this.isFriendPost) {
        this.isFriendScrolling = true;
        this.skipFriendCount = this.skipFriendCount + 5;
        this.limitFriendCount = 5;

        if (this.skipFriendCount <= this.friendPostCount) {
          this.showLoader = true;
          this.dataService
            .getFriendPost(this.skipFriendCount, this.limitFriendCount)
            .subscribe((result: any) => {
              if (result && result.message === 'Success') {
                if (result.data.totalMyPost > 0) {
                  result.data.myPost.forEach((element) => {
                    this.friendPostList.push(element);
                  });
                  
                  this.ref.detectChanges();
                  this.isFriendPost = true;
                  this.showLoader = false;
                  this.isFriendScrolling = false;
                }
              }
            });
        }
      }
    });
  }
  public getAdvertisement() {
    this.dataService.getAdvertisement().subscribe((result: any) => {
      this.randomAdvertisement = result;
    });
  }

  public navigate(obj) {
    if (obj.UserWebUrl) {
      window.open(obj.UserWebUrl, "_blank");
    }
  }

  public getRandom(i) {
    var index = Math.floor(Math.random() * this.randomAdvertisement.length);

    if (this.randomAdvertisement.length > 0 && !this.displayedAdvertisement[i]) {
      this.displayedAdvertisement[i] = this.randomAdvertisement[index];
    }
  }

  public getRandomFriendSuggestions() {
    this.randomSuggestion = [];

    this.dataService.randomSuggestions(this.pageIndex).subscribe((result: any) => {
      if (result && result.length > 0) {
        this.randomSuggestion = result;
        this.randomSuggestionLoaded = true;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    });
  }
  private initForm() {
    return this.fb.group({
      postContent: [''],
      postType: ['Public', Validators.required],
    });
  }

  toggleClass(className, i) {
    if (className === 'as') {
      this.previewClass = 'ash';
    } else {
      this.previewClass = 'as';
    }
    this.clickIndex = i;
  }

  removeBlur(i) {
    this.clickBlurIndex = i;
  }
  ngOnInit(): void {

    this.inViewportChange = new Subject<boolean>();

    this.inViewportChange.subscribe((inViewport: boolean) => {

    });
  }
  public onIntersection({ target, visible }: { target: any; visible: boolean }): void {
    if (target.tagName.toUpperCase() == 'VIDEO') {
      if (visible) {
        //target.play();
        target.pause();
      }
      else {
        target.pause();
      }
    }
  }

  navigateToProfile() {
    this.router.navigateByUrl('/profile');
  }
  getFriendsPost() {
    this.publicPostList = [];
    this.showLoader = true;
    this.skipFriendCount = 0;
    this.limitFriendCount = 5;
    this.dataService
      .getFriendPost(this.skipFriendCount, this.limitFriendCount)
      .subscribe((result: any) => {
        if (result && result.message === 'Success') {
          if (result.data.totalMyPost > 0) {
            this.friendPostCount = result.data.totalMyPost;
            this.friendPostList = result.data.myPost;
            this.showLoader = false;
            this.isFriendPost = true;
            this.isFriendScrolling = false;
          } else {
            this.showLoader = false;
          }
        }
      });
  }
  getPublicPost() {
    this.friendPostList = [];
    this.skipCount = 0;
    this.limitCount = 5;

    this.showLoader = true;
    this.dataService
      .getPublicPost(this.skipCount, this.limitCount)
      .subscribe((result: any) => {

        if (result && result.message === 'Success') {
          if (result.data.totalMyPost > 0) {
            this.publicPostCount = result.data.totalMyPost;
            this.publicPostList = result.data.myPost;
            this.isFriendPost = false;
            this.showLoader = false;
            this.isScrolling = false;

          }
          this.getRandomFriendSuggestions();
        }
      });
  }
  public openFriendProfilepost(userid) {
    this.router.navigateByUrl('/profile/' + userid);
  }

  public openFriendProfile(data: any) {
    this.router.navigateByUrl('/profile/' + data._id);
  }
  public AddFriend(data: any, index: number) {
    const friendRequest = {
      userToId: data._id,
    };
    this.dataService
      .sendFriendRequest(friendRequest)
      .subscribe((result: any) => {
        if (result && result.message === 'Success') {
          this.notifyService.showSuccess('Friend Request Sent.', '');
          this.randomSuggestion.splice(index, 1);
          if (this.randomSuggestion.length === 0) {
            this.randomSuggestionLoaded = false;
            this.getRandomFriendSuggestions();
          }
        } else {
          this.notifyService.showWarning(result.message, '');
        }
      });
  }

  public SendFriendToChat(data: any) {
    const friendRequest = {
      userToId: data.Author._id,
    };
    this.dataService
      .sendFriendRequest(friendRequest)
      .subscribe((result: any) => {
        data.IsFriend = 2;
      });
  }

  public AcceptRequest(data: any) {
    this.dataService.acceptFriendRequest(data.Author._id).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        data.IsFriend = 1;
        this.navigateToChat(data.Author._id);
      }

    });
  }

  onKeydown(event) {
    event.preventDefault();
  }
  saveComment(comment, i, postType, postId, postData) {
    this.dataService.postComment(postId, comment).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        if (postType === 'Friend') {
          const res = result.data.comment;
          const json = { email: '', fullName: '', _id: '', imageURL: {} };
          json.email = this.userDetails.email;
          json.fullName = this.userDetails.fullName;
          json._id = this.userDetails._id;
          json.imageURL = this.userDetails.imageURL;
          res.commentAuthor = json;
          this.friendPostList[i].comment.push(result.data.comment);
          this.friendPostList[i].totalComment =
            this.friendPostList[i].totalComment + 1;
        }
        const searchFriend = new FriendRequest();
        searchFriend.limit = 0;
        searchFriend.skip = 0;
        searchFriend.status = 'Accepted';
        this.dataService
          .getFriendRequest(searchFriend)
          .subscribe((result: any) => {
            if (result && result.message === 'Success') {
              const userEmails = result.data.friendList.map((d) => d.email);

              const emailJson = {
                email: userEmails,
                message:
                  this.userDetails.fullName +
                  ' has commented on ' +
                  (postData.postAuthor.fullName === 'undefined'
                    ? postData.Author.fullName
                    : postData.postAuthor.fullName) +
                  ' post(' +
                  postData.postTitle +
                  ').',
                Subject: 'rFreaks Post Notification',
              };
              this.dataService
                .notifyUserByEmail(emailJson)
                .subscribe((result: any) => {
                  console.log('Post Created', result);
                });
              result.data.friendList.forEach((element) => {
                const json = {
                  isDeleted: false,
                  isView: false,
                  isRead: false,
                  senderId: this.userDetails._id,
                  receiverId: element._id,
                  textMessage:
                    this.userDetails.fullName +
                    ' has commented on ' +
                    (postData.postAuthor.fullName === 'undefined'
                      ? postData.Author.fullName
                      : postData.postAuthor.fullName) +
                    ' post(' +
                    postData.postTitle +
                    ').',
                  notificationType: 'Friends_Post_Comment',
                };
                this.dataService.notifyUser(json).subscribe((result: any) => {
                  console.log('Post Created', result);
                });
              });
            }
          });
        // if (postType === 'Public') {
        //   const res = result.data.comment;
        //   const json = { email: '', fullName: '', _id: '', imageURL: {} };
        //   json.email = this.userDetails.email;
        //   json.fullName = this.userDetails.fullName;
        //   json._id = this.userDetails._id;
        //   json.imageURL = this.userDetails.imageURL;
        //   res.commentAuthor = json;
        //   this.publicPostList[i].comment.push(result.data.comment);
        //   this.publicPostList[i].totalComment =
        //     this.publicPostList[i].totalComment + 1;
        //   this.MyPublicElement.nativeElement.value = '';
        // }
      }
    });
  }
  //Updated By Anjali 15/07/24

  UpdateLikeStatus(id, status, index, type, postData) {
    let likeStatus: boolean = !status;
    
    this.dataService.postLike(id, likeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {

        if (type === 'Friend') {
          if (likeStatus === false) {
            this.friendPostList[index].totalLike =
              this.friendPostList[index].totalLike === 0
                ? 0
                : this.friendPostList[index].totalLike - 1;
          } else {
            this.friendPostList[index].totalLike++;

            if (this.friendPostList[index].isDislike) {
              this.friendPostList[index].totalDislike =
                this.friendPostList[index].totalDislike === 0
                  ? 0
                  : this.friendPostList[index].totalDislike - 1;
              this.friendPostList[index].isDislike = false;
            }
          }
          this.friendPostList[index].isLike = likeStatus;
        }
        if (type === 'Public') {
          if (likeStatus === false) {
            this.publicPostList[index].totalLike =
              this.publicPostList[index].totalLike === 0
                ? 0
                : this.publicPostList[index].totalLike - 1;
          } else {
            this.publicPostList[index].totalLike++;

            if (this.publicPostList[index].isDislike) {
              this.publicPostList[index].totalDislike =
                this.publicPostList[index].totalDislike === 0
                  ? 0
                  : this.publicPostList[index].totalDislike - 1;
              this.publicPostList[index].isDislike = false;
            }
          }
          this.publicPostList[index].isLike = likeStatus;
        }
        const searchFriend = new FriendRequest();
        searchFriend.limit = 0;
        searchFriend.skip = 0;
        searchFriend.status = 'Accepted';
        this.dataService
          .getFriendRequest(searchFriend)
          .subscribe((result: any) => {
            if (result && result.message === 'Success') {
              const userEmails = result.data.friendList.map((d) => d.email);
              const emailJson = {
                email: userEmails,
                message:
                  this.userDetails.fullName +
                  ' has ' +
                  (likeStatus === true ? ' liked ' : ' unliked ') +
                  (postData.postAuthor.fullName === 'undefined'
                    ? postData.Author.fullName
                    : postData.postAuthor.fullName) +
                  ' post(' +
                  postData.postTitle +
                  ').',
                Subject: 'Friendlinq Like Notification',
              };
              this.dataService
                .notifyUserByEmail(emailJson)
                .subscribe((result: any) => {
                  console.log('Post Created', result);
                });
              result.data.friendList.forEach((element) => {
                const json = {
                  isDeleted: false,
                  isView: false,
                  isRead: false,
                  senderId: this.userDetails._id,
                  receiverId: element._id,
                  textMessage:
                    this.userDetails.fullName +
                    ' has ' +
                    (likeStatus === true ? ' liked ' : ' unliked ') +
                    (postData.postAuthor.fullName === 'undefined'
                      ? postData.Author.fullName
                      : postData.postAuthor.fullName) +
                    ' post(' +
                    postData.postTitle +
                    ').',
                  notificationType: 'Friends_Post_Like',
                };
                this.dataService.notifyUser(json).subscribe((result: any) => {
                });
              });
            }
          });
      }
    });
  }

  UpdateDislikeStatus(id, status, index, type, postData) {
    let dislikeStatus: boolean = !status;

    this.dataService.postDislike(id, dislikeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        if (type === 'Friend') {
          if (dislikeStatus === false) {
            this.friendPostList[index].totalDislike =
              this.friendPostList[index].totalDislike === 0
                ? 0
                : this.friendPostList[index].totalDislike - 1;
          } else {
            this.friendPostList[index].totalDislike++;

            if (this.friendPostList[index].isLike) {
              this.friendPostList[index].totalLike =
                this.friendPostList[index].totalLike === 0
                  ? 0
                  : this.friendPostList[index].totalLike - 1;
              this.friendPostList[index].isLike = false;
            }

          }
          this.friendPostList[index].isDislike = dislikeStatus;
        }
        if (type === 'Public') {
          if (dislikeStatus === false) {
            this.publicPostList[index].totalDislike =
              this.publicPostList[index].totalDislike === 0
                ? 0
                : this.publicPostList[index].totalDislike - 1;
          } else {
            this.publicPostList[index].totalDislike++;

            if (this.publicPostList[index].isLike) {
              this.publicPostList[index].totalLike =
                this.publicPostList[index].totalLike === 0
                  ? 0
                  : this.publicPostList[index].totalLike - 1;
              this.publicPostList[index].isLike = false;
            }
          }
          this.publicPostList[index].isDislike = dislikeStatus;
        }
        const searchFriend = new FriendRequest();
        searchFriend.limit = 0;
        searchFriend.skip = 0;
        searchFriend.status = 'Accepted';
        this.dataService
          .getFriendRequest(searchFriend)
          .subscribe((result: any) => {
            if (result && result.message === 'Success') {
              const userEmails = result.data.friendList.map((d) => d.email);
              const emailJson = {
                email: userEmails,
                message:
                  this.userDetails.fullName +
                  ' has ' +
                  (dislikeStatus === true ? ' liked ' : ' unliked ') +
                  (postData.postAuthor.fullName === 'undefined'
                    ? postData.Author.fullName
                    : postData.postAuthor.fullName) +
                  ' post(' +
                  postData.postTitle +
                  ').',
                Subject: 'Friendlinq Like Notification',
              };
              this.dataService
                .notifyUserByEmail(emailJson)
                .subscribe((result: any) => {
                  console.log('Post Created', result);
                });
              result.data.friendList.forEach((element) => {
                const json = {
                  isDeleted: false,
                  isView: false,
                  isRead: false,
                  senderId: this.userDetails._id,
                  receiverId: element._id,
                  textMessage:
                    this.userDetails.fullName +
                    ' has ' +
                    (dislikeStatus === true ? ' liked ' : ' unliked ') +
                    (postData.postAuthor.fullName === 'undefined'
                      ? postData.Author.fullName
                      : postData.postAuthor.fullName) +
                    ' post(' +
                    postData.postTitle +
                    ').',
                  notificationType: 'Friends_Post_Like',
                };
                this.dataService.notifyUser(json).subscribe((result: any) => {
                });
              });
            }
          });
      }
    });
  }

  //....

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      this.uploadedFile = file;

      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (file.type.indexOf('video') > -1) {
        this.format = 'video';
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };

      reader.readAsDataURL(file);
      // this.sendFile(file);
    };
    fileUpload.click();
  }
  onClickVideo() {
    const fileUploadVideo = this.fileUploadVideo.nativeElement;
    fileUploadVideo.onchange = () => {
      const file = fileUploadVideo.files[0];
      this.uploadedFile = file;

      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (file.type.indexOf('video') > -1) {
        this.format = 'video';
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };

      reader.readAsDataURL(file);
      // this.sendFile(file);
    };
    fileUploadVideo.click();
  }

  base64ToFile(base64: string, filename: string): File {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    return new File([u8arr], filename, { type: mime });
  }
  
  async getVideoDuration(file: File): Promise<number> {
    return new Promise<number>((resolve) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.src = URL.createObjectURL(file);
    });
  }
  
  async extractFramesFromVideo(file: File, frameRate: number): Promise<string[]> {
    console.log("extractFramesFromVideo");
    return new Promise<string[]>((resolve) => {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const frames: string[] = [];
  
      video.src = URL.createObjectURL(file);
      video.muted = true;
      video.play();
  
      video.onloadeddata = () => {
        const interval = 1 / frameRate;
        const duration = video.duration;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
  
        let currentTime = 0;
  
        const captureFrame = () => {
          if (currentTime < duration) {
            video.currentTime = currentTime;
            currentTime += interval;
          } else {
            video.pause();
            resolve(frames);
            return;
          }
        };
  
        video.ontimeupdate = () => {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          const dataURL = canvas.toDataURL('image/png');
          frames.push(dataURL);
          captureFrame();
        };
  
        captureFrame();
      };
    });
  }
  
  async CreatePost() {
    this.newpostId="";
    if (this.genderForm.invalid) {

      return;
    }

    if((!this.genderForm.value.postContent || this.genderForm.value.postContent == '') && !this.uploadedFile){
      return;
    }
    else if ((!this.genderForm.value.postContent || this.genderForm.value.postContent == '') && this.uploadedFile){
      this.genderForm.value.postContent = '..';
    }
    this.containsBannedWords=this.sightengineService.checkContent(this.genderForm.value.postContent);

    if(!this.containsBannedWords){
      console.log('dcvfdv,fdhj');

    const formData = new FormData();
    this.postDisabled = true;
    const postTitleContent = this.genderForm.value.postContent;
    formData.append('postTitle', this.genderForm.value.postContent);
    formData.append('postContent', this.genderForm.value.postContent);
    formData.append('postType', this.genderForm.value.postType);
    if (this.taggedFriend.length > 0) {
      formData.append('tagFriendList', JSON.stringify(this.taggedFriend));
    }
    if (this.format !== 'image' && this.format !== 'video') {
      formData.append('isMediaFileUploaded', 'false');
      formData.append('isMediaTypeVideo', 'false');
    }
  
    if (this.format === 'image') {
      formData.append('isMediaFileUploaded', 'true');
      formData.append('isMediaTypeVideo', 'false');
      formData.append('mediaFile', this.uploadedFile);
      console.log(this.uploadedFile);
    }
    if (this.format === 'video') {
      formData.append('isMediaFileUploaded', 'true');
      formData.append('isMediaTypeVideo', 'true');
      formData.append('mediaFile', this.uploadedFile);
    }
    let istrue:Boolean=false;
    let framecheck = true;
    if(this.format === 'video'){

      const videoDuration = await this.getVideoDuration(this.uploadedFile);
      if (videoDuration > 60) {
        const frames = await this.extractFramesFromVideo(this.uploadedFile, 1); // Extract frames at 24 FPS
        console.log(frames);
  
        for (let frame of frames) {
          this.sightengineService.checkImage(this.base64ToFile(frame,'xyz.png'), 'nudity-2.1').subscribe(
            response => {
              if(response.nudity.none>='0.90'){
              // this.uploadedFile.push(this.uploadedFile);
            }
            else{
              framecheck=false;           
            }       
            },
            error => {
              framecheck=false;
            }       
        )
       if(!framecheck ){
        break;
       }
      }
      if(!framecheck){
        this.notifyService.showInfo('Video contains inappropriate content', '');
      }
      else{
        console.log('upload Post');
        this.dataService.createPost(formData).subscribe((result: any) => {
        this.taggedFriend = [];
        if (result && result.message === 'Success') {
          this.newpostId=result.data._id;
          this.notifyService.showSuccess('Post Created Successfully.', '');
          this.postDisabled = false;
          this.genderForm = this.fb.group({
            postContent: [''],
            postType: ['Friend_Only'],
          });
          this.format = '';
          const searchFriend = new FriendRequest();
          searchFriend.limit = 0;
          searchFriend.skip = 0;
          searchFriend.status = 'Accepted';
          this.dataService
            .getFriendRequest(searchFriend)
            .subscribe((result: any) => {
              if (result && result.message === 'Success') {
                this.friendsList = result.data.friendList;
                if (
                  this.taggedList === undefined ||
                  this.taggedList.length === 0
                ) {
                  const userEmails = this.friendsList.map((d) => d.email);
  
                  const emailJson = {
                    email: userEmails,
                    message:
                      this.userDetails.fullName +
                      ' has created a post (' +
                      postTitleContent +
                      ')',
                    Subject: 'GenderFreaks Post Creation Notification',
                  };
                  this.dataService
                    .notifyUserByEmail(emailJson)
                    .subscribe((result: any) => {
                      console.log('Post Created', result);
                    });
  
                  this.friendsList.forEach((element) => {
                    const json = {
                      isDeleted: false,
                      isView: false,
                      isRead: false,
                      senderId: this.userDetails._id,
                      receiverId: element._id,
                      textMessage:
                        this.userDetails.fullName +
                        ' has created a post (' +
                        postTitleContent +
                        ')',
                      notificationType: 'New_Post',
                      postId:this.newpostId
                    };
                    this.dataService.notifyUser(json).subscribe((result: any) => {
                      console.log('Post Created', result);
                    });
                  });
                }
                if (this.taggedList !== undefined && this.taggedList.length > 0) {
                  console.log('>this.taggedList', this.taggedList);
                  const tagFriends = this.taggedList.map((d) => d.fullname);
                  let tagMessage = '';
                  tagFriends.forEach((element) => {
                    tagMessage = tagMessage + ' ' + element;
                  });
                  console.log('>>>>this.friendsList>>>>>>>', this.friendsList);
  
                  const userEmails = this.friendsList.map((d) => d.email);
  
                  const emailJson = {
                    email: userEmails,
                    message:
                      this.userDetails.fullName +
                      ' has created a post (' +
                      postTitleContent +
                      ') by tagging ' +
                      tagMessage,
                    Subject: 'GenderFreaks Post Tag Notification',
                  };
                  this.dataService
                    .notifyUserByEmail(emailJson)
                    .subscribe((result: any) => {
                      console.log('Post Created', result);
                    });
  
                  this.friendsList.forEach((element) => {
                    const json = {
                      isDeleted: false,
                      isView: false,
                      isRead: false,
                      senderId: this.userDetails._id,
                      receiverId: element._id,
                      textMessage:
                        this.userDetails.fullName +
                        ' has created a post (' +
                        postTitleContent +
                        ') by tagging ' +
                        tagMessage,
                      notificationType: 'New_Tag_Post',
                    };
                    this.dataService.notifyUser(json).subscribe((result: any) => {
                      console.log('Post Created Tag', result);
                    });
                  });
                  this.taggedList = [];
                }
              }
            });
          if (this.taggedList !== undefined && this.taggedList.length > 0) {
            result.data.taggedFriend = this.taggedList;
          }
          this.friendPostList.unshift(result.data);
          console.log('>>>>>>>>>>>>>');
        }
      });
      }
    }
      else{
      this.sightengineService.checkvideo(this.uploadedFile, 'nudity-2.1').subscribe(
        response => {

          for (let i = 0; i < response.data.frames.length; i++) {
            const frame = response.data.frames[i];
            let nudity= frame.nudity.none;
            if(nudity <'0.90'){
              istrue=true;
              break;
            }
          }            
             
      if(!istrue) {
        console.log('upload Post');
        this.dataService.createPost(formData).subscribe((result: any) => {
        this.taggedFriend = [];
        if (result && result.message === 'Success') {
          this.newpostId=result.data._id;
          this.notifyService.showSuccess('Post Created Successfully.', '');
          this.postDisabled = false;
          this.genderForm = this.fb.group({
            postContent: [''],
            postType: ['Friend_Only'],
          });
          this.format = '';
          const searchFriend = new FriendRequest();
          searchFriend.limit = 0;
          searchFriend.skip = 0;
          searchFriend.status = 'Accepted';
          this.dataService
            .getFriendRequest(searchFriend)
            .subscribe((result: any) => {
              if (result && result.message === 'Success') {
                this.friendsList = result.data.friendList;
                if (
                  this.taggedList === undefined ||
                  this.taggedList.length === 0
                ) {
                  const userEmails = this.friendsList.map((d) => d.email);
  
                  const emailJson = {
                    email: userEmails,
                    message:
                      this.userDetails.fullName +
                      ' has created a post (' +
                      postTitleContent +
                      ')',
                    Subject: 'GenderFreaks Post Creation Notification',
                  };
                  this.dataService
                    .notifyUserByEmail(emailJson)
                    .subscribe((result: any) => {
                      console.log('Post Created', result);
                    });
  
                  this.friendsList.forEach((element) => {
                    const json = {
                      isDeleted: false,
                      isView: false,
                      isRead: false,
                      senderId: this.userDetails._id,
                      receiverId: element._id,
                      textMessage:
                        this.userDetails.fullName +
                        ' has created a post (' +
                        postTitleContent +
                        ')',
                      notificationType: 'New_Post',
                      postId:this.newpostId
                    };
                    this.dataService.notifyUser(json).subscribe((result: any) => {
                      console.log('Post Created', result);
                    });
                  });
                }
                if (this.taggedList !== undefined && this.taggedList.length > 0) {
                  console.log('>this.taggedList', this.taggedList);
                  const tagFriends = this.taggedList.map((d) => d.fullname);
                  let tagMessage = '';
                  tagFriends.forEach((element) => {
                    tagMessage = tagMessage + ' ' + element;
                  });
                  console.log('>>>>this.friendsList>>>>>>>', this.friendsList);
  
                  const userEmails = this.friendsList.map((d) => d.email);
  
                  const emailJson = {
                    email: userEmails,
                    message:
                      this.userDetails.fullName +
                      ' has created a post (' +
                      postTitleContent +
                      ') by tagging ' +
                      tagMessage,
                    Subject: 'GenderFreaks Post Tag Notification',
                  };
                  this.dataService
                    .notifyUserByEmail(emailJson)
                    .subscribe((result: any) => {
                      console.log('Post Created', result);
                    });
  
                  this.friendsList.forEach((element) => {
                    const json = {
                      isDeleted: false,
                      isView: false,
                      isRead: false,
                      senderId: this.userDetails._id,
                      receiverId: element._id,
                      textMessage:
                        this.userDetails.fullName +
                        ' has created a post (' +
                        postTitleContent +
                        ') by tagging ' +
                        tagMessage,
                      notificationType: 'New_Tag_Post',
                    };
                    this.dataService.notifyUser(json).subscribe((result: any) => {
                      console.log('Post Created Tag', result);
                    });
                  });
                  this.taggedList = [];
                }
              }
            });
          if (this.taggedList !== undefined && this.taggedList.length > 0) {
            result.data.taggedFriend = this.taggedList;
          }
          this.friendPostList.unshift(result.data);
          console.log('>>>>>>>>>>>>>');
        }
      });
      }
      else{
        this.uploadPost=true;
        this.notifyService.showInfo(
          'A video cannot be uploaded',
          ''
        );
        return;
      }
        },
        error => {
          this.uploadPost=true;
          console.log('failed');
          console.log(error);
          this.notifyService.showInfo(
            'A video cannot be uploaded',
            ''
          );
          return;
        }       
    )
  }
    }
    else if(this.format === 'image'){
      this.sightengineService.checkImage(this.uploadedFile, 'nudity-2.1').subscribe(
        response => {          
          if(response.nudity.none>='0.90'){     
            console.log('upload Post');
            this.dataService.createPost(formData).subscribe((result: any) => {
            this.taggedFriend = [];
            if (result && result.message === 'Success') {
              this.newpostId=result.data._id;
              this.notifyService.showSuccess('Post Created Successfully.', '');
              this.postDisabled = false;
              this.genderForm = this.fb.group({
                postContent: [''],
                postType: ['Friend_Only'],
              });
              this.format = '';
              const searchFriend = new FriendRequest();
              searchFriend.limit = 0;
              searchFriend.skip = 0;
              searchFriend.status = 'Accepted';
              this.dataService
                .getFriendRequest(searchFriend)
                .subscribe((result: any) => {
                  if (result && result.message === 'Success') {
                    this.friendsList = result.data.friendList;
                    if (
                      this.taggedList === undefined ||
                      this.taggedList.length === 0
                    ) {
                      const userEmails = this.friendsList.map((d) => d.email);
      
                      const emailJson = {
                        email: userEmails,
                        message:
                          this.userDetails.fullName +
                          ' has created a post (' +
                          postTitleContent +
                          ')',
                        Subject: 'GenderFreaks Post Creation Notification',
                      };
                      this.dataService
                        .notifyUserByEmail(emailJson)
                        .subscribe((result: any) => {
                          console.log('Post Created', result);
                        });
      
                      this.friendsList.forEach((element) => {
                        const json = {
                          isDeleted: false,
                          isView: false,
                          isRead: false,
                          senderId: this.userDetails._id,
                          receiverId: element._id,
                          textMessage:
                            this.userDetails.fullName +
                            ' has created a post (' +
                            postTitleContent +
                            ')',
                          notificationType: 'New_Post',
                          postId:this.newpostId

                        };
                        this.dataService.notifyUser(json).subscribe((result: any) => {
                          console.log('Post Created', result);
                        });
                      });
                    }
                    if (this.taggedList !== undefined && this.taggedList.length > 0) {
                      console.log('>this.taggedList', this.taggedList);
                      const tagFriends = this.taggedList.map((d) => d.fullname);
                      let tagMessage = '';
                      tagFriends.forEach((element) => {
                        tagMessage = tagMessage + ' ' + element;
                      });
                      console.log('>>>>this.friendsList>>>>>>>', this.friendsList);
      
                      const userEmails = this.friendsList.map((d) => d.email);
      
                      const emailJson = {
                        email: userEmails,
                        message:
                          this.userDetails.fullName +
                          ' has created a post (' +
                          postTitleContent +
                          ') by tagging ' +
                          tagMessage,
                        Subject: 'GenderFreaks Post Tag Notification',
                      };
                      this.dataService
                        .notifyUserByEmail(emailJson)
                        .subscribe((result: any) => {
                          console.log('Post Created', result);
                        });
      
                      this.friendsList.forEach((element) => {
                        const json = {
                          isDeleted: false,
                          isView: false,
                          isRead: false,
                          senderId: this.userDetails._id,
                          receiverId: element._id,
                          textMessage:
                            this.userDetails.fullName +
                            ' has created a post (' +
                            postTitleContent +
                            ') by tagging ' +
                            tagMessage,
                          notificationType: 'New_Tag_Post',
                          postId:result._id

                        };
                        this.dataService.notifyUser(json).subscribe((result: any) => {
                          console.log('Post Created Tag', result);
                        });
                      });
                      this.taggedList = [];
                    }
                  }
                });
              if (this.taggedList !== undefined && this.taggedList.length > 0) {
                result.data.taggedFriend = this.taggedList;
              }
              this.friendPostList.unshift(result.data);
              console.log('>>>>>>>>>>>>>');
            }
          });
        }
        else{
          istrue=false;
          this.uploadPost=true;
          this.notifyService.showInfo(
            'This image contains inappropriate content. Please select image again.',
            ''
          );
          
        }    
        },
        error => {          
          this.notifyService.showInfo(
            'A image cannot be uploaded',
            ''
          );
          this.uploadPost=true;         
        }       
    )
    }
    else{
      this.dataService.createPost(formData).subscribe((result: any) => {
        this.taggedFriend = [];
        if (result && result.message === 'Success') {

          this.newpostId=result.data._id;

          this.notifyService.showSuccess('Post Created Successfully.', '');
          this.postDisabled = false;
          this.genderForm = this.fb.group({
            postContent: [''],
            postType: ['Friend_Only'],
          });
          this.format = '';
          const searchFriend = new FriendRequest();
          searchFriend.limit = 0;
          searchFriend.skip = 0;
          searchFriend.status = 'Accepted';
          this.dataService
            .getFriendRequest(searchFriend)
            .subscribe((result: any) => {
              if (result && result.message === 'Success') {
                this.friendsList = result.data.friendList;
                if (
                  this.taggedList === undefined ||
                  this.taggedList.length === 0
                ) {
                  const userEmails = this.friendsList.map((d) => d.email);
  
                  const emailJson = {
                    email: userEmails,
                    message:
                      this.userDetails.fullName +
                      ' has created a post (' +
                      postTitleContent +
                      ')',
                    Subject: 'GenderFreaks Post Creation Notification',
                  };
                  this.dataService
                    .notifyUserByEmail(emailJson)
                    .subscribe((result: any) => {
                      console.log('Post Created', result);
                    });
  
                  this.friendsList.forEach((element) => {
                    const json = {
                      isDeleted: false,
                      isView: false,
                      isRead: false,
                      senderId: this.userDetails._id,
                      receiverId: element._id,
                      textMessage:
                        this.userDetails.fullName +
                        ' has created a post (' +
                        postTitleContent +
                        ')',
                      notificationType: 'New_Post',
                      postId:this.newpostId
                    };
                    console.log(json);
                    this.dataService.notifyUser(json).subscribe((result: any) => {
                      console.log('Post Created', result);
                    });
                  });
                }
                if (this.taggedList !== undefined && this.taggedList.length > 0) {
                  console.log('>this.taggedList', this.taggedList);
                  const tagFriends = this.taggedList.map((d) => d.fullname);
                  let tagMessage = '';
                  tagFriends.forEach((element) => {
                    tagMessage = tagMessage + ' ' + element;
                  });
                  console.log('>>>>this.friendsList>>>>>>>', this.friendsList);
  
                  const userEmails = this.friendsList.map((d) => d.email);
  
                  const emailJson = {
                    email: userEmails,
                    message:
                      this.userDetails.fullName +
                      ' has created a post (' +
                      postTitleContent +
                      ') by tagging ' +
                      tagMessage,
                    Subject: 'GenderFreaks Post Tag Notification',
                  };
                  this.dataService
                    .notifyUserByEmail(emailJson)
                    .subscribe((result: any) => {
                      console.log('Post Created', result);
                    });
  
                  this.friendsList.forEach((element) => {
                    const json = {
                      isDeleted: false,
                      isView: false,
                      isRead: false,
                      senderId: this.userDetails._id,
                      receiverId: element._id,
                      textMessage:
                        this.userDetails.fullName +
                        ' has created a post (' +
                        postTitleContent +
                        ') by tagging ' +
                        tagMessage,
                      notificationType: 'New_Tag_Post',
                    };
                    this.dataService.notifyUser(json).subscribe((result: any) => {
                      console.log('Post Created Tag', result);
                    });
                  });
                  this.taggedList = [];
                }
              }
            });
          if (this.taggedList !== undefined && this.taggedList.length > 0) {
            result.data.taggedFriend = this.taggedList;
          }
          this.friendPostList.unshift(result.data);
          console.log('>>>>>>>>>>>>>');
        }
      });
    }

  }
    else{
      this.notifyService.showError('Post contains inappropriate content.', '');
    }
  }

  openDialog(postId, item, index, type) {
    console.log(postId)
    console.log(item)
    console.log(index)
    this.dataService.getPostComment(postId).subscribe((commentResult: any) => {
      if (commentResult && commentResult.message === 'Success') {
        if (
          commentResult &&
          commentResult.data &&
          commentResult.data.comments
        ) {
          const json = { postDetail: [], commentDetail: [], index: 0 };
          json.postDetail = item;
          json.commentDetail = commentResult.data.comments;
          json.index = index;

          const dialogRef = this.dialog.open(CommentComponent, {
            data: json,
            height: '600px',
            width: '700px',
            autoFocus: false,
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe((data) => {
            if (type === 'Friend') {
              this.friendPostList[index].totalComment = data.count;
              this.friendPostList[index].comment = data.comment;
            }
            if (type === 'Public') {
              this.publicPostList[index].totalComment = data.count;
              const abc = [];
              data.comment.forEach((element) => {
                const commentItem = this.publicPostList[index].Comments.filter(
                  (x) => x._id === element._id
                );
                if (commentItem.length > 0) {
                  abc.push(commentItem[0]);
                } else {
                  const ab = element;
                  ab['Author'] = element.commentAuthor;
                  abc.push(ab);
                }
              });
              this.publicPostList[index].Comments = abc.reverse();
            }
          });
        }
      }
    });
  }

  openShareDialog(item) {
    const json = { request: item, isGroup: false };
    const dialogRef = this.dialog.open(ShareComponent, {
      height: '400px',
      width: '700px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom',
      data: json,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'Friend_Only') {
        this.getFriendsPost();
      } else {
        this.getPublicPost();
      }
    });
  }
  reportPost(id, postType, i) {
    const reportPost = new ReportPost();
    reportPost.authorization = this.userDetails.accessToken;
    reportPost.postId = id;
    reportPost.userId = this.userDetails._id;
    reportPost.sensitive = true;
    reportPost.admin = false;
    this.previewClass = 'as';
    this.dataService.reportPost(reportPost).subscribe((commentResult: any) => {
      this.notifyService.showSuccess('Post Reported Successfully.', '');
      if (postType === 'Friend') {
        this.friendPostList[i].sensitive = true;
      } else {
        this.publicPostList[i].sensitive = true;
      }
    });
  }

  nextSuggestion() {
    this.pageIndex++;
    this.getRandomFriendSuggestions();
  }

  lastSuggestion() {
    this.pageIndex--;
    if (this.pageIndex < 0) {
      this.pageIndex = 0;
    }

    this.getRandomFriendSuggestions();
  }

  navigateToChat(id) {
    this.router.navigateByUrl('/chat/' + id);
  }

  tagFriend() {
    const dialogRef = this.dialog.open(TagfriendComponent, {
      height: '600px',
      width: '700px',
      autoFocus: false,
      disableClose: true,
      data: this.taggedList,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.taggedFriend = [];
      this.taggedList = [];
      res.taggedFriend.forEach((element) => {
        this.taggedFriend.push(element.id);

        this.taggedList.push(element);
      });
    });
  }
}
