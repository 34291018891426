

<div class="container">
    <h2 style="color: #22a642;"><b>Request Support</b></h2>
    <form (ngSubmit)="onSubmit(supportForm)" #supportForm="ngForm">
      <div class="form-group">
        
        <input type="text" id="name" name="name" ngModel required class="form-control" placeholder=" Name">
      </div>
  
      <div class="form-group">
        
        <input type="email" id="email" name="email" ngModel required class="form-control" placeholder="Email">
      </div>
  
      <div class="form-group">

        <input type="tel" id="phone" name="phone" ngModel class="form-control" placeholder="Phone">
      </div>
  
      <div class="form-group">
        
        <textarea id="message" name="message" ngModel required class="form-control" rows="4" placeholder="Message"></textarea>
      </div>
      <div class="form-group button-div" style="margin-top: 20px;">
        <button class="btn btn-success" style="margin-top: 10px; border-radius: 30px;">Send</button>
      </div>
    </form>
  </div>
  
  