<a class="back-button" style="cursor: pointer;margin-top: 3px;" (click)="goback()"><i class="fa fa-arrow-circle-left"
        style="color: #0041c1"></i></a>
<a class="close-button" style="cursor: pointer;margin-top: 3px;color: #007bff" (click)="goback()">Done</a>
<h2 mat-dialog-title class="title">{{title}}</h2>
<div class="container">
    <div class="row">
        <div class="col-md-12 col-12">
            <div class="friends-heading">
                <form [formGroup]="searchFriendForm">
                    <div class="search-Friends">
                        <input type="text" placeholder="search" name="searchValue" (input)="searchFriend()"
                            formControlName="searchValue">
                        <i class="fa fa-search" aria-hidden="true" (click)="searchFriend()"></i>

                    </div>
                    <div class="editor-tag" (click)="focusTagInput()" *ngIf="tags.length>0">
                        <span class="tag-item" *ngFor="let tag of tags">
                            {{tag.fullname}}
                            <span class="remove-tag" (click)="removeTag(tag)">×</span>
                        </span>
                        <input #tagInput type="text" class="input-tag" (keyup)="onKeyUp($event)" formControlName="tag">
                    </div>
                </form>
            </div>
            <div class="add-friends">
                <div class="tab-content">
                    <div id="home">
                        <div class="friends-list" [ngStyle]="{'margin-top': (tags.length>0) ? '100px' : '10px'}">
                            <div class="friends-list-inner" *ngFor="let item of friendsList;let i = index">
                                <div class="friends-list-image" style="cursor: pointer;">
                                    <img src="{{imageUrl}}/{{item.imageURL.thumbnail}}">
                                </div>
                                <div class="friends-list-details" style="cursor: pointer; margin-top: 4px;
                                font-size: 18px;" (click)="addTag(item)">
                                    {{item.fullName}}


                                </div>


                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>