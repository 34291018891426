<div id="audio-chat-container" class="video-position">
    <audio id="local-video" #videoLocalElement controls autoplay muted="muted"></audio>
    <audio id="remote-video" #videoRemoteElement controls autoplay></audio>
    <button id="Disconnect" (click)="disconnect()" class="btn danger close">Disconnect</button>
</div>
<div id="loader" class="showLoader" *ngIf="showLoader">

    <img src="../../../../assets/images/call-loader.gif">
    <p class="callingText">Calling ..............</p>
</div>

<div id="logo" *ngIf="!showLoader">
    <img src="../../../../assets/images/logo.png" height="50%" width="50%" style="margin-top: 80px;margin-left: 350px;">
</div>