import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/api/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DataService } from '../../public/service/data.service';

@Component({
  selector: 'app-request-message',
  templateUrl: './request-message.component.html',
  styleUrls: ['./request-message.component.css'],
})
export class RequestMessageComponent implements OnInit {
  message: string;
  friendData: any;
  userDetails: any;
  constructor(
    private dataService: DataService,
    private notifyService: NotificationService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<RequestMessageComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.friendData = data;
    this.userDetails = this.authService.getloggedUserDetails();
  }

  ngOnInit(): void {}
  SendWithoutMessage() {
    const friendRequest = {
      userToId: this.friendData._id,
    };

    this.dataService
      .sendFriendRequest(friendRequest)
      .subscribe((result: any) => {
        if (result && result.message === 'Success') {
          this.notifyService.showSuccess('Friend Request Sent.', '');
          this.dialogRef.close(this.friendData);
        } else {
          this.notifyService.showWarning(result.message, '');
        }
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }
  SendMessage(message: string) {
    const friendRequest = {
      userToId: this.friendData._id,
    };

    this.dataService
      .sendFriendRequest(friendRequest)
      .subscribe((result: any) => {
        if (result && result.message === 'Success') {
          this.notifyService.showSuccess('Friend Request Sent.', '');
          const msg =
            'Hi' +
            this.friendData.fullName +
            ' ,' +
            this.userDetails.fullName +
            'send a friend request. <br>' +
            message;
          const emailArr = [];
          // emailArr.push('admin@rearguardapp.com');
          emailArr.push(this.friendData.email);

          const emailJson = {
            email: emailArr,
            message: msg,
            Subject: 'Gender Freaks New Friend Request Notification',
          };

          this.dataService
            .notifyUserByEmail(emailJson)
            .subscribe((rlt: any) => {
              console.log('Post Created', result);
            });
          this.dialogRef.close(this.friendData);
        } else {
          this.notifyService.showWarning(result.message, '');
        }
      });
  }
}
