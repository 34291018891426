export class SignUp {
  fullName: string;
  email: string;
  password: string;
  age: number;
  country: string;
  state: string;
  gender: string;
  city: string;
  dob: Date;
  occupation: string;
  education_level: string;
  personality: string;
  looking_for: string;
  interests: string;
  exercise_habits: string;
  alcohol_use: string;
  drug_use: string;
  political_views: string;
  religion: string;
  usertype:string;
  deviceType: string;
  deviceToken: string;
  phoneNumber: any;
  streetAddress: any;
  otherAddress: any;
  about:string;
}
export class Profile {
  fullName: string;
  country: string;
  gender: string;
  city: string;
  state: string;
  phoneNumber: string;
  about: string;
  age: number;
  occupation: string;
  education_level: string;
  personality: string;
  looking_for: string;
  interests: string;
  exercise_habits: string;
  alcohol_use: string;
  drug_use: string;
  political_views: string;
  religion: string;
}
export class RequestToAdmin {
  UserId: string;
 }


 export class UserProfileVideoUpdate {
  userVideo: string;
  userVideoThumbnail:string;
} 
export class UserProfileImageUpdate {
  image: string;
} 


export class PyamentMethodImpl {
  authorization: string;
  packageId: string;
  charges: number;
  stripeToken: string;
  stripeEmail: string;
} 

export class GeTbALANCE {
  authorization: string;
} 

export class  chagetype{
  authorization: string;
  user_type:number;
  user_id: string;
} 


export class feedback{
  authorization: string;
  name:string;
  email: string;
  phoneNumber: string;
  feedback: string;
} 

export class changePassword{
  // authorization: string;
  oldPassword:string;
  newPassword: string;
} 

