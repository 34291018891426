import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/shared/api/http.service';
import { RearGuardAPImethod } from 'src/app/shared/constants/constant';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SignUp } from '../../models/signup.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Login, LoginWithGoogle } from '../../models/login.model';
import { AuthService } from 'src/app/shared/api/auth.service';
import { Chat, ChatMedia, SendMessage } from '../../models/chat.model';
import { Router } from '@angular/router';
import {
  ReportPost,
  ShareGroupPost,
  SharePost,
} from '../../models/sharePost.model';

@Injectable({ providedIn: 'root' })
export class DataService {
  getPortfolio() {
    throw new Error('Method not implemented.');
  }
  private baseUrl = environment.rearGuardApiBaseUri;
  private rearGuradNetBaseUri = environment.rearGuradNetBaseUri;
  private baseUrlWithoutVersion = environment.rearGuardBaseUri;
  private baseUrlChat = environment.rearGuardBaseChatUri;
  private basePostUrl = environment.rearGuardBasePostUri;
  private basePostVersionUrl = environment.rearGuardBasePostVersionUri;
  private rearGuardBaseGroupVersionUri =
    environment.rearGuardBaseGroupVersionUri;
  private rearGuardBaseGroupUri = environment.rearGuardBaseGroupUri;
  private rearGuardBaseNotificationUri =
    environment.rearGuardBaseNotificationUri;
  private randomCallURL = environment.randomCallBaseUri;

  private userDetails: any;
  constructor(
    private httpService: HttpService,
    private notifyService: NotificationService,
    private authService: AuthService,
    private router: Router
  ) {}
  // tslint:disable-next-line:typedef
  private handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigateByUrl('/login');
    } else {
      this.notifyService.showError(error.error.message, 'Error');
    }
  }

  
  // tslint:disable-next-line:typedef
  private handleErrorNet(error: HttpErrorResponse) {
    if (error.status === 401 || error.status === 403) {
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigateByUrl('/login');
    } else {
      this.notifyService.showError(error.error.message, 'Error');
    }
  }

  private getAccessToken(): any {
    this.userDetails = this.authService.getloggedUserDetails();
    if (this.userDetails && this.userDetails.accessToken) {
      return this.userDetails.accessToken;
    }
  }
  public getHeaders(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }

  public getHeadersWithFirebaseToken(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authorization: 'key=AAAAUrcoVYQ:APA91bFPr1TTXb899foJ0JLiwoSyc7yWzrhaS7oBeyj2DJqpdUEfv5tbybAEE5rcYw2OEu1aqbYlTquCEUF07oCJFbfMPoL6hj7ACWTLW6cSnJ4i49fqQSuhhym16uxglbebtY_-542F'
      }),
    };
  }

  public getHeaderswithAuthorization(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authorization: this.getAccessToken(),
      }),
    };
  }

  public getFormHeaderswithAuthorization(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        authorization: this.getAccessToken(),
      }),
    };
  }

  public getHeadersForImageupload(): any {
    return {
      headers: new HttpHeaders({
        authorization: this.getAccessToken()
      }),
    };
  }

  
  public getHeadersForImageupload1DotNet(): any {
    return {
      headers: new HttpHeaders({
        authorization: this.getAccessToken(),
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
      }),
    };
  }

  public getHeadersForDotNet(): any {
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
      }),
    };
  }
  //#region SignUp API
  signup(signuUp: SignUp): any {
    const signupUrl = `${this.baseUrl}${RearGuardAPImethod.registration}`;
    return this.httpService.post(signupUrl, signuUp, this.getHeaders()).pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleError(error);
        }
        return throwError(error);
      })
    );
  }

  GetUserByEmail(email){
    const GetUserByUid = `${this.baseUrl}getUserByEmail`;
    // let data:any;
    // data["email"] = email;
    return this.httpService
      .post(GetUserByUid, email, this.getHeaders())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            // this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Login API
  login(login: Login): any {
    const loginUrl = `${this.baseUrl}${RearGuardAPImethod.login}`;
    return this.httpService.post(loginUrl, login, this.getHeaders()).pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleError(error);
        }
        return throwError(error);
      })
    );
  }
  //#endregion
  //#region Upload Profile Pic
  uploadProfilePic(imageData: any): any {
    const name = imageData.get('document').name;
    const imageUploadUrl = `${this.baseUrl}${RearGuardAPImethod.uploadsProfilePic}`;
    return this.httpService
      .post(imageUploadUrl, imageData, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Upload Banner Pic
  uploadBannerImage(imageData: any): any {
    const name = imageData.get('document').name;
    const imageUploadUrl = `${this.baseUrl}${RearGuardAPImethod.uploadsBannerImage}`;
    return this.httpService
      .post(imageUploadUrl, imageData, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion

  //#region Forgot Password
  forgotPassword(email: string): any {
    return this.httpService
      .post(
        `${this.rearGuradNetBaseUri}/Customer/sendforgotemail`,
        email,
        this.getHeaders()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion

  //#region Login API
  loginWithGoogle(loginWithGoogle: LoginWithGoogle): any {
    const loginwithGoogleUrl = `${this.baseUrl}${RearGuardAPImethod.loginWithGoogle}`;
    return this.httpService
      .post(loginwithGoogleUrl, loginWithGoogle, this.getHeaders())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion

  ////#region Update Profile Data
  saveProfileDate(profileData): any {
    const saveProfileDataUrl = `${this.baseUrl}${RearGuardAPImethod.saveProfileData}`;
    return this.httpService
      .post(saveProfileDataUrl, profileData, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  ////#region Update Profile Data
  searchFriend(searchData): any {
    const searchDataUrl = `${this.baseUrlWithoutVersion}${RearGuardAPImethod.searchData}`;
    return this.httpService
      .post(searchDataUrl, searchData, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Sent Friend request
  sendFriendRequest(friendRequestData): any {
    const friendRequestUrl = `${this.baseUrlWithoutVersion}${RearGuardAPImethod.sendFriendRequest}`;
    return this.httpService
      .post(
        friendRequestUrl,
        friendRequestData,
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion

  //#region get sent Friend request
  getFriendRequest(friendRequestData): any {
    const friendRequestUrl = `${this.baseUrlWithoutVersion}${RearGuardAPImethod.getInvitations}`;
    return this.httpService
      .post(
        friendRequestUrl,
        friendRequestData,
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region LogOut
  logout(): any {
    const logouturl = `${this.baseUrlWithoutVersion}${RearGuardAPImethod.logout}`;
    return this.httpService
      .put(logouturl, {}, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region LogOut
  friendSuggestions(): any {
    const friendSuggestions = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.friendSuggestion}`;
    return this.httpService
      .post(friendSuggestions, { authorization: this.getAccessToken() }, {})
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  friendCount(friendId): any {
    const friendSuggestions = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.GetFriendCount}`;
    return this.httpService
      .post(friendSuggestions, { authorization: this.getAccessToken(), friendId: friendId }, {})
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  scheduleMeeting(data){
    const friendSuggestions = `https://natural.selectnaturally.com/api/v1/call/web/schedule`;
   // data["authorization"] = this.getAccessToken();
    return this.httpService
      .post(friendSuggestions, data, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  validateMeeting(data){
    const friendSuggestions = `https://natural.selectnaturally.com/api/v1/call/single?email=`+data.email+`&channelId=`+data.channelId;
   // data["authorization"] = this.getAccessToken();
    return this.httpService
    .get(friendSuggestions, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.router.navigateByUrl('/dashboard');
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  updateDeviceToken(data){
    const UpdateDeviceToken = `${this.rearGuradNetBaseUri}Customer/UpdateDeviceToken`;
    data["authorization"] = this.getAccessToken();
    return this.httpService
      .post(UpdateDeviceToken, data, { authorization: this.getAccessToken() })
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  GetUserByUid(data){
    const GetUserByUid = `${this.rearGuradNetBaseUri}Customer/GetUserByUid`;
    data["authorization"] = this.getAccessToken();
    return this.httpService
      .post(GetUserByUid, data, { authorization: this.getAccessToken() })
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  updateGroupChannel(data){
    const postDetailUrl = `${this.rearGuardBaseGroupUri}updateGroupChannel`;
    return this.httpService
      .post(postDetailUrl, data, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  

  
  getAdvertisement(): any {
    const getRandomAdvertisements = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.GetRandomAdvertisements}`;
    return this.httpService
      .get(getRandomAdvertisements, {})
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  //#endregion
  //#region Accept Friend request
  acceptFriendRequest(id): any {
    const acceptFriend = `${this.baseUrlWithoutVersion}${RearGuardAPImethod.acceptFriendRequest}`;
    return this.httpService
      .post(
        acceptFriend,
        { userToId: id, status: 'Accepted' },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  rejectFriendRequest(id, reason): any {
    if(reason != 'Rejected'){
      reason = "Canceled"
    }
    const acceptFriend = `${this.baseUrlWithoutVersion}${RearGuardAPImethod.acceptFriendRequest}`;
    return this.httpService
      .post(
        acceptFriend,
        { userToId: id, status: reason },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Make User Friend to Admin
  // tslint:disable-next-line:typedef
  MakeUserFriendWithAdmin(RequestToAdmin) {
    const friendWithAdmin = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.friendWithAdmin}`;
    return this.httpService.post(friendWithAdmin, RequestToAdmin, {}).pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleErrorNet(error);
        }
        return throwError(error);
      })
    );
  }
  //#endregion
  //#region Get Profile of Another User
  getFriendProfile(id): any {
    const getProfileofAnotherUser = `${this.baseUrlWithoutVersion}${RearGuardAPImethod.getProfileofAnotherUser}`;
    return this.httpService
      .post(
        getProfileofAnotherUser,
        { userId: id },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get chat messages
  getChatMessage(chatModel: Chat): any {
    const queryString =
      '?receiverId=' + chatModel.receiverId + '&skip=0&limit=0';
    const getChatMessage = `${this.baseUrlChat}${RearGuardAPImethod.getChatMessage}${queryString}`;
    return this.httpService
      .get(getChatMessage, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get chat messages
  sendMessage(sendMessage: SendMessage): any {
    const sendMessageUrl = `${this.baseUrlChat}${RearGuardAPImethod.sendMessage}`;
    return this.httpService
      .post(sendMessageUrl, sendMessage, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  sendFirebaseMessage(sendMessage: any  ): any {
    const sendMessageUrl = `https://fcm.googleapis.com/fcm/send`;
    return this.httpService
      .post(sendMessageUrl, sendMessage, this.getHeadersWithFirebaseToken())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
           // this.handleError(error);
           this.notifyService.showWarning(sendMessage["data"]["name"]+ "looks offline right now.", 'Warning');
          }
          return throwError(error);
        })
      );
  }

  deleteMessage(_id){
    const friendSuggestions = `${this.rearGuradNetBaseUri}Chat/DeleteChat`;
    return this.httpService
      .post(friendSuggestions, { authorization: this.getAccessToken(), ChatId: _id }, {})
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get chat messages
  sendChatMedia(chatMedia: any): any {
    const chatMediaUrl = `${this.baseUrlChat}${RearGuardAPImethod.chatMedia}`;
    return this.httpService
      .post(chatMediaUrl, chatMedia, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get friend post
  getFriendPost(skipCount: number, limitCount: number): any {
    const getFriendPost = `${this.basePostUrl}${RearGuardAPImethod.getAllFriendsPost}`;
    return this.httpService
      .post(
        getFriendPost,
        {
          skip: skipCount,
          limit: limitCount,
        },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get public post
  getPublicPost(skipCount: number, limitCount: number): any {
    const getPublicPost = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.getAllPublicPost}`;
    return this.httpService
      .post(
        getPublicPost,
        {
          authorization: this.getAccessToken(),
          skip: skipCount,
          limit: limitCount,
        },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Post Comment
  postComment(id: string, comment: string): any {
    const postComment = `${this.basePostVersionUrl}${RearGuardAPImethod.postComment}`;
    return this.httpService
      .post(
        postComment,
        {
          postId: id,
          commentText: comment,
        },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Post Comment
  postLike(id: string, likeStatus: boolean): any {
    const postComment = `${this.basePostVersionUrl}${RearGuardAPImethod.likeUnlikePost}`;
    return this.httpService
      .post(
        postComment,
        {
          postId: id,
          isLike: likeStatus,
        },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
   //Updated by Anjali
   postDislike(id: string, dislikeStatus: boolean): any {
    
    const postComment = `${this.basePostVersionUrl}${RearGuardAPImethod.dislikePost}`;
    console.log(postComment);
    return this.httpService
      .post(
        postComment,
        {
          postId: id,
          isDislike: dislikeStatus,
        },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get chat messages
  createPost(postMedia: any): any {
    const postMediaUrl = `${this.basePostVersionUrl}${RearGuardAPImethod.createPost}`;
    return this.httpService
      .post(postMediaUrl, postMedia, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Added By poonam Update Post
  UpdatePost(postMedia: any): any {
    const postMediaUrl = `${this.basePostVersionUrl}${RearGuardAPImethod.UpdatePost}`;
    return this.httpService
      .put(postMediaUrl, postMedia, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  UpdatePostComment(postMedia: any): any {
    const postMediaUrl = `${this.basePostVersionUrl}${RearGuardAPImethod.UpdatePostComment}`;
    return this.httpService
      .put(postMediaUrl, postMedia, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  GetProfile(): any {
    const postMediaUrl = `${environment.rearGuardBaseUri}${RearGuardAPImethod.getProfile}`;
    return this.httpService
      .get(postMediaUrl, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  UpdateProfileVideo(postMedia: any): any {
    const postMediaUrl = `${environment.rearGuardApiBaseUri}${RearGuardAPImethod.UpdateProfileVideo}`;
    return this.httpService
      .post(postMediaUrl, postMedia, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  UpdateProfileTop4Images(postMedia: any): any {
    const postMediaUrl = `${environment.rearGuardApiBaseUri}${RearGuardAPImethod.UpdateProfileTop4Images}`;
    return this.httpService
      .post(postMediaUrl, postMedia, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get post detail
  getPostDetail(id: any): any {
    const postDetailUrl = `${this.basePostUrl}${RearGuardAPImethod.getPostDetail}`;
    return this.httpService
      .post(postDetailUrl, { postId: id }, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get post detail
  getPostComment(id: any): any {
    const postCommentUrl = `${this.basePostUrl}${RearGuardAPImethod.getPostCommentDetail}`;
    return this.httpService
      .post(
        postCommentUrl,
        { postId: id, skip: 0, limit: 0 },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get post detail
  deleteComment(id: any): any {
    const deleteCommentUrl = `${this.basePostUrl}${RearGuardAPImethod.deleteComment}`;
    return this.httpService
      .post(
        deleteCommentUrl,
        { commenttId: id },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get post detail
  getMyPost(): any {
    const getMyPost = `${this.basePostUrl}${RearGuardAPImethod.getMyPost}`;
    return this.httpService
      .post(
        getMyPost,
        {
          skip: 0,
          limit: 0,
        },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get post detail
  getAnotherUsersPost(id: any): any {
    const getAnotherUserPost = `${this.basePostUrl}${RearGuardAPImethod.getAnotherUserPost}`;
    return this.httpService
      .post(
        getAnotherUserPost,
        { userToId: id, skip: 0, limit: 0 },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get post detail
  sharePost(sharePostModel: SharePost): any {
    const sharePostURl = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.sharePost}`;
    return this.httpService.post(sharePostURl, sharePostModel, {}).pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleErrorNet(error);
        }
        return throwError(error);
      })
    );
  }
  //#endregion
  //#region get post detail
  reportPost(reportPostModel: ReportPost): any {
    const reportPostURl = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.reportPost}`;
    return this.httpService.post(reportPostURl, reportPostModel, {}).pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleErrorNet(error);
        }
        return throwError(error);
      })
    );
  }
  //#endregion
  //#region get post detail
  deletePost(id: any): any {
    const deletePostUrl = `${this.basePostUrl}${RearGuardAPImethod.deleteMyPost}`;
    return this.httpService
      .post(deletePostUrl, { postId: id }, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion

  //#region get groups
  getGroups(): any {
    const getGroupUrl = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.getGroups}`;
    return this.httpService
      .post(getGroupUrl, { authorization: this.getAccessToken() }, {})
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get group by userid
  getGroupsByUserId(userId): any {
    const getGroupsByUserId = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.getGroupsByUserId}`;
    return this.httpService
      .post(
        getGroupsByUserId,
        { authorization: this.getAccessToken(), groupId: userId },
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Create Group
  createGroup(data): any {
    const createGroup = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.submitPrivateGroup}`;
    return this.httpService
      .post(
        createGroup,
        data,
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  editGroup(data): any {
    const updateGroup = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.UpdateGroup}`;
    return this.httpService
      .post(
        updateGroup,
        data,
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  
  getGroupPaymentDetails(data): any {
    const paymentGroup = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.GetGroupPaymentDetails}`;
    return this.httpService
      .post(
        paymentGroup,
        data,
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  submitGroupPaymentDetails(data): any {
    const paymentGroup = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.ProcessGroupPayment}`;
    return this.httpService
      .post(
        paymentGroup,
        data,
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  IsSuspended(data){
    const IsSuspended = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.IsSuspended}`;
    return this.httpService
      .post(
        IsSuspended,
        data,
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  //#endregion
  //#region get group by userid
  submitGroupRequest(data): any {
    const submitGroupRequest = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.submitGroupRequest}`;
    return this.httpService.post(submitGroupRequest, data, {}).pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleErrorNet(error);
        }
        return throwError(error);
      })
    );
  }
  //#endregion
  //#region get group request
  getGroupRequest(id): any {
    const getGroupRequest = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.getGroupRequest}`;
    return this.httpService
      .post(
        getGroupRequest,
        { authorization: this.getAccessToken(), groupId: id },
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  setOnlineStatus(isLoggedin): any {
    const getGroupRequest = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.SetUserOnlineStatus}`;
    return this.httpService
      .post(
        getGroupRequest,
        { 
          authorization: this.getAccessToken(),
          IsOnline: isLoggedin
         },
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  getOnlineStatus(friendId): any {
    const getGroupRequest = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.GetUserOnlineStatus}`;
    return this.httpService
      .post(
        getGroupRequest,
        { 
          authorization: this.getAccessToken(),
          FriendId: friendId
         },
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  IsChatSubscribed(): any {
    const getGroupRequest = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.IsChatSubscribed}`;
    return this.httpService
      .post(
        getGroupRequest,
        { 
          authorization: this.getAccessToken()
         },
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }


  //#endregion
  //#region
  processGroupRequest(data): any {
    const processGroupRequest = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.processGroupRequest}`;
    return this.httpService.post(processGroupRequest, data, {}).pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleErrorNet(error);
        }
        return throwError(error);
      })
    );
  }
  //#endregion
  //#region
  getGroupMembers(data): any {
    const getGroupMembers = `https://natural.selectnaturally.com/api/v1/postGroup/${RearGuardAPImethod.getGroupMembers}`;
    return this.httpService
      .post(getGroupMembers, data, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region
  deleteGroupMembers(data): any {
    const deleteMemberOfGroup = `${this.rearGuardBaseGroupUri}${RearGuardAPImethod.deleteMemberOfGroup}`;
    return this.httpService
      .post(deleteMemberOfGroup, data, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region
  AddGroupMembers(data): any {
    const addMemberUrl = `${this.rearGuardBaseGroupVersionUri}${RearGuardAPImethod.addMember}`;
    return this.httpService
      .post(addMemberUrl, data, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Upload Profile Pic
  uploadGroupIcon(imageData: any): any {
    const updateGroupIcon = `${this.rearGuardBaseGroupVersionUri}${RearGuardAPImethod.updateGroupIcon}`;
    return this.httpService
      .post(updateGroupIcon, imageData, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Upload Profile Pic
  getGroupPost(id: string): any {
    const getGroupPost = `${this.rearGuardBaseGroupUri}${RearGuardAPImethod.getGroupPost}`;
    return this.httpService
      .post(
        getGroupPost,
        { groupId: id, skip: 0, limit: 0 },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get chat messages
  createGroupPost(postMedia: any): any {
    const postMediaUrl = `${this.rearGuardBaseGroupVersionUri}${RearGuardAPImethod.createGroupPost}`;
    return this.httpService
      .post(postMediaUrl, postMedia, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get post detail
  shareGroupPost(sharePostModel: ShareGroupPost): any {
    const sharePostURl = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.sharePost}`;
    return this.httpService.post(sharePostURl, sharePostModel, {}).pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleErrorNet(error);
        }
        return throwError(error);
      })
    );
  }
  //#endregion
  //#region get post detail
  deleteGroup(id: string): any {
    const sharePostURl = `${this.rearGuardBaseGroupUri}${RearGuardAPImethod.deleteGroup}`;
    return this.httpService
      .post(sharePostURl, { groupId: id }, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Sent Friend request
  getNotification(): any {
    const queryString = '?&skip=0&limit=0';
    const notificationUrl = `${this.rearGuardBaseNotificationUri}${RearGuardAPImethod.getNotification}${queryString}`;
    return this.httpService
      .get(notificationUrl, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  getChatCount(): any {
    const notificationUrl = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.GetCount}`;
    return this.httpService
      .post(notificationUrl, { authorization: this.getAccessToken() }, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  getChatList(): any {
    const notificationUrl = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.GetChatList}`;
    return this.httpService
      .post(notificationUrl, { authorization: this.getAccessToken() }, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  setChatRead(senderId): any {
    const notificationUrl = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.ReadChat}`;
    return this.httpService
      .post(notificationUrl, 
        { 
          authorization: this.getAccessToken(),
          senderId: senderId,
          dateTime: new Date().toISOString()
        }, this.getHeaderswithAuthorization())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region Sent Friend request
  readNotification(id): any {
    const readNotificationUrl = `${this.rearGuardBaseNotificationUri}${RearGuardAPImethod.readNotification}`;
    return this.httpService
      .post(
        readNotificationUrl,
        { notificationId: id },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion

  //#region get post detail
  notifyUser(data): any {
    const sharePostURl = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.notifyUser}`;
    return this.httpService.post(sharePostURl, data, {}).pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleErrorNet(error);
        }
        return throwError(error);
      })
    );
  }
  //#endregion
  //#region get post detail
  notifyUserByEmail(data): any {
    return this.httpService
      .post(this.randomCallURL + 'SendRearGuardEmail', data, {})
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  
  verifyEmail(data): any {
    return this.httpService
      .post(this.rearGuradNetBaseUri + 'Customer/VerifyEmail', data, {})
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  //#endregion
  //#region Random Call
  getRandomuserWeb(id, previousUserId): any {
    return this.httpService
      .get(
        this.randomCallURL +
          'Customer/GetRandomuserWeb?id=' +
          id +
          '&previousUserId=' +
          previousUserId,
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  saveRandomUser(data): any {
    return this.httpService
      .post(this.randomCallURL + 'Customer/SaveRandomLead', data, {})
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  updateRandomUser(id, status): any {
    return this.httpService
      .get(
        this.randomCallURL +
          'Customer/UpdateRandomLead?id=' +
          id +
          '&status=' +
          status,
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  updateUserStatus(id, status): any {
    return this.httpService
      .get(
        this.randomCallURL +
          'Customer/UpdateUserStatus?id=' +
          id +
          '&status=' +
          status,
        {}
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  updateCallDuration(data): any {
    return this.httpService
      .post(this.randomCallURL + 'UpdateCallDuration', data, {})
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion

  //#region create Portfolio
  createPortfolio(postMedia: any): any {
    const postMediaUrl = `${this.basePostVersionUrl}createPortfolio`;
    return this.httpService
      .post(postMediaUrl, postMedia, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }

  
  createPrivatePortfolio(postMedia: any): any {
    const postMediaUrl = `${this.rearGuradNetBaseUri}Customer/UploadPrivatePortfolio`;
    return this.httpService
      .post(postMediaUrl, postMedia, this.getHeadersForImageupload1DotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  
  
  accessToPrivatePortfolio(data: any): any {
    const postMediaUrl = `${this.rearGuradNetBaseUri}Customer/AccessToPrivatePortfolio`;
    return this.httpService
      .post(postMediaUrl, data, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  
  getPrivatePortfolioAccessibleFriends(data: any): any {
    const postMediaUrl = `${this.rearGuradNetBaseUri}Customer/GetPrivatePortfolioAccessibleFriends`;
    return this.httpService
      .post(postMediaUrl, data, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  
  
  checkFriendsPrivatePortfolioAccess(data: any): any {
    const postMediaUrl = `${this.rearGuradNetBaseUri}Customer/CheckPrivatePortfolioAccess`;
    return this.httpService
      .post(postMediaUrl, data, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  
  getFriendsPrivatePortfolio(data: any): any {
    const postMediaUrl = `${this.rearGuradNetBaseUri}Customer/CheckPrivatePortfolioAccessAndGet`;
    return this.httpService
      .post(postMediaUrl, data, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  
  getPrivatePortfolio(data: any): any {
    const postMediaUrl = `${this.rearGuradNetBaseUri}Customer/GetPrivatePortfolio`;
    return this.httpService
      .post(postMediaUrl, data, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  deletePrivatePortfolio(data: any): any {
    const postMediaUrl = `${this.rearGuradNetBaseUri}Customer/DeletePrivatePortfolio`;
    return this.httpService
      .post(postMediaUrl, data, this.getHeadersForImageupload())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion

  //#region get friend post
  getMyPortfolio(): any {
    const getMyPortfolio = `${this.basePostVersionUrl}getMyPortfolio`;
    return this.httpService
      .post(
        getMyPortfolio,
        {
          skip: 0,
          limit: 0,
        },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get post detail
  deletePortfolio(id: any): any {
    const deletePortfolio = `${this.basePostVersionUrl}deleteMyPortfolio`;
    return this.httpService
      .post(
        deletePortfolio,
        { portfolioId: id },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region get post detail
  getAnotherUsersPortfolio(id: any): any {
    const getAnotherUsersPortfolio = `${this.basePostVersionUrl}getAnotherUsersPortfolio`;
    return this.httpService
      .post(
        getAnotherUsersPortfolio,
        { userToId: id, skip: 0, limit: 0 },
        this.getHeaderswithAuthorization()
      )
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleError(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion
  //#region LogOut
  randomSuggestions(pageIndex = 0): any {
    const friendSuggestions = `${this.rearGuradNetBaseUri}${RearGuardAPImethod.randomSuggestion}`;
    return this.httpService
      .post(friendSuggestions, { authorization: this.getAccessToken(), PageIndex: pageIndex }, {})
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  submitEnquiry(data:any){
    return this.httpService
    .post(`${this.rearGuradNetBaseUri}Enquiry/PostEnquiry`, data, this.getHeaders())
    .pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleErrorNet(error);
        }
        return throwError(error);
      })
    );
  }


  inviteuser(data){
    const Sendinvitationmail = `${this.rearGuradNetBaseUri}Customer/Sendinvitationmail`;
    //const Sendinvitationmail = "http://localhost:49887/api/Customer/Sendinvitationmail";
    data["authorization"] = this.getAccessToken();
    return this.httpService
      .post(Sendinvitationmail, data, { authorization: this.getAccessToken() })
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  //#endregion

  //#region   STRIPE PAYMENTS
  GetStripePlans(){
    const Sendinvitationmail = `${this.rearGuradNetBaseUri}Plan/GetPlans`;
    return this.httpService
      .post(Sendinvitationmail,null,this.getHeadersForDotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );

   

  }

  CompletePayment(data){
    const Sendinvitationmail = `${this.rearGuradNetBaseUri}ProcessPayment`;
    //const Sendinvitationmail = "http://localhost:49887/api/Customer/Sendinvitationmail";
    // data["authorization"] = this.getAccessToken();
    return this.httpService
      .post(Sendinvitationmail, data, this.getHeadersForDotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  CheckPayment(data){
    const Sendinvitationmail = `${this.rearGuradNetBaseUri}Customer/GetActivePlan`;
    //const Sendinvitationmail = "http://localhost:49887/api/Customer/Sendinvitationmail";
    
    // let data:any;
    // data["authorization"] = this.getAccessToken();
    return this.httpService
      .post(Sendinvitationmail, data, this.getHeadersForDotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  //#endregion

  //#region TOGGLE BETWEEN USER TYPE
  ToggleUser(data){
    const Sendinvitationmail = `${this.rearGuradNetBaseUri}Customer/updateUserType`;

    return this.httpService
      .post(Sendinvitationmail, data, this.getHeadersForDotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
 //#endregion

 //#region FEEDBACK
 SendFeedback(data){
  const Sendinvitationmail = `${this.rearGuradNetBaseUri}feedback/PostFeedback`;

  return this.httpService
    .post(Sendinvitationmail, data, this.getHeadersForDotNet())
    .pipe(
      catchError((error) => {
        if (
          error.status === 401 ||
          error.status === 403 ||
          error.status === 400
        ) {
          // handle error
          this.handleErrorNet(error);
        }
        return throwError(error);
      })
    );
}
 //#endregion

  //#region SETTINGS API
  IsEmailSubscribed(email:string){
    const Sendinvitationmail = `${this.rearGuradNetBaseUri}Customer/EmailsSubscribed?email=`+email;
  
    return this.httpService
      .post(Sendinvitationmail, null, this.getHeadersForDotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  SubscribeEmail(){
    const Sendinvitationmail = `${this.rearGuradNetBaseUri}Customer/SubscribeEmail`;
  
    return this.httpService
      .post(Sendinvitationmail,  { authorization: this.getAccessToken() }, this.getHeadersForDotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  UnsubscribeEmail(){
    const Sendinvitationmail = `${this.rearGuradNetBaseUri}Customer/UnsubscribeEmail`;
  
    return this.httpService
      .post(Sendinvitationmail,  { authorization: this.getAccessToken() }, this.getHeadersForDotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }
  

  GetBalance(){
    const Sendinvitationmail = `${this.rearGuradNetBaseUri}GetBalance`;
  
    return this.httpService
      .post(Sendinvitationmail,  { authorization: this.getAccessToken() }, this.getHeadersForDotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  changePassword(data:any){
    const Sendinvitationmail = `${this.baseUrl}changePassword`;
  
    return this.httpService
      .post(Sendinvitationmail, data, this.getHeadersForDotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

  DeleteAccount(){
    const Sendinvitationmail =`${this.rearGuradNetBaseUri}Customer/SuspendAccount`;
  
    return this.httpService
      .post(Sendinvitationmail, { authorization: this.getAccessToken() }, this.getHeadersForDotNet())
      .pipe(
        catchError((error) => {
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.status === 400
          ) {
            // handle error
            this.handleErrorNet(error);
          }
          return throwError(error);
        })
      );
  }

   //#endregion

}
