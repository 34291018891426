import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ChatService } from 'src/app/shared/services/chat.service';
import { DataService } from '../../public/service/data.service';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommentComponent } from '../comment/comment.component';
import { ShareComponent } from '../share/share.component';
import { AuthService } from 'src/app/shared/api/auth.service';
import { ReportPost } from '../../models/sharePost.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { StaticData } from 'src/app/shared/constants/constant';
import { PortfolioModalComponent } from '../portfolio-modal/portfolio-modal.component';
@Component({
  selector: 'app-friend-profile',
  templateUrl: './friend-profile.component.html',
  styleUrls: ['./friend-profile.component.css'],
})
export class FriendProfileComponent implements OnInit {
  userId: string;
  friendsProfile: any;
  public isPicExists: string;
  public isFriend: string;
  public socket;
  myPosts: any;
  friendsPortfolio: any;
  friendsPrivatePortfolio: any;
  imageUrl: string;
  imageUrlPrivate: string;
  previewClass: string;
  clickIndex: number;
  clickBlurIndex: number;
  loggedInUsername: string;
  totalFriends: number = 0;
  profileImage:any;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private chatService: ChatService,
    private dialog: MatDialog,
    private authService: AuthService,
    private notifyService: NotificationService
  ) {
    this.clickBlurIndex = -1;
    this.previewClass = 'as';
    this.socket = io(environment.rearGuradSocketBaseUri);
    router.events.subscribe((event: NavigationStart) => {
      if (event instanceof NavigationStart) {
        this.userId = event.url.split('/')[2];
        if (this.userId !== undefined) {
          this.getFriendProfile(this.userId);
        }
      }
      // see also}
    });
    this.loggedInUsername = this.authService.getloggedUserDetails().fullName;
    this.userId = this.route.snapshot.params['id'];
    this.getFriendProfile(this.userId);
    this.getFriendPost();
    this.getFriendPortfolio();
    this.getFriendPrivatePortfolio();
    this.friendCount();
    this.imageUrl = environment.rearGuardImageUri;
    this.imageUrlPrivate = environment.rearGuradNetUri;
  }
  removeBlur(i) {
    this.clickBlurIndex = i;
  }
  toggleClass(className, i) {
    if (className === 'as') {
      this.previewClass = 'ash';
    } else {
      this.previewClass = 'as';
    }
    this.clickIndex = i;
  }

  ngOnInit(): void {}
  getFriendProfile(id: string) {
    this.dataService.getFriendProfile(this.userId).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.isFriend = localStorage.getItem('isFriend');
        this.friendsProfile = result.data.customerData;
        this.isPicExists =
          this.friendsProfile.bannerURL.original !== null
            ? environment.rearGuardImageUri +
              '/' +
              this.friendsProfile.bannerURL.original
            : '../../../../../assets/images/no-usesr-profile-image.jpg';

            this.profileImage = this.friendsProfile.imageURL.original !== null
            ? environment.rearGuardImageUri +'/' +this.friendsProfile.imageURL.original
            : '../../../../../assets/images/no-usesr-profile-image.jpg';
            
            
            // this.friendsProfile.imageURL.original;


      }
    });
  }
  navigateToChat() {
    this.router.navigateByUrl('/chat/' + this.userId);
  }
  navigateToAudio() {
    const room = StaticData.roomId();
    const json = {
      userid: this.userId,
      type: 'Audio',
      callingUsername: this.loggedInUsername,
      roomId: room,
    };
    this.socket.emit('call-request', json);
    localStorage.setItem('callingUserId', this.userId);
    localStorage.setItem('roomId', room);

    this.router.navigateByUrl('/audio');
  }
  navigateToVideo() {
    const room = StaticData.roomId();
    const json = {
      userid: this.userId,
      type: 'Video',
      callingUsername: this.loggedInUsername,
      roomId: room,
    };
    this.socket.emit('call-request', json);
    localStorage.setItem('callingUserId', this.userId);
    localStorage.setItem('roomId', room);
    this.router.navigateByUrl('/video');
  }
  getFriendPost() {
    this.dataService.getAnotherUsersPost(this.userId).subscribe((result) => {
      if (result && result.message === 'Success') {
        this.myPosts = result.data.myPost.reverse();
        console.log(this.myPosts);
      }
    });
  }
  getFriendPortfolio() {
    this.dataService
      .getAnotherUsersPortfolio(this.userId)
      .subscribe((result) => {
        if (result && result.message === 'Success') {
          this.friendsPortfolio = result.data.userPortfolio.reverse();
        }
      });
  }

  
  getFriendPrivatePortfolio() {
    this.friendsPrivatePortfolio = [];
    var user = this.authService.getloggedUserDetails();
    this.dataService
      .getFriendsPrivatePortfolio({
        authorization: user["accessToken"],
        friendId: this.userId
      })
      .subscribe((result) => {
        if (result && result.Message === 'Success') {
          this.friendsPrivatePortfolio = result.Data;
          for(var i = 0; i < this.friendsPrivatePortfolio.length; i++){
            this.friendsPrivatePortfolio[i].isVideo = this.friendsPrivatePortfolio[i].fileType.indexOf('video') > -1;
          }
        }
        else if(result && result.Message === 'Access Denied'){

        }
      });
  }

  openDialog(postId, item, index, type) {
    this.dataService.getPostComment(postId).subscribe((commentResult: any) => {
      if (commentResult && commentResult.message === 'Success') {
        if (
          commentResult &&
          commentResult.data &&
          commentResult.data.comments
        ) {
          const json = { postDetail: [], commentDetail: [], index: 0 };
          json.postDetail = item;
          json.commentDetail = commentResult.data.comments;
          json.index = index;

          const dialogRef = this.dialog.open(CommentComponent, {
            data: json,
            height: '600px',
            width: '700px',
            autoFocus: false,
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe((data) => {
            this.myPosts[index].totalComment = data.count;
          });
        }
      }
    });
  }
  openPortfolioModal(imageUrl: string) {
    const dialogRef = this.dialog.open(PortfolioModalComponent, {
      data: environment.rearGuardImageUri + '/' + imageUrl,
      height: '600px',
      width: '700px',
      autoFocus: false,
      disableClose: true,
    });
  }
  
  openPrivatePortfolioModal(imageUrl: string) {
    const dialogRef = this.dialog.open(PortfolioModalComponent, {
      data: this.imageUrlPrivate + '/' + imageUrl,
      height: '600px',
      width: '700px',
      autoFocus: false,
      disableClose: true,
    });
  }

  saveComment(comment, i, postId) {
    this.dataService.postComment(postId, comment).subscribe((result: any) => {
      if (result && result.message === 'Success') {
      }
    });
  }
  onKeydown(event) {
    event.preventDefault();
  }
  
  UpdateLikeStatus(id, status, index) {
    let likeStatus: boolean = !status;

    this.dataService.postLike(id, likeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Post Like status updated.', '');
        if (likeStatus === false) {
          this.myPosts[index].totalLike =
            this.myPosts[index].totalLike === 0
              ? 0
              : this.myPosts[index].totalLike - 1;
        } else {
          this.myPosts[index].totalLike++;

          if (this.myPosts[index].isDislike) {
            this.myPosts[index].totalDislike =
              this.myPosts[index].totalDislike === 0
                ? 0
                : this.myPosts[index].totalDislike - 1;
            this.myPosts[index].isDislike = false;
          }
        }
        this.myPosts[index].isLike = likeStatus;
      }
    });
  }

  UpdateDislikeStatus(id, status, index) {

    let dislikeStatus: boolean = !status;

    this.dataService.postDislike(id, dislikeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Post Like status updated.', '');
        if (dislikeStatus === false) {
          this.myPosts[index].totalDislike =
            this.myPosts[index].totalDislike === 0
              ? 0
              : this.myPosts[index].totalDislike - 1;
        } else {
          this.myPosts[index].totalDislike++;
          if (this.myPosts[index].isLike) {
            this.myPosts[index].totalLike =
              this.myPosts[index].totalLike === 0
                ? 0
                : this.myPosts[index].totalLike - 1;
            this.myPosts[index].isLike = false;
          }
        }
        this.myPosts[index].isDislike = dislikeStatus;
        // 
      }

    });
  }

  friendCount(){
    this.dataService.friendCount(this.userId).subscribe((result: any) => {
      this.totalFriends = result["Data"]["TotalFriends"];
    });
  }

  openShareDialog(item) {
    const dialogRef = this.dialog.open(ShareComponent, {
      height: '350px',
      width: '700px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom',
      data: item,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.getFriendPost();
    });
  }
  reportPost(id, postType, i) {
    const reportPost = new ReportPost();
    reportPost.authorization =
      this.authService.getloggedUserDetails().accessToken;
    reportPost.postId = id;
    reportPost.userId = this.userId;
    reportPost.sensitive = true;
    reportPost.admin = false;
    this.previewClass = 'as';
    this.dataService.reportPost(reportPost).subscribe((commentResult: any) => {
      this.notifyService.showSuccess('Post reported successfully.', '');
      this.myPosts[i].sensitive = true;
    });
  }
}
