<div id="video-chat-container" class="video-position">
    <video id="local-video" #videoLocalElement playsinline autoplay controls></video>
    <video id="remote-video" #videoRemoteElement playsinline autoplay controls></video>
    <div class="endcall">
        <a (click)="Disconnect()">
            <img style="width: 40%;" src="../../../../assets/images/endcall.png">
        </a>
    </div>

</div>

<a *ngIf="showNextButton" class="carousel-control-next" (click)="getRandomUser()" style="cursor: pointer;" role="button"
    data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
</a>