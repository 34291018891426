import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/components/public/service/data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/api/auth.service';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.css']
})
export class EditGroupComponent implements OnInit {
  public userDetails: any;
  createGroupForm = this.initForm();
  
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditGroupComponent>,
    private dataService: DataService,
    private authService: AuthService,
    private notifyService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userDetails = this.authService.getloggedUserDetails();
  }

  ngOnInit(): void {
    this.createGroupForm.setValue({_id: this.data["_id"],groupName: this.data["groupName"], description: this.data["description"]});
  }

  private initForm() {
    return this.fb.group({
      _id: ['', Validators.required],
      groupName: ['', Validators.required],
      description: ['']
    });
  }

  closeComment() {
    this.dialogRef.close();
  }

  editGroup() {
    if (this.createGroupForm.invalid) {
      return;
    }

    var data = {
      authorization: this.userDetails.accessToken,
      _id:this.createGroupForm.value._id,
      name:this.createGroupForm.value.groupName,
      description:this.createGroupForm.value.description
    }
    this.dataService
      .editGroup(data)
      .subscribe((result: any) => {
        if (result && result.Message === 'Updated Successfully') {
          this.notifyService.showSuccess('Group Updated Successfully.', '');
          setTimeout(() => {
            this.dialogRef.close(true);
          }, 500);
        }
      });
  }
}
