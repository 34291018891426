<a class="close-button" style="cursor: pointer;" (click)="closeComment()"><i class="fa fa-close"
        style="color: #0041c1"></i></a>
<h2 mat-dialog-title class="title">Group Requests</h2>
<div class="container">
    <div class="row">
        <div class="col-md-12 col-12">
            <div class="add-friends">
                <div class="tab-content">
                    <div class="friends-list" *ngFor="let item of groupRequest;let i = index">
                        <div class="friends-list-inner">
                            <div class="friends-list-image" style="cursor: pointer;">
                                <img src="{{imageUrl}}/{{item.groupMember.imageURL.thumbnail}}">
                            </div>
                            <div class="friends-list-details" style="cursor: pointer;">
                                <p>{{item.groupMember.fullName}}
                                    <span class="emailid">{{item.groupMember.email}}</span>

                                </p>

                            </div>
                            <div class="friends-list-add">
                                <button style="margin-right: 10px;" (click)="AcceptRequest(item._id,i)">Accept</button>
                                <button (click)="DeleteRequest(item._id,i)">Delete</button>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>