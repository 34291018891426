<div class="container">
    <a class="close-button" style="cursor: pointer;" (click)="closePost()"><i class="fa fa-close"
            style="color: #0041c1"></i></a>
    <h2 mat-dialog-title class="title">Post</h2>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="people-all-comment">
                    <div class="person-post d-flex align-items-center mb-3">
                        <div *ngIf="postDetail.postAuthor.imageURL" class="me-2">
                            <img [src]="imageUrl + '/' + postDetail.postAuthor.imageURL.original"
                                class="img-fluid rounded-circle" style="width: 50px; height: 50px;">
                        </div>
                        <div>
                            <p class="mb-0 pl-2">{{postDetail.postAuthor.fullName}}<br><span class="text-muted">{{
                                    postDetail.createdAt | date: 'h:mm a dd-MMM-yy' }}</span></p>
                        </div>
                    </div>

                    <div class="latest-post mb-3"
                        [ngClass]="{'blur-content': postDetail.sensitive && clickBlurIndex === -1}">
                        <p>{{postDetail.postContent}}</p>
                        <div *ngIf="postDetail.imageURL.original">
                            <img [src]="imageUrl + '/' + postDetail.imageURL.original" class="img-fluid">
                        </div>
                        <div *ngIf="postDetail.videoURL">
                            <video width="100%" controls>
                                <source [src]="imageUrl + '/' + postDetail.videoURL" type="video/mp4">
                            </video>
                        </div>
                        <p *ngIf="postDetail.sensitive">
                            This post contains sensitive content.
                            <strong><a class="text-primary" style="cursor: pointer;" (click)="removeBlur()">See this
                                    post</a></strong>
                        </p>
                    </div>

                    
                    <div class="likecomment-section">
                        <div class="like-part">
                            <p><span><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></span> {{post.totalLike}}
                                <span class="ml-4"><i class="fa fa-thumbs-down" aria-hidden="true"></i></span>
                                {{post.totalDislike}}
                            </p>
                        </div>
                        <div class="comment-part">
                            <p>{{commentDetail.length}} Comments</p>
                        </div>
                    </div>
                    <div class="share">
                        <div class="like-part">
                            <p *ngIf="post.isLike" style="color: #0041c1;cursor: pointer"
                                (click)="UpdateLikeStatus(post._id, post.isLike,postIndex,'Public',post)"><i
                                    class="fa fa-thumbs-o-up" aria-hidden="true"
                                    style="color: #0041c1;cursor: pointer"></i>
                                Like</p>
                            <p style="cursor: pointer;" *ngIf="!post.isLike"
                                (click)="UpdateLikeStatus(post._id, post.isLike,postIndex,'Public',post)"><i
                                    class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                                Like</p>
                        </div>
                        <div class="like-part">
                            <p *ngIf="post.isDislike" style="color: #0041c1;cursor: pointer"
                                (click)="UpdateDislikeStatus(post._id, post.isDislike,postIndex,'Public',post)"><i
                                    class="fa fa-thumbs-down" aria-hidden="true" style="cursor: pointer"></i>
                                Dislike</p>
                            <p style="cursor: pointer;" *ngIf="!post.isDislike"
                                (click)="UpdateDislikeStatus(post._id, post.isDislike,postIndex,'Public',post)"><i
                                    class="fa fa-thumbs-down" aria-hidden="true"></i>
                                Dislike</p>
                        </div>
                        <div class="comment-part">
                            <p style="cursor: pointer;" (click)="openShareDialog(postDetail)"><i class="fa fa-share"
                                    aria-hidden="true">
                                    
                                </i> Share</p>
                        </div>
                    </div>
                    <div class="public-comments-main">
                        <div *ngFor="let comment of commentDetail,let i = index">
                            <div class="public-comments d-flex">
                                <div class="public-img">
                                    <img src="{{imageUrl}}/{{comment.commentAuthor.imageURL.original}}">
                                </div>
                                <div class="publiccommentInner">
                                    <h5>{{comment.commentAuthor.fullName}}</h5>
                                    <p class="comment"> {{ comment.commentText }}
                                        <a *ngIf="comment.commentAuthor._id===userDetails._id" class="comment-delete"
                                            (click)="deleteComment(comment._id,i)"><i class="fa fa-close"
                                                style="color: #0041c1"></i></a>

                                        <a *ngIf="comment.commentAuthor._id===userDetails._id" class="comment-update"
                                            (click)="openEditDialog(comment._id,i)"><i class="fa fa-edit"
                                                style="color: #0041c1"></i></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--  -->


                    <!-- Edit Comment Dialog -->
                    <ng-template #editPostDialog let-dialogRef>
                        <a class="close-button" style="cursor: pointer;" (click)="closeCommentEdit()">
                            <i class="fa fa-close" style="color: #0041c1;"></i>
                        </a>
                        <h1>Edit Comment</h1>
                        <div>
                            <form>
                                <mat-form-field appearance="fill" class="w-100">
                                    <textarea matInput placeholder="Post content" [(ngModel)]="selectedPost.commentText"
                                        name="content"></textarea>
                                </mat-form-field>
                            </form>
                        </div>
                        <div>
                            <button mat-raised-button color="primary" class="update-button"
                                (click)="onSaveClick(postDetail._id, dialogRef)">Update</button>
                        </div>
                    </ng-template>

                    <div class="self-comment d-flex align-items-start mt-3">
                        <div class="me-2 mt-2 mr-1">
                            <img [src]="imageUrl + '/' + userDetails.imageURL.original" class="img-fluid rounded-circle"
                                style="width: 40px; height: 40px;">
                        </div>
                        <div class="flex-grow-1">
                            <textarea name="txtComment" placeholder="Write a public comment here..."
                                class="form-control txtcomment" (keydown.enter)="onKeydown($event)"
                                (keyup.enter)="saveComment(comment)" [(ngModel)]="comment"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>