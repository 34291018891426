import { Component, OnInit } from '@angular/core';
import { ChatService } from 'src/app/shared/services/chat.service';
import { io } from 'socket.io-client';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/api/auth.service';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.css'],
})
export class AudioComponent implements OnInit {
  @ViewChild('videoLocalElement') videoElement: any;
  @ViewChild('videoRemoteElement') videoRemoteElement: any;
  public randomData: any;
  public socket;
  public ipAddress;
  public mediaConstraints = {
    audio: true,
    video: false,
  };
  public localStream;
  public remoteStream;
  public isRoomCreator;
  public rtcPeerConnection; // Connection between the local device and the remote peer.
  public roomId;
  public iceServers = {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:stun1.l.google.com:19302' },
      { urls: 'stun:stun2.l.google.com:19302' },
      { urls: 'stun:stun3.l.google.com:19302' },
      { urls: 'stun:stun4.l.google.com:19302' },
    ],
  };
  public remoteSrc;
  public localSrc;
  previousUrl;
  isNotify: boolean;
  showLoader;

  constructor(
    private chatService: ChatService,
    private router: Router,
    private notifyService: NotificationService,
    private authService: AuthService
  ) {
    this.isNotify = false;
    this.showLoader = true;
    this.socket = io(environment.rearGuradSocketBaseUri);
    this.createRoom();
    this.socket.on('room_created', async (data) => {
      await this.setLocalStream(this.mediaConstraints);
      console.log('Room Created.......', data);
      this.isRoomCreator = true;
    });
    this.socket.on('call-declined', (result) => {
      if (result) {
        const uid = localStorage.getItem('roomId');
        if (uid === result.roomId) {
          if (this.localStream) {
            this.localStream.getTracks().forEach((track) => {
              track.stop();
            });
          }
          this.isRoomCreator = undefined;
        }
        const userid = localStorage.getItem('callingUserId');
        if (userid !== null) {
          if (uid === result.roomId) {
            if (this.isNotify === false) {
              this.notifyService.showInfo(
                'Call Declined by ' + result.callingUsername,
                ''
              );
            }
            this.isNotify = true;
            this.router.navigateByUrl('/profile/' + result.userid);
          }
        }
      }
    });
    this.socket.on('call-disconnected', (result) => {
      if (result) {
        const uid = localStorage.getItem('roomId');
        if (uid === result) {
          if (this.localStream) {
            this.localStream.getTracks().forEach((track) => {
              track.stop();
            });
            this.localStream.getAudioTracks()[0].stop();
          }
          this.isRoomCreator = undefined;
        }
        const userid = localStorage.getItem('callingUserId');
        if (userid !== null) {
          if (uid === result) {
            this.router.navigateByUrl('/profile/' + userid);
          }
        } else {
          this.router.navigateByUrl('/dashboard');
        }
      }
    });

    this.socket.on('room_joined', async () => {
      console.log('Call Joined.......', this.roomId);
      await this.setLocalStream(this.mediaConstraints);
      this.socket.emit('start_call', this.roomId);
    });

    this.socket.on('full_room', () => {
      console.log('Socket event callback: full_room');
    });

    this.socket.on('start_call', async () => {
      console.log('Call Started.......', this.isRoomCreator);
      if (this.isRoomCreator) {
        this.rtcPeerConnection = new RTCPeerConnection(this.iceServers);
        this.addLocalTracks(this.rtcPeerConnection);
        this.rtcPeerConnection.ontrack = (event) => {
          console.log('Call Set Remote Video.......');
          this.videoRemoteElement.nativeElement.srcObject = event.streams[0];
          this.showLoader = false;
          this.remoteStream = event.stream;
        };
        this.rtcPeerConnection.onicecandidate = (e) => {
          if (e.candidate) {
            console.log('On Ice Candidate.......');
            const roomId = this.roomId;
            this.socket.emit('webrtc_ice_candidate', {
              roomId,
              label: e.candidate.sdpMLineIndex,
              candidate: e.candidate.candidate,
            });
          }
        };
        await this.createOffer(this.rtcPeerConnection);
      }
    });

    this.socket.on('webrtc_offer', async (event) => {
      if (!this.isRoomCreator) {
        this.rtcPeerConnection = new RTCPeerConnection(this.iceServers);
        this.addLocalTracks(this.rtcPeerConnection);
        this.rtcPeerConnection.ontrack = (ev) => {
          this.videoRemoteElement.nativeElement.srcObject = ev.streams[0];

          this.remoteStream = ev.stream;
          this.showLoader = false;
        };
        this.rtcPeerConnection.onicecandidate = (e) => {
          if (e.candidate) {
            const roomId = this.roomId;
            this.socket.emit('webrtc_ice_candidate', {
              roomId,
              label: e.candidate.sdpMLineIndex,
              candidate: e.candidate.candidate,
            });
          }
        };
        this.rtcPeerConnection.setRemoteDescription(
          new RTCSessionDescription(event)
        );
        await this.createAnswer(this.rtcPeerConnection);
      }
    });

    this.socket.on('webrtc_answer', (event) => {
      this.rtcPeerConnection.setRemoteDescription(
        new RTCSessionDescription(event)
      );
    });

    this.socket.on('webrtc_ice_candidate', (event) => {
      // ICE candidate configuration.
      const candidate = new RTCIceCandidate({
        sdpMLineIndex: event.label,
        candidate: event.candidate,
      });

      this.rtcPeerConnection.addIceCandidate(candidate);
    });
  }

  ngOnInit(): void {}
  createRoom() {
    const userId = localStorage.getItem('callingUserId');
    const randomId = localStorage.getItem('roomId');
    this.roomId = randomId;
    if (userId === '') {
      this.joinRoom(this.roomId);
    } else {
      this.socket.emit('join', this.roomId);
      this.showVideoConference();
    }
  }

  joinRoom(room) {
    if (room === '') {
      alert('Please type a room ID');
    } else {
      this.roomId = room;
      this.socket.emit('join', room);
      this.showVideoConference();
    }
  }

  showVideoConference() {
    // roomSelectionContainer.style = 'display: none';
    // videoChatContainer.style = 'display: block';
  }

  setRemoteStream(event) {
    this.videoRemoteElement.nativeElement.srcObject = event.streams[0];

    this.remoteStream = event.stream;
  }

  sendIceCandidate(event) {
    if (event.candidate) {
      const rid = this.roomId;
      this.socket.emit('webrtc_ice_candidate', {
        rid,
        label: event.candidate.sdpMLineIndex,
        candidate: event.candidate.candidate,
      });
    }
  }

  addLocalTracks(rtcPeerConnection) {
    console.log('Add Local Tracks.......', this.localStream);
    this.localStream.getTracks().forEach((track) => {
      rtcPeerConnection.addTrack(track, this.localStream);
    });
  }

  async setLocalStream(mediaConstraints) {
    let stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
    } catch (error) {
      console.error('Could not get user media', error);
    }

    this.localStream = stream;
    this.videoElement.nativeElement.srcObject = stream;
  }

  async createOffer(rtcPeerConnection) {
    console.log('offer Created.......');
    let sessionDescription;
    try {
      sessionDescription = await rtcPeerConnection.createOffer();
      rtcPeerConnection.setLocalDescription(sessionDescription);
    } catch (error) {
      console.error(error);
    }
    const roomId = this.roomId;
    this.socket.emit('webrtc_offer', {
      type: 'webrtc_offer',
      sdp: sessionDescription,
      roomId,
    });
  }

  async createAnswer(rtcPeerConnection) {
    console.log('Answer Created.......');
    let sessionDescription;
    try {
      sessionDescription = await rtcPeerConnection.createAnswer();
      rtcPeerConnection.setLocalDescription(sessionDescription);
    } catch (error) {
      console.error(error);
    }
    const roomId = this.roomId;
    const id = localStorage.getItem('Id');
    this.socket.emit('webrtc_answer', {
      type: 'webrtc_answer',
      sdp: sessionDescription,
      roomId,
    });
  }

  async initCamera(config) {
    navigator.mediaDevices.getUserMedia(config).then((mediaStream) => {
      this.videoElement.nativeElement.srcObject = mediaStream;
      this.localStream = mediaStream;
    });
  }

  disconnect() {
    this.socket.emit('call-disconnect', this.roomId);
    if (this.localStream) {
      if (this.localStream.getVideoTracks() !== undefined) {
        this.localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    }
    this.isRoomCreator = undefined;
  }
}
