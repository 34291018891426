<div class="wrapper">
    <nav>
        <input type="checkbox" id="show-search">
        <input type="checkbox" id="show-menu">
        <label for="show-menu" class="menu-icon"><i class="fa fa-bars" aria-hidden="true"></i></label>
        <div class="content">
            <div class="logo"><a href="#"><img src="../../../../../assets/images/logo.png"></a></div>
            <ul class="links">
                <li style="display: none;"><a href="#" class="active">Home</a></li>
               
                
                <li style="display: none;"><a href="https://play.google.com/store/apps/details?id=com.rearguard.socialapp" target="_blank"
                        class="nav-img"><img src="../../../../../assets/images/google-play.png"></a></li>
                <li style="display: none;"><a href="https://apps.apple.com/us/app/rearguardsocialapp/id1552662073" target="_blank"
                        class="nav-img"><img src="../../../../../assets/images/apple-store.png"></a></li>
            </ul>
        </div>
    </nav>
</div>
<router-outlet></router-outlet>
<footer>
    <div class="footer-section">
        <div class="container">
            <div class="row">
                <!-- <div class="col-md-1 col-11"></div> -->
                <div class="col-md-4 col-11">
                    <!-- <h3>Logo</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                    <div class="social-icons">
                        <!-- <ul class="list-inline">
                            <li>
                                <a href=""><i aria-hidden="true" class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a href=""><i aria-hidden="true" class="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a href=""><i aria-hidden="true" class="fa fa-instagram"></i></a>
                            </li>
                            <li>
                                <a href=""><i aria-hidden="true" class="fa fa-youtube-play"></i></a>
                            </li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-md-5 col-11">
                    <h3>Contact Us</h3>
                    <div class="footer-contact">
                        <i aria-hidden="true" class="fa fa-map-marker"></i>
                        <p>USA</p>
                    </div>
                    <div class="footer-contact">
                        <i aria-hidden="true" class="fa fa-envelope"></i>
                        <p><a href="mailto:info@friendlinq.com">info@friendlinq.com</a></p>
                    </div>
                    <!-- <div class="footer-contact">
                        <i aria-hidden="true" class="fa fa-phone"></i>
                        <p><a href="tel:+91%209756204602">+91 9756204602</a>, <a href="tel:+91%209837633939">+91
                                9837633939</a>, <a href="tel:+91%209837030176">+91 9837030176</a></p>
                    </div> -->
                </div>
                <div class="col-md-3 col-11">
                    <!-- <h3>Quick Contact</h3>
                    <ul class="list-inline">
                        <li>
                            <a href="#">Home</a>
                        </li>
                        <li>
                            <a href="#">Home</a>
                        </li>
                        <li>
                            <a href="#">Home</a>
                        </li>
                        <li>
                            <a href="#">Home</a>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
        <div class="container-fluid rem-pad">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="footer-bottom text-center">
                        <p>Copyright © 2024 Friendlinq , All right reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>