import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/components/public/service/data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthService } from 'src/app/shared/api/auth.service';
import { changePassword,chagetype, GeTbALANCE, PyamentMethodImpl } from 'src/app/components/models/signup.model';
import { NavigationEnd, Router } from '@angular/router';
import { loadStripe, Stripe, StripeCardNumberElement, StripeElements } from '@stripe/stripe-js';


@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  public emailUpdates: boolean;
  public userDetails: any;
  public TotalBalance:number=0;
  public PrivatePortfolioBalance:number=0;
  public PrivateGroupBalance:number=0;
  public showSettings:boolean=true;

  public isPasswordFormVisible = false;
  public currentPassword = '';
  public newPassword = '';
  public confirmPassword = '';
  
  public showPlans:boolean=false;
  public selectedItem: any | null = null;
  public stripe: Stripe | null = null;
  public elements: StripeElements | null = null;
  public cardNumber: StripeCardNumberElement | null = null;
  public allplans:any;
  public showPaymentDetails:boolean=false;


  constructor(private authService:AuthService,private notifyService:NotificationService,private dataService:DataService,
    private router: Router
  ) {
    this.userDetails = this.authService.getloggedUserDetails();
    this.emailUpdates=false;

    this.dataService.GetStripePlans().subscribe(
      (planData: any) => {
        this.allplans = planData.data;
        this.selectedItem = this.allplans[0];
        
      },
      (error) => {
        console.error(error);
      }
    );

   }

  ngOnInit(): void {
    this.GetEmailSubscribed();
    this.GetBalance();
  }


  // Checking the email preference
  GetEmailSubscribed() {

    

    this.dataService.IsEmailSubscribed(this.userDetails.email).subscribe(
      (data: any) => {
        if(data.StatusCode==200){     
          console.log(data);
          if(data.SubscribeEmail){this.emailUpdates=true;}
        }
      else{
        this.emailUpdates=false;
      }
      },
      (error) => {
        console.log(error);
      }    
    );
  }

  GetBalance(){

    this.dataService.GetBalance().subscribe(
      (data: any) => {
        if(data.StatusCode==200){     
          console.log(data);
          this.TotalBalance=data.balance;
          this.PrivateGroupBalance=data.paymentForPrivateGroup;
          this.PrivatePortfolioBalance=data.paymentForPortfolio;
        }
      else{
        this.emailUpdates=false;
      }
      },
      (error) => {
        console.log(error);
      }    
    );
  }

  // Updating the email preference
  onToggleEmailUpdates() {
    // First, check the current email subscription status
    this.dataService.IsEmailSubscribed(this.userDetails.email).subscribe(
      (data: any) => {
        if (data.StatusCode == 200) {
          console.log(data);
  
          // Determine if the user is already subscribed or not
          if (data.SubscribeEmail) {
            // If the user is subscribed, proceed with unsubscribing
            this.unsubscribeEmail();
          } else {
            // If the user is not subscribed, proceed with subscribing
            this.subscribeEmail();
          }
        } else {
          this.emailUpdates = false; // Handle the case where the status check fails
        }
      },
      (error) => {
        console.error('Error checking subscription status:', error);
        this.emailUpdates = false; // Handle error scenario
      }
    );
  }
  
  subscribeEmail() {
    // Call the API to subscribe the user to email updates
    this.dataService.SubscribeEmail().subscribe(
      (data: any) => {
        if (data.StatusCode == 200) {
          console.log('Successfully subscribed to email updates:', data);
          this.notifyService.showSuccess("Successfully subscribed to email updates","");    
          this.emailUpdates = true; // Update the toggle state to reflect the subscription
          this.GetEmailSubscribed(); // Optionally refresh the subscription status
        } else {
          console.error('Failed to subscribe to email updates:', data);
          this.emailUpdates = false;
        }
      },
      (error) => {
        console.error('Error subscribing to email updates:', error);
        this.emailUpdates = false;
      }
    );
  }
  
  unsubscribeEmail() {
    // Call the API to unsubscribe the user from email updates
    this.dataService.UnsubscribeEmail().subscribe(
      (data: any) => {
        if (data.StatusCode == 200) {
          console.log('Successfully unsubscribed from email updates:', data);
          this.notifyService.showSuccess("Successfully unsubscribed from email updates","");
          this.emailUpdates = false; // Update the toggle state to reflect the unsubscription
          this.GetEmailSubscribed(); // Optionally refresh the subscription status
        } else {
          console.error('Failed to unsubscribe from email updates:', data);
          this.emailUpdates = true;
        }
      },
      (error) => {
        console.error('Error unsubscribing from email updates:', error);
        this.emailUpdates = true;
      }
    );
  }

  togglePasswordForm() {
    this.isPasswordFormVisible = !this.isPasswordFormVisible;
  }

  onChangePassword(settingchangepassword:any) {
    if (this.newPassword === this.confirmPassword) {
      const changetypeWrapper = new changePassword();
      // changetypeWrapper.authorization = this.userDetails.accessToken;
      changetypeWrapper.oldPassword =this.currentPassword;
      changetypeWrapper.newPassword = this.newPassword;

      console.log(changetypeWrapper);

      this.dataService.changePassword(changetypeWrapper).subscribe(
       
        (data: any) => {
          console.log(data);

          if(data.statusCode==200){
          this.notifyService.showSuccess("Password changed successfully","");    
          
              }
        else{
          this.notifyService.showError("Failed!! Try Again","");
        }
        },
        (error) => {
          console.log(error);
        }
      );


    } 
    else {
      this.notifyService.showError("Password and confirm password doesn't match","");
    }
  }

  SuspendAccount(){
    this.dataService.DeleteAccount().subscribe(
      (data: any) => {
        if(data.StatusCode==200){     
          console.log(data);
          this.notifyService.showSuccess("Account deleted successfully","");    

          this.dataService.setOnlineStatus(0).subscribe((res: any) => {
            this.dataService.logout().subscribe((result: any) => {
              if (result && result.message === 'Success') {
                this.notifyService.showSuccess('LogOut Successfully', '');
                sessionStorage.clear();
                localStorage.clear();
                this.router.navigateByUrl('/login');
              }
            });
          });

        }
      else{
        this.emailUpdates=false;
        this.notifyService.showSuccess("Please try again",""); 
      }
      },
      (error) => {
        console.log(error);
        this.notifyService.showSuccess("Please try again",""); 
      }    
    );
  }

  showAllPlans(){
    this.showPlans=true;
    this.showSettings=false;
  }

  onCheckboxChange(item: any, event: any) {
    if (event.target.checked) {
      this.selectedItem = item;
      this.uncheckOtherItems(item._id);  // Uncheck other items
    } else {
      this.selectedItem = null;
    }
  }

  uncheckOtherItems(selectedItemId: any) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      if (+checkbox.value !== selectedItemId) {
        checkbox.checked = false;
      }
    });
  }

  isChecked(item:any): boolean {
    return this.selectedItem?._id === item._id;
  }

  async initializeStripe() {
    this.stripe = await loadStripe('pk_test_51OeCKVBOfCO7ilUokdzDowOyC7C55g5LhV5neUfmTg9nJLif4BUOz7sen8KVGdv8B7fgAg858ybAPGR3soA30NhI00BC2IwEeS');
    this.elements = this.stripe.elements();

    const style = {
      base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      }
    };

    
    this.cardNumber = this.elements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number-element');

    const cardExpiry = this.elements.create('cardExpiry', { style });
    cardExpiry.mount('#card-expiry-element');

    const cardCvc = this.elements.create('cardCvc', { style });
    cardCvc.mount('#card-cvc-element');

  }

  CompletePurchase(){
    this.showPlans=false;
    this.showPaymentDetails=true;
    this.initializeStripe();
  }

  async handlePaymentfromsettings() {

    if (!this.stripe || !this.cardNumber) {
      return;
    }

    const { token, error } = await this.stripe.createToken(this.cardNumber);
    if (error) {
    } else {
      const servicecall=new PyamentMethodImpl();

      
      servicecall.authorization=this.userDetails.accessToken;
      servicecall.packageId= this.selectedItem._id;
      servicecall.charges= this.selectedItem.Price;
      servicecall.stripeToken= token.id;
      servicecall.stripeEmail=this.userDetails.email;

      console.log(servicecall);

      this.dataService.CompletePayment(servicecall).subscribe(

        (data: any) => {
          if(data.StatusCode==200){
          this.notifyService.showSuccess("Payment is processed Successfully!!","");       
            this.router.navigateByUrl('/dashboard');              
          }
          else{
            this.notifyService.showError("Payment Failed!!","");
          }
         },
        (error) => {
          console.log(error);
        }
      );
    }
  }

}
