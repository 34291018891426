export class SearchFriend {
  searchText: string;
  skip: number;
  limit: number;
}
export class FriendRequest {
  status: string;
  skip: number;
  limit: number;
}
