<div class="container">
    <div class="row">
        <div class="col-md-12 col-12">
            <div class="friends-heading">
                <h1 style="cursor: pointer;" (click)="getGroupsByUserId()">Groups</h1>

                <div class="search-Friends">
                    <input type="text" placeholder="search">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <a href="javascript:void(0;" id="cancel">Cancel</a>
                </div>
            </div>
            <div class="add-friends new-groups">
                
                <ul class="nav nav-pills">
                    <div class="btn-group" style="float: rig    t;" role="group" aria-label="Basic example">
                        <button type="button" (click)="selectGroups('All')" [ngClass]="selectGroup == 'All' ? 'btn-primary' : 'btn-secondary'" class="btn">All</button>
                        <button type="button" (click)="selectGroups('Public')" [ngClass]="selectGroup == 'Public' ? 'btn-primary' : 'btn-secondary'" class="btn">Public</button>
                        <button type="button" (click)="selectGroups('Private')" [ngClass]="selectGroup == 'Private' ? 'btn-primary' : 'btn-secondary'" class="btn">Private</button>
                    </div>
                    <li class="active" (click)="getjoinGroupList()"><a data-toggle="pill" style="cursor: pointer;"><i
                                class="fa fa-user-plus" aria-hidden="true"></i>
                            Join Group</a>
                    </li>
                    <li><a data-toggle="pill" style="cursor: pointer;" (click)="createGroup()"><i
                                class="fa fa-user-plus" aria-hidden="true"></i>
                            Create Group</a>
                    </li>
                    <li><a data-toggle="pill" style="cursor: pointer;" (click)="createPrivateGroup()"><i
                                class="fa fa-user-plus" aria-hidden="true"></i>
                            Create Private Group</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div id="home" class="tab-pane fade in active show" *ngIf="isUserGroup">
                        <div class=" friends-list">
                            <div *ngIf="showLoader" class="loader"> <img src="../../../../assets/images/loading.gif">
                            </div>
                            <div *ngIf="!showLoader && isUserGroup">
                                <div *ngFor="let userGroup of userGroups">
                                    <div class="friends-list-inner" style="cursor: pointer;"
                                        (click)="navigateToDashboard(userGroup._id,userGroup)">
                                        <div class="friends-list-image">
                                            <i *ngIf="userGroup.type != 'Private Group'" title="Public Group" class="fa fa-users" aria-hidden="true"></i>
                                            <i class="fa fa-lock" aria-hidden="true" title="Private Group" *ngIf="userGroup.type == 'Private Group'"></i>
                                        </div>
                                        <div class="friends-list-details">
                                            
                                            <button *ngIf="userGroup.ExtraElements && userGroup.ExtraElements.isActive" class="btn btn-sm" style="cursor: pointer;float: right;" (click)="navigateToVideo(userGroup, $event)">Join Call</button>
                                             <button class="btn btn-sm" (click)="navigateToVideo(userGroup, $event)" style="cursor: pointer;float: right;"> <i class="fa fa-video-camera" aria-hidden="true"></i></button>
                                            <button class="btn btn-sm" (click)="EditGroup(userGroup, $event)" style="float: right;" title="Edit" *ngIf="userGroup && userGroup.groupAdminId && userDetails._id == userGroup.groupAdminId._id"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                            </button>
                                            <span *ngIf="userGroup.type == 'Private Group'" style="float: right;">Private Group</span>

                                            <p>{{userGroup.groupName}}
                                                <span style="font-size: 14px;">{{userGroup.description}}</span>
                                                <span class="emailid">{{ userGroup.createdAt | date :'h:mm a dd-MMM-yy'
                                                    }}</span>
                                                    
                                            </p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="menu1" class="tab-pane fade in active show" *ngIf="!isUserGroup">
                        <div class="friends-list">

                            <div *ngIf="showLoader" class="loader"> <img src="../../../../assets/images/loading.gif">
                            </div>
                            <div>
                                <div *ngFor="let group of groupSuggestion;let i = index">
                                    <div class="friends-list-inner" *ngIf="group.groupAdminId !==userDetails._id">
                                        <div class="friends-list-image">
                                            <i *ngIf="group.type != 'Private Group'" title="Public Group" class="fa fa-users" aria-hidden="true"></i>
                                            <i class="fa fa-lock" aria-hidden="true" title="Private Group" *ngIf="group.type == 'Private Group'"></i>
                                        </div>
                                        <div class="friends-list-details">
                                            <p>{{group.groupName}}
                                                <span style="font-size: 14px;">{{group.description}}</span>
                                                <span class="emailid">{{ group.createdAt | date :'h:mm a dd-MMM-yy'
                                                    }}</span>
                                                    <span *ngIf="group.type == 'Private Group'" style="font-style: italic;font-size: 14px;" >Private Group</span>
                                            </p>
                                        </div>
                                        <button id="paymentjoin" (click)="joinRequestWithPayment(group._id,i)"
                                            *ngIf="group.type == 'Private Group' && (group.status === null || group.status === 'cancel')"
                                            class="btn btn-success infoPayment">Make Payment & Join</button>
                                        <button id="join" (click)="joinRequest(group._id,i)"
                                            *ngIf="group.type != 'Private Group' && (group.status === null || group.status === 'cancel')"
                                            class="btn info">Join</button>
                                        <button id="cancel" (click)="cancelRequest(group._id,i)"
                                            *ngIf="group.status === 'pending'" class="btn danger">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>