import { Component, OnInit } from '@angular/core';
import { io } from 'socket.io-client';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/api/auth.service';

@Component({
  selector: 'app-calling',
  templateUrl: './calling.component.html',
  styleUrls: ['./calling.component.css'],
})
export class CallingComponent implements OnInit {
  socket;
  userId;
  userName;

  constructor(
    private dialogRef: MatDialogRef<CallingComponent>,
    private authService: AuthService
  ) {
    this.socket = io(environment.rearGuradSocketBaseUri);
    this.userId = this.authService.getloggedUserDetails()._id;
    this.userName = this.authService.getloggedUserDetails().fullName;
  }

  ngOnInit(): void {}
  onAcceptClick() {
    this.dialogRef.close('Accept');
  }
  onDeclineClick() {
    const room = localStorage.getItem('roomId');
    const json = {
      userid: this.userId,
      callingUsername: this.userName,
      roomId: room,
    };
    localStorage.clear();
    this.socket.emit('call-decline', json);
    this.dialogRef.close('Decline');
  }
}
