import { Component, OnInit } from '@angular/core';
import { DataService } from '../service/data.service'

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {

  showMessage: boolean;
  showJoinUs: boolean;
  name: string;
  email: string = "";
  phoneNumber: string;
  message: string;
  validationMessage: string = "Email is required!!";
  showValidation: boolean = false;
  showSuccessMessage: boolean = false;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
  }

  showMessageFunc(){
    this.showMessage = true;
  }

  hideMessageFunc(){
    this.showMessage = false;
  }

  showJoinUsFunc(){
    this.showJoinUs = !this.showJoinUs;
  }

  SubmitEnquiry(){
    var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if(this.email == ""){
      this.validationMessage = "Email is required!!";
      this.showValidation = true;
    }
    else if(this.email != "" && !regexp.test(this.email)){
      this.validationMessage = "Please enter a valid Email!!";
      this.showValidation = true;
    }

    if(this.showValidation){
      setTimeout(function(){
        this.showValidation = false;
      }.bind(this), 4000);
      return;
    }

    this.dataService.submitEnquiry({
      name:this.name,
      feedback: this.message,
      email: this.email,
      phoneNumber: this.phoneNumber 
    }).subscribe((msg) =>{
      if(msg["Message"] == "Success"){
        this.showSuccessMessage = true;
      }
      setTimeout(function(){
        this.showJoinUsFunc();
      }.bind(this), 3000);
    })
  }

}
