<div class="login signup">
    <div class="container">
        <div class="row" *ngIf="isProcessing">
            <i style="margin: auto;" class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        </div>
        <div class="row" *ngIf="!isProcessing">
            <div class="col-md-12 col-12">
                <div class="login-inner">
                    <div *ngIf="showOTP">
                        <div class="form-group">
                            <form (submit)="sendOTP(form)" #form="ngForm">
                                <input type="email" style="background-color: lightgray;" readonly="readonly"
                                    placeholder="Email" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                    [(ngModel)]="emailToVerify" name="emailToVerify">
                                <button type="submit" [disabled]="generatingEmail" class="btn btn-primary btn-xs"
                                    style="margin-left: 5px;">{{otpMessageText}}</button>
                            </form>
                        </div>
                        <span *ngIf="generatingEmail">
                            <i style="position: inherit;color: #fff;" class="fa-li fa-2x fa fa-spinner fa-spin"></i>
                        </span>
                        <div class="extra-text" *ngIf="sentOTP">
                            <p>
                                A Signup OTP has been sent in your registering email. Please check span messages if you
                                are not able to see OTP in your main inbox.
                                <br />It may take 2 to 5 mins to be received in your email.
                            </p>
                        </div>
                        <input type="text" placeholder="OTP" *ngIf="sentOTP" [(ngModel)]="otpToVerify" name="OTP">
                        <button class="btn btn-primary btn-xs" *ngIf="sentOTP" style="margin-left: 5px;"
                            (click)="verifyEmail()">Verify Email</button>
                    </div>
                    <div *ngIf="mainSignupStatus">
                        <form [formGroup]="signupForm">
                            <h1>Sign Up</h1>
                                
                            <div class="form-group">
                                <input type="email" placeholder="Enter your email" name="email" formControlName="email">
                                <span class="required-asterisk">*</span>
                                <div *ngIf="signupForm.get('email').invalid && (signupForm.get('email').touched || signupForm.get('email').dirty)"
                                    class="text-danger">
                                    <span *ngIf="signupForm.get('email').errors?.required">Email is required.</span>
                                    <span *ngIf="signupForm.get('email').errors?.email">Please enter a valid email
                                        address.</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <input [type]="passwordVisible ? 'text' : 'password'" placeholder="Create a strong password" name="password" formControlName="password">
                                <span class="required-asterisk">*</span>
                                <span class="toggle-password" (click)="togglePasswordVisibility('password')">
                                  <i [ngClass]="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                </span>
                                <div class="text-danger" *ngIf="signupForm.get('password')?.invalid && (signupForm.get('password')?.touched || signupForm.get('password')?.dirty)">
                                  Password is required.
                                </div>
                              </div>
                              
                              <div class="form-group">
                                <input [type]="confirmPasswordVisible ? 'text' : 'password'" placeholder="Confirm your password" name="confirmpassword" formControlName="confirmpassword">
                                <span class="required-asterisk">*</span>
                                <span class="toggle-password" (click)="togglePasswordVisibility('confirmPassword')">
                                  <i [ngClass]="confirmPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                </span>
                                <div class="text-danger" *ngIf="passwordNotMatch">Passwords must match</div>
                                <div class="text-danger" *ngIf="signupForm.get('confirmpassword')?.invalid && (signupForm.get('confirmpassword')?.touched || signupForm.get('confirmpassword')?.dirty)">
                                  Confirm Password is required.
                                </div>
                              </div>

                            <div class="form-group button-div" style="margin-top: 20px;">
                                <button class="btn btn-success" style="margin-top: 10px; border-radius: 30px;"
                                    (click)="sendOTP(signupForm)" [disabled]="!signupForm.valid">Next</button>
                            </div>

                            <div class="extra-text">

                                <p>Already have an account?
                                    <span><a routerLink="/login">Login</a></span>
                                </p>
                            </div>

                        </form>
                    </div>

                    <div *ngIf="showPlans">
                        <div class="row">
                            <div class="col-md-12 col-12" style="font-size: 30px; font-weight: bolder;">
                                Choose Your FriendLinq Experience
                            </div>
                        </div>

                        <!-- Social Media Plan Section -->
                        <div class="row">
                            <div class="col-md-12 col-12" style="font-weight: bolder;">
                                FriendLinq Social Media
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                Connect with friends and make ones in your area.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                Features:
                            </div>
                            <div class="col-md-12 col-12">
                                <ul>
                                    <li><i class="fa fa-check" aria-hidden="true" style="color:green"></i> Create custom
                                        profile</li>
                                    <li><i class="fa fa-check" aria-hidden="true" style="color:green"></i> Join groups
                                    </li>
                                    <li><i class="fa fa-check" aria-hidden="true" style="color:green"></i> Message
                                        friends</li>
                                    <li><i class="fa fa-check" aria-hidden="true" style="color:green"></i> Post and
                                        download in your feed</li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                <button style="margin-top: 20px; border-radius: 30px;" [routerLink]="['/signup']">Join
                                    FriendLinq Social Media for Free</button>
                            </div>
                        </div>

                        <!-- Flirting/Dating Plan Section -->
                        <div class="row">
                            <div class="col-md-12 col-12" style="font-weight: bolder;">
                                FriendLinq Flirting/Dating
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                Find meaningful connections and potential partners.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                Features:
                            </div>
                            <div class="col-md-12 col-12">
                                <ul>
                                    <li><i class="fa fa-check" aria-hidden="true" style="color:green"></i> All Social
                                        Features, plus</li>
                                    <li><i class="fa fa-check" aria-hidden="true" style="color:green"></i> Single Adults
                                        Flirting/Dating</li>
                                    <li><i class="fa fa-check" aria-hidden="true" style="color:green"></i> Premium
                                        Messaging</li>
                                    <li><i class="fa fa-check" aria-hidden="true" style="color:green"></i> Ad free
                                        experience</li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-12" style="font-weight: bolder; font-size: 30px; color: green;">
                                Pricing
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                Plans starting at $0.99/month with our limited-time offer!
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-12">
                                <button style="margin-top: 20px; border-radius: 30px;"
                                [routerLink]="['/signup']" [queryParams]="{datingstatus: 'true'}">Explore Flirting/Dating Plans</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>