<!-- Added By Poonam 8 June 2024 -->
<div class="profile-content">
<div class="menu-container">
    <div class="hamburger-menu" (click)="toggleMenu()">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="dropdown-menu" style="left:8vw !important; top:40px !important;" [class.show]="menuOpen">
        <a href="javascript:void(0)" (click)="openImageVideoUploadDialog('Add Top 4 Images')">Add Top 4 Images</a>
        <a href="javascript:void(0)" (click)="openImageVideoUploadDialog('Add video')">Add video</a>
    </div>
</div>
<!-- Added By Poonam 8 June 2024 -->


<div class="profile">
    <div class="profile-banner"> 

        <img src="{{isPicExists}}">
        <div class="upload-camera">
            <img src="../../../../../assets/images/camera.png" (click)="onClick()" width="70%" height="auto" style="cursor: pointer;">
            <input type="file" #fileUpload id="fileUpload" (click)="onClick()" name="fileUpload" accept="image/*"
                style="display:none;" />
        </div>
        <div class="photo">
            <img src="{{imageUrl}}/{{profileImage}}" (click)="profilePicChange()">
            <div class="upload-camera">
                <img src="../../../../../assets/images/camera.png" (click)="profilePicChange()"  style="   width: 50px;
                height: 50px;                
                top: 230px; cursor: pointer;">
                <input type="file" #profileUpload id="profileUpload" (click)="profilePicChange()" name="profileUpload" accept="image/*"
                style="display:none;" />
            </div>
        </div>


    </div>
</div>

<div class="general-setting profile-data">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="profile-inner">
                    <h1>General Account Settings
                        <button class="btn btn-primary" style="float: right; background-color: green; border:none"
                            (click)="copyMessage()">{{copyText}}</button>

                    </h1>
                    <div class="heading-border"></div>
                </div>

                <div class="account-details">
                    <form [formGroup]="profileForm">
                        <div class="profile-details">
                            <div class="profile-label">
                                <label>Name</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{
                                    this.userDetails.fullName}}</p>
                                <p *ngIf="isEdit"> <input type="text" placeholder="fullName" name="fullName"
                                        formControlName="fullName"></p>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                                <label>About</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{
                                    this.userDetails.about}}</p>
                                <p *ngIf="isEdit"> <input type="text" placeholder="About yourself" name="about"
                                        formControlName="about" [ngValue]="about"></p>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                                <label>I identify as</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit"> {{
                                    this.userDetails.gender}}</p>
                                <p *ngIf="isEdit"> <select name="gender" formControlName="gender">
                                        <option value="">Choose your gender</option>
                                        <option *ngFor="let gender of genderList" [ngValue]="gender">
                                            {{gender}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                                <label>Email</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{
                                    this.userDetails.email}}</p>
                                <p *ngIf="isEdit"> <input type="text" placeholder="email" name="email"
                                        formControlName="email"></p>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                                <label>City</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{
                                    this.userDetails.city}}</p>
                                <p *ngIf="isEdit"> <input type="text" placeholder="City" name="city"
                                        formControlName="city"></p>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                                <label>State</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.state}}</p>
                                <p *ngIf="isEdit"> <select name="state" formControlName="state">
                                        <option value="">Choose your State</option>
                                        <option *ngFor="let state of states" [ngValue]="state.name">
                                            {{ state.name }}
                                        </option>
                                    </select>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                                <label>Country</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit"> {{
                                    this.userDetails.country}}</p>
                                <p *ngIf="isEdit"> <select name="country" formControlName="country"
                                        (change)="onCountryChange($event.target.value)">
                                        <option value="">Choose your Country</option>
                                        <option *ngFor="let country of countryList" [ngValue]="country.name">
                                            {{country.name}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">
                            <div class="profile-label">
                                <label>Age</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.age}}</p>
                                <p *ngIf="isEdit"> <select name="age" formControlName="age">
                                        <option value="">Choose your age</option>
                                        <option *ngFor="let age of ageList" [ngValue]="age">
                                            {{age}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">
                            <div class="profile-label">
                                <label>Occupation</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.occupation}}</p>
                                <p *ngIf="isEdit"> <input type="text" placeholder="Occupation" name="occupation"
                                        formControlName="occupation" [ngValue]="occupation"></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">
                            <div class="profile-label">
                                <label>Education level</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.education_level}}</p>
                                <p *ngIf="isEdit"> <select name="education_level" formControlName="education_level">
                                        <option value="">Choose your Education</option>
                                        <option *ngFor="let education_level of educationLevelList"
                                            [ngValue]="education_level">
                                            {{education_level}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">
                            <div class="profile-label">
                                <label>Personality</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.personality}}</p>
                                <p *ngIf="isEdit"> <select name="personality" formControlName="personality">
                                        <option value="">Choose your personality</option>
                                        <option *ngFor="let personality of personalityList" [ngValue]="personality">
                                            {{personality}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">
                            <div class="profile-label">
                                <label>Looking for</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.looking_for}}</p>
                                <p *ngIf="isEdit"> <select name="looking_for" formControlName="looking_for">
                                        <option value="">Choose your looking for</option>
                                        <option *ngFor="let looking_for of lookingForList" [ngValue]="looking_for">
                                            {{looking_for}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">
                            <div class="profile-label">
                                <label>Interests and hobbies</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{
                                    this.userDetails.interests}}</p>
                                <p *ngIf="isEdit"> <input type="text" placeholder="Interests and Hobbies"
                                        name="interests" formControlName="interests" [ngValue]="interests"></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">

                            <div class="profile-label">
                                <label>Exercise habits</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.exercise_habits}}</p>
                                <p *ngIf="isEdit"> <select name="exercise_habits" formControlName="exercise_habits">
                                        <option value="">Choose your exercise habits</option>
                                        <option *ngFor="let exercise_habits of exerciseHabitsList"
                                            [ngValue]="exercise_habits">
                                            {{exercise_habits}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">
                            <div class="profile-label">
                                <label>Alcohol use</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.alcohol_use}}</p>
                                <p *ngIf="isEdit"> <select name="alcohol_use" formControlName="alcohol_use">
                                        <option value="">Choose your Alcohol use</option>
                                        <option *ngFor="let alcohol_use of alcoholUseList" [ngValue]="alcohol_use">
                                            {{alcohol_use}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">
                            <div class="profile-label">
                                <label>Drug use</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.drug_use}}</p>
                                <p *ngIf="isEdit"> <select name="drug_use" formControlName="drug_use">
                                        <option value="">Choose your Drug use</option>
                                        <option *ngFor="let drug_use of drugUseList" [ngValue]="drug_use">
                                            {{drug_use}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">
                            <div class="profile-label">
                                <label>Political views</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.political_views}}</p>
                                <p *ngIf="isEdit"> <select name="political_views" formControlName="political_views">
                                        <option value="">Choose your Political views</option>
                                        <option *ngFor="let political_views of politicalViewsList"
                                            [ngValue]="political_views">
                                            {{political_views}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details" *ngIf="this.userType === '1'">
                            <div class="profile-label">
                                <label>Religion</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.religion}}</p>
                                <p *ngIf="isEdit"> <select name="religion" formControlName="religion">
                                        <option value="">Choose your Religion</option>
                                        <option *ngFor="let religion of religionList" [ngValue]="religion">
                                            {{religion}}
                                        </option>
                                    </select></p>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                                <label>Phone Number</label>
                            </div>
                            <div class="profile-name">
                                <p *ngIf="!isEdit">{{this.userDetails.phoneNumber}}</p>
                                <p *ngIf="isEdit"> <input type="text" maxlength="10" placeholder="Phone Number"
                                        name="phoneNumber" formControlName="phoneNumber" [ngValue]="phoneNumber"></p>
                            </div>
                        </div>

                        <div class="profile-details">
                            <div class="profile-label">
                            </div>
                            <div class="profile-name">
                            </div>
                            <div class="profile-button">
                                <p><a (click)="editProfile()" *ngIf="!isEdit"><i class="fa fa-pencil"
                                            aria-hidden="true"></i> Edit</a>
                                </p>
                                <p *ngIf="isEdit"><a (click)="updateProfile()" [disabled]="!profileForm.valid"><i
                                            class="fa fa-floppy-o" aria-hidden="true"></i> Save</a>
                                    <a (click)="resetForm()" style="margin-left:20px"><i class="fa fa-times"
                                            aria-hidden="true"></i>
                                        Cancel</a>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Section to display top 4 images -->
<div class="general-setting">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="top-images-container"
                    *ngIf="topimagesandvideodetails.topFourImage && topimagesandvideodetails.topFourImage.length > 0">
                    <!-- <h1>Top 4 Images</h1> -->

                    <div class="profile-inner">
                        <h1>Top 4 Images </h1>
                        <div class="heading-border"></div>
                    </div>
                    <div class="top-images">
                        <div *ngFor="let image of topimagesandvideodetails.topFourImage">
                            <img src="{{image}}" alt="Top Image" class="top-image-thumbnail">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Section to display top 4 images -->
<div class="general-setting">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="top-images-container" *ngIf="topimagesandvideodetails.userVideo!=' '">
                    <div class="profile-inner">
                        <h1>Top Video</h1>
                        <div class="heading-border"></div>
                    </div>
                    <div class="top-images">
                        <video controls autoplay muted loop>
                            <source src="{{topimagesandvideodetails.userVideo}}" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="change-background">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="heading">
                    <h1>Post</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="people-all-comment">
                    <div *ngFor="let item of myPosts;let i = index">
                        <div class="person-post">
                            <p><img src="{{imageUrl}}/{{profileImage}}"></p>
                            <p>{{item.postAuthor.fullName}}
                                <span style="display: inline;"
                                    *ngIf="item.taggedFriendList &&  item.taggedFriendList.length>0">
                                    is
                                    with
                                    <span *ngFor="let tagItme of item.taggedFriendList" style="display: inline;">
                                        <span *ngIf="tagItme.tagAuthor" style="display: inline;">
                                            {{tagItme.tagAuthor.fullName}}</span></span>

                                </span>
                                <span style="display: inline;" *ngIf="item.isShared">

                                    <span style="display: inline;">
                                        shared {{item.sharedPostOwner}} post</span>

                                </span>


                                <span>{{ item.createdAt | date :'h:mm a dd-MMM-yy'
                                    }}</span>
                            </p>
                            <p class="dropdown-container">
                                <a (click)="toggleClass(previewClass, i)">
                                    <i class="fa fa-ellipsis-v delete" aria-hidden="true"></i>
                                </a>
                                <span *ngIf="previewClass === 'as'" class="dropdown-menu as">
                                    <a (click)="openEditDialog(item._id, i)">Edit Post</a>
                                    <a (click)="deletePost(item._id, i)">Delete Post</a>
                                </span>
                                <span *ngIf="previewClass === 'ash' && clickIndex === i" class="dropdown-menu ash">
                                    <a (click)="openEditDialog(item._id, i)">Edit Post</a>
                                    <a (click)="deletePost(item._id, i)">Delete Post</a>
                                </span>
                            </p>
                        </div>
                        <!-- Dialog template -->
                        <ng-template #editPostDialog let-dialogRef>
                            <a class="close-button" style="cursor: pointer;" (click)="closeComment()"><i
                                    class="fa fa-close" style="color: #0041c1"></i></a>
                            <h1 mat-dialog-title>Edit Post</h1>
                            <div mat-dialog-content>
                                <form>
                                    <mat-form-field appearance="fill" class="full-width">
                                        <textarea matInput placeholder="Post content"
                                            [(ngModel)]="selectedPost.postTitle" name="content"></textarea>

                                        <!-- Display existing photo if present -->
                                        <div *ngIf="selectedPost.imageURL.thumbnail || tempImageUrl"
                                            class="existing-media">
                                            <img *ngIf="tempImageUrl; else existingImage"
                                                [(ngModel)]="selectedPost.imageURL.thumbnail" [src]="tempImageUrl"
                                                alt="Current Image" class="current-image" />
                                            <ng-template #existingImage>
                                                <img [src]="imageUrl + '/' + selectedPost.imageURL.thumbnail"
                                                    [(ngModel)]="selectedPost.imageURL.thumbnail" alt="Current Image"
                                                    class="current-image" />
                                            </ng-template>
                                        </div>

                                        <!-- Display existing or temporarily uploaded video -->
                                        <div *ngIf="selectedPost.videoThumbnailUrl.thumbnail || tempVideoUrl"
                                            class="existing-media">
                                            <video width="100" height="100" controls class="current-video">
                                                <source *ngIf="tempVideoUrl; else existingVideo" [src]="tempVideoUrl"
                                                    type="video/mp4"
                                                    [(ngModel)]="selectedPost.videoThumbnailUrl.thumbnail" />
                                                <ng-template #existingVideo>
                                                    <source
                                                        [src]="imageUrl + '/' + selectedPost.videoThumbnailUrl.thumbnail"
                                                        [(ngModel)]="selectedPost.videoThumbnailUrl.thumbnail"
                                                        type="video/mp4" />
                                                </ng-template>
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>

                                    </mat-form-field>
                                    <div style="display: flex; justify-content: space-around;">
                                        <button mat-icon-button color="primary" (click)="uploadFile('image')"
                                            style="border: none;">
                                            <i class="fas fa-photo-video" style="color: green;"></i>
                                            <span>Photo</span>
                                        </button>

                                        <button mat-icon-button color="primary" (click)="uploadFile('video')"
                                            style="border: none;">
                                            <i class="fas fa-video" style="color: green;"></i>
                                            <span>Video</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div mat-dialog-actions>
                                <button mat-raised-button color="primary" class="update-button"
                                    [disabled]="isButtonDisabled" (click)="onSaveClick(dialogRef)">Update</button>
                            </div>
                        </ng-template>
                        <!-- Dialog template -->

                        <div class="latest-post" *ngIf="!item.sensitive">
                            <p>{{item.postContent}}</p>
                            <p *ngIf="item.imageURL.original !== null"><img class="timelineImage"
                                    src="{{imageUrl}}/{{item.imageURL.original}}">
                            <p *ngIf="item.videoURL !== null"> <video width="420" height="345" controls>
                                    <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                                </video>
                            </p>
                        </div>

                        <div class="latest-post" *ngIf="item.sensitive">
                            <p>{{item.postContent}}</p>
                            <p *ngIf="item.imageURL.original !== null && clickBlurIndex !== i"><img
                                    class="blurPost timelineImage" src="{{imageUrl}}/{{item.imageURL.original}}">
                            </p>

                            <p *ngIf="item.imageURL.original !== null && clickBlurIndex === i"><img
                                    class="timelineImage" src="{{imageUrl}}/{{item.imageURL.original}}">
                            </p>
                            <p *ngIf="item.videoURL !== null &&  clickBlurIndex!== i">
                                <video width="420" height="345" controls class="blurPost">
                                    <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                                </video>
                            </p>
                            <p *ngIf="item.videoURL !== null &&  clickBlurIndex === i"> <video width="420" height="345"
                                    controls>
                                    <source src="{{imageUrl}}/{{item.videoURL}}" type="video/mp4">
                                </video>
                            </p>
                            <p>This post is bad content.
                                <strong> <a style="cursor: pointer;" (click)="removeBlur(i)">See this
                                        post</a></strong>
                            </p>
                        </div>
                        <div class="likecomment-section">
                            <div class="like-part">
                                <p><span><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></span> {{item.totalLike}}
                                    <span class="ml-4"><i class="fa fa-thumbs-down" aria-hidden="true"></i></span>
                                    {{item.totalDislike}}
                                </p>
                            </div>
                            <div class="comment-part">
                                <p>{{item.totalComment}} Comments</p>
                            </div>
                        </div>
                        <div class="share">
                            <div class="like-part">
                                <p *ngIf="item.isLike" style=" color: white; cursor: pointer;"
                                    (click)="UpdateLikeStatus(item._id,item.isLike,i)"><i class="fa fa-thumbs-o-up"
                                        aria-hidden="true"></i>
                                    Like</p>
                                <p *ngIf="!item.isLike" style=" color: grey; cursor: pointer;"
                                    (click)="UpdateLikeStatus(item._id,item.isLike,i)"><i class="fa fa-thumbs-o-up"
                                        aria-hidden="true"></i>
                                    Like</p>
                            </div>
                            <div class="like-part">
                                <p *ngIf="item.isDislike" style=" color: white; cursor: pointer;"
                                    (click)="UpdateDislikeStatus(item._id,item.isDislike,i)"><i
                                        class="fa fa-thumbs-down" aria-hidden="true" style="cursor: pointer;"></i>
                                    Dislike</p>
                                <p *ngIf="!item.isDislike" style=" color: grey; cursor: pointer;"
                                    (click)="UpdateDislikeStatus(item._id,item.isDislike,i)"><i
                                        class="fa fa-thumbs-down" aria-hidden="true" style="cursor: pointer;"></i>
                                    Dislike</p>
                            </div>

                            <div class="comment-part">
                                <p style="cursor: pointer;" (click)="openDialog(item._id,item,i,'Friend')"><i
                                        class="fa fa-comment-o" aria-hidden="true"></i>
                                    Comment</p>
                            </div>
                            <div class="comment-part">
                                <p style="cursor: pointer;" (click)="openShareDialog(item)"><i class="fa fa-share"
                                        aria-hidden="true"></i> Share
                                </p>
                            </div>
                        </div>
                        <div class="public-comments-main">
                            <div *ngFor="let comment of item.Comments">
                                <div class="public-comments">
                                    <div class="public-img">
                                        <img src="{{imageUrl}}/{{comment.commentAuthor.imageURL.original}}">
                                    </div>
                                    <div class="publiccommentInner">
                                        <h5>{{comment.commentAuthor.fullName}}</h5>
                                        <p class="comment">{{comment.commentText}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    </div>
</div>
</div>