import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/api/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { DataService } from '../../public/service/data.service';
import { environment } from 'src/environments/environment';
import { RequestMessageComponent } from '../request-message/request-message.component';

@Component({
  selector: 'app-randomsuggestion',
  templateUrl: './randomsuggestion.component.html',
  styleUrls: ['./randomsuggestion.component.css'],
})
export class RandomsuggestionComponent implements OnInit {
  randomSuggestion: [];
  title: string;
  public imageUrl: string;
  userDetails: any;
  showLoader: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dataService: DataService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private notifyService: NotificationService,
    private dialogRef: MatDialogRef<RandomsuggestionComponent>,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.title = 'Friend Suggestion';
    this.userDetails = this.authService.getloggedUserDetails();
    if (this.userDetails && this.userDetails.accessToken) {
      this.getRandomFriendSuggestions();
    }
    this.imageUrl = environment.rearGuardImageUri;
  }

  ngOnInit(): void {}

  AddFriend(item: any) {
    const dialogRef = this.dialog.open(RequestMessageComponent, {
      height: '300px',
      width: '600px',
      autoFocus: false,
      disableClose: false,
      data: item,
    });
  }

  public getRandomFriendSuggestions() {
    this.randomSuggestion = [];
    this.showLoader = true;
    this.dataService.randomSuggestions().subscribe((result: any) => {
      if (result && result.length > 0) {
        this.randomSuggestion = result;
      }
      this.showLoader = false;
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }

  openProfile(item) {
    // Converts the route into a string that can be used
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/profile/${item._id}`])
    );

    window.open(url, '_blank');
  }
}
