import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/api/auth.service';
import { DataService } from '../../public/service/data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateGroupComponent } from './create-group/create-group.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { CreatePrivateGroupComponent } from './create-private-group/create-private-group.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css'],
})
export class GroupComponent implements OnInit {
  public groupSuggestion: any;
  public groupSuggestionAll: any[];
  public userGroups: any;
  public userGroupsAll: any[];
  public userDetails: any;
  public showLoader: boolean;
  public isUserGroup: boolean;

  public selectGroup: string= 'All';

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.userDetails = this.authService.getloggedUserDetails();
    this.getGroupsByUserId();
  }
  ngOnInit(): void {}

  getGroupsSuggestion() {
    this.showLoader = true;
    this.dataService.getGroups().subscribe((result: any) => {
      if (result && result.length > 0) {
        this.groupSuggestion = result;
        this.groupSuggestionAll = result;
        this.selectGroups(this.selectGroup);
      } else {
        this.groupSuggestion = [];
      }
      this.showLoader = false;
    });
  }
  getGroupsByUserId() {
    this.showLoader = true;
    this.isUserGroup = true;
    this.dataService
      .getGroupsByUserId(this.userDetails._id)
      .subscribe((result: any) => {
        if (result && result.length > 0) {
          this.userGroups = result;
          this.userGroupsAll = result;
          this.selectGroups(this.selectGroup);
        } else {
          this.userGroups = [];
        }
        this.showLoader = false;
      });
  }
  getjoinGroupList() {
    this.isUserGroup = false;
    this.getGroupsSuggestion();
  }

  EditGroup(userGroup, event){
    event.stopPropagation();
    const dialogRef = this.dialog.open(EditGroupComponent, {
      height: '390px',
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: userGroup
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getGroupsByUserId();
      }
    });
  }

  createGroup() {
    const dialogRef = this.dialog.open(CreateGroupComponent, {
      height: '390px',
      width: '500px',
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getGroupsByUserId();
      }
    });
  }
  createPrivateGroup() {
    const dialogRef = this.dialog.open(CreatePrivateGroupComponent, {
      height: '390px',
      width: '500px',
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getGroupsByUserId();
      }
    });
  }
  joinRequest(id, index) {
    const json = {
      authorization: this.userDetails.accessToken,
      groupId: id,
      status: 'pending',
    };
    this.dataService.submitGroupRequest(json).subscribe((result: any) => {
      this.groupSuggestion[index].status = 'pending';
    });
  }
  
  joinRequestWithPayment(id, index) {
    
    this.router.navigateByUrl('/paymentgroup/' + id);
  }

  selectGroups(sel){
    if(sel == 'Public'){
      if(this.userGroupsAll){
        this.userGroups = (this.userGroupsAll).filter((g) => { return g.type != 'Private Group' });
      }

      if(this.groupSuggestionAll){
        this.groupSuggestion = (this.groupSuggestionAll).filter((g) => { return g.type != 'Private Group' });
      }
    }
    else if(sel == 'Private'){
      if(this.userGroupsAll){
        this.userGroups = (this.userGroupsAll).filter((g) => { return g.type == 'Private Group' });
      }
      if(this.groupSuggestionAll){
        this.groupSuggestion = (this.groupSuggestionAll).filter((g) => { return g.type == 'Private Group' });
      }
    }
    else{
      this.userGroups = this.userGroupsAll;
      this.groupSuggestion = this.groupSuggestionAll;
    }
    this.selectGroup = sel;
  }
  cancelRequest(id, index) {
    const json = {
      authorization: this.userDetails.accessToken,
      groupId: id,
      status: 'cancel',
    };
    this.dataService.submitGroupRequest(json).subscribe((result: any) => {
      this.groupSuggestion[index].status = 'cancel';
    });
  }
  navigateToDashboard(id, data) {
    console.log(arguments);
    localStorage.setItem('groupData', JSON.stringify(data));
    this.router.navigateByUrl('/group/' + id);
  }

  navigateToVideo(userGroup, event) {
    console.log(arguments);
    event.stopPropagation();
    console.log(userGroup);
    if(userGroup.ExtraElements && userGroup.ExtraElements.isActive){
      this.router.navigateByUrl('/video/groupcall-join-'+userGroup._id);

    }
    else{
        this.router.navigateByUrl('/video/groupcall-'+userGroup._id);
    }
  }
}
