import { Component, Inject, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/api/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from 'src/environments/environment';
import { FriendRequest } from '../../models/friend.model';
import { DataService } from '../../public/service/data.service';
import { CommentComponent } from '../comment/comment.component';
import { ShareComponent } from '../share/share.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {
  @ViewChild('editPostDialog') editPostDialog!: TemplateRef<any>;
  @ViewChild('myPublicElement', { static: true }) MyPublicElement: ElementRef;
  @ViewChild('myFriendElement', { static: true }) MyFriendElement: ElementRef;

  post:any;
  postIndex: any=null;
  imageUrl: string;
  postDetail: any;
  commentDetail: any;
  userDetails: any;
  comment: string;
  index: number;
  clickBlurIndex: number;
  selectedPost: any = {};
  inViewportChange: Subject<boolean>;
  public friendPostList: any;
  public publicPostList: any;
  public isFriendPost: boolean;
  public taggedFriend: any;
  public taggedList: any;
  public friendsList: any;
  public pageIndex: number = 0;
  containsBannedWords: boolean = false;
  updatedComment: string;
  commentDialogOpen = false;
  editDialogRef: MatDialogRef<any>;
  editDialogOpen = false;
  imageSrc: string;
  format: string;
  preview: string;
  uploadedFile: any;
  postContent: string;
  showLoader: boolean;
  previewClass: string;
  clickIndex: number;
  postDisabled: boolean;
  skipCount: number;
  limitCount: number;
  isScrolling: boolean;
  publicPostCount: number;
  skipFriendCount: number;
  limitFriendCount: number;
  isFriendScrolling: boolean;
  friendPostCount: number;
  randomSuggestionLoaded: boolean;
  randomAdvertisement: [];
  displayedAdvertisement: any = {};
  newpostId:any="";
  uploadPost: boolean;
  userId: any;
 

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router:Router,
    private dataService: DataService,
    private notifyService: NotificationService,
    public dialog: MatDialog
  ) {
    this.imageUrl = environment.rearGuardImageUri;
    
    this.comment = '';
    this.index = 0;
    this.clickBlurIndex = -1;
    // 
    this.randomSuggestionLoaded = false;
    this.postDisabled = false;
    this.taggedFriend = [];
    this.friendPostList = [];
    this.publicPostList = [];
    this.isFriendPost = true;
    this.friendsList = [];
    this.previewClass = 'as';
    this.skipCount = 0;
    this.limitCount = 5;
    this.isScrolling = false;
    this.skipFriendCount = 0;
    this.limitFriendCount = 5;
    this.isFriendScrolling = false;
    this.imageUrl = environment.rearGuardImageUri;
    this.userDetails = this.authService.getloggedUserDetails();
    this.route.paramMap.subscribe(params => {
      const postId = params.get('id');
      this.getPostDetails(postId);
    });
    if (this.userDetails && this.userDetails.accessToken) {
      this.getPublicPost();
    }

    
  }

  ngOnInit(): void {
    
  }


  getPostDetails(postId: any): void {
    this.dataService.getPostDetail(postId).subscribe(response => {
      this.postDetail = response.data.postDetails;

    });
    this.dataService.getPostComment(postId).subscribe(result=>{
      this.commentDetail = result.data.comments;
      console.log(this.commentDetail);
    })
  }

  closePost() {
    this.router.navigate(['dashboard']);
  }

  onKeydown(event) {
    event.preventDefault();
  }

  removeBlur() {
    this.clickBlurIndex = 0;
  }

  saveComment(comment) {
    this.dataService.postComment(this.postDetail._id, comment).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Comment added successfully.', '');
        const res = result.data.comment;
        res.commentAuthor = {
          email: this.userDetails.email,
          fullName: this.userDetails.fullName,
          _id: this.userDetails._id,
          imageURL: this.userDetails.imageURL
        };
        this.commentDetail.push(res);
        this.comment = '';

        const searchFriend = new FriendRequest();
        searchFriend.limit = 0;
        searchFriend.skip = 0;
        searchFriend.status = 'Accepted';
        this.dataService.getFriendRequest(searchFriend).subscribe((result: any) => {
          if (result && result.message === 'Success') {
            const userEmails = result.data.friendList.map(d => d.email);

            const emailJson = {
              email: userEmails,
              message: `${this.userDetails.fullName} has commented on ${this.postDetail.postAuthor.fullName} post (${this.postDetail.postTitle}).`,
              Subject: 'Friendlinq Comment Notification',
            };
            this.dataService.notifyUserByEmail(emailJson).subscribe((result: any) => {
              console.log('Post Created', result);
            });

            result.data.friendList.forEach(element => {
              const json = {
                isDeleted: false,
                isView: false,
                isRead: false,
                senderId: this.userDetails._id,
                receiverId: element._id,
                textMessage: `${this.userDetails.fullName} has commented on ${this.postDetail.postAuthor.fullName} post (${this.postDetail.postTitle}).`,
                notificationType: 'Friends_Post_Comment',
              };
              this.dataService.notifyUser(json).subscribe((result: any) => {
                console.log('Post Created', result);
              });
            });
          }
        });
      }
    });
  }

  closeComment() {
    const json = {
      comment: this.commentDetail.length > 3 ? this.commentDetail.slice(-2).reverse() : this.commentDetail.reverse(),
      index: this.index,
      count: this.commentDetail.length
    };
    this.dialog.closeAll();
  }

  deleteComment(id, index) {
    this.dataService.deleteComment(id).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        this.notifyService.showSuccess('Comment deleted successfully.', '');
        this.commentDetail.splice(index, 1);
      }
    });
  }

// -----------------------------
  UpdateLikeStatus(id, status, index, type, postData) {
    let likeStatus: boolean = !status;
    
    this.dataService.postLike(id, likeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        if (type === 'Friend') {
          if (likeStatus === false) {
            this.friendPostList[index].totalLike =
              this.friendPostList[index].totalLike === 0
                ? 0
                : this.friendPostList[index].totalLike - 1;
          } else {
            this.friendPostList[index].totalLike++;

            if (this.friendPostList[index].isDislike) {
              this.friendPostList[index].totalDislike =
                this.friendPostList[index].totalDislike === 0
                  ? 0
                  : this.friendPostList[index].totalDislike - 1;
              this.friendPostList[index].isDislike = false;
            }
          }
          this.friendPostList[index].isLike = likeStatus;
        }
        if (type === 'Public') {
          if (likeStatus === false) {
            this.publicPostList[index].totalLike =
              this.publicPostList[index].totalLike === 0
                ? 0
                : this.publicPostList[index].totalLike - 1;
          } else {
            this.publicPostList[index].totalLike++;

            if (this.publicPostList[index].isDislike) {
              this.publicPostList[index].totalDislike =
                this.publicPostList[index].totalDislike === 0
                  ? 0
                  : this.publicPostList[index].totalDislike - 1;
              this.publicPostList[index].isDislike = false;
            }
          }
          this.publicPostList[index].isLike = likeStatus;
        }
        const searchFriend = new FriendRequest();
        searchFriend.limit = 0;
        searchFriend.skip = 0;
        searchFriend.status = 'Accepted';
        this.dataService
          .getFriendRequest(searchFriend)
          .subscribe((result: any) => {
            if (result && result.message === 'Success') {
              const userEmails = result.data.friendList.map((d) => d.email);
              const emailJson = {
                email: userEmails,
                message:
                  this.userDetails.fullName +
                  ' has ' +
                  (likeStatus === true ? ' liked ' : ' unliked ') +
                  (postData.postAuthor.fullName === 'undefined'
                    ? postData.Author.fullName
                    : postData.postAuthor.fullName) +
                  ' post(' +
                  postData.postTitle +
                  ').',
                Subject: 'Friendlinq Like Notification',
              };
              this.dataService
                .notifyUserByEmail(emailJson)
                .subscribe((result: any) => {
                  console.log('Post Created', result);
                });
              result.data.friendList.forEach((element) => {
                const json = {
                  isDeleted: false,
                  isView: false,
                  isRead: false,
                  senderId: this.userDetails._id,
                  receiverId: element._id,
                  textMessage:
                    this.userDetails.fullName +
                    ' has ' +
                    (likeStatus === true ? ' liked ' : ' unliked ') +
                    (postData.postAuthor.fullName === 'undefined'
                      ? postData.Author.fullName
                      : postData.postAuthor.fullName) +
                    ' post(' +
                    postData.postTitle +
                    ').',
                  notificationType: 'Friends_Post_Like',
                };
                this.dataService.notifyUser(json).subscribe((result: any) => {
                });
              });
            }
          });
      }
    });
  }

  UpdateDislikeStatus(id, status, index, type, postData) {
    let dislikeStatus: boolean = !status;
    this.dataService.postDislike(id, dislikeStatus).subscribe((result: any) => {
      if (result && result.message === 'Success') {
        if (type === 'Friend') {
          if (dislikeStatus === false) {
            this.friendPostList[index].totalDislike =
              this.friendPostList[index].totalDislike === 0
                ? 0
                : this.friendPostList[index].totalDislike - 1;
          } else {
            this.friendPostList[index].totalDislike++;

            if (this.friendPostList[index].isLike) {
              this.friendPostList[index].totalLike =
                this.friendPostList[index].totalLike === 0
                  ? 0
                  : this.friendPostList[index].totalLike - 1;
              this.friendPostList[index].isLike = false;
            }

          }
          this.friendPostList[index].isDislike = dislikeStatus;
        }
        if (type === 'Public') {
          if (dislikeStatus === false) {
            this.publicPostList[index].totalDislike =
              this.publicPostList[index].totalDislike === 0
                ? 0
                : this.publicPostList[index].totalDislike - 1;
          } else {
            this.publicPostList[index].totalDislike++;

            if (this.publicPostList[index].isLike) {
              this.publicPostList[index].totalLike =
                this.publicPostList[index].totalLike === 0
                  ? 0
                  : this.publicPostList[index].totalLike - 1;
              this.publicPostList[index].isLike = false;
            }
          }
          this.publicPostList[index].isDislike = dislikeStatus;
        }
        const searchFriend = new FriendRequest();
        searchFriend.limit = 0;
        searchFriend.skip = 0;
        searchFriend.status = 'Accepted';
        this.dataService
          .getFriendRequest(searchFriend)
          .subscribe((result: any) => {
            if (result && result.message === 'Success') {
              const userEmails = result.data.friendList.map((d) => d.email);
              const emailJson = {
                email: userEmails,
                message:
                  this.userDetails.fullName +
                  ' has ' +
                  (dislikeStatus === true ? ' liked ' : ' unliked ') +
                  (postData.postAuthor.fullName === 'undefined'
                    ? postData.Author.fullName
                    : postData.postAuthor.fullName) +
                  ' post(' +
                  postData.postTitle +
                  ').',
                Subject: 'Friendlinq Like Notification',
              };
              this.dataService
                .notifyUserByEmail(emailJson)
                .subscribe((result: any) => {
                  console.log('Post Created', result);
                });
              result.data.friendList.forEach((element) => {
                const json = {
                  isDeleted: false,
                  isView: false,
                  isRead: false,
                  senderId: this.userDetails._id,
                  receiverId: element._id,
                  textMessage:
                    this.userDetails.fullName +
                    ' has ' +
                    (dislikeStatus === true ? ' liked ' : ' unliked ') +
                    (postData.postAuthor.fullName === 'undefined'
                      ? postData.Author.fullName
                      : postData.postAuthor.fullName) +
                    ' post(' +
                    postData.postTitle +
                    ').',
                  notificationType: 'Friends_Post_Like',
                };
                this.dataService.notifyUser(json).subscribe((result: any) => {
                });
              });
            }
          });
      }
    });
  }

  getFriendsPost() {
    this.publicPostList = [];
    this.showLoader = true;
    this.skipFriendCount = 0;
    this.limitFriendCount = 5;
    this.dataService
      .getFriendPost(this.skipFriendCount, this.limitFriendCount)
      .subscribe((result: any) => {
        if (result && result.message === 'Success') {
          if (result.data.totalMyPost > 0) {
            this.friendPostCount = result.data.totalMyPost;
            this.friendPostList = result.data.myPost;
            this.showLoader = false;
            this.isFriendPost = true;
            this.isFriendScrolling = false;
          } else {
            this.showLoader = false;
          }
        }
      });
  }
  // ---------------------------

   getPublicPost() {
    this.friendPostList = [];
    this.skipCount = 0;
    this.limitCount = 5;
    this.showLoader = true;
    this.dataService
      .getPublicPost(this.skipCount, this.limitCount)
      .subscribe((result: any) => {

        if (result && result.message === 'Success') {
          if (result.data.totalMyPost > 0) {
            this.publicPostCount = result.data.totalMyPost;
            this.publicPostList = result.data.myPost;
            this.isFriendPost = false;
            this.showLoader = false;
            this.isScrolling = false;
            this.getPost(this.postDetail.postAuthor._id , this.postDetail._id);
          }
        }
      });
  }


  openShareDialog(item) {
    const json = { request: item, isGroup: false };
    const dialogRef = this.dialog.open(ShareComponent, {
      height: '400px',
      width: '700px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom',
      data: json,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'Friend_Only') {
        this.getFriendsPost();
      } else {
        this.getPublicPost();
      }
    });
  }
// -------------------
  getPost(id:any, postId: any) {
      this.post = this.publicPostList.find(post=> post.postAuthor === id);
      console.log(this.post);
      this.postIndex = this.publicPostList.findIndex(post=> post.postAuthor === id);
      if(this.post === undefined){
        this.notifyService.showError('Post is deleted.', '');
        this.router.navigate(["notification"])
      }

  }
  getFriendPost(id:any, postId: any) {
    this.dataService.getAnotherUsersPost(id).subscribe(result=>{
      this.post = result.data.myPost.find(post=> post._id === postId);
      this.postIndex = result.data.myPost.findIndex(post=> post._id === postId);
      if(this.post === undefined){
        this.notifyService.showError('Post is deleted.', '');
        this.router.navigate(["notification"])
      }
    })
  }
  // ------------------
  
  openEditDialog(commentId: string, index: number): void {
    this.selectedPost = this.commentDetail[index];
    this.editDialogRef = this.dialog.open(this.editPostDialog);
  }

  closeCommentEdit(): void {
    if (this.editDialogRef) {
      this.editDialogRef.close();
    }
  }

  onSaveClick(id, data) {
    const formData = new FormData();
    formData.append('postId', this.postDetail._id);
    formData.append('commentId', this.selectedPost._id);
    formData.append('commentText', this.selectedPost.commentText);

    this.dataService.UpdatePostComment(formData).subscribe((result: any) => {
      this.notifyService.showSuccess('Comment updated successfully.', '');
      const commentIndex = this.commentDetail.findIndex(comment => comment._id === this.selectedPost._id);
      if (commentIndex !== -1) {
        this.commentDetail[commentIndex].commentText = this.selectedPost.commentText;
      }
      this.closeEditDialog();
    });
  }

  closeEditDialog() {
    if (this.editDialogRef) {
      this.editDialogRef.close();
      this.editDialogOpen = false;
    }
  }
}

// Dialog component
@Component({
  selector: 'edit-comment-dialog',
  template: `
    <h1 mat-dialog-title>Edit Comment</h1>
    <div mat-dialog-content>
      <form>
        <mat-form-field appearance="fill" class="full-width">
          <textarea matInput placeholder="Comment content" [(ngModel)]="data.commentText" name="content"></textarea>
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="onSaveClick()">Update</button>
    </div>
  `
})
export class EditCommentDialog {
  constructor(
    public dialogRef: MatDialogRef<EditCommentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onSaveClick(): void {
    this.dialogRef.close(this.data);
  }
}
