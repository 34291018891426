export class SharePost {
  authorization: string;
  imageUrl: string;
  imageThumbnail: string;
  videoUrl: string;
  videoThumbnail: string;
  postTitle: string;
  postContent: string;
  postType: string;
  postOwnerId: string;
  postOwnerName: string;
  sharedBy: string;
}
export class ShareGroupPost {
  authorization: string;
  imageUrl: string;
  imageThumbnail: string;
  videoUrl: string;
  videoThumbnail: string;
  postTitle: string;
  postContent: string;
  groupId: string;
  postOwnerId: string;
  postOwnerName: string;
  sharedBy: string;
}
export class ReportPost {
  authorization: string;
  postId: string;
  userId: string;
  sensitive: boolean;
  admin: boolean;
}
