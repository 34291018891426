import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { PublicContainerComponent } from './components/public/container/public-container/public-container.component';
import { ForgotPasswordComponent } from './components/public/forgot-password/forgot-password.component';
import { LoginComponent } from './components/public/login/login.component';
import { SignupComponent } from './components/public/signup/signup.component';
import { PrivateContainerComponent } from './components/private/container/private-container/private-container.component';
import { DashboardComponent } from './components/private/dashboard/dashboard.component';
import { ProfileComponent } from './components/private/profile/profile.component';
import { AddFriendComponent } from './components/private/add-friend/add-friend.component';
import { FriendProfileComponent } from './components/private/friend-profile/friend-profile.component';
import { ChatComponent } from './components/private/chat/chat.component';
import { VideoComponent } from './components/private/video/video.component';
import { AudioComponent } from './components/private/audio/audio.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CommentComponent } from './components/private/comment/comment.component';
import { ShareComponent } from './components/private/share/share.component';
import { CallingComponent } from './components/private/calling/calling.component';
import { GroupComponent } from './components/private/group/group.component';
import { CreateGroupComponent } from './components/private/group/create-group/create-group.component';
import { GroupDashboardComponent } from './components/private/group/group-dashboard/group-dashboard.component';
import { GroupRequestComponent } from './components/private/group/group-request/group-request.component';
import { GroupMemberComponent } from './components/private/group/group-member/group-member.component';
import { NotificationComponent } from './components/private/notification/notification.component';
import { TagfriendComponent } from './components/private/tagfriend/tagfriend.component';
import { RandomCallComponent } from './components/private/random-call/random-call.component';
import { PortfolioComponent } from './components/private/portfolio/portfolio.component';
import { PortfolioModalComponent } from './components/private/portfolio-modal/portfolio-modal.component';
import { RandomsuggestionComponent } from './components/private/randomsuggestion/randomsuggestion.component';
import { RequestMessageComponent } from './components/private/request-message/request-message.component';
import { ChatListComponent } from './components/private/chat-list/chat-list.component';
import { InViewportModule } from 'ng-in-viewport';
import { CreatePrivateGroupComponent } from './components/private/group/create-private-group/create-private-group.component';
import { EditGroupComponent } from './components/private/group/edit-group/edit-group.component';
import { PaymentGroupComponent } from './components/private/group/payment-group/payment-group.component';
import { MainPageComponent } from './components/public/main-page/main-page.component';
import { PrivacyPolicyComponent } from './components/public/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/public/terms-and-conditions/terms-and-conditions.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { MessagingService } from './shared/services/messaging.service';
import { ScheduleMeetingComponent } from './components/private/schedule-meeting/schedule-meeting.component';
import { enableProdMode } from '@angular/core';
import { inviteuserComponent } from './components/private/inviteuser/inviteuser.component';
import { PhotoVideoUploadComponent } from './components/private/photo-video-upload/photo-video-upload.component';
import { SignupImageComponent } from './components/public/signup/signup-image/signup-image.component';
import { EditCommentDialog, PostComponent } from './components/private/post/post.component';
import { SignupMainComponent } from './components/public/signup-main/signup-main.component';
import { SupportComponent } from './components/private/options/support/support.component';
import { SettingComponent } from './components/private/options/setting/setting.component';


enableProdMode();

@NgModule({
  declarations: [
    AppComponent,
    PublicContainerComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    PrivateContainerComponent,
    DashboardComponent,
    ProfileComponent,
    AddFriendComponent,
    FriendProfileComponent,
    ChatComponent,
    VideoComponent,
    AudioComponent,
    CommentComponent,
    ShareComponent,
    CallingComponent,
    GroupComponent,
    CreateGroupComponent,
    GroupDashboardComponent,
    GroupRequestComponent,
    GroupMemberComponent,
    NotificationComponent,
    TagfriendComponent,
    RandomCallComponent,
    PortfolioComponent,
    PortfolioModalComponent,
    RandomsuggestionComponent,
    RequestMessageComponent,
    ChatListComponent,
    CreatePrivateGroupComponent,
    EditGroupComponent,
    PaymentGroupComponent,
    MainPageComponent,
    ScheduleMeetingComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    inviteuserComponent,
    PhotoVideoUploadComponent,
    SignupImageComponent,
    PostComponent,
    EditCommentDialog,
    SignupMainComponent,
    SupportComponent,
    SettingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SocialLoginModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule,
    InViewportModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  entryComponents: [EditCommentDialog],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '448774167684-l4u6hip14o5v0dmi7prqe14bg3nehg8i.apps.googleusercontent.com'
            ),
          }
        ],
      } as SocialAuthServiceConfig,
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    MessagingService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
